import React from "react";
import Form from "react-bootstrap/Form";
import "../../style/ProductDetail.scss";
import { useNavigate } from "react-router-dom";
import { languageAction } from "../../store/Language";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

function Language() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [chosenLanguage, setChosenLanguage] = useState(language.language);

  const onLanguageChangeHandler = () => {
    if (chosenLanguage === "Japanese") {
      dispatch(languageAction.japanese());
    } else if (chosenLanguage === "English") {
      dispatch(languageAction.english());
    }
    navigate('/account')
  };

  return (
    <>
      <div className="container-fluid">
        <div>
          <svg
            onClick={() => navigate("/news")}
            xmlns="http://www.w3.org/2000/svg"
            style={{ float: "right" }}
            width="28"
            height="28"
            fill="currentColor"
            className="bi bi-x-lg my-2"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <div
          className="col-12 px-2 d-flex flex-column"
          style={{ minHeight: "90vh" }}
        >
          <div className="my-3"><h4>Language</h4></div>
          <div className="mb-3">
            <Form.Check
              type="radio"
              name="language"
              checked={chosenLanguage === "Japanese" ? true : false}
              label={`Japanese`}
              onChange={() => setChosenLanguage("Japanese")}
            />

            <Form.Check
              type="radio"
              name="language"
              label={`English`}
              checked={chosenLanguage === "English" ? true : false}
              onChange={() => setChosenLanguage("English")}
            />
          </div>
          <div className="col-12 my-4">
            <button type="button" className="btn btn-secondary mx-2 col-5">
              Cancel
            </button>
            <button
              type="button"
              onClick={onLanguageChangeHandler}
              className="btn btn-dark mx-2 col-5"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Language;
