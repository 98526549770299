import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AppWrapper from '../../components/wrappers/AppWrapper';
import { TokenCategoryNavRow } from '../../components/common/FlexNavRow'
import { getData, deleteData } from "../../services/fetch";
import { SwalCorrect, SwalWrong } from "../../components/common/Swal";
import { BottomModal } from "../../components/common/BottomModal";
import { strings, translations } from "../../services/localization";
import { OnLoading } from '../../components/common/OnLoading'
import { TokenType, getTokenImageByContractAddress } from '../../components/common/enum'
import { appContext } from '../../context/appContext'
import { Web3authContext } from '../../context/web3authContext'
import { getWalletAddress } from '../../services/storage'
import RPC from "../../components/common/web3RPC";
import { ReceiveIcon, SendIcon, BackIcon, ChevronRightIcon, SwapIcon } from '../../components/common/Icon';
import WalletAddressRow from './WalletAddressRow';
import Pagination from '../../components/common/pagination';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { calculateBN, cutNumber } from '../../components/common/commonFnc';
import { getTypeByContractAddress } from '../../components/common/enum';

const WalletTokenDetail = (props) => {
    const navigate = useNavigate();
    const { token_id } = useParams();
    const [item, setItem] = useState({});
    const { timeZone } = useContext(appContext);
    const [last_tx_id, setLast_tx_id] = useState(-1);
    const [txHistory, setTxHistory] = useState([]);
    const [showBottomModal, setShowBottomModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const tokenDetailPage = translations.tokenDetailPage;
    const language = translations._language;
    const walletTableLabel = translations.walletTableLabel;
    const [balance, setBalance] = useState('-')
    const [showFullBalance, setShowFullBalance] = useState(false)
    const [id_token, setIdToken] = useState(null);
    const [page, setPage] = useState(1);
    const [last_page, setLast_page] = useState(1);
    const [total, setTotal] = useState(0);

    const { wallet_address, provider, init, loginIfNotLogin, setWallet_address } = useContext(Web3authContext);
    const isPC = useMediaQuery({ minWidth: 992 })
    const limitPerPage = 10

    useEffect(() => {
        if (language && timeZone) {
            fetchData();
        }
    }, [language, timeZone])

    useEffect(() => {
        getBalance(item.contract_address)
    });

    useEffect(() => {
        if (txHistory.length > 0) {
            const _pageCount = Math.ceil(txHistory.length / limitPerPage)
            setLast_page(_pageCount)
        }
    }, [txHistory])

    const getBalance = async (contract_address) => {
        if (!contract_address) {
            return;
        }
        const rpc = new RPC(provider);
        const _balance = await rpc.getCustomTokenBalance(contract_address, getWalletAddress(), 0, true);
        setBalance(_balance)
    };

    const fetchData = async () => {
        setLoading(true);
        const rpc = new RPC();
        const datas = await getData(`user-tokens`);
        const item = (datas?.data?.response ?? []).find((i) => i.id === Number(token_id))
        item.token_name = await rpc.getName(item.contract_address);
        item.token_type_id = getTypeByContractAddress(item.contract_address);
        item.token_image = getTokenImageByContractAddress(item.contract_address)
        setItem(item || {});

        const categories = []
        const wallet_address = getWalletAddress()
        setWallet_address(wallet_address)
        categories.push(item.contract_address)

        getBalance(item.contract_address)

        const response = await getData(`tokens/transactions?wallet_address=${wallet_address}&categories=${JSON.stringify(categories)}&limit=10&last_tx_id=${last_tx_id}&timezone=${timeZone}`);
        if (response.ok) {
            let _txHistory = response.data?.data || []
            _txHistory = _txHistory.map((i) => ({ ...i, amount: calculateBN(i.amount, i.decimal, 2) || 0 }))
            setTxHistory(_txHistory)
            setTotal(response.data?.total)
        }
        setLoading(false);
    }

    const onChangeTokenType = (type) => {
        if (type === 'send') {
            navigate(`/wallet/send/address`, { state: { token: { token_type_id: TokenType.Token }, token_type_id: TokenType.Token, user_token_id: token_id } })
        } else if (type === 'receive') {
            navigate('/wallet/receive')
        } else if (type === 'delete') {
            setShowBottomModal(true)
        }
    }

    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    const deleteCustomToken = async () => {
        setShowBottomModal(false)
        setLoading(true);
        const response = await deleteData(`user-tokens/${token_id}`)
        if (response.ok) {
            const text = `${item.token_name} ${strings.formatString(tokenDetailPage.〇〇_Token_has_been_successfully_deleted)}`
            SwalCorrect.fire({
                text
            }).then(() => {
                navigate("/wallet/token");
            });
        } else {
            setLoading(false);
            SwalWrong.fire({
                title: "Error",
                text: response.data.message,
            });
        }
    }

    const handleClickAnimaBalance = () => {
        if (Number(item.token_type_id) === Number(TokenType.Anima)) {
            setShowFullBalance(show => !show);
        }
    }

    const currentTransactions = txHistory?.filter((i, j) => j >= (page - 1) * limitPerPage && j < page * limitPerPage) || []

    const paginationCount = limitPerPage * page

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onSubmitHandler={deleteCustomToken}
                    onShow={showBottomModal}
                    label={`${strings.formatString(tokenDetailPage.Are_you_sure_you_want_to_delete_〇〇_Token)} ${item.token_name}?`}
                    cancelBtnLabel="yes"
                    BackBtnLabel="no"
                    notNavigate
                />
            }
            <AppWrapper title={strings.formatString(tokenDetailPage.My_Wallet)}>
                {
                    loading ? <OnLoading /> :
                        isPC ?
                            <div className='wallet-token'>
                                <WalletAddressRow />

                                <div className='d-flex align-items-center my-4'>
                                    <div onClick={() => navigate(-1)}>
                                        <BackIcon />
                                    </div>
                                    <span className='fs-35 text-uppercase m-l-20' style={{ fontFamily: 'Kardust Condensed' }}>
                                        {item.token_name}
                                    </span>
                                    <span className='fs-30 text-uppercase m-l-40 pointer' style={{ fontFamily: 'Kardust Condensed' }} onClick={handleClickAnimaBalance}>
                                        {Number(item.token_type_id) === Number(TokenType.Anima) ? showFullBalance ? (balance || 0) : cutNumber(balance || 0, 6) : (balance || 0)} TKN
                                    </span>
                                </div>

                                <div className='d-flex mb-5 align-items-center'>
                                    <TokenCategoryNavRow onChangeTokenType={onChangeTokenType} />
                                </div>

                                <div className='mb-3'>
                                    <span className='primary-color p-r-15'>
                                        {tokenDetailPage.transaction}
                                    </span>
                                    <span>{`${paginationCount < total ? paginationCount : total} / ${total}`}</span>
                                </div>

                                <div className='table-responsive my-4'>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr className='text-center transaction-table-header'>
                                                <th style={{ textAlign: 'left', minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelAction)}
                                                </th>
                                                <th style={{ minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelDate)}
                                                </th>
                                                <th style={{ minWidth: 217 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelTxID)}
                                                </th>
                                                <th style={{ minWidth: 161 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelFrom)}
                                                    {strings.formatString(walletTableLabel.labelSlash)}
                                                    {strings.formatString(walletTableLabel.labelTo)}
                                                </th>
                                                <th style={{ minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelAmount)}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    <div className='divider mb-3 mt-3'></div>
                                                </td>
                                            </tr >
                                            {
                                                currentTransactions?.length > 0 && currentTransactions.map((item, index) =>
                                                    <tr
                                                        className='text-center transaction-table-body jp-f-notosan'
                                                        // onClick={() => {
                                                        //     Swal.fire({
                                                        //         html: (<WalletTransactionDetail wallet_address={wallet_address} transaction={item} />)
                                                        //     });
                                                        // }} 
                                                        key={index}
                                                    >
                                                        <td
                                                            className="table-row-data tx-ellipsis jp-f-notosan pe-2"
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            {wallet_address === item.from
                                                                ? <><SendIcon className="wallet-item-icon" /> {tokenDetailPage.Sent}</>
                                                                : <><ReceiveIcon className="wallet-item-icon" /> {tokenDetailPage.Received}</>
                                                            }
                                                        </td>
                                                        <td className="table-row-data jp-f-notosan pe-2">
                                                            {item.timestamp}
                                                        </td>
                                                        <td
                                                            className="table-row-data tx-ellipsis jp-f-notosan pe-2"
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            {item.tx_hash}
                                                        </td>
                                                        <td className="table-row-data jp-f-notosan pe-2" style={{ textAlign: 'left' }}>
                                                            {wallet_address === item.from
                                                                ? item.to
                                                                :
                                                                item.from
                                                            }
                                                        </td>
                                                        <td className="table-row-data p-0 jp-f-notosan">{item.amount || 1}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Pagination
                                            onPageChange={page => setPage(page)}
                                            page={page} last_page={last_page} total={total}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className='token-image mb-3 text-center'>{item.token_image}</div>
                                <div className='fs-25 f-condensed text-uppercase text-center m-t-15'>
                                    {item.token_name}
                                </div>
                                <div className='fs-20 f-condensed text-uppercase text-center m-t-15'>
                                    {cutNumber(balance || 0, 6) || 0} TKN
                                </div>

                                <div className='d-flex my-5'
                                    style={{ justifyContent: 'space-evenly' }}
                                >
                                    <div className='d-flex flex-column align-items-center'>
                                        <SendIcon />
                                        <span>Send</span>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <ReceiveIcon />
                                        <span>Receive</span>
                                    </div>
                                    <div className='d-flex flex-column align-items-center icon-opacity'>
                                        <SwapIcon className='wh-25-h-39' />
                                        <span>Swap</span>
                                    </div>
                                </div>

                                <div className='divider mt-5 mb-2'></div>

                                {
                                    txHistory?.length > 0 && txHistory.map((item, index) =>
                                        <div key={'tx-' + index} className="card token-card my-3">
                                            <div className="card-body d-flex align-items-center">
                                                <div className='col-12 py-2'>
                                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            {
                                                                wallet_address === item.from ?
                                                                    <div className='d-flex'>
                                                                        <SendIcon className="purple-icon" />
                                                                        <div className='d-flex flex-column m-l-15'>
                                                                            <span className='token-name mb-0 f-regular fs-16 primary-color'>
                                                                                Sent
                                                                            </span>
                                                                            <span className='m-t-5 fs-12'>
                                                                                {moment(item.timestamp).format("MMM DD, \xa0HH:mm")}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='d-flex'>
                                                                        <ReceiveIcon className="purple-icon" />
                                                                        <div className='d-flex flex-column m-l-15'>
                                                                            <span className='token-name mb-0 f-regular fs-16 primary-color'>
                                                                                Received
                                                                            </span>
                                                                            <span className='m-t-5 fs-12'>
                                                                                {moment(item.timestamp).format("MMM DD, \xa0HH:mm")}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className='token-price d-flex align-items-center fs-20'>
                                                            <span className='m-r-15'>
                                                                {item.amount > 0 ? item.amount : 1}
                                                            </span>

                                                            <ChevronRightIcon className="purple-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                }
            </AppWrapper>
        </>
    )
}

export default WalletTokenDetail
