import { Button } from "../../pages/squares/Button";
import Modal from './Modal'
import { translations } from '../../services/localization';
import { Avatar } from "../../pages/squares/SquareProfile";

export const SquarePointAlert = ({ opened, onClose, onSave, squareMaker, onSaveLoading, squarePoint }) => {
    const content = translations?.squareDetail;
    return (
        <Modal displayModal={opened} onClose={onClose}>
            <div className="d-flex justify-content-center align-items-center flex-column px-3" style={{ position: 'relative', zIndex: 1000 }}>
                <h1 className="p-0 m-0 fs-20 mb-4">{translations?.formatString(content?.generateArcana)}</h1>
                <Avatar src={squareMaker?.icon} />
                <span className='fs-20 text-nowrap my-1' style={{ margin: '20px 0' }}>{squarePoint}</span>
                <div className="mt-3 mb-4 text-center">{
                    translations.formatString(content?.squarePointAlert, squareMaker?.name)
                }</div>
                <Button
                    loading={onSaveLoading}
                    onClick={onSave}
                    active
                    className="w-100 fs-20 bordor-radius-15"
                    style={{ height: 55, marginBottom: 20 }}
                >
                    {translations.formatString(content?.createArcanaDialog?.okButton)}
                </Button>
                <Button
                    onClick={onClose}
                    className="w-100 fs-20 bordor-radius-15"
                    style={{ height: 55, marginBottom: 20 }}
                >
                    {translations.formatString(content?.createArcanaDialog?.cancelButton)}
                </Button>
            </div>
        </Modal>
    )
}