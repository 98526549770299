import React, {  } from 'react'
import { useNavigate } from 'react-router-dom'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { translations } from "../../services/localization";

const WalletSupport = () => {
    const navigate = useNavigate();
    const content = translations.walletSupport
    
    return (
        <AppWrapper title={translations.formatString(content.headerTitle)}>
            <div className='row' style={{ marginTop: 75 }}>
                <div className='col-12 text-center f-condensed'>
                    <span className='wallet-support-font'>{translations.formatString(content.notifyParagraph)}</span>
                    <div className='py-4'>
                        <div className='divider'></div>
                    </div>
                    <p className='wallet-support-mail'>info@anicana.org</p>
                </div>
            </div>
        </AppWrapper>
    )
}

export default WalletSupport
