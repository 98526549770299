import { useMediaQuery } from "react-responsive"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { strings, translations } from "../../services/localization"
import { BackGradientIcon } from "../../components/common/Icon"
import { ReactComponent as LevicaLogo } from '../../assets/images/levica-logo.svg';
import { FaEnvelope } from "react-icons/fa";
import { RxDoubleArrowRight } from "react-icons/rx"
import { LevicaFooter } from "../../components/common/Footer";
import { BsClock, BsFillTelephoneFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const LevicaExpirationList = () => {

    const isTbl = useMediaQuery({ minWidth: 768 })
    return (
        <div className={`f-condensed ${isTbl ? "flex-grow-1" : "mt-4"}`} style={{ minHeight: 400 }}>
            <div className={`${isTbl && "levica-container ms-auto d-none"}`}>
                <div className="d-flex flex-column">
                    <p className="text-center pb-3">Expiration dates of LEVICA as of</p>
                    <h4 className="text-center pb-4"><BsClock className="me-3" style={{ fontSize: "17px" }} />2023.08.03.</h4>

                    <div className="levica-scroll-container">
                        {
                            [...Array(16)].map((item, index) => (
                                <div key={`levica-list-${index}`}>
                                    <div className="d-flex justify-content-between px-4 levica-card" key={index}>
                                        <p className="m-0">2023.08.03 12:00</p>
                                        <p className="m-0">¥ 500,000</p>
                                    </div>
                                    <div className={`divider w-100 mx-auto ${isTbl ? "my-2" : "my-3"} `} />
                                </div>
                            )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const LevicaPage = () => {
    const [showExpirationList, setShowExpirationList] = useState(false);
    const levica = translations.levica;

    const navigate = useNavigate();
    const isTbl = useMediaQuery({ minWidth: 768 })
    const leviasFooter = translations.leviasFooter;

    const changePage = () => {
        setShowExpirationList(true)
    }

    const back = () => {
        if (showExpirationList) {
            setShowExpirationList(false)
        } else {
            navigate(-1)
        }
    }

    const handlePhone = () => {
        document.location.href = "tel:+0364023330";
    }

    const handleEmail = () => {
        window.location = "https://levias.co.jp/contact/";
        // window.open("..") for new window
    }

    return (
        <div className="levica my-3">
            <div className="d-flex justify-content-start align-items-center">
                <BackGradientIcon onClick={back} />
                <div className={`d-flex ${isTbl ? "justify-content-start ps-5" : "mx-auto"}`}>
                    <LevicaLogo style={{ width: isTbl ? "80px" : "100px" }} />
                </div>
            </div>

            {
                showExpirationList ? <LevicaExpirationList /> :
                    <div className={` pt-4 f-condensed`}>
                        <div className={`${!isTbl && "px-2"}`}>
                            <div className="d-flex flex">
                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div>
                                            <h4>{strings.formatString(levica.amount)}</h4>
                                        </div>
                                        <div className={`${isTbl && "mx-auto"}`}>
                                            <div className="d-flex align-items-center mb-1 d-none" onClick={changePage}>
                                                {!isTbl && (<><p className="m-0 fs-12">{strings.formatString(levica.expirationDate)}</p><RxDoubleArrowRight className="fs-16" /></>)}
                                            </div>
                                            <h4>¥ 16,000</h4>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isTbl && <LevicaExpirationList />
                                }

                            </div>
                            {!isTbl && <div className="divider mb-5 mt-4 w-100 mx-auto" />}
                            <div className={`d-flex ${isTbl ? " align-items-center mt-2" : "flex-column"}`}>
                                <div className={`me-4 ${!isTbl && "my-3"}`}>
                                    <h4 className="m-0">{strings.formatString(levica.contactUs)}</h4>
                                    {/* <p className="m-0">Inquiries about LEVICA</p> */}
                                </div>

                                {isTbl ? (
                                    <>
                                        <div className={`d-flex align-items-center me-4 ${!isTbl && "my-3"}`} onClick={handlePhone} role="button">
                                            <BsFillTelephoneFill className="ms-2 me-3 fs-24" />
                                            <div className="d-flex flex-column">
                                                <span className="m-0">{strings.formatString(levica.byPhone)}</span>
                                                <span className="fs-14">{`${strings.formatString(levica.onWeekdays)} 10:00 - 17:00`}</span>
                                            </div>
                                        </div>
                                        <div className={`d-flex align-items-center me-4 ${!isTbl && "my-3"}`} onClick={handleEmail} role="button">
                                            <FaEnvelope className="ms-2 me-3 fs-24" />
                                            <span>{strings.formatString(levica.byEmail)}</span>
                                        </div>
                                    </>
                                ) : (
                                    <>

                                        <div className={`d-flex align-items-center me-4 ${!isTbl && "my-3"}`}  onClick={handleEmail} role="button">
                                            <FaEnvelope className="ms-2 me-3 fs-24" />
                                            <span>{strings.formatString(levica.byEmail)}</span>
                                            <RxDoubleArrowRight className="ms-auto" style={{ width: 20, height: 20 }} />
                                        </div>
                                        <div className={`d-flex align-items-center me-4 ${!isTbl && "my-3"}`} onClick={handlePhone} role="button">
                                            <BsFillTelephoneFill className="ms-2 me-3 fs-24" />
                                            <div className="d-flex flex-column">
                                                <span>{strings.formatString(levica.byPhone)}</span>
                                                <span className="fs-14">{`${strings.formatString(levica.onWeekdays)} 10:00 - 17:00`}</span>
                                            </div>
                                            <RxDoubleArrowRight className="ms-auto" style={{ width: 20, height: 20 }} />
                                        </div>
                                    </>)
                                }

                            </div>
                        </div>

                        <div className="divider my-3 w-100 mx-auto divider-footer">
                            {
                                isTbl &&
                                <span className='fs-16 footer-line'>{strings.formatString(leviasFooter.LEVIAS_Inc_All_rights_reserved)}</span>
                            }
                        </div>

                        <LevicaFooter />
                    </div>
            }

        </div>
    )
}

const Levica = () => {
    const isPh = useMediaQuery({ minWidth: 576 })
    const content = translations.wallet;
    const metaTitle = 'マイウォレット｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <AppWrapper
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            enableAddtionalNav title={strings.formatString(content.headerTitle)}
            activeWallet
            offOverFlowX
            disableFooter={!isPh}>
            <LevicaPage />
        </AppWrapper>
    )
}

export default Levica