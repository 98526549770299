import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntersection } from "../../hooks/useIntersection";
import { OnLoading } from "../../components/common/OnLoading";
import styles from "../../style/drawChains.module.css";
import { BackIcon, PersonaIcon } from "../../components/common/Icon";
import { useFetch, useLoading } from "../../services/hook";
import { Button } from "../squares/Button";
import { SquareProfile } from "../squares/SquareProfile";

const DrawChains = () => {
    const { square_id } = useParams();
    const { data: drawchains, isLoading: drawchainsLoading } = useFetch(`drawchains?square_id=${square_id}`, true);
    const loading = useLoading([drawchainsLoading], true);

    const isPC = useMediaQuery({ minWidth: 992 })

    const { ref, entry } = useIntersection({
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    return (
        <>
            {loading ? (
                <div className="w-100 position relative">
                    <OnLoading inline marginAuto />
                </div>
            ) : (
                <>
                    <div className={`${styles.container} pt-0 position-relative`}>
                        {!entry?.isIntersecting && <DrawChainHeader />}
                        <div className={`w-100 mt-4 mb-4 ${isPC ? 'position-sticky' : ''}`} style={{ top: 0, zIndex: 2, isolation: 'isolate' }} ref={ref}>
                            <TitleWithBackButton />
                        </div>
                        <div className={`d-flex ${isPC ? '' : 'flex-column'} justify-content-center justify-content-sm-start p-x-17 p-x-lg-0 p-r-lg-17`} style={{ gap: isPC ? 24 : 10 }}>
                            {
                                drawchains != null && drawchains.map((drawchain) =>
                                    <DrawChainCard item={drawchain} square_id={square_id} key={drawchain.id} />
                                )
                            }
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export const TitleWithBackButton = ({ className = '' }) => {
    const navigate = useNavigate(-1);
    const { ref, entry } = useIntersection({
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });
    const isPC = useMediaQuery({ minWidth: 992 })
    return (
        <div className={`w-100 d-flex align-items-center ${entry?.isIntersecting ? '' : 'primary-bg-gradient'} ${className} py-2`} >
            <div className="w-100 position-absolute" style={{ height: 5, inset: 0, transform: 'translateY(-100%)' }} ref={ref}></div>
            <div className="position-relative w-100 d-flex">
                <BackIcon
                    className="position-absolute"
                    style={{ width: 21, height: 21, left: 10, top: '50%', transform: 'translateY(-50%)', marginInline: 16 }}
                    onClick={() => navigate(-1)} />
                <span className={`fs-${isPC ? 25 : 20} f-regular fw-600 primary-color`} style={{ marginLeft: 60, marginBottom: 3 }}>
                    Draw Chain
                </span>
            </div>
        </div>
    )
}

export const DrawChainHeader = ({ name, tailButton }) => {
    const navigate = useNavigate(-1);
    return (
        <div
            className='header-animation position-fixed w-100 d-flex align-items-center primary-bg-gradient justify-content-between py-2'
            style={{ minHeight: 97, top: 0, zIndex: 99, gap: 5 }}>
            <div className="position-relative w-100 d-flex flex-center">
                <BackIcon
                    style={{ width: 18, height: 18, marginLeft: 16 }}
                    onClick={() => navigate(-1)} />
                <span className={`fs-20 f-regular fw-600 primary-color text-nowrap text-ellipsis`} style={{ margin: '0 0 3px 15px', maxWidth: 170 }}>
                    {name ?? "Draw Chain"}
                </span>
                <div className="d-flex flex-center" style={{ marginInline: 'auto 16px' }}>
                    {tailButton}
                </div>
            </div>
        </div>
    )
}

export const DrawChainCard = ({ item, square_id, disabled }) => {
    const isSP = useMediaQuery({ maxWidth: 576 })
    const navigate = useNavigate();

    return (
        <div className={styles.card__wrapper} onClick={() => disabled || navigate(`/squares/${square_id}/draw-chains/${item.id}`)}>
            <div className={styles.card}>
                <div
                    className={`${styles.card__image} position-relative`}
                    style={{
                        backgroundImage: `url(${item.thumbnail_image_url})`,
                    }}
                >
                    {item.drawchain_category ? <Button
                        style={{ whiteSpace: "nowrap" }}
                        custom
                        className="draw-chain-btn py-0 px-3 fs-10 mt-2 ms-2"
                    >
                        {item.drawchain_category}
                    </Button> : null}
                    {item?.thumbnail_image_url ? null : <span className="position-absolute top-50 start-50 translate-middle text-center fs-10" style={{ color: 'white' }}>No Image Available</span>}
                </div>
                <div className="p-relative p-2 w-50 d-flex flex-column">
                    <h1 className={styles.card__title}>
                        {item.title}
                    </h1>
                    <div className={styles.date} style={{ opacity: (item.from_date && item.to_date) ? 1 : 0 }}>{item.from_date} - {item.to_date}</div>
                    <div
                        className={`${styles.card__body} multiline-ellipsis`}
                        dangerouslySetInnerHTML={{
                            __html: item.description
                        }}></div>
                    <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: 'auto', maxHeight: 80, gap: 10 }}>
                        <div style={isSP ? { width: '55%' } : {}}>
                            <span className={styles.persona__title}>
                                <div style={{ minWidth: 20 }}>
                                    <PersonaIcon className="purple-icon"
                                        style={{ width: 20, height: 16 }}
                                    />
                                </div>
                                Persona
                            </span>
                            <div className={`${styles.persona__description} multiline-ellipsis`}>
                                {item?.persona?.name}
                            </div>
                        </div>
                        <div className={styles.persona__image}></div>
                    </div>
                </div>
            </div>
            {item.persona?.image ? <img
                alt="Persona"
                className={`${styles.persona__image} ${styles.img} ${styles.float} ${styles.border}`}
                src={item.persona?.image}
            /> : <NoImage />}
        </div>
    );
};

const NoImage = () => (
    <div
        className={`${styles.persona__image} ${styles.float} ${styles.border} fs-10 text-white d-flex justify-content-center align-items-center`}
        style={{ backgroundColor: 'gray', filter: 'brightness(0.7)' }}>No Image</div>
)

export default DrawChains;
