import React, { useRef, useState } from 'react'
import PinInput from 'react-pin-input';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BackIcon } from '../../components/common/Icon';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { strings, translations } from '../../services/localization'

const WhatsCodeD = () => {

  const buttonlist = translations.buttonlist;
  const content = translations.codeD;

  const navigate = useNavigate();
  const { code_d_id } = useParams()

  return (
    <div className={`${code_d_id === '1' ? 'clip-left-bg-white' : code_d_id === '2' ? 'clip-left-bg-blue' : ''}`}>
    <AppWrapper 
      className="auth-container" 
      title="CODE-D" 
      pageid="CodeD"
      activeProfile
      isSpecCodeD={code_d_id === '1' || code_d_id === '2' ? true : false}
      disableBottomNav
      disableSidebar={code_d_id === '1' || code_d_id === '2' ? true : false}
      disableSearchbox={code_d_id === '1' || code_d_id === '2' ? true : false}
    >
      
      <div className={`${code_d_id === '1' ? 'code-d-w-wrapper' : code_d_id === '2' ? 'code-d-b-wrapper' : 'code-d-wrapper'} mx-3 mx-md-auto`}>
      <div className='d-flex mt-3 align-content-center' style={{lineHeight: '20px'}}>
        <div onClick={() => navigate(-1)} className="pointer">
          <BackIcon 
            className={`${code_d_id === '1' ? 'green-icon' : code_d_id === '2' ? 'white-icon' : 'purple-icon'}`} 
          />
        </div>
        <div className='fs-25 w-100 text-uppercase text-center jp-f-notosan'>
          {content.aboutTitle}
        </div>
      </div>
      <div className={`${code_d_id === '1' || code_d_id === '2' ? '' : 'divider'} mt-3 mb-5`}></div>

      <div className='px-1 about-content' style={{ textAlign: 'left' }}>
        {content.about.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
      
      <div 
        className='f-regular fs-18 text-center lh-lg my-5'
      >
        contact@anidrive.jp
      </div>

      <div className='mx-auto mt-5'>
       <span className='fs-14 '>
              Copyright
         &copy; ANIDRIVE Inc.
            </span>
        <br />
        <span className='fs-14 '>
              All rights reserved.
            </span>
      </div>
      </div>
    </AppWrapper>
    </div>
  )
}

export default WhatsCodeD
