import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { strings, translations } from "../../services/localization";
import { useMediaQuery } from 'react-responsive';

export const Footer = () => {
  const footerPage = translations.footerPage; // Usage: strings.formatString(footerPage.Contact)
  return (
    <div className='footer toggle-signout' style={{ marginTop: '80%' }}>
      <div className="divider mb-5 mt-3 w-100 mx-auto"></div>
      <div className={`${translations._language === 'jp' ? 'f-notosan' : 'f-regular'} flex-center links`}>
        <div className="center-block mb-5">
          <Link className='link' style={{ zIndex: 1 }} to={`/contact-us`}>
            {strings.formatString(footerPage.Contact)}
          </Link>
        </div>
        <div className='row w-100'>
          <div className='col-12 col-md-6 mb-5 text-center'>
            <Link className='link' style={{ zIndex: 1 }} to={`/terms`}>
              {strings.formatString(footerPage.Terms_Of_Use)}
            </Link>
          </div>
          <div className='col-12 col-md-6 mb-5 text-center'>
            <Link className='link' style={{ zIndex: 1 }} to={`/privacy`}>
              {strings.formatString(footerPage.Privacy_Policy)}
            </Link>
          </div>
          <div className='col-12 col-md-6 mb-5 text-center'>
            <Link className='link' style={{ zIndex: 1 }} to={`/cookies-policy`}>
              {strings.formatString(footerPage.Cookie_Policy)}
            </Link>
          </div>
        </div>
        <div className='row w-100'>
          <div className='col-12 col-md-6 mb-5 text-center'>
            <Link onClick={() => window.reload()} className='link' style={{ zIndex: 1 }} to={'/ANICANA_WP106_JP221118.pdf'}>
              {strings.formatString(footerPage.White_Paper)}
            </Link>
          </div>
          <div className='col-12 col-md-6 mb-5 text-center'>
            <Link onClick={() => window.open('https://anicana-technicalpaper.readthedocs.io/ja/latest/', '_blank')} className='link' style={{ zIndex: 1 }}>
              {strings.formatString(footerPage.Technical_Paper)}
            </Link>
          </div>
        </div>
      </div>
      <div className={`mt-3 copy-right ${translations._language === 'jp' ? 'f-notosan' : 'f-regular'} fs-20`}
        style={{ textAlign: "center" }}
      >
        <span>
          {strings.formatString(footerPage.Company)}
        </span>
      </div>
    </div>
  )
}

export const LeviasFooter = () => {
  const footerPage = translations.footerPage;
  const leviasFooter = translations.leviasFooter;
  return (
    <div className='levias-footer'>
      <div className='levias-mb-5'>
        <Link className='link mr-47' style={{ zIndex: 1 }} target={'_blank'} to={`/LEVIAS-Account-Terms-of-Use.pdf`}>{strings.formatString(footerPage.Terms_Of_Use)}</Link>
        <Link className='link' style={{ zIndex: 1 }} target={'_blank'} to={`/Levias-ID-Privacy-Policy.pdf`}>{strings.formatString(footerPage.Privacy_Policy)}</Link>
      </div>
      <div>
        <span>{strings.formatString(leviasFooter.LEVIAS_Inc_All_rights_reserved)}</span>
      </div>
    </div>
  )
}

export const LevicaFooter = () => {
  const isTbl = useMediaQuery({ minWidth: 768 })
  const leviasFooter = translations.leviasFooter;
  return (
    <div className='f-regular levica-footer'>
      {
        !isTbl &&
        <span className='fs-12'>{strings.formatString(leviasFooter.LEVIAS_Inc_All_rights_reserved)}</span>
      }
      <div className={`d-flex mt-3 flex-wrap`}>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/about/`}>
          {strings.formatString(leviasFooter.company)}
        </Link>
        <div className='divider ms-0 me-2'></div>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/levica/document/term-of-use.pdf`}>
          {strings.formatString(leviasFooter.termOfUse)}
        </Link>
        <div className='divider ms-0 me-2'></div>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/levica/document/privacy-policy.pdf`}>
          {strings.formatString(leviasFooter.privacyPolicy)}
        </Link>

        <div className='divider ms-0 me-2'></div>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/levica/document/merchant-agreement.pdf`}>
          {strings.formatString(leviasFooter.merchantAgreement)}
        </Link>
        <div className='divider ms-0 me-2'></div>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/levica/document/cooperatives-act.pdf`}>
          {strings.formatString(leviasFooter.paymentService)}
        </Link>
        <div className='divider ms-0 me-2'></div>
        <Link className='link fs-13 me-2' style={{ zIndex: 1 }} to={`https://levias.co.jp/levica/document/specified-commercial-transaction-act.pdf`}>
          {strings.formatString(leviasFooter.actOnTransition)}
        </Link>
      </div>
    </div>

  )
}
