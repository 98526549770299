import React, { useMemo, useState, useCallback, useContext,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_DOMAIN } from "../../config";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { SwalCorrect, SwalEmail, SwalWrong } from "../../components/common/Swal";
import { strings, translations } from "../../services/localization";
import { AuthContext } from "../../context/authContext";

import {
    ChangeEmailForm,
    ChangeEmailVerifyForm,
    ChangeMobileForm,
    MobileVerifyForm,
    PasswordResetForm,
    SetPasswordResetForm,
    TwoFactorVerifyForm,
} from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {BottomModal, LeviasBottomModal} from "../../components/common/BottomModal";
import { getToken } from "../../services/storage";
import { postData } from "../../services/fetch";

function ChangeEmail() {
    const content = translations.changeMobileForm;
    const buttonlist = translations.buttonlist;
    const messagelist = translations.message;
    const changeEmailVerificationPage = translations.changeEmailVerificationPage; // Usage: strings.formatString(changeEmailVerificationPage.change_Email_Address)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext)
    // const [emailValidated, setEmailValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const language = useSelector((state) => state.language.language);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false)
    const [confirmEmail, setConfirmEmail] = useState("")
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [phoneCodeValid, setPhoneCodeValid] = useState(false);
    const [emailCodeValid, setEmailCodeValid] = useState(false);

    const [smsVerificationCode, setSmsVerificationCode] = useState("");
    const [emailVerificationCode, setEmailVerificationCode] = useState("");
    const [idmsRegistrationStep, setIdmsRegistrationStep] = useState("setEmail");
    const [onShow, setOnShow] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState("");
    const [phoneValid, setPhoneValid] = useState(false)
    const [transactionId, setTransactionId] = useState('');

    const onShowHandler = (type, status) => {
        if (type === "email") {
            setEmail(status);
        } else if (type === "confirm-email") {
            setConfirmEmail(status);
        }
    };

    const passwordValidator = (value, result) => {
        if (value === "password") {
            if (result.length > 7) {
                if (result === confirmPassword) {
                    setPasswordValid(true);
                }
            }
        } else if (value === "con-password") {
            if (result.length > 7) {
                if (password === result) {
                    setPasswordValid(true);
                }
            }
        }
    };

    const textChangeHandler = (type, value) => {
        if (type === "email") {
            setEmail(value);
        } else if (type === "confirm-email") {
            setConfirmEmail(value);
        } else if (type === "verify-email-code") {
            if (value.length > 5) {
                setEmailCodeValid(true);
            }
            else {
                setEmailCodeValid(false);
            }
            setEmailVerificationCode(value);
        }
    };

    const onEmailVerifyHandler = () => {
        setIdmsRegistrationStep('phoneVerify')
    };

    const onShowModalHandler = () => {
        setOnShow(true);
    };

    const onCloseHandler = () => {
        setOnShow(false);
    };

    const emailValidator = () => {
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)&&/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(confirmEmail)&&email===confirmEmail){
            setEmailValid(true);
        }else{
            setEmailValid(false)
        }
    };

    useEffect(()=>{
        emailValidator()
    },[email, confirmEmail])

    const sendEmailHandler = async () => {
        var token = await getToken()
        const url = API_DOMAIN + 'accounts/change-email'
        axios
            .post(
                url,
                {
                    email,
                    confirm_email: confirmEmail
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data) {
                    if (response.data.response.errorMessage) {
                        setLoading(false);
                        let message = response.data.response.errorMessage;
                        SwalWrong.fire({
                            title: "Error",
                            text: message,
                        });
                        // } else if (response.data.message) {
                        //   setLoading(false);
                        //   SwalCorrect.fire({
                        //     title: `You have been successfully signed out`,
                        //   }).then((result) => {

                        //   });
                    } else if (response.data.response.transactionId) {
                        setTransactionId(response.data.response.transactionId);
                        setIdmsRegistrationStep('emailVerify')
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.data && error.response.data.message) {
                    SwalWrong.fire({
                        title: "Error",
                        // text: response.data.message,
                        text: error.response.data.message
                    });
                }
                else if (error.response.data && error.response.data.reason) {
                    SwalWrong.fire({
                        title: "Error",
                        // text: response.data.message,
                        text: error.response.data.reason
                    });
                }
            })
    }

    const nextStepHandler = async () => {
        if (idmsRegistrationStep === 'setEmail') {
            sendEmailHandler()
        }
        else if (idmsRegistrationStep === 'emailVerify') {
            var response = await postData(`accounts/verify-change-email`, {
                email,
                otp: emailVerificationCode,
                transactionId
            });
            if (response.ok) {
                if (response.data) {
                  if (response.data.message) {
                    SwalCorrect.fire({
                        text: strings.formatString(changeEmailVerificationPage.Your_email_address_has_been_successfully_changed),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setUser(response.data.user)
                            navigate("/account_detail");
                        }
                    });
                  }
                }
              } else {
                setLoading(false);
                SwalWrong.fire({
                  title: "Error",
                  text: response.data.message,
                });
              }
        }
    }

    let body = (
        <>
            <ChangeEmailForm
                onShowHandler={onShowHandler}
                emailValid={emailValid}
                setEmailValid={setEmailValid}
                onEmailVerifyHandler={onEmailVerifyHandler}
                textChangeHandler={textChangeHandler}
                phoneNumber={phoneNumber}
                phoneValid={phoneValid}
                email={email}
                confirmEmail={confirmEmail}
                smsVerificationCode={smsVerificationCode}
                nextStepHandler={nextStepHandler}
            />
        </>
    );
    if (idmsRegistrationStep === "emailVerify") {
        body = (<ChangeEmailVerifyForm
            setIdmsRegistrationStep={setIdmsRegistrationStep}
            onShowModalHandler={onShowModalHandler}
            phoneCodeValid={phoneCodeValid}
            emailCodeValid={emailCodeValid}
            smsVerificationCode={smsVerificationCode}
            emailVerificationCode={emailVerificationCode}
            nextStepHandler={nextStepHandler}
            textChangeHandler={textChangeHandler}
        />)
    } else if (idmsRegistrationStep === "setPassword") {
        body = (
            <SetPasswordResetForm
                showPassword={showPassword}
                showConfirmPassword={showConfirmPassword}
                onShowHandler={onShowHandler}
                onShowModalHandler={onShowModalHandler}
                nextStepHandler={nextStepHandler}
                passwordValid={passwordValid}
                password={password}
                confirmPassword={confirmPassword}
                textChangeHandler={textChangeHandler}
            />
        )
    }

    return (
        <>
            <BottomModal onCloseHandler={onCloseHandler} onShow={onShow} label={strings.formatString(changeEmailVerificationPage.Cancel_message)} />
            <AppWrapper title={strings.formatString(content.headerTitle)} isForm isCenter hasInput>
                <Container fluid className="d-flex flex-row auth-container">
                    {body}
                </Container>
            </AppWrapper>
        </>
    );
}

export default ChangeEmail;
