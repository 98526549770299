import React, {
  useState,
  useMemo,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import "../../style/Auth.scss";
import { API_DOMAIN } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { PasswordResetForm } from "../../components/auth/AuthForm";
import { AuthForm } from "../../components/auth/AuthForm";
import { AuthBackground } from "../../components/auth/AuthForm";
import { LoginForm } from "../../components/auth/AuthForm";
import { MemberRegistrationStep } from "../../components/auth/AuthForm";
import { IDMSRegistrationForm } from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import { getData } from "../../services/fetch";
import AppWrapper from "../../components/wrappers/AppWrapper";
import Swal from "../../components/common/Swal";
import {BottomModal, LeviasBottomModal} from "../../components/common/BottomModal";
import Header from "../../layouts/mainlayout/Header";
import BottomNav from "../../layouts/mainlayout/BottomNav";

export default function Auth() {
  useEffect(() => {
    navigate('/login/idms')
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname
  const language = useSelector((state) => state.language.language);

  const [currentForm, setCurrentForm] = useState("auth");

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailVerificationCode, setEmailVerificationCode] = useState("");

  const [password, setPassword] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsVerificationCode, setSmsVerificationCode] = useState("");

  const [selectMember, setSelectMember] = useState("");

  const [idmsRegistrationStep, setIdmsRegistrationStep] = useState(1);

  const textChangeHandler = (type, value) => {
    if (type === "email") {
      setEmail(value);
    } else if (type === "confirm-email") {
      setConfirmEmail(value);
    } else if (type === "password") {
      setPassword(value);
    } else if (type === "phone") {
      setPhoneNumber(value);
    } else if (type === "verify-email-code") {
      setEmailVerificationCode(value);
    } else if (type === "verify-phone-code") {
      setSmsVerificationCode(value);
    }
  };

  const onRegisterEmailHandler = useCallback(() => {
    const url = API_DOMAIN + "accounts/register-email";
    axios
      .post(url, {
        email,
        confirmEmail,
      })
      .then((response) => {
        let { data } = response.data.data;
        if (response.data) {
          // setGames(data);
        } else {
          //error
        }
      })
      .catch((error) => {
        //error
      });
  }, [email, confirmEmail]);

  const onVerifyEmailHandler = useCallback(() => {
    const url = API_DOMAIN + "accounts/verify-email";
    axios
      .post(url, {
        otp: emailVerificationCode,
      })
      .then((response) => {
        let { data } = response.data.data;
        if (response.data) {
          // setGames(data);
        } else {
          //error
        }
      })
      .catch((error) => {
        //error
      });
  }, [emailVerificationCode]);

  const onVerifyPhoneHandler = useCallback(() => {
    const url = API_DOMAIN + "accounts/verify-mobile";
    axios
      .post(url, {
        otp: smsVerificationCode,
      })
      .then((response) => {
        let { data } = response.data.data;
        if (response.data) {
          // setGames(data);
        } else {
          //error
        }
      })
      .catch((error) => {
        //error
      });
  }, [smsVerificationCode]);

  const onRegisterPhoneHandler = useCallback(async () => {
    const url = API_DOMAIN + "accounts/register-mobile";
    axios
      .post(url, {
        mobile: phoneNumber,
      })
      .then((response) => {
        let { data } = response.data.data;
        if (response.data) {
          // setGames(data);
        } else {

          //error
        }
      })
      .catch((error) => {
        //error
      });
  }, [phoneNumber]);



  const selectMemberHandler = useCallback(
    (registerType) => {
      setSelectMember(registerType);
      if (selectMember.length > 0) {
        setCurrentForm("IDMS-registration");
      }
    },
    [selectMember]
  );

  const nextStepHandler = useCallback(() => {
    if (idmsRegistrationStep < 5) {
      setIdmsRegistrationStep((prev) => prev + 1);
    }
    if (idmsRegistrationStep === 1) {
      onRegisterEmailHandler();
    } else if (idmsRegistrationStep === 2) {
      onVerifyEmailHandler();
    } else if (idmsRegistrationStep === 3) {
      onRegisterPhoneHandler();
    } else if (idmsRegistrationStep === 4) {
      onVerifyPhoneHandler();
    }
  }, [
    idmsRegistrationStep,
    email,
    confirmEmail,
    emailVerificationCode,
    phoneNumber,
    smsVerificationCode,
  ]);

  const loginHandler = useCallback(() => {
    navigate('/login/idms')
  }, []);

  const memberRegisterHandler = useCallback(() => {
    navigate('/register/idms')
  }, []);

  const forgetPasswordHandler = useCallback(() => {
    setCurrentForm("password-reset");
  }, []);

  const idmsRegistrationHandler = useCallback(() => {
    navigate('/register/idms')
  }, []);

  const body = useMemo(() => {
    return (
      <>
        <AuthForm
          loginHandler={loginHandler}
          memberRegisterHandler={memberRegisterHandler}
          idmsRegistrationHandler={idmsRegistrationHandler}
          language={language}
        />
      </>
    );

  }, []);

  return (
    <>
      <div className=''>
        <Header />
        {body}
        <BottomNav />
      </div>
    </>
  );
}
