/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { copyToClipboard, formatNumber, isJP, calculateBN, swalText } from '../../components/common/commonFnc'
import { CopyIcon, ReceiveIcon, SendIcon, HistoryIcon, ManaAddressIcon, AbsorbIcon, ChainSingleIcon } from '../../components/common/Icon'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { getWalletAddress, removeBidInfo } from '../../services/storage'
import { Web3authContext } from '../../context/web3authContext'
import { strings, translations } from "../../services/localization";
import { useMediaQuery } from 'react-responsive'
import { getTypeByContractAddress, TokenType, orderCurrency } from '../../components/common/enum'
import RPC from "../../components/common/web3RPC";
import { appContext } from '../../context/appContext'
import { getData, postData } from '../../services/fetch'
import '../../style/wallet-style.css'
import '../../style/app/Wallet.scss'
import { ReactComponent as LineBreak } from '../../assets/icons/line-break.svg';
import WalletTransactionDetail from './WalletTransactionDetail';
import Swal, { SwalSettings } from '../../components/common/Swal'
import Receive from './Receive'
import { OnLoading } from "../../components/common/OnLoading";
import WalletAddressRow from './WalletAddressRow'
import { Toast } from 'react-bootstrap'
import BodyEnd from '../../components/common/BodyEnd'
import { useDidUpdateEffect } from '../../services/hook'
import { Btn } from '../../components/common/Button'

const Wallet = (props) => {
  const navigate = useNavigate();
  const { timeZone } = useContext(appContext)
  const { wallet_address, setWallet_address } = useContext(Web3authContext);
  const [tokenItems, setTokenItems] = useState([]);
  const MANA_PAGE_LIMIT = 10
  const [balances, setBalances] = useState({
    token: null,
    levica: null,
  })
  const [leviasChainAddress, setLeviasChainAddress] = useState('')
  const isPC = useMediaQuery({ minWidth: 992 })
  const [active, setActive] = useState(props?.activeItem);
  const limit = 10;
  const [mLeft, setMLeft] = useState(0);
  const [mRight, setMRight] = useState(0);
  const [showBadge, setShowBadge] = useState(true);
  const [manaStartIndex, setManaStartIndex] = useState(0)
  const [manaloadLimit, setManaLoadLimit] = useState(MANA_PAGE_LIMIT)
  const limitPerPage = 10

  const [paginationLoading, setPaginationLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [manaItems, setManaItems] = useState(0)
  const [manaInfoCount, setManaInfoCount] = useState(null)
  const [drawHistories, setDrawHistories] = useState([]);
  const [drawHistoryPage, setDrawHistoryPage] = useState(1);
  const [drawHistory_has_previous_page, setDrawHistory_has_previous_page] = useState(1);
  const [drawHistory_has_next_page, setDrawHistory_has_next_page] = useState(1);
  const [txHistories, setTxHistories] = useState([]);
  const [txHistoryFirstId, setTxHistoryFirstId] = useState(0);
  const [txHistoryLastId, setTxHistoryLastId] = useState(0);
  const [previousLastid, setPreviousLastid] = useState(0);
  const [txHistoryHasPreviousPage, setTxHistoryHasPreviousPage] = useState(false);
  const [txHistoryHasNextPage, setTxHistoryHasNextPage] = useState(false);
  const [txHistoryTotal, setTxHistoryTotal] = useState(0);
  const [txHistoryCurrentCount, setTxHistoryCurrentCount] = useState(0);
  const [txHistoryNewCount, setTxHistoryNewCount] = useState(0);
  const txHistoryPaginationCount = useRef(1);
  const language = translations._language;
  const content = translations.wallet;
  const buttonlist = translations.buttonlist;
  const iPhSE = useMediaQuery({ maxHeight: 700 }) ? '70px' : '255px'
  const isPh = useMediaQuery({ minWidth: 576 })
  const walletTransaction = translations.walletTransaction;
  const walletDashboardPage = translations.walletDashboardPage;
  const walletManaAddress = translations.walletManaAddress;
  const walletDrawHistory = translations.walletDrawHistory;

  const previousTxHistory = () => {
    setPaginationLoading(true)
    --txHistoryPaginationCount.current
    getTxHistoryTransactions(wallet_address, txHistoryFirstId, 'ASC')
  }

  const nextTxHistory = () => {
    setPaginationLoading(true)
    ++txHistoryPaginationCount.current
    getTxHistoryTransactions(wallet_address, txHistoryLastId)
  }

  const prevManaPage = () => {
    if (manaStartIndex + manaloadLimit >= manaInfoCount) {
      return;
    }
    setManaStartIndex(manaStartIndex + manaloadLimit)
    setManaLoadLimit(MANA_PAGE_LIMIT)
  }
  const nextManaPage = () => {
    let startIndex = Math.max(0, manaStartIndex - MANA_PAGE_LIMIT);
    setManaStartIndex(startIndex)
    if (startIndex === 0) {
      //last page
      setManaLoadLimit(manaInfoCount % MANA_PAGE_LIMIT)
    } else {
      setManaLoadLimit(MANA_PAGE_LIMIT)
    }
  }

  const prevDrawPage = () => {
    if (drawHistory_has_previous_page) {
      setDrawHistoryPage(page => page - 1);
    }
  }
  const nextDrawPage = () => {
    if (drawHistory_has_next_page) {
      setDrawHistoryPage(page => page + 1);
    }
  }

  const goArcanaGen = (manaAddress) => {
    navigate('/arcana-gen/' + manaAddress)
  }

  const getTxHistoryTransactions = async (wallet_address, last_id = -1, sort_order = 'DESC', is_previous = 0) => {
    // setLoading(true);
    setPreviousLastid(last_id)
    const response = await getData(`tokens/transactions?wallet_address=${wallet_address}&limit=${limit}&last_tx_id=${last_id}&sort_order=${sort_order}&timezone=${timeZone}`);
    setPaginationLoading(false)
    if (response.ok) {
      const { data, has_next_tx_history, has_previous_tx_history, total } = response.data
      const tx_data = data.map((i) => ({ ...i, amount: calculateBN(i.amount, i.decimal, 2) || 0 })).sort((a, b) => b.tx_id - a.tx_id)
      const sortedTx = [...tx_data].sort((a, b) => a.tx_id - b.tx_id);
      setTxHistories(tx_data)
      setTxHistoryHasNextPage(has_next_tx_history)
      setTxHistoryHasPreviousPage(has_previous_tx_history)
      setTxHistoryFirstId(sortedTx[sortedTx.length - 1]?.tx_id || 0)
      setTxHistoryLastId(tx_data[tx_data.length - 1]?.tx_id || 0)
      setTxHistoryTotal(total)
      if (!is_previous) {
        setTxHistoryCurrentCount(has_next_tx_history ? txHistoryPaginationCount.current * limit : txHistoryCurrentCount + tx_data.length)
      }
      setTxHistoryNewCount([...tx_data].filter((i) => i.is_read === false)?.length)
    }
    setLoading(false);
  }

  const getAnimaBalance = async (address) => {
    const rpc = new RPC();
    const balance = await rpc.getAnimaBalance(address);
    balances.anima = balance;
    setBalances(JSON.parse(JSON.stringify(balances)));
  };
  const getPersonaBalance = async (address) => {

    const rpc = new RPC();
    const balance = await rpc.getPersonaBalance(address);
    balances.persona = balance || 0;
    setBalances(JSON.parse(JSON.stringify(balances)));
  };

  const getArcanaBalance = async (address) => {
    const rpc = new RPC();
    const balance = await rpc.getArcanaBalance(address);
    balances.arcana = balance || 0;
    setBalances(JSON.parse(JSON.stringify(balances)));
  };
  const getLevicaBalance = async () => {
    const resp = await getData(`user/levica-balance`);
    balances.levica = resp?.data?.balance || resp?.balance || 0;
    setBalances(JSON.parse(JSON.stringify(balances)));
    setLeviasChainAddress(resp?.levias_chain_address || '');
  };

  const getTokenItems = async () => {
    var tokenItems = await getData(`user-tokens`);
    setTokenItems(tokenItems?.data?.response || []);
  }

  useEffect(() => {
    const rpc = new RPC();
    const loadMana = async () => {
      await rpc.getInfoByBeneficiary(getWalletAddress(), manaStartIndex, manaloadLimit).then(res => {
        setManaItems([...(res || [])].reverse())
      })
    }

    if (active === 'mana') {
      if (manaInfoCount == null) {
        rpc.getManaTotalInfo(getWalletAddress()).then(manaTotalCount => {
          setManaInfoCount(manaTotalCount)
          let startIndex = Math.max(0, manaTotalCount - MANA_PAGE_LIMIT);
          setManaStartIndex(startIndex)
          loadMana()
        });
      } else {
        loadMana()
      }

    }

  }, [active, manaStartIndex]);
  // Detect React Page Leave Event
  useEffect(() => {
    // Component Mount Event
    return async () => {
      // Component Unmount Event
      if (txHistoryNewCount > 0) {
        // Page Leave Event
        const ids = txHistories.filter((i) => i.is_read === false).map((j) => j.tx_id)
        if (isPC && ids.length > 0) {
          await postData(`tokens/transactions/read`, { ids });
        }
      }
    };
  }, [active, txHistoryCurrentCount, txHistoryNewCount]) // Do not add another state

  useEffect(() => {
    if (active !== 'transactions') {
      setTxHistoryNewCount(0)
      getTxHistoryTransactions(wallet_address, previousLastid, 'DESC', 1)
    }
  }, [active])

  useEffect(() => {
    removeBidInfo();
    return async () => {
      // Same page, get page reload event
      const isEqual = (window.history.state.prevUrl === window.location.href) && isPC
      if (isEqual) setShowBadge(false)
      window.history.pushState({ prevUrl: window.location.href }, null, null)
    }
  }, []) // Do not add another state

  const getDrawHistories = async (wallet_address, page) => {
    setLoading(true);
    const response = await getData(`drawchain-histories?page=${page}&wallet_address=${wallet_address}`);
    if (response.ok) {
      setDrawHistories(response.data.data);
      setDrawHistory_has_previous_page(response.data.has_previous_page)
      setDrawHistory_has_next_page(response.data.has_next_page)
    }
    setLoading(false);
  }

  useEffect(() => {
    const address = getWalletAddress()
    if (!address) {
      navigate('/')
      return
    }
    setWallet_address(address)

    async function fetchData() {
      const promises = [
        getTokenItems(),
        getAnimaBalance(address),
        getPersonaBalance(address),
        getArcanaBalance(address),
        getLevicaBalance(),
        getTxHistoryTransactions(address),
        getDrawHistories(address, 1),
      ]
      await Promise.allSettled(promises);
      setLoading(false)
      setPaginationLoading(false)
    }

    if (timeZone) {
      fetchData();
    }
  }, [timeZone]);

  useDidUpdateEffect(() => {
    getDrawHistories(getWalletAddress(), drawHistoryPage);
  }, [drawHistoryPage])

  const getDate = (timestamp) => {
    let date = new Date(parseInt(timestamp) * 1000)
    return new Intl.DateTimeFormat("ja-jp", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  }

  const metaTitle = 'マイウォレット｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  if (isPC) {
    return (
      <AppWrapper metaTitle={metaTitle} metaDescription={metaDescription} enableAddtionalNav title={strings.formatString(content.headerTitle)} activeWallet offOverFlowX
        positionFixed={!isPh} disableFooter={!isPh}>
        <>
          <WalletAddressRow />
          <div className='mt-3 row m-b-10'>
            <WalletDashboardCard
              balances={balances}
              TokenType={TokenType}
              tokenItems={tokenItems}
              setActive={setActive}
              leviasChainAddress={leviasChainAddress}
            />
          </div>
          <div className='d-flex m-b-46'>
            <WalletLinkPC
              props={props}
              transactionsCount={txHistoryNewCount}
              setActive={setActive}
              activeItem={active}
              language={language}
              showBadge={showBadge}
            />
          </div>
          {/* <div className='row col-lg-12 clearfix'>
                    <div className='col-lg-12'>
                        <div className='divider my-3'></div>
                    </div>
                </div> */}
          {
            loading ? <OnLoading noLabel inline marginAuto /> : (
              <>
                {active === 'receive' && <Receive />}
                {active === 'levias' && <Receive address={leviasChainAddress} />}
                {active === 'transactions' && <>
                  <span className='transaction-header-pc' style={{ marginRight: 5 }}>
                    {strings.formatString(walletDashboardPage.TX_History)}
                  </span>
                  <span className='transaction-header-amount-pc'>
                    {txHistoryCurrentCount}/{txHistoryTotal}
                  </span>
                  {
                    <div className='row mt-3'>
                      <div className='table-responsive wallet-table'>
                        <table className='w-100'>
                          <tbody>
                            <tr className='text-center transaction-table-header'>
                              <th style={{ textAlign: 'left', minWidth: 80 }} className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelItem)}
                              </th>
                              <th style={{ minWidth: 80 }} className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelTokenID)}
                              </th>
                              <th style={{ minWidth: 217 }} className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelTxHash)}
                              </th>
                              <th className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelDate)}
                              </th>
                              <th style={{ minWidth: 161 }} className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelFrom)}
                                {strings.formatString(walletTransaction.labelSlash)}
                                {strings.formatString(walletTransaction.labelTo)}
                              </th>
                              <th style={{ minWidth: 80 }} className="jp-fw-400">
                                {strings.formatString(walletTransaction.labelAmount)}
                              </th>
                            </tr>
                            <tr>
                              <td colSpan="6">
                                <div className='divider mb-3 mt-3'></div>
                              </td>
                            </tr >
                            {!paginationLoading ?
                              txHistories?.length > 0 &&
                              txHistories.map((item, index) => {
                                const amount = getTypeByContractAddress(item.contract_address) === TokenType.Anima ? Number(item.amount) : 1;

                                return (
                                  <tr className='text-center transaction-table-body jp-f-notosan' onClick={() => {
                                    SwalSettings.fire({
                                      html: (<WalletTransactionDetail wallet_address={wallet_address} transaction={item} />),
                                      showCancelButton: false
                                    }, true);
                                  }} key={index}>
                                    <td className="table-row-data jp-f-notosan" style={{ textAlign: 'left' }}>
                                      {TokenType.getNameByContractAddress(item.contract_address)}
                                    </td>
                                    <td className="table-row-data jp-f-notosan">
                                      {item.token_id !== null ? item.token_id : "N/A"}
                                    </td>
                                    <td className="table-row-data tx-ellipsis jp-f-notosan">
                                      {item.tx_hash}
                                    </td>
                                    <td className="table-row-data jp-f-notosan">
                                      {item.timestamp}
                                    </td>
                                    <td className="table-row-data tx-ellipsis text-left jp-f-notosan">
                                      {wallet_address === item.from
                                        ? <><SendIcon className="wallet-item-icon" /> {item.to}</>
                                        : <><ReceiveIcon className="wallet-item-icon" /> {item.from}</>
                                      }
                                    </td>
                                    <td className="table-row-data p-0 jp-f-notosan">{formatNumber(amount)}</td>
                                  </tr>)
                              })
                              :
                              <tr>
                                <td colSpan="6">
                                  <OnLoading noLabel inline marginAuto />
                                </td>
                              </tr >
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {
                    txHistoryCurrentCount >= limit &&
                    <div className='row my-5'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          onClick={previousTxHistory}
                          className={`pagination-pc me-4 user-select-none ${!txHistoryHasPreviousPage ? 'pe-none' : ''}`}
                        >
                          {translations.formatString(buttonlist.previouspaginationBtn)}
                        </a>
                        <LineBreak></LineBreak>
                        <a onClick={nextTxHistory} className={`pagination-pc ms-4 user-select-none ${!txHistoryHasNextPage ? 'pe-none' : ''}`}>{translations.formatString(buttonlist.nextpaginationBtn)}</a>
                      </div>
                    </div>
                  }
                </>}
                {active === 'mana' && <>
                  <span className='transaction-header-pc' style={{ marginRight: 5 }}>
                    {strings.formatString(walletManaAddress.title)}
                  </span>
                  {manaInfoCount !== null && <span className='transaction-header-amount-pc'>
                    {manaInfoCount - manaStartIndex}/{manaInfoCount}
                  </span>}
                  {
                    <div className='row mt-3'>
                      <div className='table-responsive'>
                        <table className='w-100'
                          style={{
                            borderCollapse: 'separate',
                            borderSpacing: '0px 5px'
                          }}
                        >
                          <tbody>
                            <tr className='text-center mana-table-header'>
                              <th className='w-20 jp-fw-400 text-start'>
                                {strings.formatString(walletManaAddress.labelTokenID)}
                              </th>
                              <th className="jp-fw-400">
                                {strings.formatString(walletManaAddress.title)}
                              </th>
                              <th className="jp-fw-400">
                                {strings.formatString(walletManaAddress.labelDate)}
                              </th>
                              <th className='w-20 jp-fw-400'>
                                {strings.formatString(walletManaAddress.labelStatus)}
                              </th>
                              <th className='w-20 jp-fw-400'>
                                {strings.formatString(walletManaAddress.labelAction)}
                              </th>
                            </tr>
                            <tr>
                              <td colSpan="6">
                                <div className='divider mb-3 mt-3'></div>
                              </td>
                            </tr >
                            {
                              manaItems?.length > 0 && manaItems.map((item, index) =>
                                <tr key={index} className='text-center mana-table-body'>
                                  <td className="col-2 table-row-data jp-f-notosan text-start">{item.info.eggId}</td>
                                  <td className="col-2 table-row-data jp-f-notosan" style={{ minWidth: 250, textAlign: 'left' }}>{item.info.manaAddress}</td>
                                  <td className="col-2 table-row-data jp-f-notosan" style={{ minWidth: 150 }}>{getDate(item.info.timestamp)}</td>
                                  <td className="col-2 table-row-data jp-f-notosan" >
                                    {/*<span style={{color:' #F87979'}}>
                                                            {strings.formatString(walletManaAddress.labelFailed)}
                                                        </span>*/}
                                    {item.isDone ?
                                      <span style={{ color: '#00FF19' }}>
                                        {strings.formatString(walletManaAddress.labelCompleted)}
                                      </span>
                                      :
                                      <span style={{ color: '#FFF625' }}>
                                        {strings.formatString(walletManaAddress.labelIncomplete)}
                                      </span>
                                    }
                                  </td>
                                  <td className="col-2 table-row-data jp-f-notosan" >
                                    {item.isDone ?
                                      ""
                                      :
                                      <Btn intent="none" onClick={() => goArcanaGen(item.info.manaAddress)} className="btn-mana-address" style={{ background: 'transparent' }}>
                                        {strings.formatString(walletManaAddress.labelRestore)}
                                      </Btn>
                                    }
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {manaInfoCount > limitPerPage &&
                    <div className='row my-5'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a className='pagination-pc me-4' onClick={prevManaPage} >
                          {translations.formatString(buttonlist.previouspaginationBtn)}
                        </a>
                        <LineBreak></LineBreak>
                        <a className='pagination-pc ms-4' onClick={nextManaPage} >
                          {translations.formatString(buttonlist.nextpaginationBtn)}
                        </a>
                      </div>
                    </div>
                  }
                </>}
                {active === 'drawhistories' && <>
                  <span className='transaction-header-pc' style={{ marginRight: 5 }}>
                    {strings.formatString(walletDrawHistory.title)}
                  </span>
                  {/*<span className='transaction-header-amount-pc'>*/}
                  {/*    50/154*/}
                  {/*</span>*/}
                  {
                    <div className='row mt-3'>
                      <div className='table-responsive'>
                        <table className='w-100'
                          style={{
                            borderCollapse: 'separate',
                            borderSpacing: '0px 5px'
                          }}
                        >
                          <tbody>
                            <tr className='text-center mana-table-header'>
                              <th className='w-20 jp-fw-400 text-start'>
                                {strings.formatString(walletManaAddress.labelTokenID)}
                              </th>
                              <th className="jp-fw-400">
                                {strings.formatString(walletDrawHistory.labelDate)}
                              </th>
                              <th className='w-20 jp-fw-400'>
                                {strings.formatString(walletDrawHistory.lableDrawChainName)}
                              </th>
                              <th className='w-20 jp-fw-400'>
                                {strings.formatString(walletDrawHistory.lableUsedPersona)}
                              </th>
                              <th className="jp-fw-400">
                                {strings.formatString(walletDrawHistory.labelStatus)}
                              </th>
                            </tr>
                            <tr>
                              <td colSpan="6">
                                <div className='divider mb-3 mt-3'></div>
                              </td>
                            </tr >
                            {
                              drawHistories?.length > 0 && drawHistories.map((item, index) =>
                                <tr key={index} className='text-center mana-table-body'>
                                  <td className="col-2 table-row-data jp-f-notosan text-start">{item.personaId}</td>
                                  <td className="col-2 table-row-data jp-f-notosan">{item.createdAt}</td>
                                  <td className="col-2 table-row-data jp-f-notosan">{item.drawchainName}</td>
                                  <td className="col-2 table-row-data jp-f-notosan">{item.personaName}</td>
                                  <td className="col-2 table-row-data jp-f-notosan" >
                                    <span style={{ color: '#00FF19' }}>
                                      {strings.formatString(walletDrawHistory.labelSuccess)}
                                    </span>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {drawHistories?.length > limitPerPage &&
                    <div className='row my-5'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a className='pagination-pc me-4' onClick={prevDrawPage} >
                          {translations.formatString(buttonlist.previouspaginationBtn)}
                        </a>
                        <LineBreak></LineBreak>
                        <a className='pagination-pc ms-4' onClick={nextDrawPage} >
                          {translations.formatString(buttonlist.nextpaginationBtn)}
                        </a>
                      </div>
                    </div>
                  }
                </>}
              </>
            )
          }
        </>
      </AppWrapper>
    )
  }
  else {
    return (
      <AppWrapper metaTitle={metaTitle} metaDescription={metaDescription} enableAddtionalNav title={strings.formatString(content.headerTitle)} activeWallet offOverFlowX disableFooter={!isPh}>
        {loading ? <OnLoading /> : <>
          <div className='row col-lg-12'>
            <div className='col-lg-12'
              style={{
                paddingLeft: mLeft,
                paddingRight: mLeft
              }}
            >
              <WalletAddress titleClassName='ms-0' />
            </div>
          </div>
          <div className='mt-3 row'>
            <WalletDashboardCard
              balances={balances}
              TokenType={TokenType}
              tokenItems={tokenItems}
              setActive={setActive}
              isComingSoon={true}
              mLeft={mLeft}
              setMLeft={setMLeft}
              mRight={mRight}
              setMRight={setMRight}
              leviasChainAddress={leviasChainAddress}
            />
          </div>
          <div className='row col-lg-12 clearfix'>
            <div className='col-lg-12'>
              <div className='divider my-3'></div>
            </div>
          </div>
          <div className='row menulist mt-3' style={{ height: `${iPhSE}`, textAlign: 'center' }}>
            <WalletLinkSP
              transactionsCount={txHistoryNewCount}
              language={language}
              setActive={setActive}
              activeItem={active}
              showBadge={showBadge}
            />
          </div>
          {active === 'receive' && <Receive />}
          {active === 'levias' && <Receive address={leviasChainAddress} />}
        </>
        }
      </AppWrapper>
    )
  }
}

export default Wallet

export const WalletAddress = ({ title, style, icon, disabledIcon, address, ...rest }) => {
  const { wallet_address } = useContext(Web3authContext);
  const userWalletPage = translations.userWalletPage;

  return (
    <BorderBoxText {...rest} title={strings.formatString(userWalletPage.Address)} value={address || wallet_address}
      style={style} icon={icon} disabledIcon={disabledIcon} />
  )
}

export const BorderBoxText = ({
  title,
  titleComponent,
  titleClassName,
  value,
  className,
  disabledTextOverflow,
  style,
  icon,
  disabledIcon,
  myWalletAddressClassName,
  onConfirmCopy
}) => {
  const onClickCopy = () => {
    copyToClipboard(value).then(res => {
      // window.location.href=('/wallet')
    })
  }
  // const [copyToast, setCopyToast] = useState(false);
  const commonlist = translations.common;
  const handleCopy = () => {
    copyToClipboard(value, onConfirmCopy);
    // if (!useCopyToast) {
    //   copyToClipboard(value);
    // } else {
    //   navigator.clipboard.writeText(value);
    //   setCopyToast(true);
    // }
  }
  return (
    <>
      <div className={`d-flex flex-column ${className || ''}`} style={Object.assign({}, style)}>
        {
          titleComponent || <h4 className={`mb-3 wallet-address-title jp-fw-400 ms-0 ${titleClassName || ''}`}
            style={{ marginLeft: '27px' }}>{title || 'My Address'}</h4>
        }
        <div className={`d-flex flex-row justify-content-between align-items-center border-box mt-2 width-content ${myWalletAddressClassName || ''}`}
          style={{ minHeight: 45 }}>
          <a className={`${!disabledTextOverflow ? "text-overflow" : ""} text-start`}>{value}</a>
          {
            !disabledIcon &&
            <div className='ms-2' style={{ width: 22 }}>
              {
                icon ? icon :
                  <CopyIcon data-bs-toggle="tooltip" data-bs-title="Default tooltip" data-bs-trigger="click"
                    className="pointer" onClick={handleCopy} />
              }
            </div>
          }
        </div>
        {/* <BodyEnd>
          <Toast
            onClose={() => setCopyToast(false)}
            autohide
            delay={2000}
            show={copyToast}
            bg="dark"
            className="position-fixed"
            style={{ bottom: '100px', left: 'calc(50% - 174px)', zIndex: 999999 }}>
            <Toast.Body className="text-white text-center">{translations.formatString(commonlist.copy_text)}</Toast.Body>
          </Toast>
        </BodyEnd> */}
      </div>
    </>
  )
}

export const Detail = ({ name, value, isWalletAddress, isAnima, isToken, unit, currency, token_symbol, className }) => {
  return (
    <div className='d-flex flex-column mb-3'>
      <span className={`fw-400 text-white text-start ${isJP() ? 'f-notosan' : 'f-regular'} ps-3`}>{name}</span>
      <span
        style={{ color: '#82808C' }}
        className={`text-break text-start wallet-detail-value px-3 ${isJP() ? 'f-notosan' : 'f-regular'} ${className} ${isWalletAddress ? 'isWalletAddress' : ''} ${isAnima || isToken ? 'gradient-value' : ''}`}>
        {
          currency && <font className={`fw-400 text-start ${isJP() ? 'f-notosan' : 'f-regular'} ps-3 m-r-7`}>{translations.formatString(currency === orderCurrency.ANIMA ? translations.common.animaSymbol : translations.common.yen)}</font>
        }
        {value}
        {
          isAnima &&
          <small>ANM</small>
        }
        {
          token_symbol &&
          <small className='ms-1'>{token_symbol}</small>
        }
      </span>
    </div>
  )
}

const WalletDashboardCard = ({
  balances, TokenType, tokenItems, isComingSoon = false, setActive,
  mLeft, setMLeft, mRight, setMRight,
  leviasChainAddress,
}) => {
  const cardRef = useRef();
  const navigate = useNavigate();
  const isPC = useMediaQuery({ minWidth: 992 })
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const walletDashboardPage = translations.walletDashboardPage;
  const WalletDashboardItems = [
    {
      id: 'arcana',
      title: strings.formatString(walletDashboardPage.ARCANA),
      total: balances.arcana,
      className: 'arcana-menu',
      onClick: () => navigate(`/wallet/${TokenType.getRouteName(TokenType.Arcana)}`),
    },
    {
      id: 'persona',
      title: strings.formatString(walletDashboardPage.PERSONA),
      total: balances.persona,
      className: 'persona-menu',
      onClick: () => navigate(`/wallet/${TokenType.getRouteName(TokenType.Persona)}`),
      // isDisableStyle: true,
    },
    {
      id: 'anima',
      title: strings.formatString(walletDashboardPage.ANIMA),
      total: balances.anima,
      className: 'anima-menu',
      onClick: () => navigate(`/wallet/send/address`, {
        state: {
          token: { token_type_id: TokenType.Anima },
          token_type_id: TokenType.Anima
        }
      }),
    },
    {
      id: 'token',
      title: strings.formatString(walletDashboardPage.OTHERS),
      total: tokenItems?.length,
      className: 'token-menu',
      onClick: () => navigate(`/wallet/token`),
    },
    {
      id: 'levias',
      title: strings.formatString(walletDashboardPage.LEVICA),
      total: balances.levica,
      className: 'levias-menu',
      // isDisableStyle: true,
      // onClick: () => isComingSoon ? navigate(`/wallet/levias`) : setActive('levias'),
      onClick: () => navigate(`/levica`)
    },
    {
      id: 'security-token',
      title: strings.formatString(walletDashboardPage.securityToken),
      // total: null,
      className: 'security-token-menu',
      isDisableStyle: true,
    }
  ]

  useLayoutEffect(() => {
    if (cardRef.current && isMobile) {
      if (cardRef.current.offsetWidth < 576) {
        setMLeft((cardRef.current.offsetWidth - 160) * .635)
        setMRight((cardRef.current.offsetWidth - 160) * .315)
      }
    }
  }, []);

  return (
    <>
      {
        WalletDashboardItems?.map((item, index) =>
          <div
            key={index}
            onClick={() => item.isDisableStyle ? '' : item.onClick()}
            className={`menu-card ${item.isDisableStyle ? 'pe-none' : ''} col-6 col-md-3`}
            ref={cardRef}
          >
            <div
              className={`card mb-3 ${item.className || ''}`}
              style={{
                marginLeft: item.id === 'arcana' || item.id === 'anima' || item.id === 'levias' ? mLeft + 'px' : mRight + 'px'
              }}
            >
              <div className="card-body d-flex pointer">
                <span
                  style={{ textOverlow: "ellipsis", lineHeight: '16px' }}
                >
                  {item.title}
                </span>
                <span className="number" style={{
                  textOverlow: "ellipsis",
                  width: "100%",
                  fontSize: '22px',
                  fontWeight: '500',
                }}>{formatNumber(item.total)}</span>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

const WalletLinkSP = ({ transactionsCount, language, showBadge }) => {
  const walletDashboardPage = translations.walletDashboardPage;
  const WalletLinkItems = [
    {
      id: 'send',
      name: strings.formatString(walletDashboardPage.Send),
      icon: (active) => <SendIcon className="wallet-send-icon" />,
      to: '/wallet/send',
    },
    {
      id: 'receive',
      name: strings.formatString(walletDashboardPage.Receive),
      icon: (active) => <ReceiveIcon className="wallet-send-icon" />,
      to: '/wallet/receive',
    },
    {
      id: 'transactions',
      name: strings.formatString(walletDashboardPage.TX_History),
      icon: (active) => <HistoryIcon className="wallet-item-icon" />,
      to: '/wallet/transactions',
      badge: transactionsCount,
    },
    // {
    //     id: 'setting',
    //     name: strings.formatString(walletDashboardPage.Wallet_Settings),
    //     icon: (active) => <SettingIcon />,
    //     to: '/wallet/setting',
    // },
    {
      id: 'mana',
      name: strings.formatString(walletDashboardPage.Mana_Address),
      icon: (active) => <ManaAddressIcon className="wallet-item-icon" />,
      to: '/wallet/mana-addresses',
    },
    {
      id: 'absorb',
      name: strings.formatString(walletDashboardPage.Absorb),
      icon: (active) => <AbsorbIcon style={{ width: 25, height: 25 }} />,
      to: '/wallet/absorb',
    },
    {
      id: 'draw',
      name: strings.formatString(walletDashboardPage.Draw_History),
      icon: (active) => <ChainSingleIcon style={{ width: 25, height: 25 }} />,
      to: '/wallet/draw-history',
    },
  ]

  return (
    <>
      {
        WalletLinkItems?.map((item, index) => {
          const fontSize = String(item.badge).length > 2 ? 10 : 15;
          return (
            <div key={index} className='col-4 col-lg-10 mb-5'>
              <Link
                to={item.to || ''}
                className={`df-center flex-column ${item.isDisableStyle ? 'pe-none' : ''}`}
              >
                <div className='d-lg-flex flex-center gx-3 wallet-sp-item'>
                  {item.icon()}
                  {item.badge > 0 && showBadge &&
                    <div className='df-center wallet-badge'
                      style={{ fontSize: fontSize || 10 }}
                    >
                      {item.badge}
                    </div>
                  }
                </div>
                <span
                  className={`h3 mb-0 pt-1 sm-font ${language === 'jp' ? 'f-notosan' : 'f-expended-regular'} ${item.isDisableStyle ? 'disable-text-color' : ''}`}>
                  {item.name}
                </span>
              </Link>
            </div>
          )
        })
      }
    </>
  )
}

const WalletLinkPC = ({ props, transactionsCount, setActive, language, activeItem, showBadge }) => {
  const walletDashboardPage = translations.walletDashboardPage;
  const WalletLinkPCItems = [
    {
      id: 'send',
      name: strings.formatString(walletDashboardPage.Send),
      icon: (active) => <SendIcon className={`wallet-send-icon ${active ? 'wallet-icon-active' : ''}`} />,
      to: '/wallet/send',
    },
    {
      id: 'receive',
      name: strings.formatString(walletDashboardPage.Receive),
      icon: (active) => <ReceiveIcon className={`wallet-send-icon ${active ? 'wallet-icon-active' : ''}`} />,
      onClick: () => setActive('receive')
    },
    {
      id: 'transactions',
      name: strings.formatString(walletDashboardPage.TX_History),
      icon: (active) => <HistoryIcon className={`wallet-item-icon ${active ? 'wallet-icon-active' : ''}`} />,
      badge: transactionsCount,
      onClick: () => setActive('transactions')
    },
    {
      id: 'mana',
      name: strings.formatString(walletDashboardPage.Mana_Address),
      icon: (active) => <ManaAddressIcon className={`wallet-item-icon ${active ? 'wallet-icon-active' : ''}`} />,
      onClick: () => setActive('mana')
    },
    {
      id: 'absorb',
      name: strings.formatString(walletDashboardPage.Absorb),
      icon: (active) => <AbsorbIcon className={`wallet-item-icon ${active ? 'wallet-icon-active' : ''}`} />,
      to: '/wallet/absorb',
    },
    {
      id: 'drawhistories',
      name: strings.formatString(walletDashboardPage.Draw_History),
      icon: (active) => <ChainSingleIcon className={`wallet-item-icon ${active ? 'wallet-icon-active' : ''}`} />,
      onClick: () => setActive('drawhistories')
    },
  ]

  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringItem, setIsHoveringItem] = useState('');

  const handleIsActive = (item) => {
    item.onClick()
  }

  const handleMouseOver = (item) => {
    setIsHovering(true);
    setIsHoveringItem(item);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setIsHoveringItem('');
  };

  return (
    <>
      {
        WalletLinkPCItems?.map((item, index) => {
          const is_active = item.id === activeItem;
          const is_hover = (item.id === isHoveringItem) && isHovering;
          const fontSize = String(item.badge).length > 2 ? 12 : 13;
          return (
            <div key={index} className='wallet-item hover-effect pe-5'>
              <Link
                to={item.to || ''}
                onMouseOver={() => handleMouseOver(item.id)}
                onMouseOut={handleMouseOut}
                onClick={() => !item.to ? handleIsActive(item) : ''}
                className={`d-lg-flex flex-row align-items-center ${(is_active || is_hover) && !item.isDisableStyle ? 'active-span' : ''}`}
              >
                <div className='d-lg-flex flex-center pe-2'>
                  {item.icon && item.icon(is_active || is_hover)}
                </div>
                <div
                  className="position-relative"
                >
                  <span
                    className={`h3 mb-0 fs-15 sm-font position-relative jp-fw-400 ${language === 'jp' ? 'f-notosan' : 'f-expended-regular'} ${item.isDisableStyle ? 'disable-text-color' : ''}`}
                  >
                    {item.name}
                  </span>
                  {item.badge > 0 && showBadge &&
                    <div className='ml-2 wallet-pc-badge' style={{ fontSize: fontSize || 20 }}>
                      {item.badge}
                    </div>
                  }
                </div>
              </Link>
            </div>
          )
        })
      }
    </>
  )
}

const WalletLinkSettingPC = ({ icon, name, to, className, badge }) => {
  return (
    <Link to={to || '#'}>
      <div className={`d-flex flex-row align-items-center ${className || ''}`} style={{ marginBottom: 10 }}>
        <div className='d-flex flex-center' style={{ width: 25, height: 25 }}>
          {icon}
        </div>
        <span className='h5 mb-0 ms-3 wallet-link-pc-view' style={{ fontSize: 15 }}>{name}</span>
        {badge > 0 && <span className='badge-custom ms-3' style={{ background: "red" }}>{badge}</span>}
      </div>
    </Link>
  )
}
