import React, { useState } from "react";
import SweetAlert from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { postData } from "../../services/fetch";
import { translations } from "../../services/localization";
import { setValue } from "../../services/storage";
import { TextBox } from "./Form";
import { WalletPageTitle } from "./Title";
import { Btn, buttonCVA } from "./Button";

export const swalOption = {
  customClass: {
    htmlContainer: 'swal2-title text-fff',
    icon: "swal2-icon-hide my-3",
    popup: 'modal-box-bordered w-lg-25',
    container: 'swal2-custom-backdrop',
    confirmButton: buttonCVA({ intent: "swalActive" }),
    cancelButton: buttonCVA({ intent: "swalCancel" }),
    closeButton: 'swal2-custom-close',
    title: "swal2-title text-fff pt-1",
    actions: "swal2-settings-actions"
  },
};

export const leviasSwalOption = {
  customClass: {
    htmlContainer: 'levias-swal2-title text-000',
    icon: "swal2-icon-hide my-3",
    popup: 'levias-modal-box-bordered w-lg-25',
    container: 'levias-swal2-custom-backdrop',
    confirmButton: "levias-btn-custom-active shadow-none",
    denyButton: "levias-btn-custom-active shadow-none",
    title: "levias-swal2-title text-000 pt-1",
  },
};

const Swal = withReactContent(SweetAlert);
export default Swal.mixin({ customClass: { ...swalOption.customClass, htmlContainer: '' } });

export const SwalOffer = (isJP, containerClassName = '') => Swal.mixin({
  showConfirmButton: false,
  allowOutsideClick: false,
  showCloseButton: true,
  customClass: {
    htmlContainer: 'swal2-title text-fff',
    icon: "swal2-icon-hide my-3",
    popup: 'modal-box-bordered w-lg-25',
    container: `swal2-custom-backdrop ${containerClassName || ''}`,
    confirmButton: "btn btn-custom-active text-white d-block btn-confirm btn-mini my-4 ladda-label btn-custom-swal shadow-none",
    cancelButton: 'btn btn-custom-cancel btn-mini f-monodb btn-custom-swal shadow-none',
    closeButton: isJP ? 'swal2-custom-close-jp m-r--10' : 'swal2-custom-close m-r--10',
    title: "swal2-title text-fff pt-1",
  }
});

export const SwalEmail = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      iconHtml: '<div class="sent-email-icon"></div>',
      backdrop: "#19162D",
      mixBlendMode: "normal",
      backdropFilter: "blur(15px)",
    }),
};

export const LeviasSwalEmail = {
  fire: (params) =>
    Swal.mixin(leviasSwalOption).fire({
      ...params,
      iconHtml: '<div class="levias-sent-email-icon"></div>',
      backdrop: "#19162D",
      mixBlendMode: "normal",
      backdropFilter: "blur(15px)",
    }),
};

export const SwalCustom = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      customClass: { ...swalOption.customClass, ...params.customClass }
    }),
};

export const SwalCorrect = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      buttonsStyling: false,
      iconHtml: '<div class="correct-icon"></div>',
      backdrop: "#19162D",
      customClass: {
        ...swalOption.customClass,
        ...params.customClass
      }
    }),
};

export const LeviasSwalCorrect = {
  fire: (params) =>
    Swal.mixin(leviasSwalOption).fire({
      ...params,
      iconHtml: '<div class="levias-correct-icon"></div>',
      backdrop: "#19162D",
    }),
};

export const LeviasSwalIDMsg = {
  fire: (params) =>
    Swal.mixin(leviasSwalOption).fire({
      ...params,
      backdrop: "#19162D",
      customClass: {
        ...leviasSwalOption.customClass,
        htmlContainer: 'levias-swal-id-msg text-000',
      }
    }),
};

export const LeviasSwalWrong = {
  fire: (params) =>
    Swal.mixin(leviasSwalOption).fire({
      ...params,
      iconHtml: '<div class="levias-wrong-icon"></div>',
      backdrop: "#19162D",
      customClass: {
        ...leviasSwalOption.customClass,
        title: "levias-swal2-title text-f00 pt-1",
        htmlContainer: 'levias-swal2-title text-000',
      }
    }),
};

export const LeviasSwalProfileIcon = {
  fire: (params) =>
    Swal.mixin(leviasSwalOption).fire({
      ...params,
      buttonsStyling: false,
      // showDenyButton: true,
      customClass: {
        ...leviasSwalOption.customClass,
        popup: 'levias-modal-box-bordered w-lg-25 w-390px h-200px',
        confirmButton: "btn-mini levias-btn-custom-cancel levias-btn col-8 text-uppercase levias-gallery-icon df-center",
        // denyButton: "btn-mini levias-btn-custom-cancel levias-btn col-8 my-3 text-uppercase levias-take-photo-icon df-center",
      },
      backdrop: "#19162D",
    }),
};

export const SwalWrong = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      buttonsStyling: false,
      iconHtml: '<div class="wrong-icon"></div>',
      backdrop: "#19162D",
    }),
}

export const SwalWarn = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      iconHtml: '<div class="warn-icon"></div>',
      backdrop: "#19162D",
    }),
}

export const SwalProfileIcon = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      buttonsStyling: false,
      // showDenyButton: true,
      customClass: {
        ...swalOption.customClass,
        confirmButton: "btn btn-custom-cancel mx-auto col-8 aniBtn btn-mini mb-4 text-uppercase gallery-icon df-center flex-row-reverse",
        // denyButton: "btn btn-custom-cancel mx-auto col-8 aniBtn btn-mini text-uppercase take-photo-icon df-center flex-row-reverse",
      },
      backdrop: "#19162D",
    }),
};

export const SwalSms = {
  fire: (params) =>
    Swal.mixin(swalOption).fire({
      ...params,
      iconHtml: '<div class="sent-sms-icon"></div>',
      backdrop: "#19162D",
    }),
}

export const SwalSettings = {
  fire: (params, hideCancelButton) =>
    Swal.mixin({
      customClass: hideCancelButton ? Object.fromEntries(
        Object.entries(Object.assign(swalOption.customClass, { actions: `swal2-settings-actions` })).filter(([key]) =>
          key !== 'cancelButton')) : Object.assign(swalOption.customClass, { actions: `swal2-settings-actions` })
    }).fire({
      ...params,
      buttonsStyling: false,
      showCancelButton: params.showCancelButton ?? true,
      showConfirmButton: params.showConfirmButton ?? true,
      cancelButton: 'btn btn-custom-cancel btn-mini',
      backdrop: "#19162D",
      reverseButtons: params.reverseButtons ?? true,
    }),
}

export const SwalConfirm = {
  fire: (params) =>
    Swal.mixin({
      customClass: {
        cancelButton: 'btn btn-custom-cancel-sm border-radius-15 flex-1 fs-20',
        confirmButton: 'btn btn-custom-active-sm border-radius-15 flex-1 fs-20',
        htmlContainer: 'swal2-title text-fff m-3',
        icon: "swal2-icon-hide my-3",
        popup: 'modal-box-bordered w-lg-25',
        container: 'swal2-custom-backdrop',
        title: "swal2-title text-fff pt-1",
        actions: `w-100 justify-content-center gap-20 p-3 ${params?.reverseActions ? 'flex-row-reverse' : ''}`
      }
    }).fire({
      ...params,
      buttonsStyling: false,
      showCancelButton: true,
      backdrop: "#19162D",
    }),
}


export const SwalChangeProfileName = (title, setLoading, userInfo, setUser) => {
  Swal.mixin(swalOption).fire({
    showConfirmButton: false,
    html: (<ChangeProfileNameBoxContent title={title} userInfo={userInfo} setUser={setUser} />)
  });
}

export const ChangeProfileNameBoxContent = ({ title, userInfo, setUser }) => {
  const [name, setName] = useState('');
  const buttonlist = translations.buttonlist;
  const accountDetailPage = translations.accountDetailPage;

  const onCancel = () => Swal.close();

  const onNameHandler = async () => {
    const response = await postData(`accounts/verify-account-name`, { accountName: name?.trim() });
    if (response.ok) {
      onSubmit()
    } else {
      SwalWrong.fire({
        title: "Error",
        text: response.data.message,
      }).then(_ => {
        Swal.mixin(swalOption).fire({
          showConfirmButton: false,
          html: (<ChangeProfileNameBoxContent title={title} userInfo={userInfo} setUser={setUser} />)
        });
      })
    }
  }

  const onSubmit = async () => {
    try {
      const response = await postData(`user/update`, { name: name?.trim() });
      if (response.ok) {
        let newUserInfo = { ...userInfo }
        newUserInfo.account_name = name
        setUser(newUserInfo)
        setValue('user_info', newUserInfo)
        SwalCorrect.fire({
          title: translations.formatString(accountDetailPage.infoSuccess)
        })
      } else {
        throw new Error(response.data.name)
      }
    } catch (error) {
      SwalWrong.fire({
        title: "Error",
        text: error,
      })
    }
  }

  return (
    <SwalBoxContent title={title} onCancel={onCancel} onSubmit={onNameHandler} cancelBtnText={translations.formatString(buttonlist.backBtn)} confirmBtnText={translations.formatString(buttonlist.saveBtn)} confirmBtnVariant={name ? "active" : "success"} disableConfirmBtn={!name?.trim()}>
      <div className='row'>
        <div className='col px-0'>
          <TextBox className="focus" inputStyle={{ height: 40 }} onChange={e => setName(e.target.value)} value={name} />
        </div>
      </div>
    </SwalBoxContent>
  )
}

export const SwalBoxContent = ({ title, onCancel, onSubmit, children, cancelBtnVariant = 'cancel', confirmBtnVariant = 'success', disableConfirmBtn = false, cancelBtnText, confirmBtnText, isLevias, disableBtn, noTitleMargin = false }) => {
  const buttonlist = translations.buttonlist;
  const prefix = isLevias ? "levias-" : "";
  const isEng = translations?._language === 'en'
  return (
    <div id={isLevias ? 'levias-pc' : ''} className={`flex-center ${isLevias ? 'levias-modal-box-bordered text-000' : 'swal-react-modal text-white'}`} style={{ overflow: 'hidden' }}>
      <div className='row mx-0'>
        <div className='col-12'>
          <WalletPageTitle title={title} />
        </div>
        <div className='col-12 px-0'>
          <div className='row' style={{ margin: `${title && !noTitleMargin ? '80' : '36'}px 0px 36px` }}>
            <div className="col-12">
              {children}
            </div>
          </div>
        </div>
        <div className='col-12 px-0'>
          {
            !disableBtn &&
            <div className='flex-row flex-center mt-5'>
              <Btn type="button"
                intent="none"
                className={`${prefix}btn ${prefix}btn-custom-${cancelBtnVariant} small w-100`}
                onClick={onCancel}
              >
                {cancelBtnText || translations.formatString(buttonlist.cancelBtn)}
              </Btn>
              <Btn
                intent="none"
                type="button"
                className={`${prefix}btn ${prefix}btn-custom-${confirmBtnVariant} small ms-2 w-100`}
                disabled={disableConfirmBtn} onClick={onSubmit}
                style={{ color: 'white', opacity: 1 }}
              >
                {confirmBtnText || translations.formatString(buttonlist.confirmBtn)}
              </Btn>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
