import { useEffect, useState } from "react";
import Maintenance from "../pages/Maintenance";
import { getData } from "../services/fetch";

const withMaintenanceModeCheck = (WrappedComponent, isArcanaGen = false) => {
    const WrapperComponent = (props) => {
        const [data, setData] = useState({});
        useEffect(() => {
            (async () => {
                const response = await getData("maintenance-mode");
                setData(response.data.data);
            })();

        }, [])

        if (data?.status === "on") {
            if (!isArcanaGen || data?.arcana_gen_maintenance === 1) {
                return <Maintenance {...data} />
            }
        }
        return <WrappedComponent {...props} />;
    };

    return WrapperComponent;
};

export default withMaintenanceModeCheck;
