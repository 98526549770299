import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { MobileSearchBox, SearchBox } from "../../components/common/Form";
import {
  WalletIcon,
  ProfileVectorIcon,
  WalletVectorActiveIcon,
  ProfileVectorActiveIcon,
  SearchCircleIcon,
  AnicanaIcon,
  AniKanaIcon,
  LeviasLogoIcon,
  AnikanaGradientIcon,
  PersonaIcon,
} from "../../components/common/Icon";
import { appContext } from "../../context/appContext";
import { AuthContext } from "../../context/authContext"
import { LeviasBottomModal } from "../../components/common/BottomModal";
import { strings, translations } from "../../services/localization";
import { isMobile } from "react-device-detect";

function Header({
  enableAddtionalNav,
  title,
  activeWallet,
  activeProfile,
  disableSearchbox,
  hideNav,
  smallFont,
  noLayout,
  notSignIn,
  isLeviasLayout,
  isLeviasHandler,
  enableMobileNav,
  enableMobileSearch,
  onSearch,
  scrollableHeader,
  gameDetailObj,
  scrollPoint,
  isCustomDetailPage,
  hideToolbar,
  pageid,
  isSpecCodeD,
  isDrawchainDetail
}) {
  const navigate = useNavigate();
  const { code_d_id } = useParams()
  const { search, setSearch, filter, sort, globalSearch, setGlobalSearch, showRecentSearch, setShowRecentSearch } = useContext(appContext)
  const { user } = useContext(AuthContext)
  const { language } = useContext(appContext)
  const location = useLocation();
  const [queryParameters] = useSearchParams()
  const type = queryParameters.get('type')

  const [ID, setID] = useState(code_d_id || type || null)
  const [showImageCapModal, setShowImageCapModal] = useState();
  const [openSearch, setOpenSearch] = useState(false);

  const globalSearchInputRef = useRef();
  const searchInputRef = useRef();
  const buttonlist = translations.buttonlist;
  const cancelRegPage = translations.cancelRegPage;
  const className = isSpecCodeD && ID === '1' ? 'green-color f-cambay fs-25' : isSpecCodeD && ID === '2' ? 'text-white f-cambay fs-25' : ''

  const toggleOpenSearch = () => setOpenSearch(openSearch => !openSearch);

  const handleClickBack = () => {
    setSearch('');
    toggleOpenSearch();
  }

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    setSearch(searchInputRef.current.value || '');
    navigate(`/tokens/${filter.token_type_id}`);
  }

  const handleResetSearch = () => {
    setSearch('');
    navigate(`/tokens/${filter.token_type_id}`);
  }

  const onGlobalSearchSubmit = async (e) => {
    e.preventDefault();
    handleGlobalSearch(globalSearchInputRef.current.value || '');
  }

  const handleGlobalSearch = async (value) => {
    setGlobalSearch(value);
    if (location.pathname !== "/search") {
      navigate(`/search`);
    }
  }

  const handleResetGlobalSearch = () => {
    setGlobalSearch('');
    if(globalSearchInputRef.current) {
      globalSearchInputRef.current.focus();
    }
    if (location.pathname !== "/search") {
      navigate(`/search`);
    }
    setShowRecentSearch(true);
  }

  const handleOnFocusGlobalSearch = () => {
    setShowRecentSearch(true);
  }

  useEffect(() => {
    if (searchInputRef.current && !searchInputRef.current.value && search) {
      searchInputRef.current.value = search;
    }

    if (globalSearchInputRef.current && !globalSearchInputRef.current.value && globalSearch) {
      globalSearchInputRef.current.value = globalSearch;
    }

  }, [])

  useEffect(() => {
    if (openSearch && searchInputRef.current) {
      if (!searchInputRef.current.value && search) {
        searchInputRef.current.value = search;
      }
    }
  }, [openSearch])

  // useDidUpdateEffect(() => {
  //   if (onSearch) {
  //     onSearch(search, filter, sort, 1);
  //   }
  // }, [search])

  const mobileNavItems = [
    {
      name: 'All',
      to: '/'
    },
    {
      name: 'ARCANA',
      to: '/tokens/1'
    },
    {
      name: 'Game',
      to: '/products'
    },
    {
      name: 'PERSONA',
      to: '/tokens/2'
    },
    {
      name: 'News',
      to: '/news'
    }
  ]

  function onClickLogo() {
    if (window.location.href.match(/arcana-gen/)) {
      return;
    }
    if (!noLayout && !isLeviasLayout) {
      navigate('/')
    }
  }

  function onCloseImageCapModal() {
    setShowImageCapModal(false);
  };

  function isArcanaGen() {
    const str = location.state?.redirect_uri?.split('/')
    return str?.indexOf('arcana-gen')
  }

  function isExternal() {
    const isPreviousURLExternal = (window.document.referrer !== '') && (window.document.referrer !== window.location.origin)
    return isPreviousURLExternal
  }

  function goBack() {
    if (isLeviasHandler === 'login') {
      if (isArcanaGen()) {
        return <div className="d-flex levias-logo-box" onClick={() => navigate(location.state?.redirect_uri)}>
          {buttonlist.closeBtn}
        </div>
      } else if (isExternal()) {
        if (window != window.parent) {
          return <div className="d-flex levias-logo-box" onClick={() => window.parent.postMessage({
            'func': '__close_portal_login',
            'message': 'Message text from iframe.'
          }, "*")}>{buttonlist.closeBtn}</div>
        } else {
          return <div className="d-flex levias-logo-box" onClick={() => window.history.back()}>{buttonlist.closeBtn}</div>
        }
      }
    } else if (isLeviasHandler === 'forgot') {
      return <div className="d-flex levias-logo-box" onClick={() => navigate('/login/idms')}>{buttonlist.closeBtn}</div>
    } else if (isLeviasHandler === 'register') {
      return <div className="d-flex levias-logo-box" onClick={() => setShowImageCapModal(true)}>{buttonlist.cancelBtn}</div>
    }

    return <div className="d-flex levias-logo-box pointer" style={{ minWidth: 60 }} onClick={() => navigate('/')}>{buttonlist.closeBtn}</div>
  }

  let isLeviasHandlerTemplage = goBack()

  if (!!isLeviasHandler && isLeviasHandler !== 'login') {
    delete window.document.referrer
    window.document.__defineGetter__('referrer', function () {
      return "";
    });
  }

  const tl_contactus = translations.contactUs;
  const gameDetailHeader =
    <div className={`game-detail-header d-flex flex-row justify-content-between align-items-center`}>
      <div className="d-flex">
        <div onClick={() => navigate(-1)} className="game-detail-arrow"></div>
        <span className={`tx-ellipsis ${language === 'English' ? '' : 'f-notosan'}`} style={{ maxWidth: 170}}>{gameDetailObj?.title}</span>
      </div>
      <a href={gameDetailObj?.url}>
        <div className={`btn flex-column br-10px df-center ` + (gameDetailObj?.url ? 'btn-custom-active' : 'btn-custom-nonactive')} style={{ minHeight: 45, maxHeight: 65, height: 'unset' }}>
          <div style={{ fontSize: '12px' }} className={` ${language === 'English' ? 'f-regular' : 'f-notosan'} fw-400 text-uppercase`}>
            {isDrawchainDetail ? translations.formatString(tl_contactus.title) : gameDetailObj?.price ? (gameDetailObj?.price) : (language === 'English' ? `No Price` : `無料 (ゲーム内課金有り)`)}
          </div>
        </div>
      </a>
    </div>

  let template =
    <div id={`${scrollableHeader ? '' : 'toolbarWrap'}`}>
      <div
        className={`${isCustomDetailPage && isMobile ? 'toolbarAnimation' : ''} ${scrollPoint < 350 ? scrollPoint < 100 ? "" : "initial" : "animate"}`}
        style={{
          padding: `${(isCustomDetailPage && isMobile && scrollPoint < 350) || hideToolbar ? '0' : '10'}px 16px ${(isCustomDetailPage && scrollPoint < 350 && isMobile) || hideToolbar ? '0' : '10'}px 0px`,
          marginBottom: 0
        }}
        id={`${scrollableHeader ? '' : 'toolbar'}`}
      >
        {
          openSearch
            ? <div style={{ paddingLeft: 16, paddingBottom: 0 }}>
              <form onSubmit={onSearchSubmit}>
                <MobileSearchBox
                  ref={searchInputRef}
                  onClickBack={handleClickBack}
                  placeholder={strings.formatString(buttonlist.search)}
                />
              </form>
            </div>
            : !!gameDetailObj
              ? <>
                {scrollPoint === 0 && !isDrawchainDetail && <div onClick={() => navigate(-1)} className="lg-visibility-hidden game-detail-arrow back-arrow"></div>}
                {scrollPoint > 350 && gameDetailHeader}
              </>
              : <>
                <div className="d-flex align-items-center justify-content-between w-100" style={gameDetailObj ? { paddingTop: '10px', paddingBottom: '5px' } : {}}>
                  <div className="d-flex anikana-logo-box w-100">
                    <div className={`d-flex align-items-center ${hideNav ? '' : 'sidebar-flex'} pointer`} style={{ paddingLeft: 16, minHeight: 45 }} onClick={onClickLogo}>
                      <div className="pe-2">
                        {/* <div className={`${isLeviasLayout ? 'levias-logo' : isSpecCodeD ? 'anikana-logo' : 'anikana-logo'}`}></div> */}
                        { isLeviasLayout ? null :
                          isSpecCodeD ?  <AniKanaIcon className={`${ID === '1' ? 'green-icon' : 'white-icon'}`} />
                          : <AnikanaGradientIcon />
                        }
                      </div>
                      {!isLeviasLayout ? title ?
                        <span 
                          className={`text-capitalize ${className || 'f-monodb gradient-text'}`} 
                          style={{ 
                            fontSize: smallFont ? '25px' : '30px'
                          }}
                        >
                          {title || "ANICANA"}
                        </span>
                        :
                        <AnicanaIcon />
                        : null
                      }
                    </div>
                    {
                      !hideNav && !disableSearchbox && !isLeviasLayout &&
                      <div className="row w-100">
                        <div className="col">
                          <form onSubmit={onGlobalSearchSubmit}>
                            <SearchBox
                              placeholder={strings.formatString(buttonlist.search)}
                              ref={globalSearchInputRef}
                              id="globalSearch"
                              onClose={handleResetGlobalSearch}
                              containerClassName="d-none d-lg-block"
                              showRecentSearch={showRecentSearch}
                              onClickRecentSearchItem={handleGlobalSearch}
                              onFocus={handleOnFocusGlobalSearch}
                              autoComplete={"off"}
                            />
                          </form>
                        </div>
                      </div>
                    }
                  </div>

                  {!hideNav && enableAddtionalNav && !noLayout && !isLeviasLayout && (
                    <>
                      <div className="d-flex anikana-logo-box ">
                        {
                          enableMobileSearch && window.innerWidth < 992 &&
                          < div >
                            <SearchCircleIcon className="me-3 headerIcon" onClick={toggleOpenSearch} />
                          </div>
                        }
                        <div>
                          {!activeWallet ? (
                            <Link to="/wallet">
                              <WalletIcon className={`${isSpecCodeD && ID === '1' ? 'green-inactive-icon' : isSpecCodeD && ID === '2' ? 'white-inactive-icon' : 'icon-stroke active'} me-3 headerIcon`} />
                            </Link>
                          ) : (
                            <Link to="/wallet">
                              <WalletVectorActiveIcon className="me-3 headerIcon" />
                            </Link>
                          )}
                        </div>
                        <div>
                          {
                            user?.image ?
                              <div className="position-relative">
                                <div className="rounded-circle over-h profile-pic-general d-flex justify-content-center headerIcon" style={{
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundImage: "url(" + user.image + ")"
                                }} onClick={() => navigate('/account')}>
                                </div>
                              </div> :
                              !activeProfile ? (
                                <Link to="/account">
                                  <ProfileVectorIcon className="headerIcon" />
                                </Link>
                              ) : (
                                <Link to="/account">
                                  <ProfileVectorActiveIcon className="headerIcon" />
                                </Link>
                              )}
                        </div>
                      </div>
                    </>
                  )}

                  {!hideNav && notSignIn && !noLayout &&
                    <>
                      {
                        isLeviasLayout
                          ? <>
                            {isLeviasHandlerTemplage}
                          </>
                          : <div className="d-flex anikana-logo-box ">
                            <div className="btn-login" onClick={() => navigate('/login/idms?f=true')}>
                              {strings.formatString(cancelRegPage.signIn)}
                            </div>
                          </div>

                      }
                    </>
                  }
                </div>
                {
                  enableMobileNav && mobileNavItems.length > 0 && false &&
                  <div className='d-flex flex-row d-block d-lg-none mobileNav'>
                    {
                      mobileNavItems.map((item, index) =>
                        index === 0 ?
                          <div className="flex -center mobileNavItemActive">{item.name}</div> :
                          <Link to={item.to} key={`mobileNav_${index}`} className={`mobileNavItem ${index === 0 ? 'active' : ''}`}>
                            <small className="">{item.name}</small>
                          </Link>
                      )
                    }
                  </div>
                }
              </>
        }
      </div >
    </div>

  return (
    <>
      {isLeviasLayout ?
        <LeviasBottomModal
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          title={strings.formatString(cancelRegPage.cancelModalTitle)}
          label={strings.formatString(cancelRegPage.cancelModalText)}
        /> : ""
      }
      {template}
    </>
  );
}

export default Header;
