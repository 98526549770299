import React, { lazy, Suspense, useEffect, useState } from "react";
import AppWrapper from "../components/wrappers/AppWrapper";
import axios from "axios";
import { API_DOMAIN } from "../config";
import { OnLoading } from "../components/common/OnLoading";
import { useContext } from "react";
import { appContext } from "../context/appContext";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { strings, translations } from "../services/localization";
import { createMarkup, handleSearch } from "../components/common/commonFnc";
import { TokenType } from "../components/common/enum";
import {
    getToken,
    getWalletAddress,
    clearAllStorage,
    removeBidInfo,
} from "../services/storage";
import TopNewsPC from "../components/common/TopNewsPC";
import TopNewsMobile from "../components/common/TopNewsMobile";
import { ItemCarousel } from "../components/common/Item";
import { getData } from "../services/fetch";
import { useDispatch, useSelector } from "react-redux";
import { getTopNews } from "../store/Home";

const Home = () => {
    const { top_news } = useSelector((state) => state.top_news);
    const navigate = useNavigate();
    // const ItemCarousel = lazy(() => import('../components/common/ItemCarousel'));
    const [trends, settrends] = useState([]);
    const [topics, settopics] = useState([]);
    const [news, setNews] = useState();
    const [topNews, setTopNews] = useState(top_news || null);
    const [arcanas, setArcanas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [arcanaloading, setArcanaLoading] = useState(true);
    const [personas, setPersonas] = useState([]);
    const [personaloading, setPersonaLoading] = useState(true);
    const [topicLoading, setTopicLoading] = useState(true);
    const [trendLoading, setTrendLoading] = useState(true);
    const [topNewsLoading, setTopNewsLoading] = useState(true);

    const { language, timeZone } = useContext(appContext);
    const { logout } = useContext(AuthContext);
    const [browsersize, setBrowsersize] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setBrowsersize(window.innerWidth);
    };
    const location = useLocation();
    const search = location.search;
    const query = new URLSearchParams(search);
    const content = translations.anicana;
    // const filter = { token_type_id: 1 }
    const dispatch = useDispatch();

    const fetchNewData = async () => {
        const shortForm = language === "English" ? "en" : "jp";
        const url =
            API_DOMAIN + "news?language=" + shortForm + "&timezone=" + timeZone;
        await axios.get(url).then((response) => {
            if (response.data) {
                if (response.data?.data?.data) {
                    const newData = response.data.data.data;
                    newData.map((each, index) => {
                        if (index < 1) {
                            let showString = each.body;
                            each.body = showString;
                        }
                    });
                    setNews(newData);
                }
            }
        });
    };

    const fetchNewHeadingData = async () => {
        const shortForm = language === "English" ? "en" : "jp";
        const url =
            API_DOMAIN + "top-news?language=" + shortForm + "&timezone=" + timeZone;
        const response = await getData(url);
        if (response.data?.data) {
            setTopNewsLoading(false);
            const data = response.data.data;
            const array = [...data.top_news, ...data.top_advertisements];
            const random = Math.floor(Math.random() * array.length);
            const randomItem = array[random];
            if (topNews.id !== randomItem.id) {
                setTopNews(randomItem);
                dispatch(getTopNews(randomItem));
            }
        }
        fetchTopicsData();
        await fetchTrendData();
    };

    const fetchNewArcanas = async () => {
        const filter = { token_type_id: 1 };
        setArcanaLoading(true);
        var { tokens } = await handleSearch("", filter);
        setArcanas(tokens);
        setArcanaLoading(false);
    };

    const fetchNewPersonas = async () => {
        setPersonaLoading(true);
        const response = await getData(`personas`);
        if (response.ok) {
            setPersonas(response.data.data.data);
            setPersonaLoading(false);
        }
    };

    const fetchTrendData = async () => {
        const shortForm = language === "English" ? "en" : "jp";
        const url =
            API_DOMAIN +
            "tokens/get-trends?language=" +
            shortForm +
            "&timezone=" +
            timeZone;
        const response = await getData(url);
        if (response.data) {
            const trendsgameData = response?.data?.data?.trend_games ?? [];
            const trendsnewsData = response?.data?.data?.trend_news ?? [];
            const conbimetrends = [...trendsgameData, ...trendsnewsData];
            settrends([...conbimetrends].sort((a, b) => a.trend - b.trend));
        }
        setTrendLoading(false);
    };

    const fetchTopicsData = async () => {
        const shortForm = language === "English" ? "en" : "jp";
        const url =
            "tokens/get-topics?language=" + shortForm + "&timezone=" + timeZone;
        const response = await getData(url);
        if (response.data) {
            const topicsgameData = response?.data?.data?.topic_games ?? [];
            const topicsnewsData = response?.data?.data?.topic_news ?? [];
            const conbimetopics = [...topicsgameData, ...topicsnewsData];
            settopics(
                [...conbimetopics].sort(
                    (a, b) =>
                        new Date(b.published).getTime() - new Date(a.published).getTime()
                )
            );
        }
        setTopicLoading(false);
    };
    // combine api call for news heading , topic and trends
    const fetchHomeData = async () => {
        const shortForm = language === "English" ? "en" : "jp";
        const url =
            API_DOMAIN +
            "get-all-data?language=" +
            shortForm +
            "&timezone=" +
            timeZone;
        await axios.get(url).then((response) => {
            if (response.data) {
                if (response.data.data.top_news) {
                    const data = response.data.data.top_news;
                    const array = [...data.news, ...data.advertisements];
                    const random = Math.floor(Math.random() * array.length);
                    const randomItem = array[random];
                    setTopNews(randomItem);
                }
                if (response.data.data.get_topic) {
                    const topicsgameData =
                        response?.data?.data?.get_topic?.topic_games ?? [];
                    const topicsnewsData =
                        response?.data?.data?.get_topic?.topic_news ?? [];
                    const conbimetopics = [...topicsgameData, ...topicsnewsData];
                    settopics(
                        [...conbimetopics].sort(
                            (a, b) =>
                                new Date(b.published).getTime() -
                                new Date(a.published).getTime()
                        )
                    );
                }
                if (response.data.data.get_trends) {
                    const trendsgameData =
                        response?.data?.data?.get_trends?.trend_games ?? [];
                    const trendsnewsData =
                        response?.data?.data?.get_trends?.trend_news ?? [];
                    const conbimetrends = [...trendsgameData, ...trendsnewsData];
                    settrends([...conbimetrends].sort((a, b) => a.trend - b.trend));
                }
            }
        });
    };

    const getHomeData = async () => {
        // await fetchHomeData()
        // await fetchNewData()
        await fetchNewHeadingData();
        fetchNewArcanas();
        fetchNewPersonas();
        setLoading(false);
    };

    useEffect(() => {
        if (query.get("clear") === "session") {
            logout();
        }
        if (language && timeZone) {
            getHomeData();
        }
    }, [language, timeZone]);

    const isPC = useMediaQuery({ minWidth: 992 });
    const isPh = useMediaQuery({ maxWidth: 576 });
    const topImage =
        (topNews?.image_url_top ? topNews?.image_url_top : topNews?.image_url) ||
        topNews?.image;
    const buttonlist = translations.buttonlist;
    const sidebarPage = translations.sidebarPage;

    useEffect(() => {
        removeBidInfo();
        async function fetchData() {
            const isLogout = new URL(`${window.location}`)?.searchParams?.get(
                "logout"
            );
            if (isLogout === "true" || !getWalletAddress()) {
                const token = getToken();
                if (token) {
                    clearAllStorage();
                    logout();
                } else {
                    navigate("/");
                }
            }
        }
        fetchData();
    }, []);

    const metaTitle = "ANICANA θυρα（アニカナテュラー）";
    const metaDescription =
        "ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

    return (
        <AppWrapper
            homeActive
            enableSubNav
            enableMobileNav
            pageid={"Home"}
            isNoSpaceAround
            offOverFlowX
            // title={content.headerTitle}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
        >
            <div className="home px-0 px-lg-2 mt-0 m-t-lg--10">
                <div
                    className="position-fixed divider-bg d-none d-lg-block"
                    style={{
                        width: "100%",
                        marginTop: 10,
                    }}
                >
                    <div className="home-divider d-none d-lg-block divider-fixed"></div>
                </div>
                <div className="myVideoWrapper">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        id="myVideo"
                        onClick={() => {
                            alert(123);
                        }}
                    >
                        <source
                            src="./Anicana_FirstView_noOverlay_0723.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                {topNewsLoading ? (
                    <OnLoading />
                ) : (
                    <>
                        {isPC ? (
                            <TopNewsPC topNews={topNews} topImage={topImage} />
                        ) : (
                            <TopNewsMobile topNews={topNews} topImage={topImage} />
                        )}
                        <div className="px-2 px-lg-0">
                            <ItemCarousel
                                topics
                                title={strings.formatString(sidebarPage.Topics)}
                                items={topics}
                                morelink="/topics"
                                containerClassName="mb-4"
                                browsersize={browsersize}
                                loading={topicLoading}
                            />
                            <ItemCarousel
                                topics
                                title={strings.formatString(sidebarPage.Trend)}
                                items={trends}
                                morelink="/trend"
                                containerClassName="mb-4"
                                browsersize={browsersize}
                                loading={trendLoading}
                            />
                            <ItemCarousel
                                title={strings.formatString(sidebarPage.NewArcana)}
                                small
                                items={arcanas}
                                morelink={`/tokens/${TokenType.Arcana}`}
                                browsersize={browsersize}
                                loading={arcanaloading}
                                containerClassName="mb-4"
                                isunboldheader="true"
                                hasSelectButton={false}
                            />

                            {/* Persona */}
                            <ItemCarousel
                                title={strings.formatString(sidebarPage.NewPersona)}
                                small
                                collection
                                items={personas}
                                morelink={`/tokens/${TokenType.Persona}/collection`}
                                browsersize={browsersize}
                                loading={personaloading}
                                isunboldheader="true"
                                token_type_id={TokenType.Persona}
                            />
                        </div>
                    </>
                )}
            </div>
        </AppWrapper>
    );
};

export default Home;
