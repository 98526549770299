import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { TokenType } from '../../components/common/enum'
import { CategoryNavRow } from '../../components/common/FlexNavRow'
import Item, { TokenItem } from '../../components/common/Item'
import { WalletPageTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { getData, postData } from '../../services/fetch'
import { getWalletAddress, getValue } from '../../services/storage'
import { translations } from "../../services/localization";
import { OnLoading } from '../../components/common/OnLoading'
import RPC from "../../components/common/web3RPC";
import { handleSearch, setUserTokens } from '../../components/common/commonFnc';
import { MenuSearchBox } from "../../components/common/Form";
import Pagination from '../../components/common/pagination'
import { useMediaQuery } from 'react-responsive'

const WalletSend = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [token_type_id, setToken_type_id] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tokenItems, setTokenItems] = useState([]);
  const [user, setUser] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const searchInputRef = useRef();
  const [last_page, setLast_page] = useState(1);
  const [page, setPage] = useState(1);

  const isPh = useMediaQuery({ maxWidth: 480 })

  const getItems = async (token_type_id, token_name, page = 1) => {
    const response = await handleSearch(token_name, { token_type_id }, "DESC", getWalletAddress(), page);
    response !== null && setItems(response.tokens || []);
    response !== null && setLast_page(response.last_page);
    setLoading(false);
  }

  useEffect(() => {
    if (Number(token_type_id) === Number(TokenType.Anima)) {
      navigate(`/wallet/send/address`, { state: { token: { token_type_id }, token_type_id } })
    } else if (Number(token_type_id) !== Number(TokenType.Token)) {
      setLoading(true);
      getItems(token_type_id);
    }

    return () => {
      setItems([]);
    }
  }, [token_type_id])

  useEffect(() => {
    getBalance(getWalletAddress());
    getTokenItems();
  }, [])

  const getTokenItems = async () => {
    const tokenItems = await getData(`user-tokens`);
    const tokens = await setUserTokens(tokenItems?.data?.response || [])
    setTokenItems(tokens);
    setUser(await getValue('user_info'))
  }

  const getBalance = async (address) => {
    const rpc = new RPC(null);
    const balance = await rpc.getBalance(address);
    setTokenBalance(JSON.parse(JSON.stringify(balance)));
  };

  const content = translations.walletsend

  const handleSearchSubmit = (e) => {
      e.preventDefault();
      if (!searchInputRef.current) return

      if (Number(token_type_id) === Number(TokenType.Token)) {
          const filteredArray = tokenItems.filter(item => item.token_name.includes(searchInputRef.current.value))
          setTokenItems(filteredArray)
      } else {
          getItems(token_type_id, searchInputRef.current.value);
      }
  }
  const handleSearchClear = () => {
      searchInputRef.current.value = "";
      if (Number(token_type_id) === Number(TokenType.Token)) {
          getTokenItems();
      } else {
          getItems(token_type_id);
      }
  }

  useEffect(() => {
    getItems(token_type_id, searchInputRef.current.value, page);
  }, [page])

  return (
    <AppWrapper title={translations.formatString(content.headerTitle)}>
      <WalletPageTitle title={translations.formatString(content.mainTitle)} />
      <div className='row mt-3'>
        <CategoryNavRow onChangeTokenType={setToken_type_id} />
      </div>
      <div className='divider' style={{ marginTop: 10, marginBottom: 21 }}></div>
      <div style={{ margin: '0px -5px' }}>
        {
          !loading
            ? <> {
              Number(token_type_id) === Number(TokenType.Token)
                ? <div className='wallet-token'>
                  <div className='m-b-16 df-center'>
                    <form onSubmit={handleSearchSubmit} style={{ width: '100%', maxWidth: 568}}>
                        <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleSearchClear} onClear={handleSearchClear} />
                    </form>
                  </div>
                  <div className="mt-4 row px-1" style={{ margin: '0px -5px' }}>
                    {tokenItems.map((item, index) =>
                      <div key={index} 
                        className={`col-6 col-sm-4 col-md-3`}
                        style={{padding: '0 5px', maxWidth: 235}}
                      >
                        <TokenItem item={item} balance={tokenBalance} user={user} onLoadingHandler={(val) => setLoading(val)} onGetItemHandler={getTokenItems} />
                      </div>)}
                  </div>
                </div>
                : <>
                  <div className='m-b-16 df-center'>
                    <form onSubmit={handleSearchSubmit} style={{ width: '100%', maxWidth: 568}}>
                        <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleSearchClear} onClear={handleSearchClear} />
                    </form>
                  </div>
                  <div className='row mt-4 px-1' style={{ margin: '0px -5px' }}>
                    {items.map((item, index) =>
                    <div key={index}
                      className={`col-6 col-sm-4 col-md-3`}
                      style={{padding: '0 5px', maxWidth: 235}}
                    >
                      <Item item={item} onClick={() => navigate(`/wallet/send/token/${token_type_id}/${item.token_id}`)} containerClassName={isPh ? 'token-item mx-auto' : ''} />
                    </div>)}
                  </div>
                  <div className='row mb-5' style={{ margin: '0px -5px' }}>
                    <div className='col-12 d-flex justify-content-center'>
                      <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} />
                    </div>
                  </div>
                </>
            }</>
            : <OnLoading />
        }
      </div>
    </AppWrapper>
  )
}

export default WalletSend
