import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getNameByContractAddress, getTypeByContractAddress, TokenType } from '../../components/common/enum';
import AppWrapper from '../../components/wrappers/AppWrapper';
import WalletWrapper from '../../components/wrappers/WalletWrapper';
import data from '../../data';
import { translations } from '../../services/localization';
import { Detail } from './Wallet';
import { formatNumber } from '../../components/common/commonFnc';

const WalletTransactionDetail = (props) => {
    const { transaction, wallet_address } = props;
    const content = translations.walletTransaction;
    const amount = getTypeByContractAddress(transaction.contract_address) === TokenType.Anima ? Number(transaction.amount) : 1;

    return (
        <div className='swal-react-modal my-3'>
            <Detail name={content.labelTxHash} value={transaction.tx_hash} className="grey-color" />
            <Detail name={content.labelBlockChain} value={'ANICANA chain'} className="grey-color" />
            <Detail name={`${content.labelSymbol}${content.labelSlash}${content.labelTokenName}`} value={`${getNameByContractAddress(transaction.contract_address)} / ${transaction.name}`} className="grey-color" />
            {
                transaction.token_id &&
                <Detail name={content.labelTokenID} value={transaction.token_id} className="grey-color" />
            }
            {
                (transaction.amount || transaction.amount === 0) &&
                <Detail name={content.labelAmount} value={formatNumber(amount)} token_symbol={transaction.token_symbol} className="grey-color" />
            }
            <Detail name={content.labelTimestamp} value={transaction.timestamp} className="grey-color" />
            <Detail name={transaction.from === wallet_address ? content.labelTo : content.labelFrom} value={transaction.from === wallet_address ? transaction.to : transaction.from} className="grey-color" />
        </div>
    )
}

export default WalletTransactionDetail