/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CardLineHeader } from "../../components/common/Card";
import { remove } from "../../components/common/commonFnc";
import { languageEnum, SearchType, TokenType } from "../../components/common/enum";
import { DatePicker, Select, TextBox } from "../../components/common/Form";
import { motion } from "framer-motion";
import {
    AbyssIcon, Anima2Icon, BirthdayIcon, ChevronRightIcon, CloseIconFilter, DeterminationIcon, DNAIcon, ElementIcon, ForceIcon, HeartIcon, IntelligenceIcon, MindIcon, StarIcon, Warning2Icon,
    FilterAdamantineRockIcon,
    FilterEternalTreeIcon,
    FilterCrimsonFlameIcon,
    FilterPeerlessSteelIcon,
    FilterHeavenlyDewIcon,
    FilterGoldenLightIcon,
    FilterUnfathomableAbyssIcon,
    CategoryIcon
} from '../../components/common/Icon';
import { appContext } from "../../context/appContext";
import { strings, translations } from "../../services/localization";
import { Btn, BtnCustom } from "../../components/common/Button"

// export const CardLineHeader = props => {
//     const { title, className, textClassName, dividerClassName, textStyle } = props;
//     return (
//         <div className={`row card-line-header ${className || ''}`}>
//             <p className={`col title fs-25 ${textClassName || ''}`} style={textStyle}>{title}</p>
//             <div className={`col divider ${dividerClassName || ''}`}></div>
//         </div>
//     )
// }

const Filter = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search, filter, updateFilter, resetFilter, increaseFilterCount, filterCount, language } = useContext(appContext);
    const content = translations.filter;
    const buttonlist = translations.buttonlist;
    const [sort, setSort] = useState("DESC");
    const [page, setPage] = useState(1);
    const [sidebar_items, setSidebar_items] = useState([]);

    const arcana_sidebar_items = [
        {
            id: 'Basics',
            name: translations.formatString(content.basics),
            className: 'group',
            to: '',
            items: [
                {
                    id: 'Elements（',
                    name: 'Elements',
                    icon: <ElementIcon />,
                    to: '/',
                    searchKey: 'element',
                    searchType: SearchType.Checkbox,
                    searchOptions: [
                        {
                            value: 0,
                            text: 'Eternal Tree',
                            icon: <FilterEternalTreeIcon className="icon-size" />,
                        },
                        {
                            value: 1,
                            text: 'Crimson Flame',
                            icon: <FilterCrimsonFlameIcon className="icon-size" />,
                        },
                        {
                            value: 2,
                            text: 'Adamantine Rock',
                            icon: <FilterAdamantineRockIcon className="icon-size" />,
                        },
                        {
                            value: 3,
                            text: 'Peerless Steel',
                            icon: <FilterPeerlessSteelIcon className="icon-size" />,
                        },
                        {
                            value: 4,
                            text: 'Heavenly Dew',
                            icon: <FilterHeavenlyDewIcon className="icon-size" />,
                        },
                        {
                            value: 5,
                            text: 'Golden Light',
                            icon: <FilterGoldenLightIcon className="icon-size" />,
                        },
                        {
                            value: 6,
                            text: 'Unfathomable Abyss',
                            icon: <FilterUnfathomableAbyssIcon className="icon-size" />,
                        },
                    ]
                },
                {
                    id: 'Bloodline',
                    name: 'Bloodline',
                    icon: <DNAIcon />,
                    to: '/',
                    searchKey: 'bloodline',
                    searchType: SearchType.Text,
                },
                {
                    id: 'Green Stars',
                    name: 'Green Stars',
                    icon: <StarIcon className="p-b-1" />,
                    to: '/',
                    searchKey: 'greenStar',
                    searchType: SearchType.Range,
                    max: 10,
                    normalizeValue: -1,
                },
                {
                    id: 'Birthday',
                    name: 'Birthday',
                    icon: <BirthdayIcon />,
                    to: '/',
                    searchKey: 'birthday',
                    searchType: SearchType.DateRange
                },
                // {
                //     id: 'ANIMA',
                //     name: 'ANIMA',
                //     icon: <Anima2Icon />,
                //     to: '/',
                //     searchKey: 'anima',
                //     searchType: SearchType.Range
                // },
                // {
                //     id: 'Vitality',
                //     name: 'Vitality',
                //     icon: <VitalityIcon />,
                //     to: '/',
                //     searchKey: 'VIT',
                //     searchType: SearchType.Range
                // },
                // {
                //     id: 'Magic Power',
                //     name: 'Magic Power',
                //     icon: <MagicIcon />,
                //     to: '/',
                //     searchKey: 'MP',
                //     searchType: SearchType.Range
                // },
            ]
        },
        {
            id: 'Scores',
            name: translations.formatString(content.scores),
            className: 'group',
            to: '',
            items: [
                {
                    id: 'FOR',
                    name: 'FOR',
                    icon: <ForceIcon />,
                    to: '/',
                    searchKey: 'FOR',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'ABS',
                    name: 'ABS',
                    icon: <AbyssIcon />,
                    to: '/',
                    searchKey: 'ABS',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'DFT',
                    name: 'DFT',
                    icon: <DeterminationIcon />,
                    to: '/',
                    searchKey: 'DFT',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'MND',
                    name: 'MND',
                    icon: <MindIcon />,
                    to: '/',
                    searchKey: 'MND',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'INT',
                    name: 'INT',
                    icon: <IntelligenceIcon />,
                    to: '/',
                    searchKey: 'INT',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'EXP',
                    name: 'EXP',
                    icon: <HeartIcon />,
                    to: '/',
                    searchKey: 'EXP',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
            ]
        },
    ]
    const persona_sidebar_items = [
        {
            id: 'Basics',
            name: translations.formatString(content.basics),
            className: 'group',
            to: '',
            items: [
                {
                    id: 'Elements（',
                    name: 'Elements',
                    icon: <ElementIcon />,
                    to: '/',
                    searchKey: 'element',
                    searchType: SearchType.Checkbox,
                    searchOptions: [
                        {
                            value: 0,
                            text: 'Eternal Tree',
                            icon: <FilterEternalTreeIcon className="icon-size" />,
                        },
                        {
                            value: 1,
                            text: 'Crimson Flame',
                            icon: <FilterCrimsonFlameIcon className="icon-size" />,
                        },
                        {
                            value: 2,
                            text: 'Adamantine Rock',
                            icon: <FilterAdamantineRockIcon className="icon-size" />,
                        },
                        {
                            value: 3,
                            text: 'Peerless Steel',
                            icon: <FilterPeerlessSteelIcon className="icon-size" />,
                        },
                        {
                            value: 4,
                            text: 'Heavenly Dew',
                            icon: <FilterHeavenlyDewIcon className="icon-size" />,
                        },
                        {
                            value: 5,
                            text: 'Golden Light',
                            icon: <FilterGoldenLightIcon className="icon-size" />,
                        },
                        {
                            value: 6,
                            text: 'Unfathomable Abyss',
                            icon: <FilterUnfathomableAbyssIcon className="icon-size" />,
                        },
                    ]
                },
                {
                    id: 'Anima',
                    name: 'Anima',
                    icon: <Anima2Icon className="p-b-1" />,
                    to: '/',
                    searchKey: 'anima',
                    searchType: SearchType.Range,
                }
            ]
        },
        {
            id: 'Scores',
            name: translations.formatString(content.scores),
            className: 'group',
            to: '',
            items: [
                {
                    id: 'FOR',
                    name: 'FOR',
                    icon: <ForceIcon />,
                    to: '/',
                    searchKey: 'FOR',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'ABS',
                    name: 'ABS',
                    icon: <AbyssIcon />,
                    to: '/',
                    searchKey: 'ABS',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'DFT',
                    name: 'DFT',
                    icon: <DeterminationIcon />,
                    to: '/',
                    searchKey: 'DFT',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'MND',
                    name: 'MND',
                    icon: <MindIcon />,
                    to: '/',
                    searchKey: 'MND',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'INT',
                    name: 'INT',
                    icon: <IntelligenceIcon />,
                    to: '/',
                    searchKey: 'INT',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
                {
                    id: 'EXP',
                    name: 'EXP',
                    icon: <HeartIcon />,
                    to: '/',
                    searchKey: 'EXP',
                    searchType: SearchType.Range,
                    min: 1,
                    max: 4196
                },
            ]
        },
    ]

    useEffect(() => {
        // updateFilter(null, null);
        if (filter && filter.token_type_id) {
            const _sidebar_items = Number(filter.token_type_id) === Number(TokenType.Arcana) ? arcana_sidebar_items : persona_sidebar_items;
            setSidebar_items(_sidebar_items);
        }
    }, [filter.token_type_id])

    // useEffect(() => {
    //     if (filter && filter.token_type_id) {
    //         getItem(search, filter, sort, page);
    //     }
    // }, [filterCount, search, sort, page])

    // const [loading, setLoading] = useState(false);
    // const [last_page, setLast_page] = useState(1);
    // const getItem = async (search, filter, sort, page) => {
    //     setLoading(true);
    //     var { tokens, last_page } = await handleSearch(search, filter, sort, '', page);
    //     setItems(tokens || []);
    //     setLast_page(last_page);
    //     setLoading(false);
    // }
    // const [items, setItems] = useState([]);

    const clear = () => {
        resetFilter();
        setTimeout(increaseFilterCount, 50);
    }
    const applySearch = () => {
        increaseFilterCount();
        if (!props.isSidebar) {
            setTimeout(props.onRequestClose, 50);
        }
    }

    return (
        <motion.div
            initial={{ '--opacity': 0 }}
            animate={{ '--opacity': 1 }}
            transition={{ duration: props.isSidebar ? 0.5 : 0 }}
            style={{ opacity: 'var(--opacity)' }}
            className="filter">
            <div className="sidebar-brand p-0 m-0 mt-4 mt-lg-0">
                <div className='d-flex flex-row align-items-center justify-content-between px-lg-4' style={{ minWidth: '320px' }}>
                    <div className={`title ${language === languageEnum.jp ? 'fs-30' : 'fs-40'} fw-400 p-0`}>
                        {translations.formatString(content.headerTitle)}
                    </div>
                    <div className="pointer"
                        onClick={props.onRequestClose}
                    >
                        {/*style={{ stroke: '#FFF', width: 'unset' }}*/}
                        <CloseIconFilter style={{ stroke: '#BF96FF' }} />
                    </div>
                </div>
            </div>
            <ul
                className={`${props.isSidebar ? 'sidebar-nav' : ''} filter filter-nav ${props.className || ''}`} id="accordionSidebar">
                {/* <li className="sidebar-brand p-0 m-0 mt-4 mt-lg-0">
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <span className={`title ${language === languageEnum.jp ? 'fs-30' : 'fs-40'} fw-400 p-0`}>
                            {translations.formatString(content.headerTitle)}
                        </span>
                        <div className="close-btn"
                            onClick={props.onRequestClose}
                        >
                            <CloseIconFilter active={1} />
                        </div>
                    </div>
                </li> */}
                <li key={`sub-category`}
                    className={`active group-row mx-3 mx-lg-0 pe-lg-3`}>
                    <a href='#'
                        className={'d-flex align-items-center justify-content-between w-100 accor-btn'}
                        data-bs-toggle="collapse"
                        data-bs-target={`#category`}>
                        <div className="d-flex align-items-center p-l-15">
                            <CategoryIcon style={{ height: 15 }} />
                            <small className='lg ms-2 p-b-2' style={{ textIndent: 0 }}>Category</small>
                        </div>
                        <ChevronRightIcon className={'accor-icon'} />
                    </a>
                    <div id={'category'}
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                    //  data-bs-parent={`#${item.id}`}
                    >
                        <div className='d-flex flex-row filter-accordion-body'>
                            <BtnCustom onClick={() => updateFilter('token_type_id', TokenType.Arcana)} variant={Number(filter.token_type_id) === Number(TokenType.Arcana) ? 'active' : 'cancel'} className={'border-radius-7 me-3'} style={{ height: 31.5 }}>
                                <font className="fw-400 fs-15 p-x-14 text-uppercase">{translations.formatString(content.arcana)}</font>
                            </BtnCustom>
                            <BtnCustom onClick={() => updateFilter('token_type_id', TokenType.Persona)} variant={Number(filter.token_type_id) === Number(TokenType.Persona) ? 'active' : 'cancel'} className={'border-radius-7 me-3'} style={{ height: 31.5 }}>
                                <font className="fw-400 fs-15 p-x-14 text-uppercase">{translations.formatString(content.persona)}</font>
                            </BtnCustom>
                        </div>
                    </div>
                </li>

                {
                    sidebar_items.map((item, index) => {
                        // const databsparent = `${item.name}id`;
                        return (
                            <li key={index} className={`${item.className} ${item.id === props.pageid ? 'active' : ''}`}
                                id={item.id}>
                                {
                                    item.items && item.items.length > 0 ?
                                        <>
                                            <CardLineHeader hasDivider className="mb-2" textClassName="fs-25" title={item.name} dividerClassName="dim" />
                                            <ul className={`me-3 ${item.type && item.type == 'row' ? 'ul-row' : ''}`}>
                                                {
                                                    item.items.map((subitem, subindex) => {
                                                        const collapseid = `subitem-collapse-${index}-${subindex}`;
                                                        return (
                                                            <li key={`sub-${subindex}`}
                                                                className={`${subitem.id == props.pageid ? 'active' : ''} ${item.type && item.type == 'row' ? 'group-row' : ''}`}>
                                                                <a href='#'
                                                                    className={`d-flex align-items-center justify-content-between w-100 accor-btn ${subitem.searchKey in filter ? "" : "collapsed"}`}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#${collapseid}`}>
                                                                    <div className="d-flex align-items-center p-l-15">
                                                                        {subitem.icon}
                                                                        <small className='lg ms-2 p-b-2' style={{ textIndent: 0 }}>{subitem.name}</small>
                                                                    </div>
                                                                    {
                                                                        item.type && item.type == 'row' ? <></> :
                                                                            <ChevronRightIcon className={'accor-icon'} />
                                                                    }
                                                                </a>
                                                                {
                                                                    item.type && item.type == 'row' ? <></> :
                                                                        <div id={collapseid}
                                                                            className={`accordion-collapse collapse ${subitem.searchKey in filter ? "show" : ""}`}
                                                                            aria-labelledby="headingOne"
                                                                        //  data-bs-parent={`#${item.id}`}
                                                                        >
                                                                            {
                                                                                subitem.searchType === SearchType.Checkbox ?
                                                                                    <CheckboxRow name={subitem.name}
                                                                                        searchKey={subitem.searchKey}
                                                                                        options={subitem.searchOptions}
                                                                                        filter={filter} updateFilter={updateFilter}
                                                                                    /> :
                                                                                    subitem.searchType === SearchType.Range ?
                                                                                        <MinMaxRow
                                                                                            searchKey={subitem.searchKey} normalizeValue={subitem.normalizeValue}
                                                                                            filter={filter} updateFilter={updateFilter} min={subitem.min} max={subitem.max} /> :
                                                                                        subitem.searchType === SearchType.Dropdown ?
                                                                                            <DropdownRow
                                                                                                searchKey={subitem.searchKey}
                                                                                                options={subitem.searchOptions}
                                                                                                filter={filter} updateFilter={updateFilter} /> :
                                                                                            subitem.searchType === SearchType.Text ?
                                                                                                <TextBoxRow
                                                                                                    searchKey={subitem.searchKey}
                                                                                                    filter={filter} updateFilter={updateFilter} /> :
                                                                                                subitem.searchType === SearchType.Date ?
                                                                                                    <DatePickerRow
                                                                                                        searchKey={subitem.searchKey}
                                                                                                        filter={filter} updateFilter={updateFilter} /> :
                                                                                                    subitem.searchType === SearchType.DateRange ?
                                                                                                        <MinMaxDateRow
                                                                                                            searchKey={subitem.searchKey}
                                                                                                            filter={filter} updateFilter={updateFilter} /> :
                                                                                                        <></>
                                                                            }
                                                                        </div>
                                                                }
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </> : <Link to={item.to}><small>{item.name}</small></Link>
                                }
                            </li>
                        )
                    })
                }
                <li className='group'>
                    <CardLineHeader hasDivider title={translations.formatString(content.saleStatus)} className='mt-4 mb-2' dividerClassName="dim" />
                    <ul className="me-3">
                        <li className='align-items-start mb-3'>
                            <BoolRow
                                name={'undersale'} searchKey={'is_sell'}
                                option={{
                                    value: true,
                                    text: strings.formatString(content.underSale)
                                }}
                                filter={filter} updateFilter={updateFilter}
                            />
                        </li>
                        {
                            filter.is_sell ?
                                <li className='price-range' >
                                    <MinMaxRow searchKey={'price'} className="ps-0" inputClassName="w-100" filter={filter} updateFilter={updateFilter} />
                                </li> : <></>
                        }
                        <li className='align-items-start mb-0'>
                            <BoolRow
                                name={'undersaleanima'} searchKey={'is_sell_anima'}
                                option={{
                                    value: true,
                                    text: strings.formatString(content.underSaleAnima)
                                }}
                                filter={filter} updateFilter={updateFilter}
                            />
                        </li>
                        {
                            filter.is_sell_anima ?
                                <li className='price-range' >
                                    <MinMaxRow searchKey={'anima_price'} className="ps-0" inputClassName="w-100" filter={filter} updateFilter={updateFilter} />
                                </li> : <></>
                        }
                        {/* <li className='d-none d-lg-block' style={{ textIndent: 0, marginTop: 32 }}>
                                        <button variant={2} className="w-100" onClick={applySearch}>Apply</button>
                                    </li> */}
                    </ul>
                </li>
            </ul >
            <div className='d-flex filter-action'>
                <div className='d-flex flex-row align-items-center justify-content-evenly w-100 h-100'>
                    <Btn
                        intent="outline"
                        className="btn-150 my-3 mx-0"
                        onClick={clear}
                    >
                        {translations.formatString(buttonlist.clearBtn)}
                    </Btn>
                    <Btn
                        onClick={applySearch}
                        className="btn-150 my-3"
                    >
                        {translations.formatString(buttonlist.applyBtn)}
                    </Btn>
                </div>
            </div>
        </motion.div>
    )
}

export default Filter;

const BoolRow = ({ name, searchKey, option, filter, updateFilter }) => {
    const handleChange = () => {
        let value = filter[searchKey];
        value = !value
        if (value === false) {
            if (searchKey === "is_sell") {
                updateFilter("price", {})
            }
            else if (searchKey === "is_sell_anima") {
                updateFilter("anima_price", {})
            }
            updateFilter(searchKey, {});
        }
        else {
            updateFilter(searchKey, value);
        }


    }

    const id = `${name}check`;
    const checked = filter[searchKey] ? filter[searchKey] : false;
    return (
        <div className="form-check">
            <div className="filter-input-check">
                <input
                    className="form-check-input"
                    type="checkbox" checked={checked}
                    onChange={handleChange}
                    value={option.value} id={id}
                />
            </div>
            <label className="form-check-label" htmlFor={id}>
                {option.text}
            </label>
        </div>
    )
}


const CheckboxRow = ({ name, searchKey, options, filter, updateFilter }) => {
    const handleChange = e => {
        let value = filter[searchKey];
        let selected_value = e.target.value;
        if (value) {
            if (value.includes(selected_value.toString())) {
                value = remove(value, selected_value); // remove / uncheck
            } else {
                value.push(selected_value); // add / check
            }
        } else {
            value = [];
            value.push(selected_value);
        }
        updateFilter(searchKey, value);
    }

    return (
        <div className='d-flex flex-column filter-accordion-body'>
            {
                options.map((option, index) => {
                    const id = `${name}check-${index}`;
                    const checked = filter[searchKey] ? filter[searchKey].includes(option.value.toString()) : false;
                    return (
                        <div key={index} className="form-check mb-2 d-flex align-items-center">
                            <div className="filter-input-check">
                                <input className="form-check-input" type="checkbox" checked={checked} onChange={handleChange} value={option.value} id={id} />
                            </div>
                            <div className={`filter-icon-div ${checked ? 'active' : ''}`}>
                                {option.icon}
                            </div>
                            <label className={`form-check-label ${checked ? 'primary-color' : ''}`} htmlFor={id}>
                                {option.text}
                            </label>
                        </div>
                    )
                })
            }
        </div>
    )
}

const MinMaxRow = ({ searchKey, className, inputClassName, filter, updateFilter, min, max, normalizeValue = 0, type = 'number' }) => {
    const content = translations.filter;
    const [showMessage, setShowMessage] = useState(false);
    const handleChange = (e, type) => {
        let input_value = parseInt(e.target.value)
        let value = filter[searchKey] || {};
        if (input_value && max && input_value > max) {
            input_value = max;
            setShowMessage(true);
        }
        value[type] = input_value;
        if (normalizeValue) {
            value["normalizeValue"] = normalizeValue;
        }
        updateFilter(searchKey, value);
    }

    const handleOnBlur = (e, type) => {
        let input_value = parseInt(e.target.value)
        let value = filter[searchKey] || {};
        if (input_value && max && input_value > max) {
            input_value = max;
            setShowMessage(true);
        } else if (input_value && min && input_value < min) {
            input_value = min;
        }
        value[type] = input_value;
        if (normalizeValue) {
            value["normalizeValue"] = normalizeValue;
        }
        updateFilter(searchKey, value);
    }

    const handleKeyPress = e => {
        if (e.key === "-" || e.key === "e") {
            e.preventDefault();
        }
    }

    const minmax = min && max ? { min, max } : min && !max ? { min } : !min && max ? { max } : {}

    return (
        <div className={`d-flex flex-column filter-accordion-body minmaxrow ${className}`}>
            <div className="d-flex flex-row">
                <TextBox className={`h-auto ${inputClassName || ''}`} type="number" {...minmax} placeholder="min"
                    value={filter[searchKey] ? (filter[searchKey]['low'] || '') : ''}
                    onChange={e => handleChange(e, 'low')} onBlur={e => handleOnBlur(e, 'low')} onKeyPress={handleKeyPress} />
                <span className='mx-2' style={{ textIndent: 0 }}>~</span>
                <TextBox className={`h-auto ${inputClassName || ''}`} type="number" {...minmax} placeholder="max"
                    value={filter[searchKey] ? (filter[searchKey]['high'] || '') : ''}
                    onChange={e => handleChange(e, 'high')} onBlur={e => handleOnBlur(e, 'high')} onKeyPress={handleKeyPress} />
            </div>
            {
                max && showMessage &&
                <div className={"d-flex flex-row align-items-center"}>
                    <span style={{ fontSize: 13, textTransform: 'inherit', maxWidth: 'unset', textIndent: 0 }} className='fw-400 f-regular attribute-value'><Warning2Icon style={{ width: 14, height: 14, marginTop: -1 }} /> {`${translations.formatString(content.scoreMaxWarning, max)}`}</span>
                </div>
            }
        </div>
    )
}

const MinMaxDateRow = ({ searchKey, filter, updateFilter, className }) => {
    const handleChange = (date, type) => {
        const value = filter[searchKey] || {};
        if (date) {
            value[type] = moment(date).format('YYYY-MM-DD')
        } else {
            delete value[type]
        }
        updateFilter(searchKey, value);
    }

    return (
        <div className={`d-flex flex-row filter-accordion-body minmaxrow ${className}`}>
            <DatePicker className="datepicker" date={filter[searchKey] ? (filter[searchKey]['low'] ? new Date(filter[searchKey]['low']) : '') : ''} placeholder="min" onChange={date => handleChange(date, 'low')} />
            <span className='mx-2' style={{ textIndent: 0 }}>~</span>
            <DatePicker className="datepicker" date={filter[searchKey] ? (filter[searchKey]['high'] ? new Date(filter[searchKey]['high']) : '') : ''} placeholder="max" onChange={date => handleChange(date, 'high')} />
        </div>
    )
}

const DropdownRow = ({ searchKey, options, className, filter, updateFilter }) => {
    const handleChange = (e) => {
        updateFilter(searchKey, e.target.value);
    }

    return (
        <div className={`d-flex flex-row filter-accordion-body ${className}`}>
            <Select className={'sort'} options={options} value={filter[searchKey] || ''} onChange={handleChange} />
        </div>
    )
}

const TextBoxRow = ({ searchKey, className, filter, updateFilter }) => {
    const handleChange = (e) => {
        updateFilter(searchKey, e.target.value);
    }

    return (
        <div className={`d-flex flex-row filter-accordion-body tt ${className}`}>
            <TextBox style={{ maxWidth: 185, height: 33 }} value={filter[searchKey] || ''} onChange={handleChange} type="number" number="number" />
        </div>
    )
}

const DatePickerRow = ({ searchKey, className, filter, updateFilter }) => {
    const handleChange = (date) => {
        updateFilter(searchKey, moment(date).format('YYYY-MM-DD'));
    }

    return (
        <div className={`d-flex flex-row filter-accordion-body ${className}`} style={{ textIndent: 0 }}>
            <DatePicker className="datepicker" date={filter[searchKey] ? new Date(filter[searchKey]) : ''} onChange={handleChange} />
        </div>
    )
}
