import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConnectedIcon, DisConnectedIcon } from '../../components/common/Icon';
import Modal from '../../components/common/Modal';
import { SwalCorrect } from '../../components/common/Swal';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { AuthContext } from '../../context/authContext';
import { Web3authContext } from '../../context/web3authContext';
import { postData } from '../../services/fetch';
import { getWalletAddress } from '../../services/storage';
import { WalletAddress } from './Wallet';
import { strings, translations } from "../../services/localization";
import { Btn } from '../../components/common/Button';

const WalletSetting = () => {
    const navigate = useNavigate();
    const [displayModal, setDisplayModal] = useState(false);
    const { logout } = useContext(AuthContext);
    const { wallet_address, setWallet_address, web3auth, provider, init, getAccounts } = useContext(Web3authContext);
    const [web3Initialzed, setWeb3Initialized] = useState(false);
    const content = translations.walletSetting;
    const buttonlist = translations.buttonlist;
    const userWalletPage = translations.userWalletPage;
    const disconnect = async () => {
        var response = await postData(`user/update`, { wallet_address: null });
        if (response.ok) {
            setWallet_address('');
            logout();
            SwalCorrect.fire({
                title: translations.formatString(userWalletPage.complete_Modal_Text)
            }).then((result) => {
                if (result.isConfirmed) {
                    setDisplayModal(false);
                }
            });
        }
    }

    const handleConnect = async () => {
        if (provider) {
            connect();
        } else {
            init();
            setWeb3Initialized(true);
        }
    }

    useEffect(() => {
        if (!wallet_address && provider && web3Initialzed) {
            connect();
        }

        return () => {
        }
    }, [provider])

    const connect = async () => {
        const wallet_address = await getAccounts();
        console.log('connect', wallet_address);
        if (wallet_address) {
            var response = await postData(`user/update`, { wallet_address });
            if (response.ok) {
                setWallet_address(wallet_address);
                SwalCorrect.fire({
                    title: 'Your wallet has been successfully connected.'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setDisplayModal(false);
                    }
                });
                // navigate('/');
            }
        }
    }

    return (
        <AppWrapper title={translations.formatString(content.mainTitle)} hideBottomNav={displayModal}>
            <div className='row'>
                <div className='col-12'>
                    <WalletAddress />
                    <div style={{ margin: '36px 0' }}>
                        <div className='divider'></div>
                    </div>
                    <div className='' style={{ marginBottom: 60 }}>
                        <span>{translations.formatString(userWalletPage.Status)}</span>
                        {/* {
                            wallet_address ?
                                <div className='d-flex flex-row align-items-center ms-3 mt-1'>
                                    <ConnectedIcon className='me-2' />
                                    <span>{ translations.formatString(userWalletPage.Connected) }</span>
                                </div>
                                :
                                <div className='d-flex flex-row align-items-center ms-3 mt-1'>
                                    <DisConnectedIcon className='me-2' />
                                    <span>{ translations.formatString(userWalletPage.Not_connected) }</span>
                                </div>
                        } */}
                        <div className='d-flex flex-row align-items-center ms-3 mt-1'>
                            <ConnectedIcon className='me-2' />
                            <span>{translations.formatString(userWalletPage.Connected)}</span>
                        </div>
                        <div className='d-flex flex-row align-items-center ms-3 mt-1'>
                            <DisConnectedIcon className='me-2' />
                            <span>{translations.formatString(userWalletPage.Not_connected)}</span>
                        </div>
                    </div>
                    <div className='flex-center flex-column'>
                        {/* {
                            wallet_address ?
                                <button type='button' onClick={() => setDisplayModal(true)} className='btn btn-custom-cancel btn-mini mb-4'>{translations.formatString(buttonlist.disconnectBtn)}</button>
                                :
                                <button type='button' onClick={() => handleConnect()} className='btn btn-custom-cancel btn-mini'>{translations.formatString(buttonlist.connectBtn)}</button>
                        } */}
                        <Btn type='outlineMini' onClick={() => setDisplayModal(true)} className='mb-4'>{translations.formatString(buttonlist.disconnectBtn)}</Btn>
                        <Btn intent="outlineMini" type='button' onClick={() => handleConnect()} >{translations.formatString(buttonlist.connectBtn)}</Btn>
                    </div>
                    <Modal displayModal={displayModal} onClose={() => setDisplayModal(!displayModal)}>
                        <span className="fw-500">
                            {translations.formatString(userWalletPage.confirm_Modal_Title)}
                        </span>
                        <div className='flex-center flex-column'>
                            <Btn intent="activeMini" type='button' onClick={() => disconnect()} className='mb-4' style={{ margin: '33px 0' }}>{translations.formatString(buttonlist.disconnectBtn)}</Btn>
                            <Btn intent="outlineMini" type='button' onClick={() => setDisplayModal(false)}>{translations.formatString(buttonlist.backBtn)}</Btn>
                        </div>
                    </Modal>
                </div>
            </div>
        </AppWrapper>
    )
}

export default WalletSetting