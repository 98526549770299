import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const SwipeableCard = ({
  children,
  buttonComponent,
  containerStyle = {},
  cardStyle = {},
  buttonContainerWidth = 80 }) => {
  const [showButton, setShowButton] = useState(false);

  const wrapperStyle = {
    ...containerStyle,
    position: 'relative',
  }

  const _cardStyle = {
    ...cardStyle,
    width: '100%',
    position: 'relative',
    zIndex: 2,
    transition: 'transform 300ms ease-out',
  }

  const buttonStyle = {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    right: 0,
    width: buttonContainerWidth,
    transform: 'translateY(-50%)',
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setShowButton(true);
    },
    onSwipedRight: () => {
      setShowButton(false);
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  });

  const transform = `translateX(${showButton ? -buttonContainerWidth : 0}px)`

  return (
    <div style={wrapperStyle}>
      <div {...swipeHandlers} style={{ ..._cardStyle, transform }}>
        {children}
      </div>
      <div style={buttonStyle}>
        {buttonComponent}
      </div>
    </div>
  );
};

export default SwipeableCard;
