import React, { useEffect, useState } from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { translations } from '../../services/localization';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderStatus, TokenType, orderCurrency } from '../../components/common/enum';
import { BrowserView, MobileView } from 'react-device-detect';
import { WalletPageTitle } from '../../components/common/Title';
import { ArcanaIcon, BackGradientIcon, PersonaIcon } from '../../components/common/Icon';
import { TextBox } from '../../components/common/Form';
import { OfferAdditionBtn } from './Offer';
import { getWalletAddress, setBidInfo } from '../../services/storage';
import RPC from '../../components/common/web3RPC'
import { getData, postData } from '../../services/fetch';
import { SwalWrong } from '../../components/common/Swal';
import { OnLoading } from '../../components/common/OnLoading';
import { REACT_APP_MARKETPLACE_ENDPOINT } from '../../config';
import { formatNumber } from '../../components/common/commonFnc';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const OfferPrice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token, type, currency } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState({ amount: '', currency });
    const content = translations.offer;
    const selectedItems = useSelector(state => state.myArcana.selectedItems);
    const isMobile = useMediaQuery({ maxWidth: 992 })

    const handleSelectCurrency = () => {
        if (currency) {
            navigate('/offer/ask/price', { state: { token, type } })
        }
    }

    const sellToken = async (offer) => {
        console.log("SELL TOKEN", offer)
        setLoading(true)
        const response = await getData(`user/bank-account`);
        if (response.ok) {
            if (response.data.has_bank_account) {
                setLoading(false)
                if (token || selectedItems.length > 0) {
                    setBidInfo(JSON.stringify({
                        token_id: token ? token.token_id : null,
                        order: {
                            type: OrderStatus.Ask,
                            token_id: token ? token.token_id : null,
                            token_type_id: token ? token.token_type_id : null,
                            amount: offer.amount,
                            currency: offer.currency,
                            status: "active",
                        },
                        creator: token ? token.creator : null,
                        token: token,
                        tokens: selectedItems,
                    }))
                    navigate("/offer/ask/confirmation", {
                        state: {
                            token_id: token ? token.token_id : null,
                            order: {
                                type: OrderStatus.Ask,
                                token_id: token ? token.token_id : null,
                                token_type_id: token ? token.token_type_id : null,
                                amount: offer.amount,
                                currency: offer.currency,
                                status: "active",
                            },
                            creator: token ? token.creator : null,
                            token: token,
                            tokens: selectedItems,
                        }
                    });
                }
            } else {
                setLoading(false)
                if (response.data.has_bank_account === false) {
                    SwalWrong.fire({
                        title: "エラー",
                        text: '銀行口座がありません。先にOctillionにて銀行口座を作成して下さい',
                        confirmButtonText: translations.formatString(translations.buttonlist.goToRegisterBtn)
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = REACT_APP_MARKETPLACE_ENDPOINT + "mypage/payment";
                        }
                    });
                } else {
                    SwalWrong.fire({
                        title: "エラー",
                        text: '通信エラーが発生しました。リロードしてもエラーが発生する場合は一度ログアウトし、再度ログインして下さい。'
                    });
                }
            }
        } else {
            setLoading(false)
            SwalWrong.fire({
                title: "Error",
                text: response.data.message,
            });
        }


    }

    const buyToken = async (offer) => {
        setBidInfo(JSON.stringify({
            token_id: token.token_id,
            order: {
                type: OrderStatus.Bid,
                token_id: token.token_id,
                token_type_id: token.token_type_id,
                amount: offer.amount,
                currency: offer.currency,
                status: "active",
            },
            creator: token.creator,
            token
        }))
        navigate("/offer/bid/confirmation", {
            state: {
                token_id: token.token_id, order: {
                    type: OrderStatus.Bid,
                    token_id: token.token_id,
                    token_type_id: token.token_type_id,
                    amount: offer.amount,
                    currency: offer.currency,
                    status: "active",
                }, creator: token.creator, token
            }
        });
    }

    useEffect(() => {
        console.log({ offer })
    }, [offer])

    const handlePriceSubmit = () => {
        console.log("handlePriceSubmit", { offer })
        if (type === OrderStatus.Ask) {
            sellToken(offer);
        } else if (type === OrderStatus.Bid) {
            buyToken(offer);
        }
    }

    return (
        <AppWrapper sidebarWrapperDivClassName={'p-t-8'} title={translations.formatString(type === OrderStatus.Ask ? content.AskOffer : content.BidOffer)}
            disableFooter
            additionalBottom={
                <MobileView>
                    <OfferPriceAdditionalBtn
                        onCancel={() => navigate(-1)}
                        onSubmit={handlePriceSubmit}
                        offer={offer}
                        setOffer={setOffer}
                        submitEnabled={offer.amount && offer.currency}
                        type={type}
                        currency={currency}
                        currentBidOffer={token ? (currency === orderCurrency.ANIMA ? (token.anm.bid || 0) : (token.jpy.bid || 0)) : 0}
                        loading={loading}
                    />
                </MobileView>
            }
        >
            <div className='px-4'>
                <BackGradientIcon onClick={() => navigate(-1)} className="position-absolute" style={{ left: 22, top: 29 }} />
                <div className='d-flex flex-column align-items-center'>
                    <WalletPageTitle className='text-white p-x-16' style={{ lineHeight: 32.5 }} underlineClassName='w-100' title={translations.formatString(type === OrderStatus.Ask ? content.setAskPrice : content.setBidPrice)} hasUnderline />
                    <div className='d-flex flex-column' style={{ minHeight: 300, gap: 5 }}>
                        {token ? <TokenCard image={token.ipfs_image || token.image_url || token.image || null} name={token.name} token_id={token.token_id} token_type_id={token.token_type_id} />
                            : selectedItems.map((item, index) => <TokenCard key={index} image={item.ipfs_image || item.image_url || item.image || null} name={item.name} token_id={item.token_id} token_type_id={item.token_type_id} />)}
                    </div>
                    <BrowserView>
                        <OfferPriceAdditionalBtn
                            onCancel={() => navigate(-1)}
                            onSubmit={handlePriceSubmit}
                            offer={offer}
                            setOffer={setOffer}
                            submitEnabled={offer.amount && offer.currency}
                            type={type}
                            currency={currency}
                            currentBidOffer={token ? (currency === orderCurrency.ANIMA ? (token.anm.bid || 0) : (token.jpy.bid || 0)) : 0}
                        />
                    </BrowserView>
                </div>
            </div>
        </AppWrapper >
    )
}

export default OfferPrice

const OfferPriceAdditionalBtn = ({ type, currency, currentBidOffer, offer, setOffer, ...rest }) => {
    const commonlist = translations.common;
    const offerTxt = translations.offer;
    const [animaBalance, setAnimaBalance] = useState(0);
    const changePrice = (val) => {
        if (currency === orderCurrency.YEN && val && val < 1) {
            val = 1;
        }

        setOffer({ amount: val, currency })

    };

    const getAnimaBalance = async (address) => {
        const rpc = new RPC();
        const balance = await rpc.getAnimaBalance(address, 3);
        setAnimaBalance(balance || 0);
    };

    useEffect(() => {
        console.log(type, currency, type === OrderStatus.Ask && currency === orderCurrency.ANIMA)
        if (type === OrderStatus.Ask && currency === orderCurrency.ANIMA) {
            getAnimaBalance(getWalletAddress());
        }
    }, [type, currency])

    const minProps = currency === orderCurrency.ANIMA ? { min: 0 } : { min: 1 };
    return (
        <div className=''>
            <div className={`d-flex flex-column mx-0 m-b-${type === OrderStatus.Ask && currency === orderCurrency.ANIMA ? 23 : 45}`}>
                {
                    type === OrderStatus.Ask &&
                    <div className={'m-b-27 text-center'}>
                        <span>
                            {translations.formatString(offerTxt.currentBidOffer)}:
                        </span>
                        <span className={'ml-3 subHeader'} style={{ marginLeft: '20px' }}>
                            {translations.formatString(currency === orderCurrency.ANIMA ? commonlist.formattedAnima : commonlist.formattedYen, formatNumber(currentBidOffer || 0))}
                        </span>
                    </div>
                }
                <div className='px-0'>
                    <TextBox currency={translations.formatString(currency === orderCurrency.ANIMA ? commonlist.animaSymbol : commonlist.yenSymbol)} {...minProps} number={1} type={"number"} className="focus border-focus" inputStyle={{ height: 40 }} onChange={e => changePrice(e.target.value)} value={offer.amount} />
                </div>
                {
                    type === OrderStatus.Ask && currency === orderCurrency.ANIMA &&
                    <div className={'d-flex justify-content-end px-0'}>
                        <span className='fw-400 fs-15 f-regular'>
                            {translations.formatString(offerTxt.myAnimaBalance)}: {formatNumber(animaBalance)}
                        </span>
                    </div>
                }
            </div>
            <OfferAdditionBtn {...rest} isConfirmBtn />
        </div>
    )
}

export const TokenCard = ({ image, name, token_id, token_type_id, containerStyle, elementStyle }) => {
    const navigate = useNavigate();
    return (
        <div className='new-games-card'>
            <div className='new-games-grid'>
                <div className={`new-games-new pointer ${containerStyle ? containerStyle : ""}`}>
                    <div className={`new-games-new-card ${elementStyle ? elementStyle : ""}`}>
                        <div
                            onClick={() => navigate(`/wallet/${TokenType.getRouteName(token_type_id)}/detail/${token_id}`)}
                            className='d-flex align-items-center'
                            style={{ width: 'inherit' }}
                        >
                            <img style={{ width: '60px', height: '60px', borderRadius: '5px', marginRight: '10px', objectFit: 'cover' }} src={image || require('../../assets/images/item_img.png')} alt="" />
                            <div className='d-flex flex-column' style={{ maxWidth: 195 }}>
                                <div>
                                    <span className='mb-1 fs-14 f-regular fw-600 text-capitalize primary-color tx-ellipsis'>
                                        {name}
                                    </span>
                                </div>
                                <span className={`mb-1 fs-14 new-games-new-card-name tx-ellipsis`}>
                                    Token ID: {token_id}
                                </span>
                            </div>
                            <div className='position-absolute' style={{ right: 14 }}>
                                {
                                    Number(token_type_id) === Number(TokenType.Arcana) ?
                                        <ArcanaIcon className="float-end btn-reset text-uppercase btn-bold token-type-img active-3 h-auto" style={{ width: 35 }} /> :
                                        Number(token_type_id) === Number(TokenType.Persona) ?
                                            <PersonaIcon className="float-end btn-reset text-uppercase btn-bold token-type-img active-3 h-auto" style={{ width: 35 }} /> :
                                            <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
