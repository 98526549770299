import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  position:{
    pageNum: null,
    scrollPositionY: null,
    data: [],
    scrollToBot: false,
    lastPage: null,
    more: false
  }
}

const positionSlice = createSlice({
  name:'position',
  initialState:initialState,
  reducers:{
    setPosition(state, action){
      state.position.scrollPositionY = action.payload.scrollPositionY || state.position.scrollPositionY
      state.position.scrollToBot = action.payload.scrollToBot || state.position.scrollToBot
    },
    setTokenPage(state, action){
      state.position.pageNum = action.payload.pageNum || state.position.pageNum
      state.position.lastPage = action.payload.lastPage
      state.position.more = action.payload.more
    },
    setTokenData(state, action){
      state.position.data = action.payload.data || state.position.data
    },
    setPositionY(state, action){
      state.position.scrollPositionY = action.payload.scrollPositionY || null
      state.position.data = action.payload.data || null
      state.position.pageNum = action.payload.pageNum || null
    },
  }
})


  
export const { setPosition, setPositionY, setTokenPage, setTokenData } = positionSlice.actions;

export default positionSlice