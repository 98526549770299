import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { AccountCardIcon, ProfileForNoImgIcon, ProfileIcon, ProfileVectorIcon, SignOutIcon } from "../../components/common/Icon";
import { AccountDetailIcon } from "../../components/common/Icon";
import { AccountHistoryIcon } from "../../components/common/Icon";
import { AccountProfileIcon } from "../../components/common/Icon";
import { AccountSettingsIcon } from "../../components/common/Icon";
import { AccountWalletIcon } from "../../components/common/Icon";
import { SwalSettings } from "../../components/common/Swal";
import { AuthContext } from "../../context/authContext";
import { strings, translations } from "../../services/localization";
import Modal from "../../components/common/Modal";
import { useMediaQuery } from 'react-responsive'

import "../../style/Account.scss";
import { useState, useContext } from "react";
import { clearLocalStorage, getValue } from "../../services/storage";
import data from "../../data";

function Account() {

  const accountPage = translations.accountPage; // Usage: strings.formatString(accountPage.Account)
  const buttonlist = translations.buttonlist;
  const confirmSignOut = translations.message.confirmSignOut;
  const [displayModal, setDisplayModal] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.language);
  const { user, userIdms, logout } = useContext(AuthContext)

  const onSignOutEvent = () => {
    SwalSettings.fire({
      text: confirmSignOut,
      confirmButtonText: strings.formatString(buttonlist.yesBtn),
      cancelButtonText: strings.formatString(buttonlist.cancelBtn),
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  }

  const onSignOutModalEvent = () => {
    logout();
    setDisplayModal(false)
  }

  const isPC = useMediaQuery({ minWidth: 992 })

  const onSignOutHandler = () => {
    isPC ? onSignOutEvent() : setDisplayModal(true)
  }

  const SignOutModal = () => {
    return (
      <>
        <Modal displayModal={displayModal} onClose={() => setDisplayModal(!displayModal)}>
            <p className="text-center fs-20">
            { confirmSignOut }
            </p>
            <div className='flex-center flex-column'>
                <button type='button' onClick={onSignOutModalEvent} className='btn btn-custom-active w-75 mb-4 fs-25' style={{ margin: '33px 0' }}>
                  <span className="fs-25">
                    {strings.formatString(buttonlist.yesBtn)}
                  </span>
                </button>
                <button type='button' onClick={() => setDisplayModal(false)} className='btn btn-custom-cancel w-75 fs-25'>
                  <span className="fs-25">
                    {strings.formatString(buttonlist.noBtn)}
                  </span>
                </button>
            </div>
        </Modal>
      </>
    );
  };

  const metaTitle = 'アカウント｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  return (
    <>
      <AppWrapper className="auth-container" 
        // title={accountPage.Account} 
        title="Account"
        activeProfile hideBottomNav={displayModal}
        metaTitle={metaTitle} metaDescription={metaDescription}
      >
        <div>
          <div className="d-flex align-items-center flex-column m-2">
            <div className="position-relative profile-acc-pic">
              {
                user?.image ?
                <div className="rounded-circle over-h profile-pic-general profile-acc-pic d-flex justify-content-center" style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: "url("+user.image+")"
                }}>
                </div> :
                  <ProfileForNoImgIcon className="position-absolute w-100 h-100 end-0" />
                  // <ProfileVectorIcon className="position-absolute w-100 h-100 end-0" />
              }
            </div>
            <span className="mx-3" style={{ fontSize: '25px',fontFamily :'KardustExpandedRegular' }}>{userIdms?.account_name ? userIdms.account_name : 'no name here'}</span>
            <span  style={{ fontSize: '18px' ,fontFamily :'KardustExpandedRegular' }}>{user?.email}</span>
          </div>
          <div className="divider mb-5 mt-4 w-100 mx-auto"></div>
        </div>
        <div className="my-3 ms-3">
          <div className="my-3 d-flex">
            <Link className="d-flex align-items-center" to="/account_detail">
              <AccountProfileIcon />
              <span className="mx-2" style={{ fontSize: '20px',fontFamily :'KardustExpandedRegular' }}>{ strings.formatString(accountPage.Account) }</span>
            </Link>
          </div>
          {/*<div className="my-3 d-flex">*/}
          {/*  <Link className="d-flex align-items-center" to="/wallet/setting">*/}
          {/*    <AccountCardIcon />*/}
          {/*    <span className="kardust-light-font mx-2">{ strings.formatString(accountPage.Payment) }</span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/* <div className="my-3 d-flex">
            <Link className="d-flex align-items-center" to="/user_profile">
              <AccountCardIcon />
              <span className="kardust-light-font mx-2">Payment</span>
            </Link>
          </div>
          <div className="my-3 d-flex">
            <Link className="d-flex align-items-center" to="/user_profile">
              <AccountHistoryIcon />
              <span className="kardust-light-font mx-1">Trade History</span>
            </Link>
          </div> */}
          <div className="my-3 d-flex">
            <Link className="d-flex align-items-center" to="/settings">
              <AccountSettingsIcon />
              <span className="mx-2"  style={{ fontSize: '20px',fontFamily :'KardustExpandedRegular' }}>{ strings.formatString(accountPage.Settings) }</span>
            </Link>
          </div>
        </div>
        <div onClick={onSignOutHandler} className="toggle-signout me-3 my-5 d-flex justify-content-end align-items-center">
          <SignOutIcon />
          <span className="mx-1"  style={{ fontSize: '20px',fontFamily :'KardustExpandedRegular' }}>{ strings.formatString(accountPage.Signout) }</span>
        </div>

            {
              <SignOutModal />
            }
      </AppWrapper>
    </>
  );
}

export default Account;
