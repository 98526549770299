import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { GameHeaderItem, GameNewCardItems, ItemCarousel } from '../../components/common/Item'
import Button from "../../components/common/Button";
import axios from "axios";
import { Container, Row, div } from "react-bootstrap";
import { API_DOMAIN } from "../../config";
import AppWrapper from "../../components/wrappers/AppWrapper";
import "../../style/gamelist.scss";
import { GamesMoreIcon } from "../../components/common/Icon";
import { OnLoading } from "../../components/common/OnLoading";
import { appContext } from "../../context/appContext";
import { useContext } from "react";
import { SwalWrong } from "../../components/common/Swal";
import ScrollContainer from 'react-indiana-drag-scroll'
import { strings, translations } from "../../services/localization";
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { createMarkup, delay, handleSearch } from '../../components/common/commonFnc';
import TopNewsPC from "../../components/common/TopNewsPC";
import TopNewsMobile from "../../components/common/TopNewsMobile";
import { HomeTitle } from "../../components/common/Title";
import { getData } from "../../services/fetch";

const Games = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [games, setGames] = useState([]);
  const [topGames, setTopGames] = useState([]);
  const [topGameNews, settopGameNews] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [recommendationsGames, setRecommendationsGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.auth);
  const { language, timeZone } = useContext(appContext)
  const [browsersize, setBrowsersize] = useState(window.innerWidth);
  const [gameLoading, setGameLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(undefined);

  const content = translations.productList;


  const fetchGameData = async (category_id) => {
    const lang = language === 'English' ? 'en' : 'jp';
    const response = await getData(`games?language=${lang}&page=1&game_category_id=${category_id??''}`);
    if (response.ok) {
      const datas = response.data.data
      setGames((datas ?? []).filter((i) => i.top_game === 1));
      setTopGames([...datas].sort((a, b) => b.view_count - a.view_count));
      if(datas.length > 9){
        datas.length = 9;
      }
      setNewGames(datas);
      setRecommendationsGames((datas ?? []).filter((i) => i.recommend === 'recommend'));
    }
    setGameLoading(false);
  };

  const fetchTopGameNewsData = async () => {
    const shortForm = language === 'English' ? 'en' : 'jp'
    const response = await getData(`top-game-news?language=${shortForm}&timezone=${timeZone}`);
    if (response.ok) {
      const topgamenewsData = response.data.data
      const array = [...topgamenewsData.top_game_news, ...topgamenewsData.top_game_advertisements];
      const random = Math.floor(Math.random() * array.length);
      const randomItem = array[random];
      settopGameNews(randomItem);
    }
    setLoading(false)
  };

  const fetchCategories = async (lang) => {
    const response = await getData(`games-categories?language=${lang}`);
    if (response.ok) {
      setCategories(response.data.data);
    }
  }

  const isPC = useMediaQuery({ minWidth: 992 })
  const isPh = useMediaQuery({ maxWidth: 576 })
  const topImage = (!isPh && topGameNews?.image_url_top ? topGameNews?.image_url_top : topGameNews?.image_url) || topGameNews?.image;
  const buttonlist = translations.buttonlist;
  const cardRef = useRef()

  useEffect(() => {
    async function fetchData() {
      if (language && timeZone) {
        await fetchGameData();
        await fetchTopGameNewsData();
      }
    }
    if(category){
      fetchGameData(category.id);
    }else{
      fetchData();
    }
  }, [language, timeZone,category]);

  useEffect(() => {
    const lang = language === 'English' ? 'en' : 'jp';
    fetchCategories(lang);
  },[language])

  const handleSubNavOnChange = (category) => {
    setCategory(category);
  }

  const metaTitle = 'プロダクト｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  return (
    <AppWrapper gamesActive
      title={content.headerTitle}
      pageid='Game'
      noPadding={true} isNoSpaceAround metaTitle={metaTitle} metaDescription={metaDescription}
      subNavItems={categories}
      subNavOnChange={handleSubNavOnChange}
    >
      {!loading
        ? <>
          {isPC
            ?
            <TopNewsPC topNews={topGameNews} topImage={topImage} className="w-100" />
            :
            <TopNewsMobile topNews={topGameNews} topImage={topImage} />
          }
          {
            gameLoading ? <OnLoading inline /> :
              <div className="new-games-card px-lg-3">
                <div className="custom-hide-scroll d-flex overflow-auto">
                  {
                    games.map((item, index) =>
                      <div key={'header-' + index} className="new-games-header m-b-50">
                        <GameHeaderItem item={item} />
                      </div>
                    )
                  }
                </div>
                {/*<div className="mt-5 mb-3">*/}
                {/*  <div className="new-games-title">*/}
                {/*    {strings.formatString(content.Top10)}*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll new-games-grid child-smoothable-transition">*/}
                {/*    <GameNewCardItems items={topGames} isTop/>*/}
                {/*</ScrollContainer>*/}

                {isPC
                  ?
                  newGames !== null && newGames.length > 0 &&
                  <ItemCarousel topics
                    items={newGames} title={strings.formatString(content.new)} morelink="list"
                    containerClassName="mb-4" browsersize={browsersize}
                  />
                  :
                  <>
                    <HomeTitle title={content.new} className="f-expanded fw-500 px-3 d-flex justify-content-between" hideLine={true} textClass={isPh?'fs-20':'fs-25'} moreLink="list" notFlex={true} />

                    <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll new-games-grid child-smoothable-transition">
                      {newGames !== null && newGames.map((item, index) =>
                        <GameNewCardItems item={item} link="products_detail" />
                      )}
                    </ScrollContainer>
                  </>
                }

                {/* <div className="mt-5 mb-3">
                  <div className="games-title new-games-title">Recommendations</div>
                </div>
                <div className="custom-hide-scroll new-games-grid overflow-auto">
                  <GameNewCardItems items={recommendationsGames}/>
                </div> */}
              </div>
          }
        </>
        : <OnLoading />}
    </AppWrapper>
  );
};

export default Games
