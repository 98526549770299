import { useCallback, useRef } from "react";

export const useIntersectionWithLastRef = (loading, hasMore, callbackFn) => {
    const observer = useRef();
    const ref = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    callbackFn();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    return { ref }
}