import React, { useContext } from 'react'
import posthog from 'posthog-js'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setCookie, getCookie, removeCookie } from '../../services/storage';
import { useEffect } from 'react';
import { translations } from '../../services/localization';
import { appContext } from '../../context/appContext';
import { BrowserView, MobileView } from 'react-device-detect';

function CookieBanner({ isLevias }) {
    const navigate = useNavigate();
    const { language } = useContext(appContext);
    const [showBanner, setShowBanner] = useState(true);
    const [cookieAccept, setCookieAccept] = useState(true);
    const [translationText, setTranslationText] = useState(undefined);

    const acceptCookie = () => {
        posthog.opt_in_capturing();
        setShowBanner(false)
        setCookie('cookie-accept', true)
    }

    const rejectCookie = () => {
        setShowBanner(false)
        removeCookie('cookie-accept')
    }

    useEffect(() => {
        const fetchData = async () => {
            const cookieAccept = await getCookie('cookie-accept')
            setCookieAccept(cookieAccept ? true : false)
        }

        fetchData();
    }, [])

    useEffect(() => {
        if (language) {
            setTimeout(() =>
                setTranslationText({
                    title: translations.formatString(translations.cookiePolicyPopup.title),
                    body: translations.formatString(translations.cookiePolicyPopup.body),
                    seeCookiePolicyBtn: translations.formatString(translations.buttonlist.seeCookiePolicyBtn),
                    okBtn: translations.formatString(translations.buttonlist.okBtn),
                    langClass: translations.formatString(translations.common.langClass),
                })
                , 50)
        }
    }, [language])

    return (
        <div>
            {language && translationText && showBanner && !cookieAccept && (
                <div className={`cookiebanner ${translationText.langClass || ''} ${isLevias ? 'levias_cookiebanner levias-container' : ''}`}>
                    <MobileView>
                        <h2>{translationText.title}</h2>
                        <p className='mt-3 pre-wrap'>{translationText.body}</p>
                        <div className='btn-container'>
                            <button className={`cookie-reject-btn ${isLevias ? 'levias-btn levias-btn-custom-cancel' : ''}`} onClick={() => navigate('/cookies-policy')}>{translationText.seeCookiePolicyBtn}</button>
                            <button className={`cookie-accept-btn ${isLevias ? 'levias-btn levias-btn-custom-success' : ''}`} onClick={acceptCookie}>{translationText.okBtn}</button>
                        </div>
                    </MobileView>
                    <BrowserView>
                        <div className='flex-center justify-content-between p-x-lg-50'>
                            <p className='mt-3 text-start pre-wrap'>{translationText.body}</p>
                            <div className='flex-center flex-column flex-lg-row justify-content-between ms-2' style={{ flex: '0 0 330px' }}>
                                <button className={`cookie-reject-btn w-100 mb-2 mb-lg-0 ${isLevias ? 'levias-btn levias-btn-custom-cancel' : ''}`} onClick={() => navigate('/cookies-policy')}>{translationText.seeCookiePolicyBtn}</button>
                                <button className={`cookie-accept-btn w-100 ms-0 ms-lg-4 ${isLevias ? 'levias-btn levias-btn-custom-success' : ''}`} onClick={acceptCookie}>{translationText.okBtn}</button>
                            </div>
                        </div>
                    </BrowserView>
                </div>
            )}
        </div>
    )
}

export default CookieBanner
