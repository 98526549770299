/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { CardLineHeader } from "./Card";
import {
  formatMoney,
  formatNumber,
  shorternAddress,
  copyToClipboard,
  isJP,
} from "./commonFnc";
// import { BookmarkIcon } from './Icon';
import {
  Elements,
  getNameByContractAddress,
  getTypeByContractAddress,
  TokenType,
  TransactionStatus,
} from "./enum";
import {
  AbyssIcon,
  ArcanaIcon,
  BirthdayIcon,
  DeterminationIcon,
  DNAIcon,
  ElementIcon,
  ForceIcon,
  HeartIcon,
  IntelligenceIcon,
  MindIcon,
  PolygonIcon,
  ReceiveIcon,
  SendIcon,
  StarIcon,
  TokenDeleteIcon,
  TokenSendIcon,
  TokenReceiveIcon,
  QuestionMarkIcon,
  AdamantineRockIcon,
  CrimsonFlameIcon,
  ExternalTreeIcon,
  GoldenLightIcon,
  HeavenlyDewIcon,
  PeerlessSteelIcon,
  UnfathomableAbyssIcon,
  ArcanaCopyIcon,
  AbsorbIcon,
  PersonaElementIcon,
  ArcanaElementIcon,
  PersonaIcon,
  AnimaIcon,
  ArcanaNoImgDetailIcon,
  TokenNoImageIcon,
  PersonaRealityIcon,
  SelectedIcon,
} from "./Icon";
import { HomeTitle } from "./Title";
import { ReactComponent as GamePadGradientSVG } from "../../assets/icons/gamepad_gradient.svg";
import { appContext } from "../../context/appContext";
import { deleteData, postData } from "../../services/fetch";
import {
  SwalCorrect,
  SwalWrong,
  SwalSettings,
} from "../../components/common/Swal";
import { BottomModal } from "../../components/common/BottomModal";
import { strings, translations } from "../../services/localization";
import { useMediaQuery } from "react-responsive";
import "../../style/app/item.scss";
import ScrollContainer from "react-indiana-drag-scroll";
import { getWalletAddress } from "../../services/storage";
import { CopyIcon } from "../../components/common/Icon";
import numeral from "numeral";
import { isBrowser } from "react-device-detect";
import { OnLoading } from "./OnLoading";
import SwipeableCard from "./SwipeableCard";
import { DrawChainCard } from "../../pages/drawChains/drawChains";
import { useDispatch, useSelector } from "react-redux";
import { setPosition } from "../../store/Position";
import { removeSelectedItem } from "../../store/MyArcana";
import { Btn } from "./Button";
import { Button } from "../../pages/squares/Button";
import { ReactComponent as PersonaGlass } from '../../assets/icons/persona-glass.svg'
import { ReactComponent as PersonaFrontGlass } from '../../assets/icons/persona-front-glass.svg'

const ImageLayout = ({
  ipfs_image,
  no_text_icon,
  imgClassName,
  iconClassName,
  token,
  style,
}) =>
  ipfs_image !== null ? (
    <img
      className={`pointer ${imgClassName || ""}`}
      src={ipfs_image}
      style={{ ...style }}
    />
  ) : no_text_icon ? (
    <TokenNoImageIcon
      className={`${iconClassName || "w-50"}`}
      style={{ objectFit: "contain", height: 120 }}
    />
  ) : (
    <ArcanaNoImgDetailIcon
      className={`w-100 p-3 ${imgClassName}`}
      style={{ objectFit: "contain", height: token ? 176 : 120 }}
    />
  );

const Item = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef();

  const item = props.item || {};
  const [is_bookmarked, setIs_bookmarked] = useState(item.is_bookmarked);

  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        if (item && item.token_id) {
          navigate(`/wallet/${TokenType.getRouteName(item.token_type_id)}/detail/${item.token_id}`)
          dispatch(setPosition({
            scrollPositionY: divRef.current?.offsetTop - 90,
            scrollToBot: true
          }))
        }
      }
    }
  };

  const { selectedItems } = useSelector((state) => state.myArcana);

  const isSelected = useMemo(() => {
    return (
      selectedItems.find((i) => i.token_id === item.token_id) !== undefined
    );
  }, [selectedItems]);

  const unselect = useCallback(() => {
    dispatch(removeSelectedItem(item));
  }, [item]);

  return (
    <a
      onClick={isSelected ? unselect : onClick}
      className={`position-relative ${props.linkClassName || ""}`}
      ref={divRef}
    >
      {props.hasSelectButton ? (
        <div className={isSelected ? "selected-arcana" : ""}>
          <div
            className="position-absolute d-flex flex-center p-1"
            style={{
              top: 10,
              right: 10,
              borderRadius: "50%",
              aspectRatio: "1/1",
              backgroundColor: "#BF96FF",
              border: "2px solid white",
            }}
          >
            <SelectedIcon />
          </div>
        </div>
      ) :
        <></>
      }
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
        ref={ref}
      >
        <div className="token-item">
          <div className="card item-card">
            <div className="card-header d-flex flex-row justify-content-between">
              {item.new && (
                <div className="tag">
                  <span className="">New</span>
                </div>
              )}
              {/* <a className="nav-link" onClick={() =>
              handleBookmark(item.token_id, item.token_type_id, () => {
                setIs_bookmarked(is_bookmarked => !is_bookmarked);
              })
            } aria-current="page">
              <BookmarkIcon is_bookmarked={is_bookmarked ? 1 : 0} />
            </a> */}
            </div>
            <div className="card-image">
              <ImageLayout
                ipfs_image={
                  item.thumbnail || item.ipfs_image || item.image_url || item.image || null
                }
                imgClassName={props.imgClassName}
                token={props.token}
              />
            </div>
            <div className="card-body">
              {Number(item.token_type_id) === Number(TokenType.Persona) ? (
                true ? (
                  <PersonaIcon className="float-end btn-reset text-uppercase btn-bold token-type-img active-3" />
                ) : (
                  <PersonaRealityIcon className="float-end btn-reset token-type-img active-3" />
                )
              ) : Number(item.token_type_id) === Number(TokenType.Arcana) ? (
                <ArcanaIcon className="float-end btn-reset text-uppercase btn-bold token-type-img active-3" />
              ) : (
                <img
                  src={
                    Number(item.token_type_id) === Number(TokenType.Anima)
                      ? require("../../assets/images/anima_menu.png")
                      : require("../../assets/images/arcana_icon.png")
                  }
                  className="float-end btn-reset text-uppercase btn-bold token-type-img"
                />
              )}
              <p
                className="card-title text-start pointer fw-400 fs-12"
                style={{ minHeight: 18 }}
              >
                {item.creator && item.creator.match(/^0x\w{40}$/)
                  ? "Token ID: " + item.token_id
                  : item.creator}
              </p>
              <div className="d-flex flex-row " style={{ height: "46px" }}>
                {/*<div className='profile-pic'>*/}
                {/*  <img src={require('../../assets/images/bagan-sunset.png')} />*/}
                {/*</div>*/}
                <span
                  className={`text-overflow ${props.isunboldheader ? "fw-normal" : "fw-bold"
                    } token-name`}
                  style={{ fontSize: 15 }}
                >
                  {item.name}
                </span>
              </div>
              {!props.hidePrices ? (
                !props.hideAnimaPrice ? (
                  <div className="row prices">
                    <div className="col-12">
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="col pe-1"></div>
                          <span
                            className={`col pe-1 mb-0 field f-regular text-center`}
                          >
                            JPY
                          </span>
                          <span
                            className={`col mb-0 field f-regular text-center`}
                          >
                            ANM
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="col pe-1 field">ASK</div>
                          <div
                            className={`col pe-1 mb-0 f-regular text-center fs-12`}
                          >
                            <b>
                              {item.jpy.ask > 0
                                ? formatNumber(item.jpy.ask)
                                : "---"}
                            </b>
                          </div>
                          <div
                            className={`col mb-0 f-regular text-center fs-12`}
                          >
                            <b>
                              {item.anm.ask > 0
                                ? formatNumber(item.anm.ask, 2)
                                : "---"}
                            </b>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="col pe-1 field">BID</div>
                          <span
                            className={`col pe-1 mb-0 f-regular text-center`}
                          >
                            <b>
                              {item.jpy.bid > 0
                                ? formatNumber(item.jpy.bid)
                                : "---"}
                            </b>
                          </span>
                          <span className={`col mb-0 f-regular text-center`}>
                            <b>
                              {item.anm.bid > 0
                                ? formatNumber(item.anm.bid, 2)
                                : "---"}
                            </b>
                          </span>
                        </div>
                      </div>
                      {/* <div className='d-flex flex-row'>
                  <p className='text-start mb-0' style={{ width: 50 }}>BID</p>
                  <p className='mb-0'><b>{item.bid_price > 0 ? formatMoney(item.bid_price) : 'No price'}</b></p>
                </div> */}
                    </div>
                    {/* <div className='col-12 col-lg-5 mt-xs-3 ps-0 pt-2 d-flex align-items-center justify-content-end'>
                <BtnCustom variant={item.token_type_id == Number(TokenType.Arcana) ? "2" : item.token_type_id == Number(TokenType.Persona) ? "" : ""} onClick={props.onClickBtn} className="float-end btn-reset text-uppercase btn-bold">{item.token_type_id == Number(TokenType.Arcana) ? "ARCANA" : item.token_type_id == Number(TokenType.Persona) ? "PERSONA" : props.buttonText}</BtnCustom>
              </div> */}
                  </div>
                ) : (
                  <div className="row prices">
                    <div className="col-12">
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="field">ASK</div>
                          <span className="mb-0 subHeader f-regular">
                            <b>
                              {item.ask_price > 0
                                ? formatMoney(item.ask_price)
                                : "No price"}
                            </b>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="field">BID</div>
                          <span className="mb-0 subHeader f-regular">
                            <b>
                              {item.bid_price > 0
                                ? formatMoney(item.bid_price)
                                : "No price"}
                            </b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div style={{ minHeight: 44 }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});

export default Item;

export const CollectionItem = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef();

  const [total, setTotal] = useState(undefined);

  const { item = {}, token_type_id } = props;

  const getCollectionItemsCount = async (item, token_type_id) => {
    const response = await postData(`tokens/search?persona_id=${item.id}`, {
      sort_order: "DESC",
      page: 1,
      limit: "12",
      token_name: "",
      token_type_id: token_type_id,
      category: TokenType.getContractAddress(token_type_id),
    });
    setTotal(response.data.results.total || 0);
  };

  useEffect(() => {
    if (item && token_type_id && total === undefined) {
      console.log({ item, token_type_id, total });
      getCollectionItemsCount(item, token_type_id);
    }
  }, [item, token_type_id]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      if (item && item.token_id) {
        navigate(
          `/wallet/${TokenType.getRouteName(item.token_type_id)}/detail/${item.token_id
          }`
        );
        dispatch(
          setPosition({
            scrollPositionY: divRef.current?.offsetTop - 90,
          })
        );
      }
    }
  };
  return (
    <a onClick={onClick} className={`${props.linkClassName || ''}`} ref={divRef}>
      <div className={`${props.containerClassName || ''}`} style={{ marginBottom: 16 }} ref={ref}>
        <div className='token-item'>
          <div className="card item-card">
            <div className="card-image">
              <ImageLayout
                ipfs_image={
                  item.ipfs_image || item.image_url || item.image || null
                }
                imgClassName={props.imgClassName}
                token={props.token}
              />
            </div>
            <div className="card-body flex-center flex-column p-t-18">
              <PersonaIcon
                className="float-end btn-reset text-uppercase btn-bold token-type-img active-3 m-b-10"
                style={{ width: 30, height: 24 }}
              />
              {/* <span className="text-overflow w-100 card-title text-center pointer f-condensed jp-f-notosan fw-500 fs-15" style={{ minHeight: 18 }}>{item.creator && item.creator.match(/^0x\w{40}$/) ? "Token ID: " + item.token_id : item.creator}</span>
              <span className='text-overflow-vertical-2 text-center f-condensed fw-500 fs-13 text-capitalize' style={{ height: 46 }}>{item.name}</span> */}
              <p
                className="card-title text-start pointer fw-400 fs-12"
                style={{ minHeight: 18 }}
              >
                {item.description}
              </p>
              <div className="d-flex flex-row " style={{ height: "46px" }}>
                <span
                  className={`text-overflow text-center text-capitalize ${props.isunboldheader ? "fw-normal" : "fw-bold"
                    } token-name`}
                  style={{ fontSize: 15 }}
                >
                  {item.name}
                </span>
              </div>
              <span
                className="f-regular fw-600 fs-20 mb-1"
                style={{ height: 30 }}
              >
                {total === undefined ? "" : total || 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});

export const SampleItem = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef();

  const item = props.item || {};

  const onClick = () => {
    navigate(`/wallet/persona/collection/detail/${item.id}`)
    dispatch(setPosition({
      scrollPositionY: divRef.current?.offsetTop - 90,
      scrollToBot: true
    }))
  }

  return (
    <a
      onClick={onClick}
      className={`position-relative ${props.linkClassName || ""}`}
      ref={divRef}
    >
      <div className={`${props.containerClassName || ''}`} style={{ marginBottom: 16 }}>
        <div className='token-item'>
          <div className="card item-card sample-card">  
            <div className="d-flex justify-content-end mt-2">
              <PersonaIcon className="me-2 purple-icon sample-persona-icon" />
            </div>
            <div className="sample-card-image">
              <PersonaGlass className="under-img" />
              <ImageLayout
                ipfs_image={
                  item.ipfs_image || item.image_url || item.image || null
                }
                imgClassName="middle-img"
                token={props.token}
              />
              <div className="front-rect"></div>
              <PersonaFrontGlass className="over-img" />
            </div>
            <div className="card-body position-relative">
              <p
                className={`text-overflow text-center text-capitalize fw-normal f-condensed token-name mb-1`}
                style={{ fontSize: 15 }}
              >
                {item.name}
              </p>
              <p
                className="card-title pointer fw-400 fs-13 f-condensed sample-item-text text-center"
                style={{ minHeight: 18 }}
              >
                {item.description}
              </p>
              <div className="flex-center persona-detail-btn position-absolute bottom-0">
                <p className="fs-14 fw-normal ff-kardust mb-0">See details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export const ItemDetailCard = ({
  item,
  itemElementFilter,
  scores_changed,
  isArcanaGen,
}) => {
  const [changed, setChanged] = React.useState(true);

  setTimeout(() => setChanged(!changed), 5000);

  return (
    <>
      <div
        className="card flip-card arcana-image-card-main"
        style={{ cursor: "default", marginBottom: "35px" }}
      >
        <div className="card-body pb-2">
          <div className="d-flex flex-row align-items-center justify-content-between mb-3 arcana-image-card-header">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <span className="mb-1 fs-20">{item.name}</span>
              {Number(item.token_type_id) === Number(TokenType.Persona) ? (
                <div className="absorb-count-container">
                  <AbsorbIcon />
                  {[...Array(5)].map((ac, index) => (
                    <div
                      key={`absorbed_count_key_${index}`}
                      className={`absorb-count ${index < item.absorbed_count ? "active" : ""
                        }`}
                    ></div>
                  ))}
                </div>
              ) : (
                !!item.greenStar &&
                item.greenStar > 0 && (
                  <div className="d-flex justify-content-end mt-1 mb-2">
                    {[...Array(item.greenStar)].map((star, index) => (
                      <StarIcon key={index} gradient={1} className="me-2" />
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="profile-pic element-frame">
              {item.preview ? (
                <div className="owner-icon">
                  <QuestionMarkIcon />
                </div>
              ) : (
                <div className="element_fadeInOut">
                  <div className="element_fadeInOut_item bottom">
                    <i>{itemElementFilter?.img || <ExternalTreeIcon />}</i>
                  </div>
                  <div className="element_fadeInOut_item top">
                    {Number(item.token_type_id) ===
                      Number(TokenType.Persona) ? (
                      <PersonaElementIcon />
                    ) : (
                      <ArcanaElementIcon />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="card-image arcana-image-card-photo">
            {!item.image && isArcanaGen && isBrowser ? (
              <video
                src={require("../../assets/video/arcana_reveal.mp4")}
                autoPlay
                muted
                loop=""
                style={{ width: "100%", height: "360px", objectFit: "cover" }}
              ></video>
            ) : (
              <ImageLayout ipfs_image={item.ipfs_image || item.image || null} />
            )}
          </div>
          <div className="row mt-3 px-2 arcana-image-card-info">
            <div className="col-4 px-0">
              <div className="d-flex flex-column me-auto">
                <Attribute
                  attr={"FOR"}
                  value={item.FOR}
                  scores_changed={scores_changed}
                />
                <Attribute
                  attr={"MND"}
                  value={item.MND}
                  scores_changed={scores_changed}
                />
              </div>
            </div>
            <div className="col-4 px-0 border-horizontal">
              <div className="d-flex flex-column mx-auto">
                <Attribute
                  attr={"ABS"}
                  value={item.ABS}
                  scores_changed={scores_changed}
                />
                <Attribute
                  attr={"INT"}
                  value={item.INT}
                  scores_changed={scores_changed}
                />
              </div>
            </div>
            <div className="col-4 px-0">
              <div className="d-flex flex-column ms-auto">
                <Attribute
                  attr={"DFT"}
                  value={item.DFT}
                  scores_changed={scores_changed}
                />
                <Attribute
                  attr={"EXP"}
                  value={item.EXP}
                  scores_changed={scores_changed}
                />
              </div>
            </div>
          </div>
          <div className="row lock mt-2" style={{ marginLeft: -16 }}>
            <div className="col-12">
              {/* <div className='d-flex align-items-center'>
                <LockIcon />
                <span className='ms-2'>38033121</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ItemDetailCard2 = ({ item }) => {
  const navigate = useNavigate();
  const walletArcanaDetailPage = translations.walletArcanaDetailPage;
  return (
    <div className="row">
      <div className="col-12 col-lg-5">
        <div className="row">
          <div className="col">
            <div className="card flip-card" style={{ cursor: "default" }}>
              <div className="">
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <span className="mb-0 fw-bold">{item.name}</span>
                    <div className="d-flex justify-content-end my-2">
                      {[...Array(item.greenStar)].map((star, index) => (
                        <StarIcon key={index} gradient={1} className="me-2" />
                      ))}
                    </div>
                  </div>
                  <div className="profile-pic">
                    <img src={require("../../assets/images/class_icon.png")} />
                  </div>
                </div>

                <div className="card-image">
                  <img src={item.image} />
                </div>
                <div className="row mt-3 px-2">
                  <div className="col-4 px-0">
                    <Attribute attr={"FOR"} value={item.FOR} />
                  </div>
                  <div className="col-4 px-0">
                    <Attribute attr={"ABS"} value={item.ABS} />
                  </div>
                  <div className="col-4 px-0">
                    <Attribute attr={"DFT"} value={item.DFT} />
                  </div>
                  <div className="col-4 px-0">
                    <Attribute attr={"MND"} value={item.MND} />
                  </div>
                  <div className="col-4 px-0">
                    <Attribute attr={"INT"} value={item.INT} />
                  </div>
                  <div className="col-4 px-0">
                    <Attribute attr={"EXP"} value={item.EXP} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row detail">
          <div className="col">
            <div className="card p-0 border-0">
              <div className="card-body">
                <CardLineHeader
                  title="Details"
                  className="pe-2"
                  textClassName="italic title"
                />
                <DetailContent
                  attr={strings.formatString(walletArcanaDetailPage.Created_By)}
                  value={item.creator}
                />
                <DetailContent
                  attr={strings.formatString(
                    walletArcanaDetailPage.Contract_Address
                  )}
                  value={shorternAddress(item.contract_address || "")}
                />
                <DetailContent
                  attr={strings.formatString(walletArcanaDetailPage.Blockchain)}
                  value={item.details.blockchain}
                />
                <DetailContent
                  attr={strings.formatString(
                    walletArcanaDetailPage.Token_Standard
                  )}
                  value={item.details.token_standard}
                />
                {/* <DetailContent attr={strings.formatString(walletArcanaDetailPage.MX_Creator_Bonus)} value={item.details.mx_creator_bonus} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-7">
        <div className="row">
          <div className="col">
            <div className="card p-0 border-0">
              <div
                className="card-body"
                style={{ backgroundColor: "transparent" }}
              >
                <Attribute
                  attr={"売値"}
                  value={
                    item.ask_price == 0
                      ? "No price"
                      : formatMoney(item.ask_price)
                  }
                  className={"my-2"}
                  valueClass="fw-bold"
                />
                <Attribute
                  attr={"買値"}
                  value={
                    item.bid_price == 0
                      ? "No price"
                      : formatMoney(item.bid_price)
                  }
                  valueClass="fw-bold"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            <div className="card p-0 border-0">
              <div className="card-body">
                <DetailContent
                  icon={<ArcanaIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Name)}
                  value={item.name}
                />
                <DetailContent
                  icon={<ElementIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Element)}
                  value={item.elements ? Elements[item.elements] : ""}
                />
                <DetailContent
                  icon={<StarIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Green_Star)}
                  value={item.greenStar}
                />
                <DetailContent
                  icon={<DNAIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Bloodline)}
                  value={item.bloodline}
                />
                <DetailContent
                  icon={<BirthdayIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Birthday)}
                  value={moment(item.birthday).format("YYYY.MM.DD")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            <div className="card p-0 border-0">
              <div className="card-body">
                <CardLineHeader
                  title="Scores"
                  className="pe-2"
                  textClassName="italic title"
                />
                <DetailContent
                  icon={<ForceIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.FOR_Force)}
                  value={item.FOR}
                />
                <DetailContent
                  icon={<AbyssIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.ABS_Abyss)}
                  value={item.ABS}
                />
                <DetailContent
                  icon={<DeterminationIcon />}
                  attr={strings.formatString(
                    walletArcanaDetailPage.DFT_Determination
                  )}
                  value={item.DFT}
                />
                <DetailContent
                  icon={<MindIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.MND_Mind)}
                  value={item.MND}
                />
                <DetailContent
                  icon={<IntelligenceIcon />}
                  attr={strings.formatString(
                    walletArcanaDetailPage.INT_Intelligence
                  )}
                  value={item.INT}
                />
                <DetailContent
                  icon={<HeartIcon />}
                  attr={strings.formatString(
                    walletArcanaDetailPage.EXP_Experience
                  )}
                  value={item.EXP}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='row my-3'>
                            <div className='col'>
                                <div className='card p-0 border-0'>
                                    <div className='card-body'>
                                        <CardLineHeader title="Features（特徴・制限）" className="pe-2" textClassName="italic title" />
                                        <div className='row'>
                                            {
                                                item.feature && item.feature.map((feature, index) =>
                                                    <Feature key={index} text={feature} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
      </div>
      {/* <div className='col-12 col-lg-3'>
                        <div className='card p-0 border-0'>
                            <div className='card-body'>
                                <CardLineHeader title="Story" className="pe-2" textClassName="italic title" />
                                <p>
                                    ガイアは、ギリシア神話に登場する女神である。地母神であり、大地の象徴と言われる。ただし、ガイアは天をも内包した世界そのものであり、文字通りの大地とは違う存在である。ヘーシオドスの『神統記』によれば、カオスから生まれ、タルタロス、エロースと同じく世界の始まりの時から存在した原初神である。
                                    <br /><br />
                                    ギリシア神話に登場する神々の多くはガイアの血筋に連なり、また人類もその血を引いているとされ、母なる女神としてギリシア各地で篤く崇拝された。未来を予言する能力を持つ女神であり、デルポイの神託所はアポローンの手に渡る前に元々ガイアのものであった。さらに、地上のあらゆる事がその上で行われることから、誓言の神でもある。
                                    <br /><br />
                                    ローマ神話におけるテルースに相当する。
                                </p>
                            </div>
                        </div>
                    </div> */}
    </div>
  );
};

export const ItemCarousel = (props) => {
  const navigate = useNavigate();
  const [isMoving, setIsMoving] = useState(false);
  const isPC = useMediaQuery({ minWidth: 992 });
  const language = translations._language;
  return (
    <div
      className={`row ${props.containerClassName || ""} ${props.rootClassName ?? ""
        }`}
    >
      <div
        className={`col px-0 p-x-lg-12 d-grid ${props.containerClassName || ""
          }`}
      >
        {props.title ? (
          <HomeTitle
            browsersize={props.browsersize}
            title={props.title}
            className="f-expanded fw-500 m-x-16 m-x-lg-0"
            style={{ fontSize: 25 }}
            moreLink={props.morelink}
            hideLine={props.hideLine}
          />
        ) : (
          <></>
        )}
        {/* <h3 className='title text-uppercase text-center'>{props.title}</h3> */}
        {props.loading ? (
          <OnLoading inline />
        ) : (
          <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll child-smoothable-transition d-flex multi-carousel-container">
            {props.items &&
              props.items.map((item, index) =>
                props.game ? (
                  <GameItemCard
                    key={index}
                    containerClassName="w-210px mb-0 mr-15px multi-carousel-item"
                    imgClassName="carousel-img"
                    item={item}
                    handleMouseMove
                    isMoving={isMoving}
                    onClickBtn={() =>
                      navigate(
                        `/wallet/${TokenType.getRouteName(
                          item.token_type_id
                        )}/detail/${item.token_id}`
                      )
                    }
                  />
                ) : props.news ? (
                  <NewsItemCard
                    key={index}
                    containerClassName="w-210px mb-0 mr-15px multi-carousel-item"
                    imgClassName="carousel-img"
                    item={item}
                    handleMouseMove
                    isMoving={isMoving}
                    onClickBtn={() =>
                      navigate(
                        `/wallet/${TokenType.getRouteName(
                          item.token_type_id
                        )}/detail/${item.token_id}`
                      )
                    }
                  />
                ) : props.topics ? (
                  <TopicItemCard
                    key={index}
                    btnText={props.btnText}
                    containerClassName="w-210px mb-0 mr-15px multi-carousel-item"
                    imgClassName="carousel-img"
                    item={item}
                    handleMouseMove
                    isMoving={isMoving}
                    onClickBtn={() =>
                      navigate(
                        `/wallet/${TokenType.getRouteName(
                          item.token_type_id
                        )}/detail/${item.token_id}`
                      )
                    }
                  />
                ) : props.square ? (
                  <div
                    className={`w-210px mr-15px ${isPC ? "" : index === 0 ? "ml-16px" : ""
                      }`}
                  >
                    <SquareCard {...item} language={language} />
                  </div>
                ) : props.persona ? (
                  <PersonaItem
                    key={index}
                    containerClassName="w-165px mb-0 mr-15px multi-carousel-item"
                    imgClassName="carousel-img"
                    item={item}
                    handleMouseMove
                    isMoving={isMoving}
                    onClick={() =>
                      navigate(
                        `/tokens/${Number(TokenType.Persona)}/collection/${item.id
                        }`
                      )
                    }
                  />
                ) : props.drawchain ? (
                  <div
                    key={index}
                    className={`mb-0 mr-15px multi-carousel-item ${isPC ? "" : index === 0 ? "ml-16px" : ""
                      }`}
                  >
                    <DrawChainCard item={item} square_id={props.square_id} />
                  </div>
                ) : props.collection ? (
                  <CollectionItem
                    key={index}
                    isunboldheader={props.isunboldheader}
                    containerClassName="w-165px mb-0 mr-15px multi-carousel-item"
                    imgClassName="carousel-img"
                    item={item}
                    handleMouseMove
                    isMoving={isMoving}
                    onClick={() => {
                      navigate(
                        `/tokens/${props.token_type_id}/collection/${item.id}`
                      );
                    }}
                  />
                ) : props.miniGame ? (
                  <MiniGameCard
                    className={`w-210px mr-15px ${isPC ? "" : index === 0 ? "ml-16px" : ""}`}
                    onClick={props.onClick}
                    item={item}
                  />
                )
                  : (
                    <Item
                      key={index}
                      isunboldheader={props.isunboldheader}
                      containerClassName="w-165px mb-0 mr-15px multi-carousel-item"
                      imgClassName="carousel-img"
                      item={item}
                      handleMouseMove
                      isMoving={isMoving}
                      hasSelectButton={props.hasSelectButton}
                      onClickBtn={() =>
                        navigate(
                          `/wallet/${TokenType.getRouteName(
                            item.token_type_id
                          )}/detail/${item.token_id}`
                        )
                      }
                    />
                  )
              )}
          </ScrollContainer>
        )}
      </div>
    </div>
  );
};

export const GameItemCard = (props) => {
  const navigate = useNavigate();
  const item = props.item || {};
  const { language, timeZone } = useContext(appContext);
  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        navigate(`/products_detail/${item.id}`);
      }
    }
  };
  const isPh = useMediaQuery({ maxWidth: 576 });
  const topImage =
    !isPh && item.top_pc_image_url ? item.top_pc_image_url : item.top_image_url;

  return (
    <a onClick={onClick}>
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
      >
        <div className="">
          <div className="card item-card game-card">
            <div className="card-image">
              <img
                className={`pointer ${props.imgClassName || ""}`}
                src={
                  topImage ||
                  item?.thumbnail_image_url ||
                  require("../../assets/images/item_img.png")
                }
              />
            </div>
            <div className="card-body">
              <p style={{ height: 43, overflow: "hidden", marginBottom: 0 }}>
                <small className="card-title text-start pointer fw-400 f-regular p-title">
                  {item?.title}
                </small>
              </p>
              {item?.game_makers?.name ? (
                <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
                  <div
                    className="creator-photo"
                    style={{ minHeight: 30, minWidth: 30 }}
                  >
                    {item?.game_makers?.game_maker_icon && (
                      <img src={item?.game_makers?.game_maker_icon} />
                    )}
                  </div>
                  <div
                    className="text-overflow fs-12"
                    style={{ marginLeft: 6 }}
                  >
                    {item?.game_makers?.name}
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
              )}
              <div className="item-date-mini-height">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <span className="price">
                    <b>
                      {" "}
                      {item?.price > 0
                        ? formatMoney(item?.price)
                        : language === "English"
                          ? `No Price`
                          : `無料`}
                    </b>
                  </span>
                  <GamePadGradientSVG />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export const NewsItemCard = (props) => {
  const navigate = useNavigate();
  const { language } = useContext(appContext);
  const shortLang = language === 'English' ? 'en' : 'jp';
  const item = props.item || {};
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name) ?? (typeof item?.news_categories === 'string' ? item?.news_categories : item?.news_categories[shortLang]);

  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        navigate(`/news_detail/${item.id}`);
      }
    }
  };
  return (
    <a onClick={onClick}>
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
      >
        <div className="">
          <div className="card item-card news-card">
            <div className="card-image">
              <img
                style={{ height: 110 }}
                className={`pointer ${props.imgClassName || ""}`}
                src={
                  item.image_url || require("../../assets/images/item_img.png")
                }
              />
            </div>
            <div className="card-body ">
              <p style={{ height: 37, overflow: "hidden" }} className="mb-0">
                <small
                  className="card-title text-start pointer fw-500 f-expanded"
                  style={{ minHeight: 18 }}
                >
                  {item.title}
                </small>
              </p>
              <div className="row">
                <div className="col-12">
                  <CardFooter
                    published={item?.published}
                    typename={typename}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export const TopicItemCard = (props) => {
  const navigate = useNavigate();
  const item = props.item || {};
  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        if (item?.game_maker_id || item?.game_makers) {
          navigate(`/products_detail/${item.id}`);
        } else {
          navigate(`/news_detail/${item.id}`);
        }
      }
    }
  };
  return (
    <a onClick={onClick}>
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
      >
        <div className="">
          {item?.game_maker_id || item?.game_makers ? (
            <TopicGameHomeItemViewCard item={item} btnText={props.btnText} />
          ) : (
            <TopicNewsHomeItemViewCard item={item} btnText={props.btnText} />
          )}
        </div>
      </div>
    </a>
  );
};

export const TopicNewsHomeItemViewCard = (props) => {
  const { language } = useContext(appContext);
  const shortLang = language === 'English' ? 'en' : 'jp';
  const item = props.item || {};
  const btnText =
    props.btnText && translations._language === "jp" ? "ニュース" : "News";
  const topImage = item.image_url;
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name) ?? (typeof item?.news_categories === 'string' ? item?.news_categories : item?.news_categories[shortLang]) ?? btnText;

  return (
    <div className="card item-card news-card">
      <div className="card-image">
        <ImageLayout
          ipfs_image={topImage || item?.thumbnail_image_url || null}
          imgClassName={`${props.imgClassName || ""}`}
          style={{ height: 120 }}
        />
      </div>
      <div className="card-body ">
        <p style={{ height: 55, overflow: "hidden" }} className="mb-0">
          <small className="card-title text-start pointer fw-400 f-regular p-title">
            {(item?.title_en && !isJP()) ? item?.title_en : item?.title}
          </small>
        </p>
        {item?.news_makers?.name || item?.creator_name ? (
          item?.news_makers?.game_maker_icon || item?.creator_icon ? (
            <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
              <div
                className="creator-photo"
                style={{ minHeight: 30, minWidth: 30 }}
              >
                <img
                  src={item?.news_makers?.game_maker_icon || item?.creator_icon}
                />
              </div>
              <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
                {item?.news_makers?.name || item?.creator_name}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
              <div
                className="creator-photo"
                style={{ minHeight: 30, minWidth: 30 }}
              ></div>
              <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
                {item?.news_makers?.name || item?.creator_name}
              </div>
            </div>
          )
        ) : (
          <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
        )}
        <div className="item-date-mini-height">
          <div className="d-flex flex-row justify-content-between align-items-center gap-20">
            <span className="fs-12 f-condensed-lg flex-shrink-0">
              {item.amount
                ? `¥ ${numeral(item.amount).format("0,0")}`
                : item.label ?? moment(item.published).format("YYYY.MM.DD")}
            </span>
            <CategoryTag typename={typename} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TopicGameHomeItemViewCard = (props) => {
  const item = props.item || {};
  const { language } = useContext(appContext);
  const btnText =
    props.btnText && language === "Japanese" ? "電子マネー" : "Game";
  const isPh = useMediaQuery({ maxWidth: 576 });
  const topImage =
    !isPh && item.image_url_pc ? item.image_url_pc : item.image_url;
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name) ?? (item?.game_category) ?? btnText;

  return (
    <div className="card item-card news-card">
      <div className="card-image">
        <ImageLayout
          ipfs_image={topImage || item?.thumbnail_image_url || null}
          imgClassName={`${props.imgClassName || ""}`}
          style={{ height: 120 }}
        />
      </div>
      <div className="card-body ">
        <p
          style={{ height: 55, overflow: "hidden", minWidth: 20 }}
          className="mb-0"
        >
          <small className="card-title text-start pointer fw-400 f-regular p-title">
            {(item?.title_en && !isJP()) ? item?.title_en : item?.title}
          </small>
        </p>
        {item?.game_makers?.name || item?.creator_name ? (
          item?.game_makers?.game_maker_icon || item?.creator_icon ? (
            <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
              <div
                className="creator-photo"
                style={{ minHeight: 30, minWidth: 30 }}
              >
                <img
                  src={item?.game_makers?.game_maker_icon || item?.creator_icon}
                />
              </div>
              <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
                {item?.game_makers?.name || item?.creator_name}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
              <div
                className="creator-photo"
                style={{ minHeight: 30, minWidth: 30 }}
              ></div>
              <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
                {item?.game_makers?.name || item?.creator_name}
              </div>
            </div>
          )
        ) : (
          <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
        )}
        <div className="item-date-mini-height">
          <CardFooter published={item?.published} typename={typename} />
        </div>
      </div>
    </div>
  );
};

export const TransactionItem = ({ transaction, wallet_address, onClick }) => {
  const status =
    transaction.from == wallet_address
      ? TransactionStatus.Send
      : TransactionStatus.Receive;
  const content = translations.tokenDetailPage;
  return (
    <a onClick={onClick}>
      <div className="card w-100 transaction-item mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center m-b-15">
            <div className="d-flex align-items-center">
              {getTypeByContractAddress(transaction.contract_address) ===
                Number(TokenType.Arcana) ? (
                <ArcanaIcon className="me-2 purple-icon" />
              ) : getTypeByContractAddress(transaction.contract_address) ===
                Number(TokenType.Persona) ? (
                <PersonaIcon
                  className="me-2 purple-icon"
                  style={{ width: 19, height: 19 }}
                />
              ) : (
                <></>
              )}
              <p className="f-condensed primary-color attribute-title text-uppercase m-0">
                {getTypeByContractAddress(transaction.contract_address) !==
                  Number(TokenType.Anima) && (
                    <font>
                      {getNameByContractAddress(transaction.contract_address)}
                    </font>
                  )}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <p className="fs-12 m-t-5 mb-0">
                {moment(transaction.timestamp).format("YYYY.MM.DD HH:mm")}
              </p>
              {Number(status) === TransactionStatus.Send ? (
                <SendIcon className="ms-2 purple-icon wallet-send-icon" />
              ) : (
                <ReceiveIcon className="ms-2 purple-icon wallet-send-icon" />
              )}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start mb-2">
            {getTypeByContractAddress(transaction.contract_address) ===
              Number(TokenType.Anima) ? (
              <div
                className="d-flex flex-column thumbnail-sm-fixed text-center"
                style={{
                  marginTop: "-30px",
                  height: 115,
                }}
              >
                <AnimaIcon className="purple-icon me-2 thumbnail-sm-fixed" />
                <font className="primary-color fs-18">
                  {getNameByContractAddress(transaction.contract_address)}
                </font>
              </div>
            ) : (
              transaction.image !== null && (
                <ImageLayout
                  ipfs_image={transaction.image || null}
                  no_text_icon
                  imgClassName="thumbnail-sm-fixed"
                  style={{ borderRadius: 10 }}
                />
              )
            )}
            <div
              className="w-100 d-flex flex-column justify-content-between align-items-start ps-3"
              style={{ minWidth: 0 }}
            >
              {getTypeByContractAddress(transaction.contract_address) ===
                Number(TokenType.Anima) && (
                  <div className={`w-100 text-end`}>
                    <span className="fw-500 mb-2 ">
                      {Number(status) === TransactionStatus.Send
                        ? content.Sent
                        : content.Received}
                    </span>
                  </div>
                )}
              <div className={`w-100 text-end`}>
                <span
                  className={`fw-500 mb-2 ${transaction.image === null ? "primary-color" : ""
                    }`}
                >
                  {transaction.image === null
                    ? transaction.amount === null
                      ? 0
                      : transaction.amount
                    : transaction.name}
                  <span className="fs-15">
                    {transaction.image === null &&
                      getTypeByContractAddress(transaction.contract_address) ===
                      Number(TokenType.Arcana) ? (
                      "ACN"
                    ) : getTypeByContractAddress(
                      transaction.contract_address
                    ) === Number(TokenType.Persona) ? (
                      ""
                    ) : getTypeByContractAddress(
                      transaction.contract_address
                    ) === Number(TokenType.Anima) ? (
                      "ANM"
                    ) : (
                      <></>
                    )}
                  </span>
                </span>
              </div>
              {getTypeByContractAddress(transaction.contract_address) !==
                Number(TokenType.Anima) && (
                  <div className="w-100">
                    <div style={{ width: 100, float: "left" }}>
                      <small className="text-gradient fw-500">Token ID</small>
                    </div>
                    <div style={{ width: "calc(100% - 100px)", float: "right" }}>
                      <div className="small text-overflow fw-500 f-condensed text-end">
                        {transaction.token_id}
                      </div>
                    </div>
                  </div>
                )}
              <div className="w-100">
                <div style={{ width: 50, float: "left" }}>
                  <small className="text-gradient fw-500">
                    {Number(status) === TransactionStatus.Send ? "To" : "From"}
                  </small>
                </div>
                <div style={{ width: "calc(100% - 50px)", float: "right" }}>
                  <div className="small text-overflow fw-500 f-condensed text-end">
                    {Number(status) === TransactionStatus.Send
                      ? transaction.to
                      : transaction.from}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export const ManaAddressItem = (props) => {
  const { items } = props;
  const navigate = useNavigate();
  const walletManaAddress = translations.walletManaAddress;

  const getDate = (timestamp) => {
    let date = new Date(parseInt(timestamp) * 1000);
    return new Intl.DateTimeFormat("ja-jp", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const goArcanaGen = (manaAddress) => {
    navigate("/arcana-gen/" + manaAddress);
  };

  return (
    <>
      {items.map((item, index) => (
        <a key={"mana-address" + index}>
          <div
            className="card w-100 transaction-item mb-3"
            style={{ cursor: "pointer" }}
          >
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-1">
                <div className="d-flex flex-column">
                  <span className="mb-1">
                    {" "}
                    {item.isDone ? (
                      <span style={{ color: "#00FF19" }}>
                        {walletManaAddress.labelCompleted}
                      </span>
                    ) : (
                      <span style={{ color: "#FFF625" }}>
                        {walletManaAddress.labelIncomplete}
                      </span>
                    )}
                  </span>
                  <span
                    style={{
                      fontFamily: "Kardust Condensed",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 12,
                      textTransform: "capitalize",
                      color: "rgba(255, 255, 255, 0.91)",
                    }}
                  >
                    {getDate(item.info.timestamp)}
                  </span>
                </div>
                <div>
                  {" "}
                  {item.isDone ? (
                    ""
                  ) : (
                    <Btn
                      intent="none"
                      onClick={() => goArcanaGen(item.info.manaAddress)}
                      className="btn-mana-address"
                      style={{ background: "transparent" }}
                    >
                      {walletManaAddress.labelRestore}
                    </Btn>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-1">
                <span
                  style={{
                    fontFamily: "Kardust Condensed",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 15,
                    textTransform: "capitalize",
                    color: "#BF96FF",
                  }}
                >
                  {walletManaAddress.labelTokenID}
                </span>
                <span
                  style={{
                    fontFamily: "Kardust Condensed",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 15,
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                >
                  {item.info.eggId}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span
                  style={{
                    fontFamily: "Kardust Condensed",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 15,
                    textTransform: "capitalize",
                    color: "#BF96FF",
                  }}
                >
                  {walletManaAddress.title}
                </span>
                <div
                  className="d-flex align-items-center"
                  style={{ maxWidth: 180 }}
                >
                  <span
                    className="tx-ellipsis"
                    style={{
                      maxWidth: 155,
                      fontFamily: "Kardust Condensed",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 15,
                      textTransform: "capitalize",
                      color: "#fff",
                    }}
                  >
                    {item.info.manaAddress}
                  </span>
                  <div className="ms-2" style={{ width: 25 }}>
                    <CopyIcon
                      onClick={() => copyToClipboard(item.info.manaAddress)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  );
};

export const RecommendedItem = ({ item, onClick }) => {
  return (
    <a onClick={onClick}>
      <div className="row w-100 mb-3 recommended-item">
        <img src={item.image} className={"col thumbnail-sm-fixed"} />
        <div className="col ps-0">
          <div className="d-flex flex-column">
            <small className="text-gradient f-regular fw-600 mb-2">
              PERSONA
            </small>
            <span className="name">Zombie siblings are on the way</span>
            <span className="price">Price : 18,000 JPY</span>
            <span className="time">Sale starts 13:00, 10.23, 2022</span>
          </div>
        </div>
      </div>
    </a>
  );
};

const Attribute = ({ attr, value, className, valueClass, scores_changed }) => {
  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center px-2 ${className}`}
    >
      <span className="title attribute-title">{attr}</span>
      <span
        className={`attribute-value ${scores_changed && scores_changed.hasOwnProperty(attr)
          ? scores_changed[attr] < 0
            ? "score-down"
            : scores_changed[attr] > 0
              ? "score-up"
              : ""
          : ""
          } ${valueClass || ""}`}
      >
        {value}
      </span>
    </div>
  );
};

export const DetailContent = ({
  icon,
  attr,
  value,
  valueStyle,
  attrStyle,
  right_icon,
  address,
  className,
  scores_changed_value,
  span_Class,
  style_not_need,
}) => {
  const walletArcanaDetailPage = translations.walletArcanaDetailPage;
  const commonlist = translations.common;
  const copyContractAddress = () => {
    const text = strings.formatString(commonlist.copy_text);
    navigator.clipboard.writeText(address);
    SwalCorrect.fire({
      text,
    }).then(() => {
      console.log("copied");
    });
  };

  return (
    <div
      className={`d-flex justify-content-between align-items-center mb-2 ${className || ""
        }`}
    >
      <div className="d-flex">
        {icon && <div className="item-icon">{icon}</div>}
        <p
          className=" mb-0 text-start attribute-title f-regular fw-400 me-1"
          style={attrStyle}
        >
          {attr}
        </p>
      </div>
      <div
        className={`attribute-value text-end text-overflow d-flex align-items-center`}
        style={{ ...valueStyle, maxWidth: style_not_need ? "" : "unset" }}
      >
        <span className={`${span_Class || ""}`}>
          {scores_changed_value ? (
            scores_changed_value < 0 ? (
              <span className="attribute-value score-down me-2">
                {scores_changed_value}
              </span>
            ) : (
              <span className="attribute-value score-up me-2">
                + {scores_changed_value}
              </span>
            )
          ) : (
            ""
          )}
          {value}
        </span>
        {right_icon && (
          <span
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => copyContractAddress()}
          >
            <div>{right_icon}</div>
          </span>
        )}
      </div>
      {/* <span className={`text-end`}>{value}</span> */}
    </div>
  );
};

export const AttributeTable = ({
  item = {},
  className,
  attrStyle,
  valueStyle,
  hideAnimaPrice,
}) => {
  const walletArcanaDetailPage = translations.walletArcanaDetailPage;
  const askHandle = (ask) =>
    item.is_approve_checking === true && item.owner === getWalletAddress()
      ? "注文確認中..."
      : ask
        ? formatMoney(ask)
        : "No Price";

  const bidHandle = (bid) => (bid ? formatMoney(bid) : "No Price");

  if (hideAnimaPrice) {
    return (
      <>
        <DetailContent
          attr={strings.formatString(walletArcanaDetailPage.ASK)}
          value={
            item.is_approve_checking === true &&
              item.owner === getWalletAddress()
              ? "注文確認中..."
              : item.jpy?.ask
                ? formatMoney(item.jpy?.ask)
                : "No Price"
          }
          attrStyle={{ fontSize: "25px" }}
          valueStyle={{ fontSize: "25px" }}
        />
        <DetailContent
          attr={strings.formatString(walletArcanaDetailPage.BID)}
          value={item.jpy?.bid ? formatMoney(item.jpy?.bid) : "No Price"}
          attrStyle={{ fontSize: "25px" }}
          valueStyle={{ fontSize: "25px" }}
        />
      </>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div style={{ width: 100 }}></div>
          <div className="col flex-center">
            <span
              className="mb-0 text-start attribute-title f-regular fw-400"
              style={attrStyle}
            >
              JPY
            </span>
          </div>
          <div className="col flex-center">
            <span
              className="mb-0 text-start attribute-title f-regular fw-400"
              style={attrStyle}
            >
              ANM
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div style={{ width: 100 }}>
            <span
              className="mb-0 text-start attribute-title f-regular fw-400"
              style={attrStyle}
            >
              ASK
            </span>
          </div>
          <div className="col flex-center">
            <span
              className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}
            >
              {item.jpy.ask > 0 ? formatNumber(item.jpy.ask) : "---"}
            </span>
          </div>
          <div className="col flex-center">
            <span
              className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}
            >
              {item.anm.ask > 0 ? formatNumber(item.anm.ask, 2) : "---"}
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div style={{ width: 100 }}>
            <span
              className="mb-0 text-start attribute-title f-regular fw-400"
              style={attrStyle}
            >
              BID
            </span>
          </div>
          <div className="col flex-center">
            <span
              className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}
            >
              {item.jpy.bid > 0 ? formatNumber(item.jpy.bid) : "---"}
            </span>
          </div>
          <div className="col flex-center">
            <span
              className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}
            >
              {item.anm.bid > 0 ? formatNumber(item.anm.bid, 2) : "---"}
            </span>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   className={`row attribute px-0 px-lg-1 ${className || ""
    //     }`}
    // >
    //   <div className="d-flex flex-column" style={{ width: 100 }}>
    //     <span className={`attribute-value ${attrStyle}`} style={{ visibility: 'hidden' }}>NO</span>
    //     <span className='mb-0 text-start attribute-title f-regular fw-400' style={attrStyle}>ASK</span>
    //     <span className='mb-0 text-start attribute-title f-regular fw-400' style={attrStyle}>BID</span>
    //   </div>
    //   <div className="col d-flex flex-column">
    //     <span className='mb-0 text-start attribute-title f-regular fw-400' style={attrStyle}>JPY</span>
    //     <span className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}>{item.jpy?.ask || 0}</span>
    //     <span className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}>{item.jpy?.bid || 0}</span>
    //   </div>
    //   <div className="col d-flex flex-column">
    //     <span className='mb-0 text-start attribute-title f-regular fw-400' style={attrStyle}>ANM</span>
    //     <span className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}>{item.anm?.ask || 0}</span>
    //     <span className={`attribute-value text-end d-flex align-items-center ${valueStyle}`}>{item.anm?.bid || 0}</span>
    //   </div>
    // </div>
  );
};

export const Feature = ({ text }) => {
  return (
    <div className="col-6">
      <div className="feature-card">{text}</div>
    </div>
  );
};

export const ItemDetail = ({
  item,
  additionalBottom,
  chooseBtn,
  animaAmount,
  scores_changed,
}) => {
  const walletArcanaDetailPage = translations.walletArcanaDetailPage;
  const itemElements = [
    { elements: 0, img: <ExternalTreeIcon />, name: "Eternal Tree" },
    { elements: 1, img: <CrimsonFlameIcon />, name: "Crimson Flame" },
    { elements: 2, img: <AdamantineRockIcon />, name: "Adamantine Rock" },
    { elements: 3, img: <PeerlessSteelIcon />, name: "Peerless Steel" },
    { elements: 4, img: <HeavenlyDewIcon />, name: "Heavenly Dew" },
    { elements: 5, img: <GoldenLightIcon />, name: "Golden Light" },
    { elements: 6, img: <UnfathomableAbyssIcon />, name: "Unfathomable Abyss" },
  ];
  const itemElementFilter =
    itemElements.find((i) => i.elements === parseInt(item.elements)) || {};

  return (
    <div
      className="row item-detail arcana-card position-relative"
      style={{ cursor: "default" }}
    >
      <div className="col-12 col-xl-4 col-lg-6 flip-card-col">
        <ItemDetailCard
          item={item}
          itemElementFilter={itemElementFilter}
          scores_changed={scores_changed}
        />
        <div className="card p-0 border-0 arcana-info-card">
          <div className="card-body">
            <DetailContent
              icon={
                Number(item.token_type_id) === Number(TokenType.Persona) ? (
                  <PersonaIcon style={{ width: 20 }} />
                ) : (
                  <ArcanaIcon />
                )
              }
              attr={strings.formatString(walletArcanaDetailPage.Name)}
              value={item.name}
            />
            {Number(item.token_type_id) === Number(TokenType.Persona) && (
              <DetailContent
                icon={<AnimaIcon style={{ width: 20 }} />}
                attr={strings.formatString(walletArcanaDetailPage.anima)}
                value={animaAmount}
              />
            )}
            <DetailContent
              icon={<ElementIcon />}
              attr={strings.formatString(walletArcanaDetailPage.Element)}
              value={itemElementFilter?.name}
            />
            {Number(item.token_type_id) === Number(TokenType.Arcana) && (
              <>
                <DetailContent
                  icon={<StarIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Green_Star)}
                  value={item.greenStar}
                />
                <DetailContent
                  icon={<DNAIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Bloodline)}
                  value={item.bloodline}
                />
                <DetailContent
                  icon={<BirthdayIcon />}
                  attr={strings.formatString(walletArcanaDetailPage.Birthday)}
                  value={moment(item.birthday).format("YYYY.MM.DD")}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-5 col-lg-6">
        <div
          className="card flip-card p-0 border-0 arcana-ask-bid-card"
          style={{ cursor: "default" }}
        >
          <div className="card-body">
            <AttributeTable
              item={item}
              attrStyle={{ fontSize: "25px" }}
              valueStyle={{ fontSize: "25px" }}
            />
          </div>
        </div>

        {additionalBottom && (
          <div className="d-none d-lg-block mb-lg-4">{additionalBottom}</div>
        )}

        <div className="card p-0 border-0 arcana-detail-card">
          <div className="card-body">
            <CardLineHeader
              title="Details"
              className="pe-2"
              textClassName="title"
            />
            <DetailContent attr="Token ID" value={item.token_id} />
            <DetailContent
              attr="Created By"
              value={item.creator}
              span_Class="text-overflow-vertical-1"
              style_not_need="true"
            />
            <DetailContent
              attr="Contract Address"
              valueStyle={{ textOverflow: "inherit" }}
              value={shorternAddress(
                TokenType.getContractAddress(item.token_type_id)
              )}
              right_icon={<ArcanaCopyIcon />}
              address={TokenType.getContractAddress(item.token_type_id)}
            />
            <DetailContent attr="Blockchain" value={item.details.blockchain} />
            <DetailContent
              attr="Token Standard"
              value={item.details.token_standard}
            />
            {/* <DetailContent attr="MX Creator Bonus" value={item.details.mx_creator_bonus} /> */}
          </div>
        </div>
        <div className="card p-0 border-0 arcana-scores-card">
          <div className="card-body">
            <CardLineHeader
              title="Scores"
              className="pe-2"
              textClassName="title"
            />
            <DetailContent
              icon={<ForceIcon />}
              attr={strings.formatString(walletArcanaDetailPage.FOR_Force)}
              value={formatNumber(item.FOR)}
              scores_changed_value={
                scores_changed ? scores_changed["FOR"] : undefined
              }
            />
            <DetailContent
              icon={<AbyssIcon />}
              attr={strings.formatString(walletArcanaDetailPage.ABS_Abyss)}
              value={formatNumber(item.ABS)}
              scores_changed_value={
                scores_changed ? scores_changed["ABS"] : undefined
              }
            />
            <DetailContent
              icon={<DeterminationIcon />}
              attr={strings.formatString(
                walletArcanaDetailPage.DFT_Determination
              )}
              value={formatNumber(item.DFT)}
              scores_changed_value={
                scores_changed ? scores_changed["DFT"] : undefined
              }
            />
            <DetailContent
              icon={<MindIcon />}
              attr={strings.formatString(walletArcanaDetailPage.MND_Mind)}
              value={formatNumber(item.MND)}
              scores_changed_value={
                scores_changed ? scores_changed["MND"] : undefined
              }
            />
            <DetailContent
              icon={<IntelligenceIcon />}
              attr={strings.formatString(
                walletArcanaDetailPage.INT_Intelligence
              )}
              value={formatNumber(item.INT)}
              scores_changed_value={
                scores_changed ? scores_changed["INT"] : undefined
              }
            />
            <DetailContent
              icon={<HeartIcon />}
              attr={strings.formatString(walletArcanaDetailPage.EXP_Experience)}
              value={formatNumber(item.EXP)}
              scores_changed_value={
                scores_changed ? scores_changed["EXP"] : undefined
              }
            />
          </div>
        </div>
        {/* <div className='card p-0 border-0'>
          <div className='card-body'>
            <CardLineHeader title="Features" className="pe-2" textClassName="title" />
            <div className='row'>
              {
                item.feature && item.feature.map((feature, index) =>
                  <Feature key={index} text={feature} />
                )
              }
            </div>
          </div>
        </div> */}
        {/* {
          chooseBtn &&
          <div className="pb-2 mx-auto choose-btn">
            {chooseBtn}
          </div>
        } */}
      </div>

      <div className="col">
        {
          <div className="card p-0 border-0 arcana-description-card">
            <div className="card-body">
              <CardLineHeader
                title="Description"
                className="pe-2"
                textClassName="title"
              />
              <p>{item.metadata?.description}</p>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export const TopicItem = (props) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const item = props.item || {};
  const cardRef = props.cardRef;

  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        if (item?.game_language) {
          navigate(`/products_detail/${item.id}`)
        }
        else {
          navigate(`/news_detail/${item.id}`)
        }
      }
    }
  };

  return (
    <a>
      <div className={`${props.containerClassName || ""}`} ref={cardRef}>
        {item?.news_maker_id || item?.news_makers ? (
          <TopicNewsItemViewCard onClick={onClick} item={item} />
        ) : (
          <TopicGameItemViewCard onClick={onClick} item={item} />
        )}
      </div>
    </a>
  );
};

export const NewsItem = (props) => {
  const navigate = useNavigate();
  const item = props.item || {};
  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else {
        navigate(`/news_detail/${item.id}`);
      }
    }
  };
  return (
    <a onClick={onClick}>
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
      >
        <div className="">
          <NewsItemViewCard item={item} />
        </div>
      </div>
    </a>
  );
};

export const NewsItemViewCard = (props) => {
  const item = props.item || {};
  const topImage = item.image_url;
  return (
    <div className="card item-card">
      <div className="card-image">
        <img
          style={{ height: 110 }}
          className={`pointer ${props.imgClassName || ""}`}
          src={topImage || require("../../assets/images/item_img.png")}
        />
      </div>
      <div className="card-body">
        <p
          style={{ height: 43, overflow: "hidden", minWidth: 20 }}
          className="mb-0"
        >
          <small className="card-title text-start pointer fw-400 f-regular p-title">
            {item?.title}
          </small>
        </p>
        {item?.news_makers?.name ? (
          <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
            <div
              className="creator-photo"
              style={{ minHeight: 30, minWidth: 30 }}
            >
              {item?.news_makers?.game_maker_icon && (
                <img src={item?.news_makers?.game_maker_icon} />
              )}
            </div>
            <div className="text-overflow" style={{ marginLeft: 6 }}>
              {item?.news_makers?.name}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center new-item-margin-buttom-without-maker mt-0"></div>
        )}
        <div className="item-date-mini-height">
          <CardFooter
            published={item?.published}
            typename={item?.types?.name}
          />
        </div>
      </div>
    </div>
  );
};

export const SPSquareCard = (props) => {
  const isPC = useMediaQuery({ minWidth: 992 });
  const item = props.item;
  const typename = item?.types?.name;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/squares/${item.id}`);
  };

  return (
    <div className="card item-card pointer min-h-unset" onClick={handleClick}>
      <div className="card-image">
        <ImageLayout
          ipfs_image={item.image_url || null}
          imgClassName={`pointer ${props.imgClassName || ""}`}
          style={{
            width: "100%",
            objectFit: "cover",
            aspectRatio: "7 / 4",
            height: "auto",
          }}
        />
      </div>
      <div className="card-body pt-1">
        <p style={{ height: 34, lineHeight: "1.5em" }} className="mb-2 w-100">
          <small className="card-title text-start pointer fw-400 f-regular p-title m-0">
            {item?.title}
          </small>
        </p>

        {item?.news_makers?.name ? (
          <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
            <div
              className="creator-photo"
              style={{ minHeight: 30, minWidth: 30 }}
            >
              {item?.news_makers?.game_maker_icon && (
                <img src={item?.news_makers?.game_maker_icon} />
              )}
            </div>
            <div
              className="text-overflow fs-14 fw-500 ff-Kardust-Light"
              style={{ marginLeft: 6 }}
            >
              {item?.news_makers?.name}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
        )}
        <div className="pt-2">
          <div className="d-flex flex-row justify-content-between align-items-center gap-20">
            <span className="f-condensed flex-shrink-0 fs-12 fw-400">
              {item.published && moment(item.published).format("YYYY.MM.DD")}
            </span>
            {typename && (
              <div className="px-1 purple-border d-flex flex-center overflow-hidden flex-shrink-1 flex-grow-0">
                <span
                  className={`w-100 primary-color ${isPC ? "fs-12" : "fs-10"
                    } fw-400 f-condensed text-nowrap text-ellipsis align-middle text-center`}
                >
                  {typename}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MiniGameCard = (props) => {
  const item = props.item ?? {};
  const onClick = (url) => props.onClick && props.onClick(url);
  return (
    <div className={`card item-card pointer min-h-unset ${props.className || ''}`}>
      <div className="card-image">
        <ImageLayout
          ipfs_image={item.thumbnail_image || null}
          style={{
            width: "100%",
            objectFit: "cover",
            aspectRatio: "7 / 4",
            height: "auto",
          }}
        />
      </div>
      <div className="card-body pt-2">
        <p style={{ height: 34, lineHeight: "1.5em" }} className="mb-2 w-100">
          <small className="card-title text-justify pointer fw-500 f-regular p-title m-0 fs-12 text-capitalize">
            {item.name}
          </small>
        </p>
        <div className="mt-auto d-flex justify-content-center">
          <Button
            onClick={() => onClick(item.url)}
            active
            className="w-75 fs-15 rounded fw-500 p-0"
            style={{ height: 30, marginBlock: 25 }}>
            PLAY
          </Button>
        </div>
      </div>
    </div>
  );
};

export const TopicNewsItemViewCard = (props) => {
  const { language } = useContext(appContext);
  const item = props.item || {};
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name) || item?.types?.name;
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      navigate(`/squares/${item.id}`);
    }
  };

  return (
    <div className="card item-card pointer min-h-unset" onClick={handleClick}>
      <div className="card-image">
        {/* <img style={{ height: 110 }} className={`pointer ${props.imgClassName || ''}`} src={item.image_url || require('../../assets/images/item_img.png')} /> */}
        <ImageLayout
          ipfs_image={item.image_url || null}
          imgClassName={`pointer ${props.imgClassName || ""}`}
          style={{ height: "auto", aspectRatio: "7/4" }}
        />
      </div>
      <div className="card-body">
        <p
          style={{ height: 43, lineHeight: "1.5em", minWidth: 20 }}
          className="mb-0"
        >
          <small className="card-title text-start pointer fw-400 f-regular p-title">
            {item?.title}
          </small>
        </p>

        {item?.news_makers?.name ? (
          <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
            <div
              className="creator-photo"
              style={{ minHeight: 30, minWidth: 30 }}
            >
              {item?.news_makers?.game_maker_icon && (
                <img src={item?.news_makers?.game_maker_icon} />
              )}
            </div>
            <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
              {item?.news_makers?.name}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
        )}
        <div className="item-date-mini-height">
          <CardFooter published={item?.published} typename={typename} />
        </div>
      </div>
    </div>
  );
};

export const TopicGameItemViewCard = (props) => {
  const { language } = useContext(appContext);
  const item = props.item || {};
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <div className="card item-card min-h-unset" onClick={handleClick}>
      <div className="card-image">
        <img
          style={{ height: "auto", aspectRatio: "7/4" }}
          className={`pointer ${props.imgClassName || ""}`}
          src={
            item?.thumbnail_image_url ||
            require("../../assets/images/item_img.png")
          }
        />
      </div>
      <div className="card-body">
        <p
          style={{ height: 43, overflow: "hidden", minWidth: 20 }}
          className="mb-0"
        >
          <small className="card-title text-start pointer fw-400 f-regular p-title">
            {item?.title}
          </small>
        </p>
        {item?.game_makers?.name ? (
          <div className="d-flex flex-row align-items-center item-profile-mini-height gap-7px">
            <div
              className="creator-photo"
              style={{ minHeight: 30, minWidth: 30 }}
            >
              {item?.game_makers?.game_maker_icon && (
                <img src={item?.game_makers?.game_maker_icon} />
              )}
            </div>
            <div className="text-overflow fs-12" style={{ marginLeft: 6 }}>
              {item?.game_makers?.name}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center item-margin-buttom-without-maker"></div>
        )}
        <div className="item-date-mini-height">
          <CardFooter published={item.published} typename={typename} />
        </div>
      </div>
    </div>
  );
};

export const responsive = {
  xxxl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 6000, min: 3000 },
    items: 8.3,
    partialVisibilityGutter: 0,
  },
  xxl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1400 },
    items: 5,
    partialVisibilityGutter: 0,
  },
  xl: {
    breakpoint: { max: 1400, min: 1200 },
    items: 5,
    partialVisibilityGutter: 0,
  },
  lg: {
    breakpoint: { max: 1200, min: 991 },
    items: 3,
    partialVisibilityGutter: 0,
  },
  md: {
    breakpoint: { max: 991, min: 768 },
    items: 3,
    partialVisibilityGutter: 0,
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 3,
    partialVisibilityGutter: 0,
  },
  xs: {
    breakpoint: { max: 576, min: 0 },
    items: 1.9,
    partialVisibilityGutter: 0,
  },
};

export const responsive_small = {
  xxxl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 6000, min: 3000 },
    items: 8,
    partialVisibilityGutter: 40,
  },
  xxl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1400 },
    items: 5,
    partialVisibilityGutter: 40,
  },
  xl: {
    breakpoint: { max: 1400, min: 1200 },
    items: 3,
    partialVisibilityGutter: 80,
  },
  lg: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 4,
    partialVisibilityGutter: 0,
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
    partialVisibilityGutter: 80,
  },
  xs: {
    breakpoint: { max: 576, min: 0 },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export const TokenItem = (props) => {
  const navigate = useNavigate();
  const [showBottomModal, setShowBottomModal] = useState(false);
  const onLoadingHandler = props.onLoadingHandler;
  const onGetItemHandler = props.onGetItemHandler;
  const balance = props.balance;
  // const [balance, setBalance] = useState('-');
  const item = props.item;
  const user = props.user;
  const isOwn = item.user_id === user.id;
  const tokenPage = translations.tokenPage;
  const deleteIcon = props.deleteIcon;

  const onClick = () => {
    navigate(`/wallet/token/detail/${item.id}`);
  };

  const onCloseBottomModal = () => {
    setShowBottomModal(false);
  };

  const deleteCustomToken = async () => {
    onLoadingHandler(true);
    const response = await deleteData(`user-tokens/${item.id}`);
    if (response.ok) {
      onGetItemHandler();
      if (response.data) {
        const text = `${item.token_name} ${strings.formatString(
          tokenPage.〇〇_Token_has_been_successfully_deleted
        )}`;
        SwalCorrect.fire({
          text,
        }).then(() => {
          onLoadingHandler(false);
        });
      }
    } else {
      onLoadingHandler(false);
      SwalWrong.fire({
        title: "Error",
        text: response.data.message,
      });
    }
  };

  const isPC = useMediaQuery({ minWidth: 992 });
  const showModalHandler = () => {
    isPC ? ondeleteCustomTokenEvent() : setShowBottomModal(true);
  };
  const ondeleteCustomTokenEvent = () => {
    const language = translations._language;
    const text =
      language === "jp"
        ? `${item.token_name} ${strings.formatString(
          tokenPage.Are_you_sure_you_want_to_delete_〇〇_Token
        )}?`
        : `${strings.formatString(
          tokenPage.Are_you_sure_you_want_to_delete_〇〇_Token
        )} ${item.token_name}?`;

    SwalSettings.fire({
      text: text,
      confirmButtonText: "yes",
      cancelButtonText: "no",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomToken();
      }
    });
  };

  const buttonComponent = (
    <div
      onClick={() => showModalHandler()}
      className="d-flex justify-content-center"
    >
      <TokenDeleteIcon className="delete-icon" />
    </div>
  );

  return (
    <>
      {
        <BottomModal
          onCloseHandler={onCloseBottomModal}
          onSubmitHandler={deleteCustomToken}
          onShow={showBottomModal}
          label={`${item.token_name} ${strings.formatString(
            tokenPage.Are_you_sure_you_want_to_delete_〇〇_Token
          )}`}
          cancelBtnLabel="yes"
          BackBtnLabel="no"
          notNavigate
          hideCross={true}
        />
      }
      {isPC ? (
        <div
          className={`${props.containerClassName || ""}`}
          style={{ marginBottom: 16 }}
        >
          <div className="card token-card">
            <div className="card-body d-flex align-items-center">
              <div onClick={onClick} className="col-12 py-2">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="token-image mb-0">{item.token_image}</div>
                    <span className="token-name mb-0 f-regular">
                      {item.token_name}
                    </span>
                  </div>
                  <div className="token-price d-flex align-items-center">
                    {balance}
                  </div>
                </div>
              </div>
              {deleteIcon && isOwn && (
                <div onClick={showModalHandler} className="token-trash">
                  <TokenDeleteIcon className="m-1" />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <SwipeableCard
          key={item.id}
          containerStyle={{
            backgroundColor: "#BF96FF",
            borderRadius: 12,
          }}
          buttonComponent={buttonComponent}
        >
          <div
            className={`${props.containerClassName || ""}`}
            style={{ marginBottom: 16 }}
          >
            <div className="card token-card">
              <div className="card-body d-flex align-items-center">
                <div onClick={onClick} className="col-12 py-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="token-image mb-0">{item.token_image}</div>
                      <span className="token-name mb-0 f-regular">
                        {item.token_name}
                      </span>
                    </div>
                    <div className="token-price d-flex align-items-center">
                      {balance}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeableCard>
      )}
    </>
  );
};

export const TokenDetailItem = (props) => {
  const item = props.item || {};
  const token = props.token || {};
  const wallet_address = props.wallet_address;
  const [showDropdown, setShowDropdown] = useState(false);
  const onClick = () => {
    setShowDropdown(!showDropdown);
  };
  const tokenDetailPage = translations.tokenDetailPage;
  const sendOrReceice = wallet_address === item.from;

  return (
    <div
      className={`${props.containerClassName || ""}`}
      style={{ marginBottom: 16 }}
    >
      <div className="card token-card pointer" onClick={onClick}>
        <div className="card-body d-flex align-items-center">
          <div className="col-12">
            <div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  {sendOrReceice ? (
                    <TokenSendIcon className="m-1" />
                  ) : (
                    <TokenReceiveIcon className="m-1" />
                  )}
                  <div className="d-flex flex-column">
                    <span className="token-type mb-0 f-regular">
                      {sendOrReceice
                        ? strings.formatString(tokenDetailPage.Send)
                        : strings.formatString(tokenDetailPage.Receive)}
                    </span>
                    <span className="token-date mb-0 f-regular">
                      {item.timestamp}
                    </span>
                  </div>
                </div>
                <div className="token-price d-flex flex-row justify-content-center align-items-center">
                  {item.amount || "-"}
                  <div>
                    <PolygonIcon
                      className={`ms-3 mb-1 dropdownicon ${showDropdown ? "open" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`token-show-dropdown ${showDropdown ? "token-show-detail-dropdown" : ""
                  }`}
              >
                <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                  <div className="d-flex flex-column ml-40px">
                    <span className="token-type mb-0 f-regular">
                      {strings.formatString(tokenDetailPage.TX_ID)}
                    </span>
                    <span className="token-date mb-0 f-regular">
                      {item.tx_hash}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                  <div className="d-flex flex-column ml-40px">
                    <span className="token-type mb-0 f-regular">
                      {strings.formatString(tokenDetailPage.From)}
                    </span>
                    <span className="token-date mb-0 f-regular">
                      {item.from}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GameHeaderItem = (props) => {
  const navigate = useNavigate();
  const item = props.item;
  const isPh = useMediaQuery({ maxWidth: 576 });
  const topImage =
    !isPh && item.top_pc_image_url ? item.top_pc_image_url : item.top_image_url;

  return (
    <div className="new-games-header-card" style={{ cursor: "pointer" }}>
      <div
        onClick={() => navigate(`/products_detail/${item.id}`)}
        className="new-games-img"
        style={{
          background: `linear-gradient(0.46deg, rgba(0, 0, 0, 0.69) 0.43%, rgba(0, 0, 0, 0.442708) 31.95%, rgba(0, 0, 0, 0) 99.64%), url('${topImage || require("../../assets/images/item_img.png")
            }') center/cover no-repeat`,
        }}
      ></div>
      <div className="new-games-body">
        <div
          onClick={() => navigate(`/products_detail/${item.id}`)}
          className="d-flex flex-row"
        >
          <div className="new-games-maker">
            <img
              style={{
                width: "31px",
                height: "31px",
                borderRadius: "5px",
                objectFit: "cover",
              }}
              src={
                item?.game_makers?.game_maker_icon ||
                require("../../assets/images/item_img.png")
              }
              alt=""
            />
          </div>
          <div className="d-flex flex-column">
            <span className="new-games-header-card-title tx-ellipsis">
              {(translations._language === "jp"
                ? item?.title
                : item?.title_en) || item?.title_en}
            </span>
            <span className="new-games-header-card-text tx-ellipsis">
              {(translations._language === "jp"
                ? item?.short_body
                : item?.short_body_en) || item?.short_body_en}
            </span>
          </div>
        </div>
        {/* <a href={item.url} className="d-flex align-items-center"><div className={"btn  new-games-header-card-btn "+ (item?.url?'btn-custom-active':'btn-custom-nonactive') }>PLAY</div></a> */}
      </div>
    </div>
  );
};

export const GameNewCardItems = (props) => {
  const navigate = useNavigate();
  const { item, index, isTop, isSquare } = props;
  return (
    <div
      key={"new-games-" + index}
      className="new-games-new"
      style={{ cursor: "pointer" }}
    >
      <div className="new-games-new-card">
        <div
          onClick={() => navigate(`/${props.link}/${item.id}`)}
          className="d-flex align-items-center"
        >
          {/* <img style={{ width: '60px', height: '60px', borderRadius: '5px', marginRight: '10px', objectFit: 'cover' }} src={item?.square_thumbnail_image_url || item?.square_thumbnail_image || require('../../assets/images/item_img.png')} alt="" /> */}
          <ImageLayout
            no_text_icon
            iconClassName="list-item-img"
            ipfs_image={
              item?.square_thumbnail_image_url ||
              item?.square_thumbnail_image ||
              null
            }
            imgClassName="list-item-img"
          />
          <div className="d-flex flex-column" style={{ maxWidth: 230 }}>
            <div className="mb-1 fs-14 f-regular fw-600 text-capitalize primary-color tx-ellipsis">
              {isSquare
                ? (translations._language === "jp"
                  ? item?.title
                  : item?.title_en) || item?.title_en
                : item?.game_categories?.name || item?.square_maker}
            </div>
            <span
              className={`mb-1 fs-14 new-games-new-card-name ${item?.short_body === null ? "tx-ellipsis2" : "tx-ellipsis"
                }`}
            >
              {isSquare
                ? item?.game_categories?.name || item?.square_maker
                : (translations._language === "jp"
                  ? item?.title
                  : item?.title_en) || item?.title_en}
            </span>
            <span className="new-games-new-card-text text-overflow-vertical-2">
              {(translations._language === "jp"
                ? item?.short_body
                : item?.short_body_en) || item?.short_body_en}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column">
          {/* {!item.url ?
            <a className="mb-1 d-flex align-items-center">
            <div className="btn btn-custom-nonactive new-games-new-card-btn">PLAY</div>
            </a>
            :

          <a href={item.url} className="mb-1 d-flex align-items-center"><div className="btn btn-custom-active new-games-new-card-btn">PLAY</div></a>
          } */}
          {/*<span className='new-games-new-card-purchase'>{item?.game_makers?.name}</span>*/}
        </div>
        {isTop && (
          <div className="new-games-number">
            <span>{++index}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const PersonaItem = (props) => {
  const item = props.item || {};
  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      }
    }
  };
  return (
    <a onClick={onClick}>
      <div
        className={`${props.containerClassName || ""}`}
        style={{ marginBottom: 16 }}
      >
        <div className="token-item" style={{ width: 170 }}>
          <div className="card item-card">
            <div className="card-image">
              {/* <ImageLayout 
                ipfs_image={item.image || null}
                imgClassName={props.imgClassName}
              /> */}
              <img
                style={{
                  height: 155,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                className={` ${props.imgClassName || ""}`}
                src={item.image || require("../../assets/images/item_img.png")}
              />
            </div>
            <div className="card-body" style={{ height: 145 }}>
              <PersonaIcon className="purple-icon float-end token-type-img" />
              <br />
              <p
                className="card-title mb-0 fw-500 fs-15"
                style={{ minHeight: 18 }}
              >
                {item.name}
              </p>
              <div
                style={{
                  display: "grid",
                  gridTemplate: "auto / 1fr 2fr 2fr",
                  gap: 2,
                  marginTop: 5,
                }}
              >
                <div className="field text-gradient"></div>
                <span className="mb-0 text-center primary-color f-regular f-500 fs-12">
                  JPY
                </span>
                <span className="mb-0 text-center primary-color f-regular f-500 fs-12">
                  ANM
                </span>
                <div className="field text-gradient">ASK</div>
                <span
                  className="mb-0 subHeader f-regular f-500 fs-12 text-white"
                  style={{ justifySelf: "center" }}
                >
                  {item.ask_price > 0 ? formatMoney(item.ask_price) : "---"}
                </span>
                <span
                  className="mb-0 subHeader f-regular f-500 fs-12 text-white"
                  style={{ justifySelf: "center" }}
                >
                  {item.ask_price > 0 ? formatMoney(item.ask_price) : "---"}
                </span>
                <div className="field text-gradient">BID</div>
                <span
                  className="mb-0 subHeader f-regular f-500 fs-12 text-white"
                  style={{ justifySelf: "center" }}
                >
                  {item.bid_price > 0 ? formatMoney(item.bid_price) : "---"}
                </span>
                <span
                  className="mb-0 subHeader f-regular f-500 fs-12 text-white"
                  style={{ justifySelf: "center" }}
                >
                  {item.bid_price > 0 ? formatMoney(item.bid_price) : "---"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export const ScoreComponent = ({ item }) => {
  const content = translations.chainDetailPage;
  return (
    <>
      <span className="primary-color f-regular fs-25 fw-400">Scores</span>
      <div className="my-3"></div>
      <DetailContent
        icon={<ForceIcon />}
        attr={strings.formatString(content.FOR_Force)}
        value={formatNumber(item.FOR)}
      />
      <div className="my-3"></div>
      <DetailContent
        icon={<AbyssIcon />}
        attr={strings.formatString(content.ABS_Abyss)}
        value={formatNumber(item.ABS)}
      />
      <div className="my-3"></div>
      <DetailContent
        icon={<DeterminationIcon />}
        attr={strings.formatString(content.DFT_Determination)}
        value={formatNumber(item.DFT)}
      />
      <div className="my-3"></div>
      <DetailContent
        icon={<MindIcon />}
        attr={strings.formatString(content.MND_Mind)}
        value={formatNumber(item.MND)}
      />
      <div className="my-3"></div>
      <DetailContent
        icon={<IntelligenceIcon />}
        attr={strings.formatString(content.INT_Intelligence)}
        value={formatNumber(item.INT)}
      />
      <div className="my-3"></div>
      <DetailContent
        icon={<HeartIcon />}
        attr={strings.formatString(content.EXP_Experience)}
        value={formatNumber(item.EXP)}
      />
    </>
  );
};

export const SquareCard = (props) => {
  const item = {
    image_url: props.feature_image,
    types: {
      name: props.news_categories,
    },
    published: props.publish_date_time,
    news_makers: {
      name: props.square_maker,
      game_maker_icon: props.square_maker_icon,
    },
    ...props,
  };
  return props.gridItem ? (
    <SPSquareCard item={item} />
  ) : (
    <TopicNewsItemViewCard item={item} />
  );
};

const CategoryTag = ({ typename }) => {
  const isPC = useMediaQuery({ minWidth: 992 });
  return (
    <div className="px-2 purple-border d-flex flex-center overflow-hidden flex-shrink-1 flex-grow-0">
      <span
        className={`w-100 primary-color ${isPC ? "f-regular fs-12 " : "f-condensed fs-10"
          } fw-400 text-nowrap text-ellipsis align-middle text-center`}
      >
        {typename}
      </span>
    </div>
  );
};

const DateTag = ({ published }) => {
  const isPC = useMediaQuery({ minWidth: 992 });
  return (
    <span
      className={`${isPC ? "f-regular" : "f-condensed"
        } flex-shrink-0 fs-12 fw-400`}
    >
      {published && moment(published).format("YYYY.MM.DD")}
    </span>
  );
};

const CardFooter = ({ typename, published }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center gap-20">
      <DateTag published={published} />
      {typename ? <CategoryTag typename={typename} /> : null}
    </div>
  );
};
