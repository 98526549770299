/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../services/fetch";
import { HomeTitle } from "../../components/common/Title";
import { ItemDesk } from "../wallet/WalletAbsorb";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { getArcanaDetail, isJP, isObjectEmpty } from "../../components/common/commonFnc";
import { LaddaButton } from "../../components/common/Button";
import { Web3authContext } from "../../context/web3authContext";
import { useDidUpdateEffect } from "../../services/hook";
import { getWalletAddress } from "../../services/storage";
import { OnLoading } from "../../components/common/OnLoading";
import { SwalCorrect, SwalWarn, SwalWrong } from "../../components/common/Swal";
import { TokenType } from "../../components/common/enum";
import { DrawChainCoverImg } from "./DrawChainDetail";
import { translations } from "../../services/localization";
import Web3 from "web3";
import { useMediaQuery } from "react-responsive";
import { TitleWithBackButton } from "./drawChains";
import { Button } from "../squares/Button";
import { PersonaIcon } from "../../components/common/Icon";

function DrawChainComparison() {

    const navigate = useNavigate();
    const { wallet_address, provider, setProvider, loginIfNotLogin, isValidWalletAddress, logout, drawContract } = useContext(Web3authContext);
    const [item, setItem] = useState(undefined)
    const [necessaryPersona, setNecessaryPersona] = useState(undefined)
    const { draw_chain_id, persona, squareDetail } = useLocation().state || {};
    const [personaItem, setPersonaItem] = useState(persona);
    const { persona_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [hideBottomNav, setHideBottomNav] = useState(false);
    const [startDraw, setStartDraw] = useState(false);
    const [id_token, setIdToken] = useState(null);
    const content = translations.drawChainComparison
    const buttonList = translations.buttonlist
    const isPC = useMediaQuery({ minWidth: 992 });

    const fetchDetail = async (draw_chain_id) => {
        if (draw_chain_id) {
            setLoading(true);
            const response = await getData(`/drawchain/${draw_chain_id}`)
            if (response.ok) {
                setItem(response.data.data)
                setNecessaryPersona(response.data.data.persona)
            }
            setLoading(false);
        }
    }

    const getPersonaItem = async (token_id) => {
        const token = await getArcanaDetail(TokenType.Persona, token_id);
        setPersonaItem(token);
        // setPersonaItem(data.dummy_persona)
    }

    useEffect(() => {
        if (!personaItem || isObjectEmpty(personaItem)) {
            getPersonaItem(persona_id);
        }
    }, [persona_id])

    useEffect(() => {
        fetchDetail(draw_chain_id)
    }, [draw_chain_id]);

    const handleDraw = async () => {
        setLoading(true);
        setStartDraw(true);
    }

    useEffect(() => {
        console.log({ provider, personaItem })
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        } else {
            setProvider(provider)
            console.log("Provider set")
        }
    }, [provider]);

    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    useDidUpdateEffect(() => {
        if (startDraw && getWalletAddress() && provider) {
            draw();
        }
    }, [wallet_address, provider, startDraw])

    const handleReselect = () => {
        navigate(`/squares/${squareDetail?.id}/draw-chains/persona/list`, { state: { draw_chain_id, squareDetail } })
    }

    const draw = async () => {
        const isValid = await isValidWalletAddress();
        if (isValid) {
            if (personaItem && !isObjectEmpty(personaItem) && personaItem.token_id) {
                console.log({ drawchain_id:item.drawchain_id, token: personaItem.token_id })
                const receipt = await drawContract(item.drawchain_id, personaItem.token_id);
                console.log('drawContract res', receipt);
                if (receipt && receipt.status) {
                    SwalCorrect.fire({
                        allowOutsideClick: false,
                        html: <p className='text-center'>{translations.formatString(content.drawSuccessMsg)}</p>
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(`/squares`)
                        }
                    });
                } else {
                    let revert_reason = "";
                    if (receipt && receipt.revertReason) {
                        revert_reason = Web3.utils.toAscii(receipt.revertReason);
                        console.log({ revert_reason })
                    } else if (receipt && receipt.error) {
                        revert_reason = receipt.error;
                    }
                    console.log({ revert_reason });
                    SwalWarn.fire({
                        allowOutsideClick: false,
                        html: <p className='text-center m-b-30 pre-wrap'>{translations.formatString(content.drawFailMsg)}</p>
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setLoading(false);
                        }
                    });
                }
            }
        } else {
            setLoading(false);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
            });
            logout();
        }
    }

    const AdditionBtn = () =>
        provider && !loading && personaItem && !isObjectEmpty(personaItem || {}) && (
            <LaddaButton type="button" className={`btn btn-custom-active common-btn m-0`} onClick={handleDraw}>
                {
                    buttonList.drawBtn
                }
            </LaddaButton>
        )

    DrawChainComparison.additionalBottom = (<MobileView>
        <AdditionBtn />
    </MobileView>)

    return (
        <>
            {
                loading ? <OnLoading inline marginAuto /> : <>
                    <div>
                        <div className={`w-100 mt-4 mb-4`} style={{ position: 'sticky', top: 0, zIndex: 2, isolation: 'isolate' }}>
                            <TitleWithBackButton />
                        </div>
                        <div>
                            <DrawChainCoverImg src={isPC ? item?.image_url : item?.image_url_sp} />
                            <div className="d-flex flex-column py-2 px-3">
                                <span className="primary-color fs-22 f-regular py-2">
                                    {isJP() ? item.title : item.title_en}
                                </span>
                                {/* <span className="primary-color fs-20 f-condensed py-2">
                                    {item.from_date ? moment(item.from_date).format('YYYY.MM.DD') : ''} -
                                    {item.from_date ? moment(item.to_date).format('YYYY.MM.DD') : ''}
                                </span> */}
                            </div>
                        </div>
                        {isPC && (
                            <div className="w-100 px-3 d-flex m-t-30">
                                <AdditionBtn />
                                <Button
                                    gap={20}
                                    className="fs-20 common-btn ms-4 border-radius-15"
                                    onClick={handleReselect}
                                    icon={<PersonaIcon className="active-3 p-t-5" />}
                                >
                                    {content?.reselectPersona}
                                </Button>
                            </div>
                        )}
                        {/* Persona */}
                        <div className={`${isPC ? 'w-100 d-flex mt-5 mb-4' : 'mx-3 my-4'}`} style={{ gap: 50 }}>
                            <div className="form-wrapper flex-column">
                                <HomeTitle title={translations.formatString(content.necessaryPersona)} className="f-expanded fw-500 fs-25" />
                                {necessaryPersona && !isObjectEmpty(necessaryPersona) && <ItemDesk item={Object.assign(necessaryPersona, {
                                    FOR: necessaryPersona.for,
                                    ABS: necessaryPersona.abs,
                                    DFT: necessaryPersona.dft,
                                    MND: necessaryPersona.mnd,
                                    INT: necessaryPersona.int,
                                    EXP: necessaryPersona.exp,
                                    token_type_id: TokenType.Persona,
                                })} className="m-t-27" cardFirst hidePrices />}
                            </div>
                            <div className="form-wrapper flex-column">
                                <HomeTitle title={translations.formatString(content.yourPersona)} className={`f-expanded fw-500 fs-25 ${isPC ? '' : 'm-t-60'}`} />
                                {personaItem && !isObjectEmpty(personaItem) && <ItemDesk item={personaItem} className="m-t-27" cardFirst />}
                            </div>
                            {/* <div className="row mt-3">
                        <div className="col-6">
                            <Item item={item} />
                        </div>
                        <div className="col-6">
                            <ScoreComponent item={item} />
                        </div>
                    </div> */}
                        </div>
                        {!isPC &&
                            <div className="w-100 px-3 mb-3">
                                <Button
                                    gap={20}
                                    className="fs-20 common-btn border-radius-15"
                                    onClick={handleReselect}
                                    icon={<PersonaIcon className="active-3" />}>
                                    {content?.reselectPersona}
                                </Button>
                            </div>
                        }
                        {!isPC && <BrowserView>
                            <div className="w-100 px-3">
                                <AdditionBtn />
                            </div>
                        </BrowserView>}
                    </div>
                </>
            }
        </>
    )
}

export default DrawChainComparison
