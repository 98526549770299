/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { TokenType } from './enum';
import {
    PersonaWalletSendIcon,
    ArcanaWalletSendIcon,
    SendIcon,
    ReceiveIcon,
    SwapIcon,
    TokenTWalletSendIcon,
    AnimaIcon
} from './Icon';
import { strings, translations } from "../../services/localization";

const FlexNavRow = ({ items, value, onClick }) => {

    const language = translations._language;

    const onClickItem = (index) => {
        if (onClick) onClick(index);
    }
    return (
        <div className='flex-nav-row'>
            <ul className="nav nav-pills">
                {
                    items.map((item, index) =>
                        <li key={index} className="nav-item">
                            <a className={`nav-link ${value === index ? 'active' : ''}`} onClick={() => onClickItem(index)}>
                                <i>
                                    {value === index ? item.imgactive : item.img}
                                </i>
                                <span className={`${language === 'jp' ? 'f-notosan' : ''} mt-1`}>{item.name}</span>
                            </a>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}

export default FlexNavRow

export const CategoryNavRow = ({ onChangeTokenType }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const onClickItem = (index) => {
        setCurrentIndex(index);
        if (onChangeTokenType) {
            onChangeTokenType(items[index].token_type_id);
        }
    }
    const items = [
        { token_type_id: TokenType.Arcana, img: <ArcanaWalletSendIcon className="arcana-send-icon" />, imgactive: <ArcanaWalletSendIcon className="active-icon" />, name: 'Arcana' },
        { token_type_id: TokenType.Persona, img: <PersonaWalletSendIcon />, imgactive: <PersonaWalletSendIcon className="active-icon" />, name: 'Persona' },
        { token_type_id: TokenType.Anima, img: <div className="anima-purple-circle pointer d-flex align-items-center justify-content-center"><AnimaIcon className="purple-icon wallet-anima-icon" /></div>, imgactive: <AnimaIcon className="active-icon" />, name: 'Anima' },
        { token_type_id: TokenType.Token, img: <TokenTWalletSendIcon />, imgactive: <TokenTWalletSendIcon className="active-icon" />, name: 'Others' },
    ];
    return (
        <FlexNavRow items={items} value={currentIndex} onClick={onClickItem} />
    )
}

export const TokenCategoryNavRow = ({ onChangeTokenType }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const onClickItem = (index) => {
        setCurrentIndex(index);
        if (onChangeTokenType) {
            onChangeTokenType(items[index].type);
        }
    }

    const tokenDetailPage = translations.tokenDetailPage;
    const items = [
        {
            img: <SendIcon />,
            imgactive: <SendIcon />,
            name: strings.formatString(tokenDetailPage.Send),
            type: 'send'
        },
        {
            img: <ReceiveIcon />,
            imgactive: <ReceiveIcon />,
            name: strings.formatString(tokenDetailPage.Receive),
            type: 'receive'
        },
        {
            img: <SwapIcon className="wallet-send-icon" />,
            imgactive: <SwapIcon className="wallet-send-icon" />,
            name: 'Swap',
            type: 'swap',
            disabled: true,
        },
    ];
    return (
        // <FlexNavRow items={items} value={currentIndex} onClick={onClickItem} />
        items.map((item, index) =>
            <a key={index}
                className={`${currentIndex === index ? 'active' : ''}`}
                onClick={() => onClickItem(index)}
            >
                <div className={`d-flex align-items-center ${item.disabled ? 'icon-opacity' : ''}`}>
                    <i>
                        {currentIndex === index ? item.imgactive : item.img}
                    </i>
                    <span className={`jp-f-notosan fs-20 p-l-10 p-r-30`} style={{ fontFamily: 'Kardust Condensed' }}>
                        {item.name}
                    </span>
                </div>
            </a>
        )
    )
}
