import React, { useEffect, useState, useRef, useCallback, useContext } from "react";

import AppWrapper from "../../components/wrappers/AppWrapper";
import { OnLoading } from "../../components/common/OnLoading";
import { appContext } from "../../context/appContext";
import { SwalWrong } from "../../components/common/Swal";
import { getData } from "../../services/fetch";
import { TopicItem } from "./Item";
import InfiniteScrollContainer from "./InfiniteScrollContainer";
import { useMediaQuery } from "react-responsive";

const InfiniteScroll = ({
  title, pageid, metaTitle, metaDescription, link
}) => {
  const isLgPh = useMediaQuery({ maxWidth: 576 });
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsLimit, setItemsLimit] = useState(null)

  const { language, timeZone } = useContext(appContext)
  const shortForm = language === 'English' ? 'en' : 'jp'

  const fetchNewData = async () => {
    const response = await getData(`${link}?language=${shortForm}&page=${page}`);
    if (response.ok) {
      const resp = response.data;
      if (resp) {
        setItems(resp.data);
        setTotal(resp.meta.total);
        setLast_page(resp.meta.last_page);
        setHasMore(resp.meta.last_page > page);
        setLoading(false);
      }

    } else {
      SwalWrong.fire({
        title: 'Error',
        text: 'Cant fetch data'
      })
      setLoading(false);
    }
  };

  useEffect(() => {
    if (language && timeZone) {
      fetchNewData();
    }
  }, [language, timeZone, page]);

  return (
    <AppWrapper
      title={title}
      pageid={pageid}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {
        loading ? <OnLoading /> :
          <div className="row gx-3 mt-4">
            <div className="col-xl-12 col-lg-12">

              <InfiniteScrollContainer
                onChangePage={setPage}
                hasMore={hasMore}
                newItems={items}
                itemSize={isLgPh ? 210 : 226}
                leftSpace={0}
                rightSpace={isLgPh ? 0 : 16}
                limit={12}
                setLimit={setItemsLimit}
                total={total}
                renderedContainerClassName='row trend-list'
                renderedComponent={(item, index, ref) =>
                  <TopicItem key={`topic_item_${index}`} item={item} language={language} cardRef={items.length === index + 1 ? ref : null} />
                } />
            </div>
          </div>
      }
    </AppWrapper>
  )
};

export default InfiniteScroll;