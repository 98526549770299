import React, { useEffect } from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { OnLoading } from '../../components/common/OnLoading'
import { useNavigate, useParams } from 'react-router-dom'
import { TokenType } from '../../components/common/enum'
import { getToken } from '../../services/storage'

const WalletAnimaCharge = () => {
    const navigate = useNavigate();
    const { address, amount } = useParams();
    useEffect(() => {
        if(getToken()) {
            if (address && (amount || amount === 0)) {
                navigate(`/wallet/send/address`, {
                    state: {
                        token: { token_type_id: TokenType.Anima },
                        token_type_id: TokenType.Anima,
                        animaChargeData: { address, amount }
                    }
                })
            }
        } else {
            navigate(`/login/idms?r=${window.location.pathname}`)
        }
    }, [address, amount])

    return (
        <AppWrapper disableHeader disableBottomNav disableFooter disableSidebar>
            {/* <OnLoading /> */}
        </AppWrapper>
    )
}

export default WalletAnimaCharge