import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { CateIcon } from "../../components/common/Icon";
import { SwalCorrect } from "../../components/common/Swal";

import "../../style/Account.scss";
import { useEffect } from "react";
import { clearLocalStorage, getValue } from "../../services/storage";
import { AuthContext } from "../../context/authContext";

function Labs() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const auth = useSelector((state) => state.auth);
    const { logout } = useContext(AuthContext);

    const onSignOutHandler = () => {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let minutes = date.getMinutes();
        let hours = date.getHours()
        minutes = minutes++ < 10 ? '0' + minutes : minutes
        hours = hours++ < 10 ? '0' + hours : hours

        let currentDate = `${day}-${month}-${year}`;
        SwalCorrect.fire({
            title: `You have been successfully signed out`,
            text: `${currentDate} ${hours}:${minutes}`,
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
            }
        });
    }


    return (
        <>
            <AppWrapper className="auth-container" title='Labs' accountActive>
                <div className="my-3 mx-3">
                    <div className="mt-2"></div>
                    <div className="my-3 d-flex">
                        <CateIcon />
                        <span className="kardust-font mx-3">Category 1</span>
                    </div>
                    <div className="divider mb-4 mt-3 w-100 mx-auto"></div>

                    <div className="my-3 d-flex">
                        <ul className="labs-list-font">
                            <li>Build Your Own X</li>
                            <li>Free Programming Books</li>
                            <li>Oh My Zsh</li>
                        </ul>
                    </div>

                    <div className="my-3 d-flex">
                        <CateIcon />
                        <span className="kardust-font mx-3">Category 2</span>
                    </div>
                    <div className="divider mb-4 mt-3 w-100 mx-auto"></div>
                    <div className="my-3 d-flex">
                        <ul className="labs-list-font">
                            <li>Coding Interview University</li>
                            <li>Gitignore</li>
                        </ul>
                    </div>
                </div>
            </AppWrapper>
        </>
    );
}

export default Labs;
