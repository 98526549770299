const AvailableTimeZones = [
 
{ value: 'Pacific/Midway', text: '(GMT-11:00) Midway Island, Samoa' }
,
{ value: 'Pacific/Honolulu', text: '(GMT-10:00) Hawaii' }
,
{ value: 'America/Juneau', text: '(GMT-9:00) Alaska' }
,
{ value: 'America/Dawson', text: '(GMT-8:00) Dawson, Yukon' }
,
{ value: 'America/Tijuana', text: '(GMT-8:00) Tijuana' }
,
{ value: 'America/Los_Angeles', text: '(GMT-8:00) Pacific Time' }
,
{ value: 'America/Boise', text: '(GMT-7:00) Mountain Time' }
,
{ value: 'America/Chihuahua', text: '(GMT-7:00) Chihuahua, La Paz, Mazatlan' }
,
{ value: 'America/Phoenix', text: '(GMT-7:00) Arizona' }
,
{ value: 'America/Chicago', text: '(GMT-6:00) Central Time' }
,
{ value: 'America/Regina', text: '(GMT-6:00) Saskatchewan' }
,
{ value: 'America/Mexico_City', text: '(GMT-6:00) Guadalajara, Mexico City, Monterrey' }
,
{ value: 'America/Belize', text: '(GMT-6:00) Central America' }
,
{ value: 'America/Detroit', text: '(GMT-5:00) Eastern Time' }
,
{ value: 'America/Bogota', text: '(GMT-5:00) Bogota, Lima, Quito' }
,
{ value: 'America/Caracas', text: '(GMT-4:00) Caracas, La Paz' }
,
{ value: 'America/St_Johns', text: '(GMT-3:30) Newfoundland and Labrador' }
,
{ value: 'America/Santiago', text: '(GMT-3:00) Santiago' }
,
{ value: 'America/Sao_Paulo', text: '(GMT-3:00) Brasilia' }
,
{ value: 'America/Montevideo', text: '(GMT-3:00) Montevideo' }
,
{ value: 'America/Argentina/Buenos_Aires', text: '(GMT-3:00) Buenos Aires, Georgetown' }
,
{ value: 'America/Godthab', text: '(GMT-3:00) Greenland' }
,
{ value: 'Atlantic/Azores', text: '(GMT-1:00) Azores' }
,
{ value: 'Atlantic/Cape_Verde', text: '(GMT-1:00) Cape Verde Islands' }
,
{ value: 'Etc/GMT', text: '(GMT+0:00) UTC' }
,
{ value: 'Europe/London', text: '(GMT+0:00) Edinburgh, London' }
,
{ value: 'Europe/Dublin', text: '(GMT+0:00) Dublin' }
,
{ value: 'Europe/Lisbon', text: '(GMT+0:00) Lisbon' }
,
{ value: 'Africa/Casablanca', text: '(GMT+0:00) Casablanca, Monrovia' }
,
{ value: 'Atlantic/Canary', text: '(GMT+0:00) Canary Islands' }
,
{ value: 'Europe/Belgrade', text: '(GMT+1:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' }
,
{ value: 'Europe/Sarajevo', text: '(GMT+1:00) Sarajevo, Skopje, Warsaw, Zagreb' }
,
{ value: 'Europe/Brussels', text: '(GMT+1:00) Brussels, Copenhagen, Madrid, Paris' }
,
{ value: 'Europe/Amsterdam', text: '(GMT+1:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' }
,
{ value: 'Africa/Algiers', text: '(GMT+1:00) West Central Africa' }
,
{ value: 'Europe/Bucharest', text: '(GMT+2:00) Bucharest' }
,
{ value: 'Africa/Cairo', text: '(GMT+2:00) Cairo' }
,
{ value: 'Europe/Helsinki', text: '(GMT+2:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius' }
,
{ value: 'Europe/Athens', text: '(GMT+2:00) Athens, Minsk' }
,
{ value: 'Asia/Jerusalem', text: '(GMT+2:00) Jerusalem' }
,
{ value: 'Africa/Harare', text: '(GMT+2:00) Harare, Pretoria' }
,
{ value: 'Europe/Moscow', text: '(GMT+3:00) Istanbul, Moscow, St. Petersburg, Volgograd' }
,
{ value: 'Asia/Kuwait', text: '(GMT+3:00) Kuwait, Riyadh' }
,
{ value: 'Africa/Nairobi', text: '(GMT+3:00) Nairobi' }
,
{ value: 'Asia/Baghdad', text: '(GMT+3:00) Baghdad' }
,
{ value: 'Asia/Tehran', text: '(GMT+3:30) Tehran' }
,
{ value: 'Asia/Dubai', text: '(GMT+4:00) Abu Dhabi, Muscat' }
,
{ value: 'Asia/Baku', text: '(GMT+4:00) Baku, Tbilisi, Yerevan' }
,
{ value: 'Asia/Kabul', text: '(GMT+4:30) Kabul' }
,
{ value: 'Asia/Yekaterinburg', text: '(GMT+5:00) Ekaterinburg' }
,
{ value: 'Asia/Karachi', text: '(GMT+5:00) Islamabad, Karachi, Tashkent' }
,
{ value: 'Asia/Kolkata', text: '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi' }
,
{ value: 'Asia/Colombo', text: '(GMT+5:30) Sri Jayawardenepura' }
,
{ value: 'Asia/Kathmandu', text: '(GMT+5:45) Kathmandu' }
,
{ value: 'Asia/Dhaka', text: '(GMT+6:00) Astana, Dhaka' }
,
{ value: 'Asia/Almaty', text: '(GMT+6:00) Almaty, Novosibirsk' }
,
{ value: 'Asia/Rangoon', text: '(GMT+6:30) Yangon Rangoon' }
,
{ value: 'Asia/Bangkok', text: '(GMT+7:00) Bangkok, Hanoi, Jakarta' }
,
{ value: 'Asia/Krasnoyarsk', text: '(GMT+7:00) Krasnoyarsk' }
,
{ value: 'Asia/Shanghai', text: '(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi' }
,
{ value: 'Asia/Kuala_Lumpur', text: '(GMT+8:00) Kuala Lumpur, Singapore' }
,
{ value: 'Asia/Taipei', text: '(GMT+8:00) Taipei' }
,
{ value: 'Australia/Perth', text: '(GMT+8:00) Perth' }
,
{ value: 'Asia/Irkutsk', text: '(GMT+8:00) Irkutsk, Ulaanbaatar' }
,
{ value: 'Asia/Seoul', text: '(GMT+9:00) Seoul' }
,
{ value: 'Asia/Tokyo', text: '(GMT+9:00) Osaka, Sapporo, Tokyo' }
,
{ value: 'Australia/Darwin', text: '(GMT+9:30) Darwin' }
,
{ value: 'Asia/Yakutsk', text: '(GMT+10:00) Yakutsk' }
,
{ value: 'Australia/Brisbane', text: '(GMT+10:00) Brisbane' }
,
{ value: 'Asia/Vladivostok', text: '(GMT+10:00) Vladivostok' }
,
{ value: 'Pacific/Guam', text: '(GMT+10:00) Guam, Port Moresby' }
,
{ value: 'Australia/Adelaide', text: '(GMT+10:30) Adelaide' }
,
{ value: 'Australia/Sydney', text: '(GMT+11:00) Canberra, Melbourne, Sydney' }
,
{ value: 'Australia/Hobart', text: '(GMT+11:00) Hobart' }
,
{ value: 'Asia/Magadan', text: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia' }
,
{ value: 'Asia/Kamchatka', text: '(GMT+12:00) Kamchatka, Marshall Islands' }
,
{ value: 'Pacific/Fiji', text: '(GMT+12:00) Fiji Islands' }
,
{ value: 'Pacific/Auckland', text: '(GMT+13:00) Auckland, Wellington' }
,
{ value: 'Pacific/Tongatapu', text: "(GMT+14:00) Nuku'alofa" }
]

export const timeZoneOptions = AvailableTimeZones