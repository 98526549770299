import React, { useContext, useEffect, useRef, useState } from 'react'
import { appContext } from '../../context/appContext'
import { translations } from "../../services/localization";
import AppWrapper from '../../components/wrappers/AppWrapper'
import axios from "axios";
import { API_DOMAIN } from "../../config";
import { OnLoading } from '../../components/common/OnLoading'
import { TopicItem } from '../../components/common/Item'
import { useMediaQuery } from "react-responsive";
import "../../style/app/app.scss";
import { getData } from "../../services/fetch";

const TopicList = () => {
    const { language, timeZone } = useContext(appContext)
    const cardRef = useRef()

    const [topics, setTopics] = useState();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();
    const content = translations.topicList
    const [pageId, setPageId] = useState([]);
    const shortForm = language === 'English' ? 'en' : 'jp'

    const fetchNewData = (category) => {
        setPageId('Topics');
        setLoading(true);
        const url = API_DOMAIN + "tokens/get-topics?language=" + shortForm + "&timezone=" + timeZone
            + (category ? '&category_id=' + category.id : '');
        axios
            .get(url)
            .then((response) => {
                if (response.data) {
                    const topicsgameData = response.data.data.topic_games;
                    const topicsnewsData = response.data.data.topic_news;
                    const conbimetopics = [...topicsgameData, ...topicsnewsData];
                    setTopics([...conbimetopics].sort((a, b) => new Date(b.published).getTime() - new Date(a.published).getTime()));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const fetchCategories = async () => {
        const response = await getData(`topic/news-categories?language=${shortForm}`);
        if (response.ok) {
            setCategories(response.data.data);
        }
    }

    const handleSubNavOnChange = (category) => {
        setCategory(category);
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        if (language && timeZone) {
            fetchNewData(category);
        }
    }, [language, timeZone, category])

    const metaTitle = 'トピックス ｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のトピック一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    const isPh = useMediaQuery({ maxWidth: 480 })

    return (
        <AppWrapper
            topicsActive
            title={translations.formatString(content.headerTitle)}
            pageid={pageId}
            sidebarWrapperDivClassName={'p-t-0'}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            noPadding
            isNoSpaceAround
            subNavItems={categories}
            subNavOnChange={handleSubNavOnChange}>
            {
                loading? <OnLoading /> : (topics && topics.length > 0 && (
                    <div className={`mt-4 responsive-grid space-${isPh ? 10 : 15}px`}>
                        {
                            topics.map((item, index) => (
                                <TopicItem key={item.id} item={item} index={index} cardRef={cardRef} />
                            )
                            )
                        }
                    </div>
                ))
            }
        </AppWrapper>
    )
}

export default TopicList