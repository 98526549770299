import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthContext } from '../../context/authContext'

import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import {
  API_DOMAIN,
  REACT_APP_RPC_TARGET,
  REACT_APP_WALLET_CLIENT_ID,
  REACT_APP_CHAIN_ID,
  REACT_APP_INCUBATOR_CONTRACT_ADDRESS,
} from "../../config";
import "../../style/arcana-gen.css";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import button from "../../components/common/Button";
import RPC, { initWeb3, openloginAdapter, web3authModalParams } from "./web3RPC";
import { strings, translations } from "../../services/localization";
import meta_mask_button from "../../assets/images/logo_metamask_button.png";
import LaddaButton, { XL, L, CONTRACT, CONTRACT_OVERLAY, EXPAND_RIGHT, EXPAND_UP, SLIDE_RIGHT, SLIDE_LEFT, EXPAND_LEFT } from 'react-ladda';
import { getWalletAddress } from "../../services/storage";
import { Web3authContext } from "../../context/web3authContext";

export const W3authLoginButton = ({ setCompleteWeb3Auth, completeIdms, onLoadProvider, generateButtonFlg = false, loadProvderOnLoad = false, onClickGenerate = null, logoutOnInit = false, loginOnStart = false, setFormData, disabled }) => {
  const navigate = useNavigate();
  const state = { loading: false };
  const [Laddaloading, setLaddaLoading] = useState(false);
  const { wallet_address, provider, init } = useContext(Web3authContext);
  const toggle = () => {
    setLaddaLoading(prev => !prev)
  }
  const test = () => {
    setLaddaLoading(true);
  }
  const buttonlist = translations.buttonlist
  const [error, setError] = useState(null);
  const [web3auth, setWeb3auth] = useState(null);
  // const [provider, setProvider] = useState(null);
  const [isWaitingProvider, setIsWaitingProvider] = useState(false);

  const [image, setImage] = useState([]);

  const tokenName = React.useRef(null);
  const creatorName = React.useRef(null);
  const { seed, egg_id, sig } = useParams();

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if (logoutOnInit) {
      logout(false)
    }
    if (loadProvderOnLoad) {
      login()
    }
    return () => {
      setLaddaLoading(false);
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const onChangeImg = (event) => {
    setImage(event.target.files[0]);
  };

  // const getWeb3Auth = () => {
  //   return new Web3Auth({
  //     clientId: REACT_APP_WALLET_CLIENT_ID,
  //     chainConfig: {
  //       // this is ethereum chain config, change if other chain(Solana, Polygon)
  //       chainNamespace: CHAIN_NAMESPACES.EIP155,
  //       chainId: REACT_APP_CHAIN_ID,
  //       rpcTarget: REACT_APP_RPC_TARGET
  //     },
  //   });
  // }

  const login = async () => {
    setLaddaLoading(true);
    if (onClickGenerate) {
      if (!onClickGenerate()) {
        setLaddaLoading(false);
        //validate error
        return;
      }
    }

    const initLogin = async () => {
      if (wallet_address) {
        console.log({ provider, setCompleteWeb3Auth, onLoadProvider })
        if (provider) {
          console.log("provider EXIST")
          if (setCompleteWeb3Auth) {
            setCompleteWeb3Auth(true);
          }
          if (onLoadProvider) {
            console.log("onLoadProvider")
            onLoadProvider(provider)
          }
        } else {
          console.log("INIT web3auth")
          setIsWaitingProvider(true);
          const provider = await init();
          if (onLoadProvider) {
            console.log("onLoadProvider")
            onLoadProvider(provider)
          }
        }
      } else {
        // console.log("w3authbtn", { redirect_uri: window.location.pathname, ...formData })
        if (setFormData) {
          setFormData();
        }
        navigate('/login/idms', { state: { redirect_uri: window.location.pathname } })
      }
    }

    initLogin();
  };

  useEffect(() => {
    if (loginOnStart) {
      login();
    }
  }, [loginOnStart])

  useEffect(() => {
    if (isWaitingProvider && wallet_address && provider) {
      setIsWaitingProvider(false);
      if (setCompleteWeb3Auth) {
        setCompleteWeb3Auth(true);
      }
      if (onLoadProvider) {
        onLoadProvider(provider)
      }
    }

    return () => {
      // setIsWaitingProvider(false);
    }
  }, [isWaitingProvider, wallet_address, provider])

  return (
    !generateButtonFlg ? (
      loadProvderOnLoad ? <></> :
        <img
          onClick={login}
          className="meta-mask-option-button"
          src={meta_mask_button}
        />
    ) :
      (
        (
          provider ?
            <LaddaButton loading={Laddaloading} disabled={disabled} className=" btn btn-custom-active  w-100 mt-3" style={{ height: 50, cursor: disabled ? 'default' : 'pointer' }} onClick={login} data-color="#eee" data-size={L} data-style={EXPAND_RIGHT} data-spinner-size={30} data-spinner-color="#ddd" data-spinner-lines={11}>
              {strings.formatString(buttonlist.createArcanaBtn)}
            </LaddaButton>
            : "")

      )
  );
};
