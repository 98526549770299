import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AppWrapper from '../../components/wrappers/AppWrapper';
import { getToken, getWalletAddress } from '../../services/storage';
import RPC, { initWeb3 } from '../../components/common/web3RPC';
import { Web3authContext } from '../../context/web3authContext';
import { SwalWrong } from '../../components/common/Swal';
import { postData } from '../../services/fetch';
import { strings, translations } from "../../services/localization";
import { Btn } from '../../components/common/Button';

const ConnectWallet = () => {
    const navigate = useNavigate();
    const { setWallet_address, web3auth, provider, init, getAccounts, logout } = useContext(Web3authContext);
    const [web3Initialzed, setWeb3Initialized] = useState(false);

    const handleConnect = async () => {
        if (provider) {
            connect();
        } else {
            init();
            setWeb3Initialized(true);
        }
    }

    useEffect(() => {
        logout()

        if (getToken()) {
            if (getWalletAddress() && getWalletAddress() != null && getWalletAddress != undefined) {
                SwalWrong.fire({
                    title: 'エラーが発生しました',
                    text: 'Your wallet is already connected to your account. If you change wallet, go to wallet connect page and disconnect wallet first.'
                });
                navigate('/');
            }
        } else {
            navigate('/');
        }

        if (provider && web3Initialzed) {
            connect();
        }

        return () => {
            // logout();
        }
    }, [provider])

    const connect = async () => {
        const address = await getAccounts();
        console.log(address)
    }
    const content = translations.connectWallet
    const buttonlist = translations.buttonlist
    return (
        <AppWrapper>
            <div className='row'>
                <h3 className="auth-form-label" style={{ margin: '56px 0' }}>{strings.formatString(content.headerTitle)} </h3>
                <div className='col-12 text-center f-regular fw-500'>
                    <p className='text-break text-justify text-lg-center' style={{ marginBottom: 34 }}>
                        {strings.formatString(content.paragraph)}
                    </p>
                    {/*<div className='d-flex flex-column' style={{ marginBottom: 56 }}>*/}
                    {/*    <font>*/}
                    {/*        {strings.formatString(content.question)}*/}
                    {/*    </font>*/}
                    {/*    <font className="text-gradient-default">*/}
                    {/*        {strings.formatString(content.labelGet)}*/}
                    {/*    </font>*/}
                    {/*</div>*/}
                    <Btn intent="outline" className='btn-small' onClick={async () => {
                        handleConnect();
                    }}>{strings.formatString(buttonlist.connectBtn)}</Btn>
                </div>
            </div>
        </AppWrapper>
    )
}

export default ConnectWallet
