import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ScrollContainer from 'react-indiana-drag-scroll'
import { GameNewCardItems, TopicNewsItemViewCard } from "../../components/common/Item";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { getData } from "../../services/fetch";
import { SwalWrong } from "../../components/common/Swal";
import { translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";
import TopNewsPC from "../../components/common/TopNewsPC";
import TopNewsMobile from "../../components/common/TopNewsMobile";
import { HomeTitle } from "../../components/common/Title";
import CategoryFilter from "../../components/common/CategoryFilter";
import { SquareCard } from "../../components/common/Item";
import { appContext } from "../../context/appContext";

const Squares = () => {
    const [loading, setLoading] = useState(false);
    const { language } = useContext(appContext);
    const shortLang = language === 'English' ? 'en' : 'jp';
    const [squares, setSquares] = useState(null);
    const [page, setPage] = useState(1);
    const [categories, setCategories] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(0);
    const [topSquareNews, setTopSquareNews] = useState(null)

    const content = translations.squareList;

    const fetchData = async () => {
        setLoading(true);
        const response = await getData(
            `squares?page=${page}&language=${shortLang}`
        );
        if (response.ok) {
            const data = response.data;
            if (data) {
                data.data.length = 9;
                setSquares(data.data);
            }
            setLoading(false);
        } else {
            SwalWrong.fire({
                title: "Error",
                text: "Can't fetch data",
            });
            setLoading(false);
        }
    };

    const fetchTopSquareNewsData = async () => {
        const response = await getData(
            `top-square-news?&language=${shortLang}`
        );
        if (response.data) {
            if (response.data?.data) {
                const data = response.data.data
                const array = [...data.top_square_news, ...data.top_square_advertisements];
                const random = Math.floor(Math.random() * array.length);
                const randomItem = array[random];
                setTopSquareNews(randomItem);
            }
        }
    };

    useEffect(() => {
        fetchData();
        fetchTopSquareNewsData()
    }, []);

    const metaTitle = "スクエア｜ANICANA θυρα（アニカナテュラー）";
    const metaDescription =
        "ANICANA θυρα（アニカナテュラー）の最新ニュース一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

    const isPC = useMediaQuery({ minWidth: 992 })
    const isPh = useMediaQuery({ maxWidth: 480 })
    const topImage = (!isPh && topSquareNews?.image_url_top !== null && topSquareNews?.image_url_top ? topSquareNews?.image_url_top : topSquareNews?.image_url) || topSquareNews?.image;

    return (
        <AppWrapper
            squareActive="1"
            title={translations.formatString(content.headerTitle)}
            pageid="Square"
            sidebarWrapperDivClassName={"p-0"}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            noPadding={true} isNoSpaceAround
        >
            {loading ? (
                <OnLoading />
            ) : (
                <>
                    {isPC && topSquareNews !== null
                        ?
                        <TopNewsPC topNews={topSquareNews} topImage={topImage} className="w-100" />
                        :
                        <TopNewsMobile topNews={topSquareNews} topImage={topImage} />
                    }
                    <div className="new-games-card px-lg-3">
                        {/* <Filters 
                        categories={categories} 
                        onChange={setCurrentCategory} 
                    /> */}
                        <HomeTitle title={content.newSquare} className="f-expanded fw-500 mt-3 mb-3 px-3 px-lg-0" moreLink="/square-list" textClass={isPh?'fs-20':'fs-25'} hideLine={isPh}/>
                        {squares !== null ?
                            !isPh ?
                                <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll new-games-grid child-smoothable-transition">
                                    {squares && squares.map((it) => (
                                        (currentCategory === 0 || it.square_category === currentCategory) &&
                                        <div className="w-210px" key={it.id}>
                                            <SquareCard {...it} />
                                        </div>
                                    ))}
                                </ScrollContainer>
                                :
                                <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll new-games-grid child-smoothable-transition">
                                    {squares && squares.map((item, index) =>
                                        <GameNewCardItems index={index} item={item} isSquare={true} link="squares" />
                                    )}
                                </ScrollContainer>
                            : null
                        }
                    </div>
                </>
            )}
        </AppWrapper>
    );
};

export default Squares;
