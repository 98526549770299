import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { Detail } from './Wallet';
import { TokenType } from '../../components/common/enum';
import { WalletPageTitle } from '../../components/common/Title';
import { formatNumber, isJP } from '../../components/common/commonFnc';
import { SwalWrong } from '../../components/common/Swal';
import { useDidUpdateEffect } from '../../services/hook';
import { Web3authContext } from '../../context/web3authContext';
import { translations } from "../../services/localization";
import { Btn, LaddaButton } from '../../components/common/Button';
import { getData, postData } from "../../services/fetch";
import { getWalletAddress } from "../../services/storage";

const WalletSendConfirmation = (props) => {
    const { web3auth, provider, init, transferContract, isValidWalletAddress, logout, loginIfNotLogin, setWallet_address, setProvider } = useContext(Web3authContext);
    const navigate = useNavigate();
    const { token_type_id, token_name, token_id, to_address, anima_amount, token_amount } = useParams();
    const [startTransfer, setStartTransfer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id_token, setIdToken] = useState(null);
    const [contract_address, setcontract_address] = useState(null);
    const { isAnimaCharge } = props;

    const handleSubmit = async () => {
        setLoading(true);
        setStartTransfer(true);
    }

    useEffect(() => {
        console.log({ isAnimaCharge })
    }, [isAnimaCharge])

    useEffect(() => {
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        } else {
            setProvider(provider)
            console.log("Provider set")
        }
    }, [provider]);

    useEffect(() => {
        console.log('state', { isAnimaCharge, anima_amount, token_amount, test: formatNumber(anima_amount) })
    }, [isAnimaCharge, anima_amount, token_amount])

    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    useEffect(() => {
        async function fetchData() {
            if (Number(token_type_id) === Number(TokenType.Token) && token_id) {
                await getUserToken();
            }

            const address = getWalletAddress()
            if (!address) {
                navigate('/')
                return
            }
            setWallet_address(address)
        }
        fetchData();

        return () => setStartTransfer(false);
    }, [])

    useEffect(() => {
        console.log({ token_type_id, token_name, token_id, to_address, anima_amount, token_amount })
    }, [token_type_id, token_name, token_id, to_address, anima_amount, token_amount])

    const getUserToken = async () => {
        const datas = await getData(`user-tokens`);
        const item = (datas?.data?.response ?? []).find((i) => i.id === Number(token_id))
        setcontract_address(item.contract_address)
    }

    const transferToken = async () => {
        const isValid = await isValidWalletAddress();
        if (isValid) {
            if (token_type_id && to_address && (token_id || anima_amount || token_amount)) {
                transferContract(
                    token_type_id,
                    to_address,
                    Number(token_type_id) === Number(TokenType.Anima)
                        ? anima_amount
                        : Number(token_type_id) === Number(TokenType.Token)
                            ? token_amount
                            : token_id,
                    Number(token_type_id) === Number(TokenType.Token) ? contract_address : null,
                    false, undefined, (isAnimaCharge && Number(token_type_id) === Number(TokenType.Anima))
                );
            } else {
                setLoading(false);
                SwalWrong.fire({
                    title: 'エラーが発生しました',
                    text: 'Please choose the token to send.'
                }).then(() => {
                    if (Number(token_type_id) === Number(TokenType.Anima)) {
                        navigate(`/wallet/send`);
                    } else if (Number(token_type_id) === Number(TokenType.Token)) {
                        navigate(`/wallet/token/${token_id}`);
                    } else {
                        navigate(`/wallet/${TokenType.getRouteName(token_type_id)}/detail/${token_id}`);
                    }
                });
            }
        } else {
            setLoading(false);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
            });
            logout();
        }
    }
    const content = translations.walletsendconfirmation
    const tokenSendConfirmationPage = translations.tokenSendConfirmationPage
    const buttonlist = translations.buttonlist
    const walletSendAddress = translations.walletSendAddress;
    let title_header = walletSendAddress.headerTitleAnima;
    let labeltext = '';
    let main_title = '';
    if (Number(token_type_id) === Number(TokenType.Anima)) {
        main_title = translations.formatString(content.mainTitleAnima);
        labeltext = translations.formatString(content.labelAnimaAmount);
    }
    else if (Number(token_type_id) === Number(TokenType.Arcana)) {
        main_title = translations.formatString(content.mainTitleArcana);
        labeltext = translations.formatString(content.labelArcanaQuantity);
    }
    else if (Number(token_type_id) === Number(TokenType.Persona)) {
        main_title = translations.formatString(content.mainTitlePersona);
        labeltext = translations.formatString(content.labelArcanaQuantity);
    }
    else if (Number(token_type_id) === Number(TokenType.Token)) {
        main_title = translations.formatString(content.mainTitleToken);
        labeltext = translations.formatString(tokenSendConfirmationPage.Amount);
    }
    let cancel = translations.formatString(content.cancelBtn);
    let submit = translations.formatString(content.submitBtn);
    useDidUpdateEffect(() => {
        if (startTransfer && provider) {
            transferToken();
        }
    }, [provider, startTransfer])

    return (
        <AppWrapper title={title_header}>
            <div className='mw-container mx-auto'>
                <WalletPageTitle title={main_title} hasUnderline />
                <div className='text-center' style={{ marginTop: -24, marginBottom: 32 }}>
                    <span className='text-break fw-500 f-nototsan' style={{ fontSize: isJP() ? '18px' : '' }}>{translations.formatString(tokenSendConfirmationPage.Please_confirm_the_transaction_details_below)}</span>
                </div>

                {/* Token Name - Token Only */}
                {Number(token_type_id) === Number(TokenType.Token) && <Detail name={translations.formatString(tokenSendConfirmationPage.Token_Name)} value={`${(token_name)} || ''`} />}
                {/* Blockchain - All */}
                <Detail name={translations.formatString(content.labelBlockchain)} value={'ANICANA chain'} />
                {/* Symbol / Token Name - Arcana & Persona */}
                {
                    (Number(token_type_id) === Number(TokenType.Arcana) || Number(token_type_id) === Number(TokenType.Persona)) &&
                    <Detail name={translations.formatString(content.labelTokenNameSymbol)} value={`${TokenType.getEnumName(token_type_id)} - ${token_name || ''}`} />
                }
                {/* Amount - Anima & Token */}
                {Number(token_type_id) === Number(TokenType.Anima) && <Detail name={labeltext} isAnima={Number(token_type_id) === Number(TokenType.Anima)} value={formatNumber(anima_amount)} />}
                {Number(token_type_id) === Number(TokenType.Token) && <Detail name={labeltext} isToken={Number(token_type_id) === Number(TokenType.Token)} value={formatNumber(token_amount)} />}
                {/* To - All */}
                <Detail name={translations.formatString(content.labelTo)} value={to_address} isWalletAddress />
                <div className='flex-row flex-center mt-5'>
                    <Btn type="button"
                        intent="outline"
                        className='w-100 me-2'
                        onClick={() => navigate(-1)}
                    >
                        {translations.formatString(buttonlist.cancelBtn)}
                    </Btn>
                    {/*<button type="button" className='btn btn-custom-active ms-2 w-100' onClick={handleSubmit}>{translations.formatString(buttonlist.submitBtn)}</button>*/}
                    <Btn type="button"
                        intent="active"
                        loading={loading}
                        id="submit-btn"
                        className={`ms-2 w-100 ${!provider ? 'disabled' : ''}`}
                        onClick={handleSubmit} disabled={!provider}
                    >
                        {translations.formatString(buttonlist.submitBtn)}
                    </Btn>
                </div>
            </div>
        </AppWrapper>
    )
}

export default WalletSendConfirmation
