import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import ReactStars from 'react-rating-stars-component'

import { EditIcon, GradientStarIcon, StarIcon } from '../../components/common/Icon'
import Swal from '../../components/common/Swal'
import { API_DOMAIN } from '../../config'
import { appContext } from '../../context/appContext'
import { postData } from '../../services/fetch'
import { translations } from '../../services/localization'


const StarIconComponent = ({
  star, className, gradient
}) => [...Array(star)].map((s, i) => gradient ? <GradientStarIcon key={`gradient_star_${i}`} gradient={1} className={className} /> : <StarIcon key={`star_${i}`} className={className} />)

const RatingReview = ({data, user, id, fetchRatingReviewData}) => {

  const checkUser = data.data !== undefined && data.data.filter(d => d.user_id === user.id && d.game_id === parseInt(id))
  const loginedUser = user.id !== undefined ? user.id : false;
  const content = translations.review;

  const [rating, setRating] = useState(checkUser.length > 0 ? checkUser[0].rating : 0);
  const [ratings, setRatings] = useState(data.percentage !== undefined ? data.percentage : [0, 0, 0, 0, 0])
  
  const { language, timeZone } = useContext(appContext)
  const shortForm = language === 'English' ? 'en' : 'jp'

  const changeRating = ( newRating ) => {
    setRating(newRating)
    writeReview(newRating)
  }

  const writeReview = async (newRating) => {
    const url = API_DOMAIN + `rating-and-review/store?language=${shortForm}&timezone=${timeZone}`;
    const data = {
      game_id: id, 
      rating: newRating, 
      user_id: user.id, 
      title: null, 
      review: null
    };
    const response = await postData(url, data);
    if (response.data) { 
      Swal.fire({
        showConfirmButton: false,
        customClass: 'feedback-wrapper modal-box-bordered',
        iconHtml: '<div class="feedback-star-icon"></div>',
        html: (<div className='text-center fs-18'>{content.feedbackTitle}</div>)
      });
      fetchRatingReviewData()
    }
  }

  return (
    <div className='px-4 py-3 mw-400 mt-3'>

      {/* Title */}
      <div className='d-flex justify-content-between align-items-center'>
        <span className='primary-color fs-25 f-expended fw-500'>
          Ratings & Reviews
        </span>
        {/* <span className='fw-500 f-condensed'
          style={{
            color: '#bf96ff'
          }}
        >
          All
        </span> */}
      </div>

      {/* Star & Percentage */}
      <div className='d-flex justify-content-between align-items-center rating mt-2'>
        <div className='text-center'>
          <div className='f-expended rating-number mb-1'>
            {data.total_rating !== undefined ? data.total_rating.toFixed(1) : 0}
          </div>
          <div className='total-rating-text f-condensed'>out of 5</div>
        </div>
        <div className='mt-4'>
          { ratings &&
            ratings.map((rating, index) => 
              <div className='d-flex justify-content-center align-items-center progress-star-div'
                key={index}
              >
                <div className='star d-flex align-items-center'>
                  <StarIconComponent star={5 - index} />
                </div>
                <div className="progress progress-div">
                  <div 
                    className="progress-bar" 
                    role="progressbar" 
                    style={{
                      width: rating + '%', 
                      background: '#fff'
                    }} 
                    aria-valuenow="25" 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            )
          }
          <div className='f-condensed fs-15 fw-500 text-right'
            style={{textAlign: 'right'}}
          >
            {data.total_rating_user !== undefined ? data.total_rating_user : 0} Ratings
          </div>
        </div>
      </div>
      {/* end Star & Percentage */}

      {/* review */}
      <div className='review mt-4'>
        {/* write a review */}
        <div className='d-flex justify-content-between align-items-center mb-2'>
          {/* <div className='d-flex align-items-center' onClick={writeReview}>
            <EditIcon />
            <span className='fs-15 f-condensed fw-500 px-2 pointer'>
              Write a review
            </span>
          </div> */}
          <div></div>
          <div>
            {
              loginedUser ? 
              <ReactStars
                count={5}
                onChange={changeRating}
                size={25}
                value={rating}
                // isHalf={true}
                emptyIcon={<StarIcon className="empty-star" />}
                // halfIcon={<StarIcon className="empty-star" />}
                filledIcon={<GradientStarIcon gradient={1} className="gradient-star" />}
                color="#fff"
              />
              :
              <>
              <StarIconComponent 
                star={rating} gradient={1} 
                className='gradient-star' 
              />
              <StarIconComponent 
                star={5-rating} className='empty-star' 
              />
              </>
            }
          </div>
        </div>

        {/* Review boxes */}
        {/* { data.data !== undefined && data.data.length > 0 && data.data.map((review, reviewIndex) => 
          <div className='my-3 review-box' key={`game-detail-review-${reviewIndex}`}>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <div>
                <div className='fs-20 f-regular'>
                  {review.title}
                </div>
                <div style={{lineHeight: 1}}>
                  <StarIconComponent 
                    star={review.rating} gradient={1} 
                    className='review-star' 
                  />
                  <StarIconComponent 
                    star={5-review.rating} className='review-star' 
                  />
                </div>
              </div>
              <div className='d-flex flex-column'>
                <span className='f-condensed' style={{fontSize: 13, textAlign: 'right'}}>
                  {moment(review.updated_at).format("YYYY.MM.DD")}
                </span>
                <span className='f-condensed' style={{fontSize: 13, textAlign: 'right'}}>
                  {review.user !== null && review.user.account_name}
                </span>
              </div>
            </div>
            <span className='fs-15 f-condensed fw-500'>
              {review.review}
            </span>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default RatingReview