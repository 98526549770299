/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { QRIcon } from '../../components/common/Icon'
import { TextBox } from '../../components/common/Form'
import { TokenType } from '../../components/common/enum'
import { WalletPageTitle } from '../../components/common/Title'
import { AuthContext } from '../../context/authContext'
import { strings, translations } from "../../services/localization";
import RPC, { initWeb3 } from "../../components/common/web3RPC";
import { Web3authContext } from '../../context/web3authContext';
import { BottomModal } from "../../components/common/BottomModal";
import { useMediaQuery } from 'react-responsive'
import { SwalSettings, SwalWrong } from "../../components/common/Swal";
import { getData, postData } from "../../services/fetch";
import { getWalletAddress } from '../../services/storage'
import { OnLoading } from "../../components/common/OnLoading";
import QRReader from './QRReader'
import { isMobile } from 'react-device-detect'
import { formatNumber, swalText } from '../../components/common/commonFnc'
import { Btn } from '../../components/common/Button'

const WalletSendAddress = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { wallet_address, setWallet_address, provider, init, loginIfNotLogin } = useContext(Web3authContext);
    const { updateUserInfo } = useContext(AuthContext);
    const { token, token_id, qr_address, user_token_id, animaChargeData } = location.state || {};
    const [to_address, setTo_address] = useState('')
    const [anima_amount, setAnima_amount] = useState('')
    const [token_amount, setToken_amount] = useState('')
    const [anima_balance, setAnima_balance] = useState('')
    const [token_balance, setToken_balance] = useState('')
    const [openQRReader, setOpenQRReader] = React.useState(false)
    const [supported, setSupported] = React.useState(true)
    const [result, setResult] = React.useState(null)
    const [validTo_Address, setValidTo_Address] = useState(false)
    const [showBottomModal, setShowBottomModal] = useState(false);
    const content = translations.walletSendAddress;
    const buttonlist = translations.buttonlist;
    const sendAnimaPage = translations.sendAnimaPage;
    const tokenSendPage = translations.tokenSendPage;
    const language = translations._language;
    const [id_token, setIdToken] = useState(null);
    const [token_item, setTokenItem] = useState(null);
    const [loading, setLoading] = useState(true)

    let title_header = content.headerTitleAnima;
    let wallet_page_title = content.mainTitleArcana;
    let text_box_label = tokenSendPage.Amount;
    if (Number(token?.token_type_id) === Number(TokenType.Anima)) {
        title_header = content.headerTitleAnima;
        wallet_page_title = content.mainTitleAnima;
        text_box_label = content.textBoxLabelAnima;
    }
    else if (Number(token?.token_type_id) === Number(TokenType.Arcana)) {
        title_header = content.headerTitleAnima;
        wallet_page_title = content.mainTitleArcana;
        text_box_label = content.textBoxLabelArcana;
    }
    else if (Number(token?.token_type_id) === Number(TokenType.Persona)) {
        title_header = content.headerTitlePersona;
        wallet_page_title = content.mainTitlePersona;
        text_box_label = content.textBoxLabelPersona;
    }
    else if (Number(token?.token_type_id) === Number(TokenType.Token)) {
        title_header = content.headerTitleAnima;
        wallet_page_title = `${buttonlist.sendBtn} ${token_item?.token_name}`;
        text_box_label = tokenSendPage.Amount;
    }

    useEffect(() => {
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        }
    }, [provider, wallet_address, token]);

    useEffect(() => {
        if (animaChargeData) {
            if (animaChargeData["address"]) {
                setTo_address(animaChargeData["address"])
            }
            if (animaChargeData["amount"]) {
                setAnima_amount(animaChargeData["amount"])
            }
        }
    }, [animaChargeData])

    useEffect(() => {
        getBalance(getWalletAddress());
    }, [token_item])


    // useEffect(() => {
    //     if (id_token) {
    //         loginIfNotLogin(id_token)
    //     }
    // }, [id_token])

    useEffect(() => {
        if (!location?.state) {
            navigate('/wallet')
        }

        async function fetchData() {
            if (Number(token?.token_type_id) === Number(TokenType.Token) && user_token_id) {
                await getUserToken();
            }

            const address = getWalletAddress()
            if (!address) {
                navigate('/')
                return
            }
            setWallet_address(address)
            setLoading(false)
        }
        fetchData();

        if (Number(token?.token_type_id) === Number(TokenType.Anima) && getWalletAddress()) {
            getAnimaBalance(getWalletAddress());
        } else if (Number(token?.token_type_id) === Number(TokenType.Token) && getWalletAddress()) {
            getBalance(getWalletAddress());
        }
    }, [])

    const handleScan = (data) => {
        if (data) {
            setResult(data)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            result ||
            (to_address &&
                (
                    (token?.token_type_id !== Number(TokenType.Anima) || anima_amount) ||
                    (token?.token_type_id !== Number(TokenType.Token) || token_amount)
                )
            )
        ) {
            if (token?.token_type_id === Number(TokenType.Anima)) {
                if (anima_amount > 0) {
                    if (anima_amount <= anima_balance) {
                        //wallet/send/confirmation/:token_type_id/:token_id/:token_name/:to_address/:anima_amount/:token_amount
                        console.log({ isAnimaCharge: !!animaChargeData }, animaChargeData)
                        navigate(`/wallet/${!!animaChargeData ? 'charge' : 'send'}/confirmation/${token.token_type_id}/${token_item ? token_item.id : token_id}/${token_item ? token_item.token_name || 'null' : token ? token.name || 'null' : 'null'}/${to_address}/${anima_amount || 0}/${token_amount || 0}`)
                    } else {
                        SwalWrong.fire({
                            title: "エラーが発生しました",
                            text: "残高が足りません"
                        })
                    }
                } else {
                    SwalWrong.fire({
                        title: "エラーが発生しました",
                        text: "不正な数量です"
                    })
                }
            } else {
                navigate(`/wallet/${!!animaChargeData ? 'charge' : 'send'}/confirmation/${token.token_type_id}/${token_item ? token_item.id : token_id}/${token_item ? token_item.token_name || 'null' : token ? token.name || 'null' : 'null'}/${to_address}/${anima_amount || 0}/${token_amount || 0}`)
            }
        }
        setOpenQRReader(false)
    }

    useEffect(() => {
        if (result) {
            setOpenQRReader(false);
            setTo_address(result);
        }
    }, [result])


    useEffect(() => {
        setValidTo_Address(/0[xX][0-9a-fA-F]+/.test(to_address));
    }, [to_address])

    useEffect(() => {
        updateUserInfo();
    }, [])

    useEffect(() => {
        if (qr_address) {
            setTo_address(qr_address);
        }
    }, [qr_address])

    const getAnimaBalance = async (address) => {
        const rpc = new RPC();
        const balance = await rpc.getAnimaBalance(address);
        setAnima_balance(balance);
    };

    const getBalance = async (address) => {
        const rpc = new RPC();
        if (token_item?.contract_address) {
            const balance = await rpc.getCustomTokenBalance(token_item.contract_address, address);
            setToken_balance(balance);
        }
    };

    const getUserToken = async () => {
        const rpc = new RPC();
        const datas = await getData(`user-tokens`);
        const item = (datas?.data?.response ?? []).find((i) => i.id === Number(user_token_id))
        item.token_name = await rpc.getName(item.contract_address);
        setTokenItem(item);
    }

    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    const isPC = useMediaQuery({ minWidth: 992 })
    const showModalHandler = () => {
        if (to_address || anima_amount || token_amount) {
            isPC ? onCancelEvent() : setShowBottomModal(true)
        } else {
            navigate('/wallet');
        }
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: strings.formatString(tokenSendPage.Are_you_sure_you_want_to_cancel_sending),
            confirmButtonText: swalText(buttonlist.yesBtn),
            cancelButtonText: swalText(buttonlist.noBtn),
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-2);
            }
        });
    }

    if (openQRReader) return <QRReader onScan={handleScan} />

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onShow={showBottomModal}
                    label={translations.formatString(tokenSendPage.Are_you_sure_you_want_to_cancel_sending)}
                    cancelBtnLabel={translations.formatString(buttonlist.yesBtn)}
                    BackBtnLabel={translations.formatString(buttonlist.noBtn)}
                    navigation='/wallet'
                />
            }
            <AppWrapper title={title_header} isForm isCenter hasInput>
                {loading ? <OnLoading /> : <>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='text-center'
                                style={{
                                    maxWidth: '450px'
                                }}
                            >
                                <WalletPageTitle title={wallet_page_title} hasUnderline />
                            </div>
                            <div>
                                <form onSubmit={handleSubmit} className="form-wrapper">
                                    <TextBox
                                        className="auth-form-input-absolute wallet-input-fs" id="placeholder-center"
                                        label={translations.formatString(content.labelAddress)}
                                        placeholder={translations.formatString(tokenSendPage.long_press_to_paste)}
                                        onChange={e => setTo_address(e.target.value)}
                                        value={to_address} required
                                        font_notosan={language === 'jp' ? 'f-notosan' : ''}
                                        inputStyle={{ height: 55 }}
                                        disabled={!!animaChargeData}
                                    />
                                    {
                                        isMobile &&
                                        <>
                                            <div className='text-center' style={{ marginTop: 13, marginBottom: 13 }}>
                                                <span className='f-expended fw-500'>or</span>
                                            </div>
                                            <a
                                                style={{
                                                    fontSize: 20,
                                                }}
                                                className={`btn btn-custom-active w-100 ${language === 'jp' ? 'f-notosan' : ''}`}
                                                onClick={() => {
                                                    setOpenQRReader(true)
                                                }}
                                            >
                                                <span className='fs-25'>
                                                    {content.labelQR}
                                                    <QRIcon />
                                                </span>
                                            </a>
                                        </>
                                    }
                                    {
                                        Number(token?.token_type_id) === Number(TokenType.Anima) &&
                                        <>
                                            <TextBox
                                                className="auth-form-input-absolute wallet-input-fs" id="placeholder-center"
                                                label={text_box_label}
                                                placeholder={translations.formatString(tokenSendPage.enter_amount)}
                                                containerStyle={{ marginTop: 60 }}
                                                inputStyle={{ marginBottom: 5, height: 55 }}
                                                style={{ width: 247 }}
                                                onChange={e => setAnima_amount(e.target.value)} value={anima_amount} required
                                                font_notosan={language === 'jp' ? 'f-notosan' : ''}
                                            />
                                            <div className='w-100 text-end'>
                                                <span className='f-condensed me-2' style={{ marginBottom: 77, fontSize: 20 }}>{strings.formatString(sendAnimaPage.Available)} <font className='ms-2'>{formatNumber(anima_balance)}</font><small className='ms-1'>ANM</small></span>
                                            </div>
                                        </>
                                    }
                                    {
                                        Number(token?.token_type_id) === Number(TokenType.Token) &&
                                        <>
                                            <TextBox
                                                className="auth-form-input-absolute wallet-input-fs" label={text_box_label}
                                                placeholder={translations.formatString(tokenSendPage.enter_amount)}
                                                containerStyle={{ marginTop: 60 }}
                                                inputStyle={{ marginBottom: 5, height: 55 }}
                                                style={{ width: 247 }}
                                                onChange={e => setToken_amount(e.target.value)}
                                                value={token_amount} required
                                                font_notosan={language === 'jp' ? 'f-notosan' : ''}
                                            />
                                            <div className='w-100 text-end'>
                                                <span className={`${language === 'jp' ? 'f-notosan' : 'f-condensed'} jp-fw-400 me-2`} style={{ marginBottom: 77, fontSize: 20 }}>{strings.formatString(sendAnimaPage.Available)} <font className='ms-2'>{token_balance}</font></span>
                                            </div>
                                        </>
                                    }
                                    <div className='flex-row flex-center mt-5'>
                                        <Btn type="button"
                                            intent="outline"
                                            className={`w-100 me-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                                            onClick={showModalHandler}
                                        >
                                            {translations.formatString(buttonlist.cancelBtn)}
                                        </Btn>
                                        <Btn type="submit"
                                            disabled={!validTo_Address || ((Number(token?.token_type_id) === Number(TokenType.Anima) && anima_balance == "") || (Number(token?.token_type_id) === Number(TokenType.Token) && token_balance == ""))}
                                            className={`w-100 ms-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                                        >
                                            {translations.formatString(buttonlist.nextBtn)}
                                        </Btn>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>}
            </AppWrapper>
        </>
    )
}

export default WalletSendAddress
