import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AppWrapper from '../../components/wrappers/AppWrapper';
import { BottomModal } from "../../components/common/BottomModal";
import { postData } from "../../services/fetch";
import { SwalCorrect, SwalWrong, SwalSettings } from "../../components/common/Swal";
import { strings, translations } from "../../services/localization";
import { OnLoading } from '../../components/common/OnLoading'
import { useMediaQuery } from 'react-responsive'
import { useForceUpdate } from "../../services/hook";
import { appContext } from "../../context/appContext";
import { Btn } from '../../components/common/Button';

const WalletTokenDetail = (props) => {
    const navigate = useNavigate();
    const [tokenName, setTokenName] = useState("");
    const [contractAddress, setContractAddress] = useState("");
    const [unit, setUnit] = useState("");
    const [showBottomModal, setShowBottomModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const tokenAddPage = translations.tokenAddPage;
    const [isDisable, setIsDisable] = useState(false)
    const { language } = useContext(appContext)
    const forceUpdate = useForceUpdate();

    const textChangeHandler = (type, value) => {
        if (type === "tokenName") {
            setTokenName(value);
        } else if (type === "contractAddress") {
            setContractAddress(value);
        } else if (type === "unit") {
            setUnit(value);
        }
    };

    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    const createCustomToken = async () => {
        setLoading(true);
        const data = {
            'contract_address': contractAddress,
        }
        const response = await postData(`user-tokens`, data)
        if (response.ok) {
            if (response.data) {
                const text = `${strings.formatString(tokenAddPage.〇〇_Token_has_been_successfully_added_to_your_wallet)}`
                SwalCorrect.fire({
                    text
                }).then(() => {
                    navigate("/wallet/token");
                });
            }
        } else {
            setLoading(false);
            SwalWrong.fire({
                title: "Error",
                text: response.data.message,
            });
        }
    }

    const isJP = translations._language === 'jp'

    useEffect(() => {
        setIsDisable(/0[xX][0-9a-fA-F]+/.test(contractAddress));
    }, [contractAddress])

    useEffect(() => {
        forceUpdate();
    }, [language])

    const isPC = useMediaQuery({ minWidth: 992 })
    const showModalHandler = () => {
        isPC ? onCancelEvent() : setShowBottomModal(true)
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: strings.formatString(tokenAddPage.Are_you_sure_you_want_to_cancel),
            confirmButtonText: 'yes',
            cancelButtonText: 'no',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/wallet/token')
            }
        });
    }

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onShow={showBottomModal}
                    label={strings.formatString(tokenAddPage.Are_you_sure_you_want_to_cancel)}
                    cancelBtnLabel="yes"
                    BackBtnLabel="no"
                    navigation="/wallet/token"
                />
            }
            <AppWrapper title={strings.formatString(tokenAddPage.My_Wallet)} isForm isCenter hasInput>
                <div className="w-100 auth-form-container">
                    <h3 className={`auth-form-label mx-4 ${isJP ? 'fs-25' : ''}`}>
                        {strings.formatString(tokenAddPage.Add_Token)}
                    </h3>
                    <div className="divider mb-4 w-100 mx-auto"></div>
                    {
                        loading ? <OnLoading /> :
                            <div>
                                <div className="mb-5">
                                    <div className="mb-4">
                                        <label className="py-1 pb-2" style={{ fontSize: "18px" }}>
                                            {strings.formatString(tokenAddPage.Token_Address)}
                                        </label>
                                        <input
                                            className="auth-form-input-absolute p-2 ps-3 w-100"
                                            onChange={(e) =>
                                                textChangeHandler("contractAddress", e.target.value)
                                            }
                                            value={contractAddress}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Btn intent="outline" onClick={showModalHandler} type="button" className="w-100 me-2" style={{ padding: 0, fontSize: `${isJP ? '20px' : '24px'}` }}>
                                        {strings.formatString(tokenAddPage.cancel)}
                                    </Btn>
                                    {
                                        !isDisable
                                            ? <Btn disabled type="button" className="w-100 ms-2" style={{ padding: 0, fontSize: `${isJP ? '20px' : '24px'}` }}>
                                                <span>
                                                    {strings.formatString(tokenAddPage.add)}
                                                </span>
                                            </Btn>
                                            : <Btn onClick={createCustomToken} type="button" className="w-100 ms-2" style={{ padding: 0, fontSize: `${isJP ? '20px' : '24px'}` }}>
                                                <span>
                                                    {strings.formatString(tokenAddPage.add)}
                                                </span>
                                            </Btn>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </AppWrapper>
        </>
    );
}

export default WalletTokenDetail
