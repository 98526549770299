import React, { useState, useEffect, useRef, useCallback, useId, useContext } from "react";
import { getData, postData } from "./fetch";
import { Web3authContext } from "../context/web3authContext";
import { getWalletAddress, setRedirect } from "./storage";
import { useLocation } from "react-router-dom";
import { isJP } from "../components/common/commonFnc";
import { appContext } from "../context/appContext";

export const useDidUpdateEffect = (fn, inputs) => {
    const fncRef = useRef();
    fncRef.current = fn;
    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            return fncRef.current();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
}

export const useOutsideAlerter = (ref, fnc) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fnc();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const useScrollEndEffect = (fn) => {
    const fncRef = useRef();
    fncRef.current = fn;
    // const inputsRef = useRef();
    // inputsRef.current = inputs;

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('app-wrapper');
        if (isBottom(wrappedElement)) {
            console.log('header bottom reached');
            fncRef.current();
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        return () => {
            document.removeEventListener('scroll', trackScrolling);
        }
    }, [])
}

export const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}

export const useScrollToTop = (inputs, customNodeId = undefined) => {
    useEffect(() => {
        if (customNodeId) {
            if (document.getElementById(customNodeId)) {
                document.getElementById(customNodeId).scrollTo(0, 0);
            }
        } else {
            if (document.getElementById('wrapper')) {
                document.getElementById('wrapper').scrollTo(0, 0);
            } else if (document.getElementById('mainbody')) {
                document.getElementById('mainbody').scrollTo(0, 0);
            }
        }
    }, [inputs])
}

export const useFetchTopNews = (language, timeZone, inputs = []) => {
    const [topNews, setTopNews] = useState(undefined);

    const fetchTopNews = async (language, timeZone) => {
        const shortForm = language === 'English' ? 'en' : 'jp'
        const url = "top-news-mobile?language=" + shortForm + "&timezone=" + timeZone;
        const response = await getData(url);
        if (response.ok) {
            if (response.data) {
                console.log(url, response.data);
                if (response.data?.data) {
                    const newheadingData = response.data.data
                    let showString = newheadingData.body
                    newheadingData.body = showString
                    setTopNews(newheadingData);
                    return;
                }
            }
        }
        setTopNews(undefined);
    }

    useEffect(() => {
        if (language && timeZone) {
            fetchTopNews(language, timeZone);
        }
    }, [language, timeZone, ...inputs])

    return topNews;
};

export function useInteraction() {
    const events = ['mousedown', 'touchstart'];
    const [ready, setReady] = useState(false);

    const listener = () => {
        if (ready === false) {
            setReady(true);
        }
    };

    useEffect(() => {
        events.forEach((event) => {
            document.addEventListener(event, listener);
        });

        return () => {
            events.forEach((event) => {
                document.removeEventListener(event, listener);
            });
        };
    }, []);

    return ready;
}

export const useWeb3AuthInit = (start = false, fnc = () => { }, loadProvider = false) => {
    const { wallet_address, provider, setProvider, loginIfNotLogin } = useContext(Web3authContext);
    const [id_token, setIdToken] = useState(null);

    useEffect(() => {
        if (loadProvider) {
            if (!provider) {
                postData(`user/get-web3auth-token`).then(response => {
                    const _id_token = response.data.web3auth_token;
                    setIdToken(_id_token);
                })
            } else {
                setProvider(provider);
                console.log("Provider set");
            }
        }
    }, [provider, loadProvider]);

    useEffect(() => {
        if (id_token) {
            setRedirect(window.location.pathname);
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    useEffect(() => {
        console.log({ start, provider, address: wallet_address })
        if (start && wallet_address && provider) {
            console.log("start fnc")
            fnc(provider);
        }
    }, [wallet_address, provider, start])
}

export const useLoading = (dependencies, defaultValue = true) => {
    const [isLoading, setLoading] = useState(defaultValue);

    useEffect(() => {
        const checkAllTrue = () => {
            const areAllFalse = dependencies.every((dependency) => dependency === false);
            setLoading(!areAllFalse);
        };

        checkAllTrue();
    }, [dependencies]);

    return isLoading;
};

export const useFetch = (url, useLang = false) => {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { language } = useContext(appContext);

    const fetchData = async (url, useLang, language) => {
        try {
            if (useLang && language) {
                url = `${url}${url.includes('?') ? '&' : '?'}language=${language === "English" ? "en" : "jp"}`;
            }
            const response = await getData(url);
            if (response.ok) {
                setData(response.data.data);
            }
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if ((useLang && language) || !useLang)
            fetchData(url, useLang, language);
    }, [url, useLang, language]);

    return { data, error, isLoading };
};