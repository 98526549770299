import React, { useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll';
import { translations } from '../../services/localization';

const CategoryFilter = ({ categories, onChange }) => {
    const [filterCategories, setFilterCategories] = useState(categories);
    const [selectedCategory, setSelectedCategory] = useState(undefined);

    const content = translations.newsList;

    useEffect(() => {
        if (filterCategories) {
            let _cats = JSON.parse(JSON.stringify(filterCategories));
            _cats.map(x => x.selected = selectedCategory ? x.id === selectedCategory.id : false);
            setFilterCategories(_cats);
        }
    }, [selectedCategory])

    const handleChange = (category) => {
        if (onChange) {
            onChange(category)
        }
        setSelectedCategory(category);
    }

    return (
        <div className='sticky-top mb-3 subNav w-100'>
            <div className='flex-center'>
                <div className={`flex-center category-filter ${!selectedCategory ? 'active' : ''}`} style={{ marginRight: 10 }} onClick={() => handleChange(undefined)}>
                    <span>{content.all}</span>
                </div>
                <div className='divider vertical-divider m-0'></div>
                <ScrollContainer
                    className="scroll-container overflow-scroll-x overflow-hidden-y scroll-behavior-auto custom-hide-scroll d-flex multi-carousel-container"
                >
                    {
                        filterCategories && filterCategories.map((item, index) => item.name !== null &&
                            <div key={index} className={`${item.hasDivider ? 'm-r-10' : 'm-r-15'}`}>
                                <div className={`flex-center category-filter ${item.selected ? 'active' : ''}`} onClick={() => handleChange(item)}><span>{item.name}</span></div>
                            </div>
                        )
                    }
                </ScrollContainer>
            </div>
        </div>
    );
};

export default CategoryFilter
