import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {
    ArcanaIcon,
    SignInIcon,
    SignOutIcon,
    TopicsIcon,
    TrendIcon,
    SideProductIcon,
    MenuCodeDIcon,
    MenuSquareIcon,
    PersonaIcon,
    WorldNewsIcon,
    HomeIcon,
    FAQIcon
} from "../../components/common/Icon";
import { AccountSettingsIcon } from "../../components/common/Icon";
import { SwalCorrect } from "../../components/common/Swal";

import "../../style/Account.scss";
import { addRecentSearch, getToken } from "../../services/storage";
import { useState } from "react";
import Labs from "./Labs";
import { AuthContext } from "../../context/authContext";
import { strings, translations } from "../../services/localization";
import { Footer } from "../../components/common/Footer";
import { MenuSearchBox } from "../../components/common/Form";
import { getData } from "../../services/fetch";
import { OnLoading } from "../../components/common/OnLoading";
import { isChrome } from "react-device-detect";
import { RecentSearchList, SearchResult } from "../search/SearchResult";
import { TokenType } from "../../components/common/enum";

function Menu() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { logout } = useContext(AuthContext);
    const auth = useSelector((state) => state.auth);
    const [currentPage, setCurrentPage] = useState('menu')
    const [onShow, setOnShow] = useState(false)
    const [searchOpen, setSearchOpen] = useState(false)
    const menuPage = translations.menuPage; // Usage: strings.formatString(menuPage.Games)
    const searchInputRef = useRef();
    const [searchResult, setSearchResult] = useState(undefined);
    const [showRecentSearch, setShowRecentSearch] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const content = translations.buttonlist

    const onGetToken = async () => {
        var token = await getToken()
        if (token) {
            setOnShow(true)
        }
    }

    useEffect(() => {
        onGetToken()

        return () => handleOnCloseSearch()
    }, [])

    const onSignOutHandler = () => {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let minutes = date.getMinutes();
        let hours = date.getHours()
        minutes = minutes++ < 10 ? '0' + minutes : minutes
        hours = hours++ < 10 ? '0' + hours : hours

        let currentDate = `${day}-${month}-${year}`;
        SwalCorrect.fire({
            title: `You have been successfully signed out`,
            text: `${currentDate} ${hours}:${minutes}`,
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
            }
        });
    }

    const handleOnFocusSearch = () => {
        setSearchOpen(true);
        setShowRecentSearch(true);
    }
    const handleOnCloseSearch = () => {
        setSearchOpen(false);
        setSearchResult(undefined);
        handleSearchClear();
        setSearchLoading(false);
        handleSearchClear(false);
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchInputRef.current) {
            addRecentSearch(searchInputRef.current.value);
        }
        handleSearch(searchInputRef.current?.value || "");
    }
    const handleSearchClear = (showRecentSearch = true) => {
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
        }
        setShowRecentSearch(showRecentSearch);
    }
    const handleSearch = async value => {
        setSearchLoading(true);
        if (searchInputRef.current && searchInputRef.current.value !== value) {
            searchInputRef.current.value = value;
        }
        const response = await getData(`search-data?name=${value}`);
        if (response.ok) {
            setSearchResult(response.data.data);
            setShowRecentSearch(false);
        }
        if (value) {
            addRecentSearch(value);
        }
        setSearchLoading(false);
    }

    let body = (
        <AppWrapper className="auth-container"
            // title={content.search}
            title="search"
            accountActive disableSideContentScroll sidebarWrapperDivClassName={'p-t-0'}
        >
            <form onSubmit={handleSearchSubmit}>
                <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} autoFocus={searchOpen} onFocus={handleOnFocusSearch} onClose={handleOnCloseSearch} onClear={handleSearchClear} />
            </form>
            <div className="overflow-scroll mb-3 menu-container" style={{ paddingBottom: isChrome ? 180 : 185 }}>
                {
                    searchOpen ? (
                        <div className="">
                            {
                                searchLoading ?
                                    <OnLoading /> :
                                    showRecentSearch ?
                                        <RecentSearchList className="mt-1 m-x-15" onClickItem={handleSearch} /> :
                                        <SearchResult className="m-t-35" result={searchResult} />
                            }
                        </div>
                    ) : <>
                        <div className="m-x-15">
                            <div className="pt-4">
                                <span className="anikana-label text-capitalize f-monodb fs-30 fw-400 text-gradient m-l-5">Menu</span>
                            </div>
                            <div className="mt-1 mb-3"></div>
                            <Link className="d-flex align-items-center" to="/">
                                <HomeIcon className='wh-25px mx-2' />
                                <span className=" mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Home)}</span>
                            </Link>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to="/topics">
                                <TopicsIcon className='wh-25px mx-2' />
                                <span className=" mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Topics)}</span>
                            </Link>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to="/trend">
                                <TrendIcon className='wh-25px mx-2' />
                                <span className=" mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Trend)}</span>
                            </Link>
                            <div className="divider mb-4 mt-4 w-100 mx-auto"></div>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to={`/tokens/${TokenType.Arcana}`}>
                                <ArcanaIcon active={0} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Arcana)}</span>
                            </Link>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to={`/tokens/${TokenType.Persona}/collection`}>
                                <PersonaIcon className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Persona)}</span>
                            </Link>
                            {/* <div className="mt-4"></div> */}
                            {/* <Link className="d-flex align-items-center" to="/products">
                                <MenuGamesIcon active={1} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>
                                    {strings.formatString(menuPage.Games)}
                                </span>
                            </Link>
                            <div className="divider mb-4 mt-4 w-100 mx-auto"></div> */}

                            {/* <Link>
                                <MenuPersonaIcon active={1} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>
                                    {strings.formatString(menuPage.Persona)}
                                </span>
                            </Link> */}
                            <div className="mt-4"></div>
                            <Link to="/squares" className="d-flex align-items-center">
                                <MenuSquareIcon active={0} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>
                                    {strings.formatString(menuPage.Square)}
                                </span>
                            </Link>
                            <div className="divider mb-4 mt-4 w-100 mx-auto"></div>
                            <Link className="d-flex align-items-center" to="/products">
                                <SideProductIcon active={0} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>
                                    {strings.formatString(menuPage.Games)}
                                </span>
                            </Link>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to="/news">
                                <WorldNewsIcon active={1} className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>
                                    {strings.formatString(menuPage.News)}
                                </span>
                            </Link>
                            <div className="mt-4"></div>
                            <Link className="d-flex align-items-center" to="/code-d">
                                <MenuCodeDIcon className='wh-25px mx-2' />
                                <span className="mx-1"
                                    style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}
                                >
                                    {strings.formatString(menuPage.CodeD)}
                                </span>
                            </Link>
                            {/* <div className="d-flex align-items-center" onClick={() => navigate('/labs')}>
                <MenuLabsIcon className='wh-25px mx-2' />
                <span className="kardust-font mx-1">Labs</span>
            </div> */}

                            {/* <div className="mt-4"></div>
            <Link className="d-flex align-items-center" to="/contact-us">
                <MenuSupportIcon className='wh-25px mx-2' />
                <span className="mx-1" style={{ fontSize: '20px',fontFamily :'KardustExpandedRegular' }}>{ strings.formatString(menuPage.Support) }</span>
            </Link> */}
                            <div className="m-t-100">
                                <Link className="d-flex align-items-center" to="/guide-and-faq">
                                    <FAQIcon className='wh-25px mx-2' active={0} />
                                    <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.guideAndFaq)}</span>
                                </Link>
                                <div className="mt-4"></div>
                                <Link className="d-flex align-items-center" to="/settings">
                                    <AccountSettingsIcon className='wh-25px mx-2' />
                                    <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.Settings)}</span>
                                </Link>
                            </div>
                        </div>
                        {
                            !onShow ? <Link to='/login/idms' className="toggle-signout toggle-signout-sm my-5 d-flex justify-content-end align-items-center">
                                <SignInIcon className='wh-25px mx-2' />
                                <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>Sign in</span>
                            </Link> :
                                <div onClick={onSignOutHandler} className="toggle-signout toggle-signout-sm my-5 d-flex justify-content-end align-items-center">
                                    <SignOutIcon className='wh-25px mx-2' />
                                    <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>Sign out</span>
                                </div>
                        }
                        <Footer />
                        <div style={{marginBottom:30}}></div>
                    </>
                }
            </div>
        </AppWrapper>
    )

    if (currentPage === 'labs') {
        body = <Labs />
    }


    return (
        <>
            {body}
        </>
    );
}

export default Menu;
