import React from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { TapScreenIcon } from '../../components/common/Icon'
import { REACT_APP_LEVICA_APP_DEEP_LINK } from '../../config'

const WalletAnimaChargeSuccess = () => {
    const handleTapScreen = () => {
        window.location.href = REACT_APP_LEVICA_APP_DEEP_LINK;
    }

    return (
        <AppWrapper disableSidebar>
            {/* <OnLoading /> */}
            <div className='flex-center flex-column justify-content-around'>
                <TapScreenIcon className="m-t-100 m-b-70 pointer" onClick={handleTapScreen} />
                <span className='pre-wrap fs-18 fw-400 f-regular text-center'>
                    Charge has been successfully completed.
                    <br /><br />
                    Please tap the screen to go back to LEVICA App.
                </span>
            </div>
        </AppWrapper>
    )
}

export default WalletAnimaChargeSuccess