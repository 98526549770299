import React from 'react'

import { translations } from "../../services/localization";
import InfiniteScroll from '../../components/common/InfiniteScroll';

const GameList = () => {

  const content = translations.productList;

  const metaTitle = 'プロダクト・ゲーム一覧｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  return (
    <InfiniteScroll 
      title={translations.formatString(content.headerTitle)} 
      pageid='Game' 
      sidebarWrapperDivClassName={'p-t-0 m-t--24'} 
      metaTitle={metaTitle} metaDescription={metaDescription}
      link="games"
    />
  )
}

export default GameList
