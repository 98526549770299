import React, { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_DOMAIN } from "../../config";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { SwalCorrect, SwalEmail, SwalWrong, LeviasSwalCorrect, LeviasSwalWrong } from "../../components/common/Swal";

import {
  LeviasPasswordResetForm,
  LeviasSetPasswordResetForm,
  LeviasTwoFactorVerifyForm,
} from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {BottomModal, LeviasBottomModal} from "../../components/common/BottomModal";
import { fetchData } from "../../services/fetch";
import { translations } from "../../services/localization";

function PasswordReset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailValidated, setEmailValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const language = useSelector((state) => state.language.language);
  const [email, setEmail] = useState("");
  // const [emailValid, setEmailValid] = useState(false)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phoneCodeValid, setPhoneCodeValid] = useState(false);

  const [smsVerificationCode, setSmsVerificationCode] = useState("");
  const [idmsRegistrationStep, setIdmsRegistrationStep] = useState("");
  const [onShow, setOnShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneValid, setPhoneValid] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const [isLeviasLayout, setIsLeviasLayout] = useState(false);

  const changePasswordPage = translations.changePasswordPage
  const loginPage = translations.loginPage
  const content = translations.anicana

  const onShowHandler = (type, status) => {
    if (type === "password") {
      setShowPassword(status);
    } else if (type === "confirm-password") {
      setShowConfirmPassword(status);
    }
  };

  const passwordValidator = (value, result) => {
    if (value === "password") {
      if (result.length > 7) {
        if (result === confirmPassword) {
          setPasswordValid(true);
        }
      }
    } else if (value === "con-password") {
      if (result.length > 7) {
        if (password === result) {
          setPasswordValid(true);
        }
      }
    }
  };

  const textChangeHandler = (type, value) => {
    if (type === "email") {
      setEmail(value);
      emailValidator(type, value);
    } else if (type === "phone") {
      setPhoneNumber(value);
      phoneValidator(type, value);
    } else if (type === "verify-phone-code") {
      if (value.length > 5) {
        setPhoneCodeValid(true);
      }
      else {
        setPhoneCodeValid(false);
      }
      setSmsVerificationCode(value);
    } else if (type === "password") {
      setPassword(value);
      passwordValidator("password", value);
    } else if (type === "confirm-password") {
      setConfirmPassword(value);
      passwordValidator("con-password", value);
    }
  };

  // const onEmailVerifyHandler = () => {
  //   SwalEmail.fire({
  //     // title: "OError",
  //     text: "A URL for password reset will be sent to your registered email address.",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // navigate("/login");
  //       setIdmsRegistrationStep('phoneVerify')
  //     }
  //   });
  // };


  const onEmailVerifyHandler = async () => {
    var response = await fetchData(`accounts/forgot-password`, {
      email,
      mobileNumber: phoneNumber,
    }, 'post', true);
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.transactionId) {
          setLoading(false);
          setIdmsRegistrationStep("phoneVerify");
          setTransactionId(response.data.transactionId);
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: loginPage.errorText,
        text: loginPage.user_not_found,
      });
    }
  }

  const onShowModalHandler = () => {
    setOnShow(true);
  };

  const onCloseHandler = () => {
    setOnShow(false);
  };

  const emailValidator = (_, result) => {
    setEmailValidated(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(result));
  };

  const nextStepHandler = async () => {
    if (idmsRegistrationStep === 'phoneVerify') {
      setIdmsRegistrationStep('setPassword')
    }
    else if (idmsRegistrationStep === 'setPassword') {
      setLoading(true)
      var response = await fetchData(`accounts/reset-password`, {
        otp: smsVerificationCode,
        password,
        confirmPassword,
        transactionId
      }, 'post', true);
      if (response.ok) {
        if (response.data) {
          if (response.data.errorMessage) {
            setLoading(false);
            let message = response.data.errorMessage;
            LeviasSwalWrong.fire({
              title: "Error",
              text: message,
            });
          } else if (response.data.message) {
            setLoading(false);
            let message = response.data.message;
            LeviasSwalWrong.fire({
              title: "Error",
              text: message,
            });
          } else {
            setLoading(false);
            // setTransactionId(response.data.response.transactionId);

            LeviasSwalCorrect.fire({
              title: changePasswordPage.password_had_been_reset,
              // text: message,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/login/idms");
                setLoading(false);
              }
            });
          }
        }
      } else {
        setLoading(false);
        LeviasSwalWrong.fire({
          title: "Error",
          text: response.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/password-reset");
          }
        });
      }
    }
  }

  const phoneValidator = (value, result) => {
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    if (value === "phone") {
      if (phoneno.test(result)) {
        setPhoneValid(true)
      }
      else {
        setPhoneValid(false)
      }
    }
  }

  const leviasLayout = () => {
    setIsLeviasLayout(true)
  }

  let body = (
    <>
      <LeviasPasswordResetForm
        showPassword={showPassword}
        showConfirmPassword={showConfirmPassword}
        onShowHandler={onShowHandler}
        emailValidated={emailValidated}
        setEmailValidated={setEmailValidated}
        onEmailVerifyHandler={onEmailVerifyHandler}
        emailValidator={emailValidator}
        textChangeHandler={textChangeHandler}
        phoneNumber={phoneNumber}
        phoneValid={phoneValid}
        email={email}
        smsVerificationCode={smsVerificationCode}
        leviasLayout={leviasLayout}
      />
    </>
  );
  if (idmsRegistrationStep === "phoneVerify") {
    body = (<LeviasTwoFactorVerifyForm
      setIdmsRegistrationStep={setIdmsRegistrationStep}
      setTransactionId={setTransactionId}
      onShowModalHandler={onShowModalHandler}
      phoneCodeValid={phoneCodeValid}
      smsVerificationCode={smsVerificationCode}
      nextStepHandler={nextStepHandler}
      textChangeHandler={textChangeHandler}
      leviasLayout={leviasLayout}
      onEmailVerifyHandler={onEmailVerifyHandler}
      email={email}
      phoneNumber={phoneNumber}
    />)
  } else if (idmsRegistrationStep === "setPassword") {
    body = (
      <LeviasSetPasswordResetForm
        showPassword={showPassword}
        showConfirmPassword={showConfirmPassword}
        onShowHandler={onShowHandler}
        onShowModalHandler={onShowModalHandler}
        nextStepHandler={nextStepHandler}
        passwordValid={passwordValid}
        password={password}
        confirmPassword={confirmPassword}
        textChangeHandler={textChangeHandler}
        loading={loading}
        leviasLayout={leviasLayout}
      />
    )
  }

  return (
    <>
      <BottomModal onCloseHandler={onCloseHandler} onShow={onShow} />
      <AppWrapper isLeviasLayout disableSidebar offPaddingBot 
        title={content.headerTitle} 
        isForm isCenter isLeviasHandler={'forgot'} hasInput
      >
        <Container fluid className="d-flex flex-row auth-container">
          {body}
        </Container>
      </AppWrapper>
    </>
  );
}

export default PasswordReset;
