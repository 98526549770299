import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { clearLocalStorage, getToken } from '../../services/storage';
import { authAction } from '../../store/Auth';
import { Footer } from '../common/Footer';
// import { button } from '../common/Form';
import { strings, translations } from "../../services/localization";

import {
    ArcanaSideIcon,
    HomeIcon,
    SignInIcon,
    SignOutIcon,
    SettingIcon,
    SquareIcon,
    PersonaIcon,
    TrendIcon,
    TopicsIcon,
    WorldNewsIcon,
    MenuCodeDIcon,
    SideProductIcon,
    GradientSquareIcon,
    MenuSquareIcon,
    FAQIcon
} from '../common/Icon';
import { SwalSettings } from '../common/Swal';
import SubNav from '../common/SubNav';
import { useMediaQuery } from 'react-responsive'
import Filter from '../../pages/tokens/Filter';
import Sort from '../../pages/tokens/Sort';
import { isSafari } from 'react-device-detect';
import { TokenType } from '../common/enum';
import CategoryFilter from '../common/CategoryFilter';
import { setPositionY, setTokenData, setTokenPage } from '../../store/Position';
import { appContext } from '../../context/appContext';

const SidebarWrapper = (props) => {
    const { subNavItems, subNavOnChange, scrollableHeader, gameDetailHeader, isCustomDetailPage, scrollPoint, isNoSpaceAround, isFilter, isSort, noPadding, sidebarWrapperClassName, disableSideContentScroll, hasAdditionalBottom } = props;
    const [showWrapper, setShowWrapper] = useState(false);
    const [filter, setFilter] = useState(isFilter);
    const [sort, setSort] = useState(isSort);

    // const subNavItems = [
    //     {
    //         name: 'top',
    //         to: '/'
    //     },
    //     {
    //         name: 'recommendations',
    //         to: '/'
    //     },
    //     {
    //         name: 'anima',
    //         to: '/'
    //     },
    // ]

    const handleScroll = event => {
        gameDetailHeader(event?.currentTarget?.scrollTop || 0)
    };

    const toggleFilter = (value) => {
        setFilter(false)
        setSort(false)
    }

    useEffect(() => {
        setFilter(isFilter);
    }, [isFilter])

    useEffect(() => {
        setSort(isSort);
    }, [isSort])

    return (
        <div id="mypage">
            <div id="wrapper" className={`wrapper-content mypage-wrapper ${isCustomDetailPage ? 'isCustomDetailPage' : ''} ${scrollableHeader ? 'scrollable-wrapper-content' : ''} ${sidebarWrapperClassName || ''} ${disableSideContentScroll ? 'overflow-hidden' : ''}`} onScroll={handleScroll}>
                <div className='d-flex wrapper-limit-view'>
                    <div className={`sidebar-col ${filter || sort ? ' isFilterSidebar' : 'defaultSidebar'} pe-0 d-none d-lg-block`} style={{ zIndex: 1 }}>
                        <Sidebar {...props} showWrapper={showWrapper} />
                        <div className='sidebar-transition'>
                            {
                                filter ?
                                    <Filter isSidebar onRequestClose={toggleFilter} /> :
                                    sort ?
                                        <Sort isSidebar onRequestClose={toggleFilter} /> : <></>
                            }
                        </div>
                    </div>
                    {
                        !filter && !sort &&
                        <Sidebar {...props} setShowWrapper={setShowWrapper} className={`d-block d-lg-none sidebar-fullscreen ${showWrapper ? 'show' : ''}`} />
                    }

                    <div className={`w-100 side-content`}>
                        <div className="page-content-wrapper p-0">
                            {/* {
                                !props.disabledSubNav ? <SubNav pageid={props.pageid} className="border-0" toggleBtn={() =>
                                    <button className="custom-toggler navbar-toggler external-link border-0" type="button" data-toggle="collapse" data-target="#sidebar-wrapper" aria-controls="sidebar-wrapper" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                } /> : props.additionalSubNav ? props.additionalSubNav() : <div id='subNav' className='empty'></div>
                            } */}

                            <div className={`side-content-body ${subNavItems ? 'hasSubNav' : ''} p-0 ps-lg-0`}>
                                {
                                    subNavItems && subNavItems.length > 0 &&
                                    <SubNav subNavItems={subNavItems} onChange={subNavOnChange} />
                                    // <CategoryFilter categories={subNavItems} onChange={subNavOnChange} />
                                }
                                <div id={'side-content-scroll'} className={`row mx-0 d-block position-relative ${disableSideContentScroll ? '' : 'side-content-scroll'}`}>
                                    <div
                                        style={isCustomDetailPage ? {} : props.sideContentStyle}
                                        className={`col ${isCustomDetailPage || isNoSpaceAround ? 'px-0 pt-0' : ''} ${props.positionFixed ? 'positionFixed' : ''} ${props.offOverFlowX ? 'offOverFlowX' : ''} ${noPadding} ${hasAdditionalBottom ? 'hasAdditionalBottom' : ''}`}
                                    >
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidebarWrapper

const Sidebar = props => {
    const [onShow, setOnShow] = useState(false)
    const { logout } = useContext(AuthContext);
    const sidebarPage = translations.sidebarPage; // Usage: strings.formatString(sidebarPage.Home)
    const { language:contextLanguage } = useContext(appContext);
    const language = contextLanguage === 'English' ? 'en' : 'jp';
    const menuPage = translations.menuPage;
    const dispatch = useDispatch()

    const onGetToken = async () => {
        var token = await getToken()
        if (token) {
            setOnShow(true)
        }
    }

    useEffect(() => {
        onGetToken()
    }, [])

    const sidebar_items = [
        {
            id: 'menu1',
            name: 'menu1',
            className: 'group',
            hasDivider: true,
            to: '',
            items: [
                {
                    id: 'Home',
                    name: strings.formatString(sidebarPage.Home),
                    icon: (active) => <HomeIcon active={active ? 1 : 0} />,
                    to: '/',
                },
                {
                    id: 'Topics',
                    name: strings.formatString(sidebarPage.Topics),
                    icon: (active) => <TopicsIcon active={active ? 1 : 0} />,
                    to: '/topics',
                },
                {
                    id: 'Trend',
                    name: strings.formatString(sidebarPage.Trend),
                    icon: (active) => <TrendIcon active={active ? 1 : 0} />,
                    to: '/trend',
                },
                // {
                //     id: 'SpecialThanks',
                //     name: 'Special Thanks',
                //     icon: (active) => <ThankIcon active={active ? 1 : 0} />,
                //     to: '/',
                // },
            ]
        },
        {
            id: 'menu2',
            name: 'menu2',
            className: 'group',
            hasDivider: true,
            to: '',
            items: [
                {
                    id: 'Arcana',
                    name: strings.formatString(sidebarPage.Arcanas),
                    icon: (active) => <ArcanaSideIcon active={active ? 1 : 0} />,
                    to: `/tokens/${TokenType.Arcana}`,
                    onClick: () => {
                        dispatch(setPositionY({ scrollPositionY: null }))
                        dispatch(setTokenData({ data: null }))
                        dispatch(setTokenPage({ pageNum: 1, lastPage: 1 }))
                    },
                },
                {
                    id: 'Persona',
                    name: strings.formatString(sidebarPage.Personas),
                    icon: (active) => <PersonaIcon className={active ? 'active' : ''} />,
                    to: `/tokens/${TokenType.Persona}/collection`,
                    onClick: () => {
                        dispatch(setPositionY({ scrollPositionY: null }))
                        dispatch(setTokenData({ data: null }))
                        dispatch(setTokenPage({ pageNum: 1, lastPage: 1 }))
                    },
                },
                {
                    id: 'Square',
                    name: strings.formatString(sidebarPage.Square), 
                    icon: (active) => <MenuSquareIcon active={active ? 1 : 0} className={active ? 'active' : ''} />,
                    // icon: (active) => active ? <GradientSquareIcon /> : <MenuSquareIcon />,
                    to: '/squares',
                },
            ]
        },
        {
            id: 'menu3',
            name: 'menu3',
            className: 'group',
            hasDivider: false,
            to: '',
            items: [
                {
                    id: 'Game',
                    name: strings.formatString(sidebarPage.Games),
                    icon: (active) => <SideProductIcon active={ active ? 1 : 0} className={active ? 'active' : ''} />,
                    to: '/products',
                },
                {
                    id: 'News',
                    name: strings.formatString(sidebarPage.News),
                    icon: (active) => <WorldNewsIcon gradient={active ? 1 : 0} />,
                    to: '/news',
                },
                {
                    id: 'CodeD',
                    name: strings.formatString(sidebarPage.CodeD),
                    icon: (active) => <MenuCodeDIcon gradient={active ? 1 : 0} />,
                    to: '/code-d',
                },
            ]
        },
        /*
<div className="m-t-100">
                                <Link className="d-flex align-items-center" to="/faqs">
                                    <FAQIcon className='wh-25px mx-2' />
                                    <span className="mx-1" style={{ fontSize: '20px', fontFamily: 'KardustExpandedRegular' }}>{strings.formatString(menuPage.faqs)}</span>
                                </Link>
                                <div className="mt-4"></div>
        */
        {
            id: 'menu4',
            name: 'menu4',
            className: 'group',
            hasDivider: true,
            to: '',
            items: [
                { 
                    id: 'Guide-and-FAQ',
                    name: strings.formatString(menuPage.guideAndFaq),
                    icon: (active) => <FAQIcon active={active ? 1 : 0} />,
                    className: 'mt-5',
                    to: '/guide-and-faq',
                },
                {
                    id: 'Setting',
                    name: strings.formatString(sidebarPage.Setting),
                    icon: (active) => <SettingIcon active={active ? 1 : 0} />,
                    to: '/settings',
                },
                {
                    id: 'SignOut',
                    name: onShow ? strings.formatString(sidebarPage.Sign_Out) : strings.formatString(sidebarPage.Sign_In),
                    icon: (active) => onShow ? <SignOutIcon active={active ? 1 : 0} /> : <SignInIcon active={active ? 1 : 0} />,
                    className: 'mt-5',
                    to: onShow ? '' : '/login/idms',
                    onClick: () => {
                        if (onShow) {
                            onSignOutHandler()
                        }
                    },
                },
                // {
                //     id: 'SignIn',
                //     name: 'Sign In',
                //     className: 'my-5',
                //     to: '/login',
                // },
            ]
        },
        {
            id: 'menu5',
            name: 'menu5',
            className: 'group sidebarmenu-5',
            hasDivider: false,
            to: '',
            items: [
                {
                    id: 'Contact',
                    name: strings.formatString(sidebarPage.Contact),
                    className: 'sidebar-footer',
                    to: '/contact-us',
                },
                {
                    id: 'TermsOfUse',
                    name: strings.formatString(sidebarPage.Terms_Of_Use),
                    className: 'sidebar-footer',
                    to: '/terms',
                },
                {
                    id: 'PrivacyPolicy',
                    name: strings.formatString(sidebarPage.Privacy_Policy),
                    className: 'sidebar-footer',
                    to: '/privacy',
                },
                {
                    id: 'CookiePolicy',
                    name: strings.formatString(sidebarPage.Cookie_Policy),
                    className: 'sidebar-footer',
                    to: '/cookies-policy',
                },
                {
                    id: 'WhitePaper',
                    name: strings.formatString(sidebarPage.White_Paper),
                    className: 'sidebar-footer',
                    to: '/ANICANA_WP106_JP221118.pdf',
                    onClick: () => {
                        window.reload();
                    },
                },
                {
                    id: 'TechnicalPaper',
                    name: strings.formatString(sidebarPage.Technical_Paper),
                    className: 'sidebar-footer',
                    onClick: () => {
                        window.open(`https://anicana-technicalpaper.readthedocs.io/${language==='jp' ? 'ja' : language}/latest/`, '_blank')
                    },
                },
                {
                    id: '@InfiCreo ZRT',
                    name: strings.formatString(sidebarPage.Company),
                    className: 'sidebar-footer sidebar-footer-copy-right',
                },
            ]
        },
    ]

    const onSignOutHandler = () => {
        const buttonlist = translations.buttonlist;
        const confirmSignOut = translations.message.confirmSignOut;
        SwalSettings.fire({
            text: confirmSignOut,
            confirmButton: "btn btn-custom-active text-white d-block btn-confirm btn-mini my-4 ladda-label btn-custom-swal shadow-none fs-25",
            cancelButton: 'btn btn-custom-cancel btn-mini f-monodb btn-custom-swal shadow-none fs-25',
            confirmButtonText: `<span>${strings.formatString(buttonlist.okBtn)}</span>`,
            cancelButtonText: strings.formatString(buttonlist.cancelBtn),
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
            }
        });
    }

    const [isHovering, setIsHovering] = useState(false);
    const [isHoveringItem, setIsHoveringItem] = useState('');

    const handleMouseOver = (item) => {
        setIsHovering(true);
        setIsHoveringItem(item);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
        setIsHoveringItem('');
    };

    return (
        <ul className={`sidebar-nav default ${props.className || ''}`}>
            {sidebar_items.map((item, index) => <li key={index} className={`${item.className} ${item.id == props.pageid ? 'active' : ''}`} id={item.id}>
                {item.items && item.items.length > 0 ?
                    <>
                        <ul>
                            {item.items.map((subitem, subindex) => {
                                const is_active = subitem.id === props.pageid;
                                const is_hover = (subitem.id === isHoveringItem) && isHovering;
                                return (
                                    <li key={`sub-${subitem.id}`} className={`${subitem.className} ${is_active || is_hover ? 'active' : ''}`} style={subitem.style}>
                                        <Link to={subitem.to || '#'}
                                            onMouseOver={() => handleMouseOver(subitem.id)}
                                            onMouseOut={handleMouseOut} onClick={subitem.onClick} className={'d-flex align-items-center subitem'}
                                        >
                                            {subitem.icon && subitem.icon(is_active || is_hover)}
                                            {
                                                item.id === 'menu5' ?
                                                    <span
                                                        className={`${language === 'jp' ? 'f-notosan fw-normal' : 'f-regular'} ms-2 ${is_active ? 'active' : ''}`}
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {subitem.name}
                                                    </span>
                                                    :
                                                    <span className={`${language === 'jp' ? 'f-notosan fw-normal' : 'f-regular'} ms-2 ${is_active ? 'active' : ''}`}>
                                                        {subitem.name}
                                                    </span>
                                            }

                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </> : <Link to={item.to || '#'}>{item.name}</Link>}
                {item.hasDivider && <div className='divider my-4'></div>}
            </li>
            )}
            <li className='filter-action d-none'></li>
        </ul>
    )
}
