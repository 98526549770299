import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppWrapper from '../../components/wrappers/AppWrapper'
import WalletWrapper from '../../components/wrappers/WalletWrapper'
import data from '../../data'
import QRCode from "react-qr-code";
import { WalletAddress } from './Wallet'
import { translations } from "../../services/localization";
import { Web3authContext } from '../../context/web3authContext'
import { Link } from "react-router-dom";
import { ReactComponent as BackTwoVectorSVG } from '../../assets/icons/back-two-vector.svg';
import QRCodeComponent from '../../components/common/QRCodeComponent'

const WalletReceive = ({ address }) => {
    const navigate = useNavigate();
    const content = translations.walletReceive
    const { wallet_address } = useContext(Web3authContext);
    const qrCodeRef = useRef();
    return (
        <AppWrapper title={translations.formatString(content.headerTitle)}>
            <div className="row" style={{ marginBottom: 30, cursor: 'pointer' }} >
                <div style={{ textAlign: "center" }} onClick={() => navigate(-2)}>
                    <BackTwoVectorSVG style={{ float: "left" }}></BackTwoVectorSVG>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <WalletAddress titleClassName="ms-0" />
                </div>
            </div>
            <div className='row my-4'>
                <div className='col-12 text-center'>
                    <h5 className='my-wallet-address'>{translations.formatString(content.labelQrCode)}</h5>
                    <div className='text-center mt-4'>
                        {
                            (address || wallet_address) &&
                            <QRCodeComponent
                                value={address || wallet_address}
                                qrCodeRef={qrCodeRef}
                            />
                        }
                    </div>
                </div>
            </div>
        </AppWrapper>
    )
}

export default WalletReceive