import React, { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { OnLoading } from '../../components/common/OnLoading'
import { ProductItem } from '../../components/common/ProductItem'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { appContext } from '../../context/appContext'
import { getData } from '../../services/fetch'
import { translations } from '../../services/localization'
import { addRecentSearch, getRecentSearch, setRecentSearch } from '../../services/storage'
import { TokenType } from '../../components/common/enum'

const SearchResultList = () => {
    const [searchResult, setSearchResult] = useState(undefined);
    const [searchLoading, setSearchLoading] = useState(false);
    const { globalSearch, setGlobalSearch, showRecentSearch, setShowRecentSearch } = useContext(appContext);
    const content = translations.anicana;

    const handleSearch = async value => {
        setSearchLoading(true);
        setShowRecentSearch(false);
        const global_search = document.getElementById("globalSearch");
        if (global_search) {
            global_search.value = value;
        }
        const response = await getData(`search-data?name=${value}`);
        if (response.ok) {
            setSearchResult(response.data.data);
        }
        if (value) {
            addRecentSearch(value);
        }
        setSearchLoading(false);
    }

    useEffect(() => {
        console.log({ globalSearch })
        if (globalSearch) {
            handleSearch(globalSearch);
        } else {
            setSearchResult(undefined);
        }

    }, [globalSearch])

    useEffect(() => {

        return () => setGlobalSearch('')
    }, [])

    return (
        <AppWrapper
            title={translations.formatString(content.headerTitle)}
            disableSideContentScroll
        >
            <div className="overflow-scroll mb-3 menu-container" style={{ paddingBottom: 180 }}>
                <div className="">
                    {
                        showRecentSearch ? <></> :
                            searchLoading ?
                                <OnLoading /> :
                                <SearchResult className="m-t-35" result={searchResult} />
                    }
                </div>
            </div>
        </AppWrapper>
    )
}

export default SearchResultList

export const RecentSearchList = ({ onClickItem, className }) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(getRecentSearch());
    }, [])
    const deleteSearchHistory = (x) => {
        const removed = (items || []).filter((i) => i !== x)
        setItems(removed)
        setRecentSearch(removed)
    }

    return (
        <div className={className || ""}>
            {
                items.map((item, index) =>
                    <div key={`recent-search-${index}`}>
                        <div className='d-flex justify-content-between align-items-center position-relative'>
                            <div className="pointer w-100" onClick={() => onClickItem ? onClickItem(item) : {}}>
                                <span className="fs-15 f-condensed">{item}</span>
                            </div>
                            <div className="pointer" onClick={() => deleteSearchHistory(item)} style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                            }}>
                                <span className="fs-15">X</span>
                            </div>
                        </div>
                        <div className="divider mx-0" style={{ borderWidth: 0.5 }}></div>
                    </div>
                )
            }
        </div>
    )
}

export const SearchResult = ({ result, className }) => {
    const commonlist = translations.common;
    if (result && result.news.length === 0 && result.games.length === 0 && result.tokens.length === 0 && result.squares.length === 0)
        return (
            <div className='d-flex flex-center w-100 mt-5'>
                <p>{translations.formatString(commonlist.noSearchResult)}</p>
            </div>
        )
    return (
        <div className={className || ""}>
            {
                result && result.news && result.news.map((data, index) =>
                    <div key={`search_result_item_${index}`}>
                        <ProductItem
                            id={data.id}
                            image={data.square_thumbnail_image_url}
                            title={data.title}
                            body={data.body}
                            news_categories={"NEWS"}
                            path={`/news_detail/${data.id}`}
                            published={data.published}
                            created_at={data.created_at}
                            squareimage
                        />
                    </div>
                )
            }
            {
                result && result.games && result.games.map((data, index) =>
                    <div key={`search_result_item_${index}`}>
                        <ProductItem
                            id={data.id}
                            image={data.square_thumbnail_image_url}
                            title={data.title}
                            body={data.body}
                            news_categories={"PRODUCT"}
                            path={`/products_detail/${data.id}`}
                            // published={data.published}
                            created_at={data.created_at}
                            squareimage
                        />
                    </div>
                )
            }
            {
                result && result.tokens && result.tokens.map((data, index) =>
                    <div key={`search_result_item_${index}`}>
                        <ProductItem
                            id={data.id}
                            image={data.thumbnail}
                            title={data.name}
                            body={data.body}
                            news_categories={"ARCANA"}
                            path={`/wallet/${TokenType.getRouteName(data.token_type_id || 1)}/detail/${data.token_id}`}
                            published={data.birthday_datetime}
                            isUTC
                            created_at={data.created_at}
                            squareimage
                        />
                    </div>
                )
            }
            {
                result && result.squares && result.squares.map((data, index) =>
                    <div key={`search_result_item_${index}`}>
                        <ProductItem
                            id={data.id}
                            image={data.square_thumbnail_image}
                            title={data.title}
                            body={data.body}
                            news_categories={"SQUARE"}
                            path={`/squares/${data.id}`}
                            published={data.birthday_datetime}
                            isUTC
                            created_at={data.created_at}
                            squareimage
                        />
                    </div>
                )
            }
        </div>
    )
}