import React, { useEffect, useState } from "react";
import { translations } from "../../services/localization";
import { OnLoading } from "./OnLoading";
import { useIntersectionWithLastRef } from "../../hooks/useIntersectionWithLastRef";
import { useMediaQuery } from "react-responsive";

const InfiniteScrollContainer = ({ newItems, itemSize, total, leftSpace = 0, rightSpace = 0, limit, setLimit, renderedContainerClassName, renderedComponent, hasMore, onChangePage, noResultText }) => {
    const isSmPh = useMediaQuery({ maxWidth: 452 });
    const commonlist = translations.common;
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [isNoResult, setIsNoResult] = useState(false);

    useEffect(() => {
        setLimit(caluclateScreenLimit)
    }, [])

    useEffect(() => {
        if (limit !== null) {
            if (page === 1) {
                setItems(newItems);
                if (!newItems || newItems.length === 0) {
                    setIsNoResult(true);
                } else {
                    setIsNoResult(false);
                }

            } else {
                setItems(prev => [...prev, ...newItems] || []);
                setLoading(false);
            }
        }
    }, [newItems, limit])

    useEffect(() => {
        setLoading(page > 1);
        onChangePage(page);
    }, [page])

    const { ref } = useIntersectionWithLastRef(loading, hasMore, () => {
        setPage(prev => prev + 1);
    })

    function calculateSize(itemSize = 226) {
        let body_size = 0;
        if (window.innerWidth > 991) {
            body_size = window.innerWidth - 260; // reduct side bar menu px
        } else {
            body_size = window.innerWidth;
        }
        let size = body_size / itemSize; // one item has max width 210 px
        if (size - Math.trunc(size) < 0.1) {
            // px 0.1< cann't diplay full item so reduce 1 item limit
            size = Math.trunc(size) - 1;
        }
        else {
            size = Math.trunc(size);
        }
        return size;
    }

    function caluclateScreenLimit() {
        let value;
        const size = calculateSize(itemSize);
        switch (size) {
            case 5 || 10: // display 5 or 10 item per row
                value = 10;
                break;
            case 7: // display 7 item per row
                value = 14;
                break;
            case 8: // display 8 item per row
                value = 16;
                break;
            case 9: // display 9 item per row
                value = 18;
                break;
            case 11: // display 11 item per row
                value = 11;
                break;
            default: //defaule limit
                value = 12;
        }
        return value;
    }

    return (
        <div>
            {isNoResult ? (
                <div className="d-flex flex-center w-100 mt-5">
                    <p>
                        {noResultText || commonlist.noSearchResult}
                    </p>
                </div>
            ) : (
                <>

                    <div className={renderedContainerClassName}>
                        {
                            items.map((item, index) =>
                                renderedComponent(item, index, items.length === index + 1 ? ref : null)
                            )
                        }
                    </div>
                    {loading && (
                        <OnLoading
                            pRelative
                            noLabel
                            marginAuto
                            className={`${isSmPh ? "" : "tokenlist-loading"} m-0`}
                            style={{
                                left: isSmPh ? 'unset' : (calculateSize(itemSize) / 2) * itemSize - (40 + ((leftSpace + rightSpace) / 2))
                            }}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default InfiniteScrollContainer