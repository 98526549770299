import React, { useContext, useRef } from 'react'

import { WalletAddress } from './Wallet'
import { strings, translations } from "../../services/localization";
import { Web3authContext } from '../../context/web3authContext'
import QRCodeComponent from '../../components/common/QRCodeComponent';

const Receive = (props) => {
  const address = props.address
  const content = translations.walletReceive
  const { wallet_address } = useContext(Web3authContext);
  const qrCodeRef = useRef();

  return (
    <div className='receive'>
      <span className='title'>
        {strings.formatString(content.title)}
      </span>
      <div className='divider my-3'></div>
      <div className='d-flex'>
        <div className='my-4' style={{minWidth: 250}}>
          <h5 className='my-wallet-address text-center'>
            {strings.formatString(content.labelQrCode)}
          </h5>
          <QRCodeComponent 
            value={address || wallet_address} 
            qrCodeRef={qrCodeRef}
          />
        </div>
        <div className='mx-4 my-4 width-content'
          style={{
            maxWidth: '60%'
          }}
        >
          <WalletAddress address={address}/>
          
          <div className='mt-4'>
            <span className='f-condensed'>
              {strings.formatString(content.notifyParagraph)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receive