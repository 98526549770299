import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ProductItem } from "../../components/common/ProductItem";
import "../../style/newlist.scss";

import AppWrapper from "../../components/wrappers/AppWrapper";
import { OnLoading } from "../../components/common/OnLoading";
import { appContext } from "../../context/appContext";
import { useContext } from "react";
import { SwalWrong } from "../../components/common/Swal";
import { translations } from "../../services/localization";
import { newsSubNavItems } from "../../components/common/SubNav";
import moment from "moment";
import { createMarkup, extractTextFromTags, parseToExecutableHTML } from "../../components/common/commonFnc";
import { HomeTitle } from "../../components/common/Title";
import Pagination from '../../components/common/pagination';
import ScrollContainer from 'react-indiana-drag-scroll'
import { useMediaQuery } from "react-responsive";
import { useFetchTopNews, useScrollToTop } from "../../services/hook";
import { NewBadge } from "../../components/common/Badge";
import TopNewsMobile from "../../components/common/TopNewsMobile";
import { getData } from "../../services/fetch";
import momenttz from 'moment-timezone';
import InfiniteScrollContainer from "../../components/common/InfiniteScrollContainer";

const NewsList = () => {

  const [hasMore, setHasMore] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [news, setNews] = useState([]);
  const { language, timeZone } = useContext(appContext);
  const content = translations.newsList;
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(1);
  const [top5News, setTop5News] = useState(null)
  const [topAds, setTopAds] = useState(null)
  const [itemsLimit, setItemsLimit] = useState(null)

  const topNews = useFetchTopNews(language, timeZone);
  const isPC = useMediaQuery({ minWidth: 992 })
  const isPh = useMediaQuery({ maxWidth: 576 })
  const topImage = topNews?.image_url_top ? topNews?.image_url_top : topNews?.image_url

  const shortForm = useMemo(() => {
    return language === 'English' ? 'en' : 'jp';
  }, [language]);

  const fetchCategories = useCallback(async () => {
    const response = await getData(`news-categories?language=${shortForm}`);
    if (response.ok) {
      setCategories(response.data.data);
    }
  }, [shortForm]);

  useScrollToTop(category, "side-content-scroll");

  const [isMounted, setMounted] = useState(false);

  const fetchNewData = async (category, page) => {
    setLoading(true);
    const response = await getData(`news?${category ? 'category_id=' + category.id + '&' : ''}page=${page}&language=${shortForm}&timezone=${timeZone}`);
    if (response.ok) {
      const resp = response.data;
      if (resp) {
        if (!isMounted) {
          const adResponse = await getData(`advertisements?page=${page}&language=${shortForm}&timezone=${timeZone}`);
          if (adResponse.ok) {
            const adArray = adResponse?.data?.data ?? [];
            const result = joinArrays(resp.data, adArray, 5)
            setNews(result);
          } else {
            setNews(resp.data);
          }
        } else if(page > 1){
          setNews([...news, ...resp.data]);
        }else{
          setNews(resp.data);
        }

        setTotal(resp.meta.total);
        setLast_page(resp.meta.last_page);
        setHasMore(resp.meta.last_page > page);
        setLoading(false);
      }

    } else {
      SwalWrong.fire({
        title: 'Error',
        text: 'Cant fetch data'
      })
      setLoading(false);
    }
  };

  const joinArrays = (array1, array2, nth) => {
    if (array1.length < nth - 1) {
      return [...array1, ...array2];
    }

    const result = [];
    const secondArray = [...array2];
    for (let i = 0; i < array1.length; i++) {
      result.push(array1[i]);
      if ((i + 1) % nth === 0 && secondArray.length > 0) {
        result.push({ ...secondArray.shift(), isAd: true });
      }
    }
    if (secondArray.length > 0) {
      return result.concat(secondArray.map(it => ({ isAd: true, ...it })));
    }
    return result;
  }

  const fetchTop5News = async () => {
    const resp = await getData('top-five-news');
    if (resp?.data?.data) {
      const response = await getData('ad-display-top-five-news-list');
      if (response?.data?.data) {
        const adArray = response?.data?.data || [];
        const result = joinArrays(resp.data.data, adArray, 2)
        setTop5News(result);
      }
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchTop5News();
  }, []);

  useEffect(() => {
    if (language && timeZone) {
      fetchNewData(category, page);
      setMounted(true);
    }
  }, [language, timeZone, page, category]);

  const handleSubNavOnChange = (category) => {
    setPage(1);
    setCategory(category);
  }

  const metaTitle = 'ニュース｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）の最新ニュース一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  if (isPC) {
    return (
      <AppWrapper
        newsActive
        offPaddingBot={news.length < 4}
        title={translations.formatString(content.headerTitle)}
        enableMobileSearch
        pageid="News"
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        subNavItems={categories}
        subNavOnChange={handleSubNavOnChange}
      >
        {
          loading ? <OnLoading /> :
            <>
              <div className="row gx-3 mt-4" >
                <div className="col-xl-12 col-lg-12">
                  {/* <HomeTitle browsersize={0} title={"Recent"} className="f-expanded fw-500" style={{ fontSize: 25 }} /> */}
                  {/* <div className='row news-list'> */}
                  {/* <div className="col-8 row pe-0"> */}
                  {/* {
                    news && news.length > 0 &&
                    news.map((item, index) => {
                      return item.isAd ? null : (<div key={`news_${page}_${index}`}
                        className='col-6 col-sm-4 col-md-3 p-0'
                        style={{ width: 230 }}
                        ref={news.length === index + 1 ? lastNewRef : null}
                      >
                        <NewsCard item={item} language={language} />
                      </div>);
                    }
                    )
                  } */}

                  <InfiniteScrollContainer
                    onChangePage={setPage}
                    hasMore={hasMore}
                    newItems={news}
                    itemSize={230}
                    limit={12}
                    setLimit={setItemsLimit}
                    total={total}
                    renderedContainerClassName='row news-list'
                    renderedComponent={(item, index, ref) =>

                      item.isAd ? null : (<div key={`news_${page}_${index}`}
                        className='col-6 col-sm-4 col-md-3 p-0'
                        style={{ width: 230 }}
                        ref={ref}
                      >
                        <NewsCard item={item} timeZone={timeZone} />
                      </div>)

                    }
                  />
                  {/* </div> */}

                  {/* top 5 news and ads */}
                  {/* <div className="col-4 p-0">
                    <HomeTitle browsersize={0} title={"Top 5"} className="f-expanded fw-500" style={{ fontSize: 25 }} hideLeftLine />
                    { top5News !== null && top5News.map((data, index) => 
                      data.isAd ? 
                        <div key={`ad_key_${index}`}>
                          <NewsCard item={data} language={language} className="w-100" isSP noBorderRadius/>
                        </div>
                      :
                      <div key={`news_${data.id}_${index}`} className="sp-new-list mb-3">
                        <ProductItem
                          isAd={data.isAd}
                          id={data.id}
                          image={data.isAd ? data.image : data.square_thumbnail_image_url}
                          title={data.title}
                          body={data.body}
                          news_categories={(translations._language === 'jp' ? data?.types_jp?.name : data?.types?.name) || data?.types?.name}
                          path={`/news_detail/${data.id}`}
                          published={data.published}
                          created_at={data.created_at}
                          showNewBadge
                          withinHour={3}
                          newBadgeCheckDate={data.published}
                          hideBottomBar 
                          index={index > 1 ? index : index + 1} showTopNews
                          px0
                        />
                      </div>
                    )}
                  </div> */}
                </div>
                {/* <div className='row mb-5'>
                  <div className='col-12 d-flex justify-content-center'>
                    <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} total={total} />
                  </div>
                </div> */}
                {/* <HomeTitle browsersize={0} title={"Category 2"} className="f-expanded fw-500" style={{ fontSize: 25 }} />
                <div className='row news-list'>
                  {
                    news && news.length >= 6 &&
                    news.slice(3, 6).map((item, index) =>
                      <div key={index} className='col-6 col-sm-4 col-md-4 col-lg-4'>
                        <NewsCard item={item} />
                      </div>
                    )
                  }
                </div>
                <HomeTitle browsersize={0} title={"Category 3"} className="f-expanded fw-500" style={{ fontSize: 25 }} />
                <div className='row news-list'>
                  {
                    news && news.length >= 9 &&
                    news.slice(6, 9).map((item, index) =>
                      <div key={index} className='col-6 col-sm-4 col-md-4 col-lg-4'>
                        <NewsCard item={item} />
                      </div>
                    )
                  }
                </div> */}
              </div>
              {/* <div className="col-lg-4 new-games-card">
                <HomeTitle browsersize={0} title={"Top 5"} className="f-expanded fw-500" style={{ fontSize: 25 }} />
                <div className="row new-games-grid" style={{ maxHeight: 'unset' }}>
                  {
                    news && news.length > 0 &&
                    news.slice(0, 5).map((item, index) =>
                      <div key={index} className="col-12">
                        <TopNewCardItem item={item} number={index + 1} />
                      </div>
                    )
                  }
                </div>
              </div> */}

              {/* </div> */}
            </>
        }
      </AppWrapper>
    );
  } else {
    return (
      <AppWrapper
        offPaddingBot={news.length < 4}
        newsActive
        title={content.headerTitle}
        pageid="News"
        noPadding={true}
        isNoSpaceAround
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        subNavItems={categories}
        subNavOnChange={handleSubNavOnChange}
      >
        {loading ? <OnLoading /> : <>

          {/* <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll child-smoothable-transition d-flex multi-carousel-container">
          {
            boxes.map((box, index) => 
              <div className="news-box-list" key={index}>
                <div 
                  onClick={() => setType(box.id)} 
                  className={`${type === box.id ? 'active' : ''} news-category`}
                >
                  {box.type}
                </div>
              </div>
            )
          }
        </ScrollContainer> */}

          {/* image */}
          {/* <div
          className='pe-lg-5 d-lg-flex p-relative'
          style={{ marginBottom: 30 }}
        >
          <div className='col-12 col-lg-5'>
            <div className='flex-center w-100 pointer news-image'></div>
          </div>
          <div className='col-12 col-lg-7 p-absolute' style={{ padding: '0 25px', bottom: 10 }}>
            <div className='w-100 d-flex flex-column justify-content-between align-items-start' style={{ minWidth: 0 }}>
              <h4 className='fw-600 f-regular text-start my-2 title'>
                {topNews?.title}
              </h4>
              <div className='fw-500 f-regular text-justify mb-2 text-overflow-vertical-2'>
                {createMarkup(topNews?.body)}
              </div>
              <div className="w-full flex-center">
                <div className="date" style={{ fontSize: 15, color: '#BF96FF', fontFamily: 'KardustCondensed' }}>{topNews && moment(topNews.published).format('YYYY.MM.DD. HH:mm')}</div>
                <NewBadge className='ms-2' />
              </div>
            </div>
          </div>
        </div> */}
          <TopNewsMobile topNews={topNews} topImage={topImage} showPublishedDate showNewBadge />
          {/* end image */}

          {/* {
        news.length === 0 ? 'no news here':''
      } */}
          <InfiniteScrollContainer
            onChangePage={setPage}
            hasMore={hasMore}
            newItems={news}
            itemSize={450}
            limit={12}
            setLimit={setItemsLimit}
            total={total}
            renderedContainerClassName='form-wrapper news-list'
            renderedComponent={(data, index, ref) =>
              data.isAd ? (
                <div key={`ad_key_${index}`} style={{ padding: '0 1rem' }}>
                  <NewsCard item={data} className="w-100" isSP noBorderRadius />
                  <div className="divider my-3 w-100 mx-auto"></div>
                </div>
              ) : (
                <div key={`news_${page}_${index}`} className="sp-new-list" ref={news.length === index + 1 ? ref : null}>
                  <ProductItem
                    isAd={data.isAd}
                    id={data.id}
                    image={data.isAd ? data.image : data.square_thumbnail_image_url}
                    title={data.title}
                    body={data.body}
                    news_categories={data?.types}
                    path={`/news_detail/${data.id}`}
                    published={data.published}
                    created_at={data.created_at}
                    showNewBadge
                    withinHour={3}
                    newBadgeCheckDate={data.published}
                  />
                </div>
              )

            }
          />
          {/* <div className="form-wrapper news-list">
            {
              news &&
              news.map((data, index) => {
                return data.isAd ? (
                  <div key={`ad_key_${index}`} style={{ padding: '0 1rem' }}>
                    <NewsCard item={data} language={language} className="w-100" isSP noBorderRadius />
                    <div className="divider my-3 w-100 mx-auto"></div>
                  </div>
                ) : (
                  <div key={`news_${page}_${index}`} className="sp-new-list" ref={news.length === index + 1 ? lastNewRef : null}>
                    <ProductItem
                      isAd={data.isAd}
                      id={data.id}
                      image={data.isAd ? data.image : data.square_thumbnail_image_url}
                      title={data.title}
                      body={data.body}
                      news_categories={(translations._language === 'jp' ? data?.types_jp?.name : data?.types?.name) || data?.types?.name}
                      path={`/news_detail/${data.id}`}
                      published={data.published}
                      created_at={data.created_at}
                      showNewBadge
                      withinHour={3}
                      newBadgeCheckDate={data.published}
                    />
                  </div>
                );
              })
            }
            {
              paginationLoading && (
                <div className='col-12 col-md-6 col-xl-4'>
                  <OnLoading pRelative noLabel />
                </div>
              )
            }
          </div> */}
        </>}
      </AppWrapper>
    )
  }
};

export default NewsList;

export const NewsCard = (props) => {
  const navigate = useNavigate();
  const { language } = useContext(appContext);
  const item = props.item || {};
  const typename = typeof item?.types === 'string' ? item.types : (language === "English" ? item?.types?.name : item?.types_jp?.name);

  const onClick = () => {
    if (!props.handleMouseMove || !props.isMoving) {
      if (props.onClick) {
        props.onClick();
      } else if (item.isAd && item.link) {

      } else {
        navigate(`/news_detail/${item.id}`)
      }
    }
  }

  const wrapper = item.isAd ? { href: item.link, target: '_blank' } : { onClick }

  return (
    <a {...wrapper}>
      <div className={`${props.containerClassName || ''}`} style={{ marginBottom: 16 }}>
        <div className=''>
          <div className="card item-card news-card" style={props.isSP ? { maxWidth: 'unset' } : { maxWidth: 200 }}>
            <div className={`card-image`}>
              <img alt="" style={{ height: props.isSP ? 195 : 110 }} className={`pointer ${props.noBorderRadius ? 'no-border-radius' : ''} ${props.imgClassName || ''}`} src={(item.isAd ? item.image : item.image_url) || require('../../assets/images/item_img.png')} />
            </div>
            <div className="card-body ">
              {item.isAd ? (<div className="d-flex flex-row justify-content-between" style={{ color: '#E0C6FF' }}>
                <span className="f-condensed-light fw-500 fs-13">{item?.title}</span>
                <span className="fw-500 fs-13" style={{ color: 'rgba(255, 255, 255, 0.91)' }}>Advertisement</span>
              </div>) : (
                <div className="d-flex flex-row justify-content-between" style={{ color: '#E0C6FF' }}>
                  <span className="f-condensed-light fw-500 fs-13">{typename}</span>
                  <span className="f-condensed-light fw-500 fs-13">{momenttz(item.published).tz(props.timeZone).format("YYYY.MM.DD HH:mm")}</span>
                </div>
              )}
              <div style={{ height: 47 }} className={`mb-0 card-title text-start pointer fw-500 f-regular fs-15 text-ellipsis line-clamp-2`}>
                {item.isAd ? item?.sub_title : item?.title}
              </div>
              {item?.body && <div className='fw-500 fs-13 f-condensed-light text-justify mt-2 mb-2 text-overflow-vertical-3' style={{ minHeight: 60, overflow: 'hidden', letterSpacing: '0.03em', lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: createMarkup(item?.body) }}></div>}
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export const TopNewCardItem = ({ item, number }) => {
  const navigate = useNavigate();

  return (
    <div key={'new-games-' + number} className="new-games-new max-w-unset">
      <div className="new-games-new-card w-100">
        <div onClick={() => navigate(`/news_detail/${item.id}`)} className='d-flex' style={{ width: 'inherit' }}>
          <img style={{ width: '60px', height: '60px', borderRadius: '5px', marginRight: '10px', objectFit: 'cover' }} src={item?.image_url || require('../../assets/images/item_img.png')} alt="" />
          <div className='d-flex flex-column' style={{ maxWidth: 130 }}>
            <span className='mb-1 new-games-new-card-title tx-ellipsis'>{item?.game_categories?.name}</span>
            <span className='mb-1 new-games-new-card-name tx-ellipsis'>{item?.title}</span>
            <span className='new-games-new-card-text tx-ellipsis'>{item?.short_body}</span>
          </div>
        </div>
        {/* <div className='d-flex flex-column'>
          {!item.url ?
            <a className="mb-1 d-flex align-items-center">
              <div className="btn btn-custom-nonactive new-games-new-card-btn">PLAY</div>
            </a>
            :

            <a href={item.url} className="mb-1 d-flex align-items-center"><div className="btn btn-custom-active new-games-new-card-btn">PLAY</div></a>
          }
          <span className='new-games-new-card-purchase'>{item?.game_makers?.name}</span>
        </div> */}
        <div className='new-games-number'><span>{number}</span></div>
      </div>
    </div>
  );
}