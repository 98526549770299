import { useContext, useEffect, useState } from "react";
import { translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";
import { CloseIcon, DropdownIcon, MinusIcon, PlusIcon, SearchThinIcon } from "../../components/common/Icon"
import { motion } from "framer-motion";
import { getData } from "../../services/fetch";
import { parseToExecutableHTML } from "../../components/common/commonFnc";
import { useMediaQuery } from "react-responsive";
import { Button } from '../squares/Button';
import Dropdown from '../../components/common/Dropdown';
import ReactStyleBlocker from '../../components/common/ReactStyleBlocker';
import { appContext } from "../../context/appContext";

export const FAQ = () => {

    const { language } = useContext(appContext);
    const [faqItems, setFAQItems] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [key, setKey] = useState('');
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);

    const content = translations.faq;
    const shortLang = language === "English" ? "en" : "jp";

    const fetchCategories = async () => {
        const response = await getData(`/faq-category?language=${shortLang}`);
        if (response.ok) {
            setCategories(response?.data?.data);
            const options = [];
            for (const it of response.data.data) {
                if (it.sub_categories) {
                    options.push({ key: it.id, value: it.id, label: it.name, group: true });
                    for (const sub of it.sub_categories) {
                        options.push({ key: sub.id, value: sub.id, label: sub.name });
                    }
                } else {
                    options.push({ key: it.id, value: it.id, label: it.name });
                }
            }
            setCategoryOptions(options);
        }
    }

    const fetchFAQs = async (category, key, lang) => {
        const response = await getData(`/faq?faq_sub_category_id=${category?.value || ''}${key ? '&key=' + key : ''}&language=${lang}`)
        if (response.ok) {
            setFAQItems(response?.data?.data);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (!categories.length) {
                    await fetchCategories();
                }
                await fetchFAQs(selectedSubCategory, key, shortLang);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
        if (language) {
            fetchData();
        }
    }, [selectedSubCategory, language]);

    const isPC = useMediaQuery({ minWidth: 992 });

    return (
        <>
            {isPC ? null : (
                <>
                    <h1 className="text-center">FAQ</h1>
                    <div className="divider" style={{ borderWidth: 0.5 }}></div>
                </>
            )}
            <div className={`d-flex py-4 ${isPC ? 'ps-3 pe-5' : 'px-3'}`}>
                {isPC && <div className="d-flex flex-column" style={{ maxWidth: '50%' }}>
                    <h1>FAQ</h1>
                    <div className="mt-2 d-grid pe-3" style={{ gridColumn: '1fr', width: 'fit-content' }}>
                        {
                            categories.map((category) => {
                                return (
                                    category.name ? <MenuGroup
                                        category={category}
                                        selectedSubCategory={selectedSubCategory}
                                        setSelectedSubCategory={setSelectedSubCategory} /> : null
                                )
                            })
                        }
                    </div>
                </div>}
                <div className={`d-flex flex-column ${isPC ? 'ms-5' : ''}`} style={{ flexGrow: 1 }}>
                    <div className={`w-100 d-flex mt-2 ${isPC ? "justify-content-end" : "flex-center"}`}>
                        <div className="d-flex flex-center flex-column" style={{ maxWidth: 280 }}>
                            <div className="d-flex">
                                <input
                                    placeholder={content.keywords}
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)}
                                    onKeyDown={async (e) => {
                                        if (e.key === 'Enter') {
                                            withTryCatch(
                                                fetchFAQs,
                                                setLoading
                                            )(selectedSubCategory, key, shortLang);
                                        }
                                    }}
                                    className="fs-18"
                                    style={{
                                        minWidth: 250,
                                        color: 'white',
                                        backgroundColor: 'transparent',
                                        outline: 0,
                                        border: 0,
                                        opacity: key ? 1 : 0.5,
                                    }} />

                                {key ?
                                    <CloseIcon
                                        className='search-icon'
                                        style={{ stroke: '#BF96FF' }}
                                        onClick={() => {
                                            setKey('');
                                            withTryCatch(
                                                fetchFAQs,
                                                setLoading
                                            )(selectedSubCategory, '', shortLang);
                                        }} /> :
                                    <SearchThinIcon />}
                            </div>
                            <div className="divider w-100"></div>
                        </div>
                    </div>

                    {!isPC && <div style={{ width: 280, margin: '20px auto' }}>
                        <Dropdown
                            width="280px"
                            closeOnSelect
                            value={selectedSubCategory}
                            placeholder={content.selectCategory}
                            options={categoryOptions}
                            onChange={(option) => {
                                setSelectedSubCategory(option);
                            }}
                            listBgColor="linear-gradient(192.05deg, #4B225C 23.44%, #19162D 50%, #19162D 100%)" />
                    </div>}
                    <div className="mt-4"></div>
                    {loading ? <OnLoading inline noLabel /> : (
                        <div className="d-flex flex-column" style={{ gap: 20 }}>
                            {faqItems.map((faq, index) => <FAQItem
                                onImageClick={(src) => setSelectedImage(src)}
                                key={faq.id}
                                number={index + 1 < 10 ? `0${index + 1}` : index + 1}
                                question={faq.question}
                                answer={faq.answer} />)}
                        </div>
                    )}
                </div>
            </div>
            <ImagePreview src={selectedImage} onClose={() => setSelectedImage(null)} />
        </>
    )
}

const FAQItem = ({ number, question, answer, onImageClick = () => { } }) => {
    const [open, setOpen] = useState(false);

    return (
        <div
            className="d-flex flex-column faq-card">
            <div
                onClick={() => setOpen(prev => !prev)}
                className="d-flex px-2 align-items-center"
                style={{ gap: 15, minHeight: 55 }}>
                <span className="fs-20">{number}</span>
                <span className="fs-15 text-ellipsis line-clamp-2 fw-500">{question}</span>
                <div className="d-flex flex-center" style={{ minWidth: 30, marginInlineStart: 'auto' }}>
                    {open ? <MinusIcon /> : <PlusIcon />}
                </div>
            </div>
            <motion.div
                initial={false}
                variants={{
                    open: { height: 'initial', opacity: 1 },
                    close: { height: 0, opacity: 0 }
                }}
                transition={{
                    duration: 0.3,
                }}
                animate={open ? 'open' : 'close'}
                className={`overflow-hidden`}>
                <div className="fw-400 px-4 text-justify pb-2 mt-2" style={{ color: 'rgba(255,255,255,0.91)' }}>
                    <ReactStyleBlocker style={{ fontSize: 14 }} onImageClick={onImageClick}>
                        <div dangerouslySetInnerHTML={{ __html: parseToExecutableHTML(answer) }}></div>
                    </ReactStyleBlocker>
                </div>
            </motion.div>
        </div>
    )
}

const MenuGroup = ({ category, selectedSubCategory, setSelectedSubCategory }) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <MenuItem
                id={category.id}
                name={category.name}
                selected={open}
                onClick={() => {
                    setOpen(prev => !prev);
                }} />
            {open ? category.sub_categories?.map(subCategory => {
                const isSubSelected = selectedSubCategory?.value == subCategory.id;
                return (
                    <MenuItem id={subCategory.id}
                        sub
                        name={subCategory.name}
                        selected={isSubSelected}
                        hideArrow
                        onClick={() => setSelectedSubCategory({ value: subCategory.id, label: subCategory.name })} />
                )
            }) : null}
        </>
    )
}

const MenuItem = ({
    selected,
    name,
    id,
    sub,
    hideArrow,
    onClick
}) => {

    const { language } = useContext(appContext);

    const isEng = language === "English";

    return (
        <Button
            icon={
                (!hideArrow) ? (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: '100%' }}>
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                rotate: selected ? '0deg' : '-90deg',
                                transition: 'all 120ms ease-in',
                                width: '18px',
                                height: '18px'
                            }}>
                            <DropdownIcon className="dropdown-icon-sm" />
                        </div>
                    </div>) : null
            }
            onClick={onClick}
            childrenClassName="justify-content-start"
            iconWrapperClassName="position-absolute top-50 start-100 translate-middle-y h-100 aspect-ratio-1"
            className="border-0 max-w-unset position-relative"
            key={id}>
            <span
                data-toggle="tooltip" data-placement="top" title={`${name || ''}`}
                className={`overflow-hidden text-ellipsis ${isEng ? 'mb-1 fs-15' : ''} ${selected ? '' : 'text-white'} ${sub ? 'ps-4' : ''}`}
            >{name}</span>
        </Button>
    )
}

const ImagePreview = ({
    src,
    onClose,
}) => {
    const isPC = useMediaQuery({ minWidth: 992 });

    return (
        src ? (
            <>
                <div class="modal-backdrop fade show"></div>
                <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} onClick={onClose}>
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: isPC ? '1000px' : 'unset' }}>
                        <div className="modal-content" style={{ maxWidth: isPC ? '1000px' : 'unset' }}>
                            <img
                                src={src}
                                alt="full-size"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </>
        ) : null
    )
}

export const withTryCatch = (fn, setLoading = () => { }) => {
    return async function (...params) {
        try {
            setLoading(true);
            await fn(...params);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }
}