import { useState, useCallback } from 'react';
import { useWindowResize } from './useWindowResize';

export function useOverflowDetection() {
    const [overflowX, setOverflowX] = useState(false);
    const [overflowY, setOverflowY] = useState(false);
    const resizedWindow = useWindowResize();
    
    const containerRef = useCallback(node => {
        if (node !== null) {
            let totalChildWidth = 0;
            let totalChildHeight = 0;
            const containerWidth = node.clientWidth;
            const containerHeight = node.clientHeight;
    
            Array.from(node.children).forEach(child => {
                totalChildWidth += child.clientWidth;
                totalChildHeight += child.clientHeight;
            });
    
            setOverflowX(totalChildWidth > containerWidth);
            setOverflowY(totalChildHeight > containerHeight);
        }
    }, [resizedWindow]);

    return { overflowX, overflowY, containerRef };
}
