import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../services/fetch";

const initialState = {
  squareDetail:null,
  loading:false,
  previous_square_id:null
}

export const getSquareAsync = createAsyncThunk(
    'get/square',
    async ({ id, language })=>{
        const response = await getData(`square/${id}?language=${language}`);
        return response.data.data;
    }
)

const squareSlice = createSlice({
  name:'square',
  initialState:initialState,
  reducers:{
    setSquareDetail(state, action){
      state.squareDetail = action.payload
    }
  },
  extraReducers:(builder)=>builder
  .addCase(getSquareAsync.pending,(state)=>{
    state.loading=true;
  })
  .addCase(getSquareAsync.fulfilled,(state,action)=>{
    state.loading=false;
    state.squareDetail=action.payload;
    state.previous_square_id=action.payload.id;
  })
  .addCase(getSquareAsync.rejected,(state)=>{
    state.loading=false;
  })
})
  
export const { setSquareDetail } = squareSlice.actions;

export default squareSlice;