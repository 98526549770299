import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { DrawChainHeader, TitleWithBackButton } from "./drawChains";
import { TokenType } from "../../components/common/enum";
import { isJP, isObjectEmpty } from "../../components/common/commonFnc";
import { OnLoading } from "../../components/common/OnLoading";
import moment from "moment";
import { ItemDesk } from "../wallet/WalletAbsorb";
import { translations } from "../../services/localization";
import { CustomButton } from "../../components/common/Button";
import { useFetch, useLoading } from "../../services/hook";
import { Button } from "../squares/Button";
import { useIntersection } from "../../hooks/useIntersection";
import { getData } from "../../services/fetch";
import { useSelector } from "react-redux";

function DrawChainDetail() {

    const navigate = useNavigate();
    const [necessaryPersona, setNecessaryPersona] = useState(undefined)
    const language = translations._language;
    const isPC = useMediaQuery({ minWidth: 992 });
    const isPh = useMediaQuery({ maxWidth: 480 });
    const { square_id, draw_chain_id } = useParams();

    const buttonlist = translations.buttonlist;
    const drawchainList = translations.drawChainList;
    const squareDetail = useSelector(state => state.square.squareDetail);
    const [item, setItem] = useState({});
    const [drawchainsLoading, setDrawchainsLoading] = useState(true);

    // const { data: item = {}, isLoading: drawchainsLoading } = useFetch(`drawchain/${draw_chain_id}`);
    const loading = useLoading([drawchainsLoading, !item, !squareDetail?.square_address, !necessaryPersona], true);
    const fetchDrawchain = async (draw_chain_id) => {
        setDrawchainsLoading(true);
        const response = await getData(`drawchain/${draw_chain_id}`);
        if (response.ok) {
            setItem(response.data.data);
            setDrawchainsLoading(false);
        }
    }
    useEffect(() => {
        if (squareDetail && draw_chain_id) {
            if (squareDetail.draw_chains && squareDetail.draw_chains.filter(x => x.id === Number(draw_chain_id)).length > 0) {
                fetchDrawchain(draw_chain_id);
            } else {
                navigate(`/squares/${square_id}`)
            }
        }
    }, [squareDetail, draw_chain_id])

    useEffect(() => {
        if (item) {
            setNecessaryPersona(item.persona)
        }
    }, [item])

    const handleDrawChain = () => {
        navigate(`/squares/${square_id}/draw-chains/persona/list`, { state: { draw_chain_id, squareDetail } })
    }

    const { ref, entry } = useIntersection({
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    const { ref: titleRef, entry: titleEntry } = useIntersection({
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    const isEng = language === "en";

    return (
        <>
            {
                loading ? <OnLoading inline marginAuto /> : <>
                    <div className="w-100 position-relative">
                        {!titleEntry?.isIntersecting && (
                            <DrawChainHeader
                                name={isEng ? item?.title_en : item.title}
                                tailButton={(!isPC && !entry?.isIntersecting && entry?.boundingClientRect?.y <= 0) &&
                                    (<Button
                                        className="br-10px"
                                        style={{ height: 47, width: 100 }}
                                        active
                                        onClick={handleDrawChain}
                                        disabled={item?.draw_button === "non-active"} >
                                        <span className="fs-12">{buttonlist.drawBtn}</span>
                                    </Button>)
                                } />)}

                        <div className={`w-100 mt-4 mb-4 ${isPC ? 'position-sticky' : ''}`} style={{ top: 0, zIndex: 2, isolation: 'isolate' }} ref={titleRef}>
                            <TitleWithBackButton />
                        </div>

                        <div>
                            <DrawChainCoverImg src={isPh ? item?.image_url_sp : item?.image_url} />
                            <div className="w-450 px-3 py-2" ref={ref}>
                                <CustomButton
                                    disabled={item?.draw_button === "non-active"}
                                    handleClick={handleDrawChain}
                                    btnClassName="btn btn-custom-active my-3 py-1 w-100"
                                    label={translations.formatString(buttonlist.drawChainBtn)}
                                />
                            </div>
                            <div className={`d-flex ${isPC ? '' : 'flex-column'}`} style={{ gap: 30 }}>
                                <div className="d-flex flex-column py-2 px-3">
                                    <span className="primary-color fs-22 f-regular py-2">
                                        {isJP() ? item.title : item.title_en}
                                    </span>
                                    {item.from_date ? (
                                        <span className="fs-20 f-condensed py-2">
                                            {item.from_date ? moment(item.from_date).format('YYYY.MM.DD') : ''} -
                                            {item.from_date ? moment(item.to_date).format('YYYY.MM.DD') : ''}
                                        </span>) : null}
                                    <div className="fs-18 f-condensed py-2 reset-spacing " dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                </div>
                                {/* Persona */}
                                <div className={`px-3 py-2 mb-4 ${isPC ? 'd-flex form-wrapper flex-column me-4' : 'mx-3'}`}>
                                    <span className="primary-color f-expended fs-25 fw-500 py-2">
                                        {
                                            translations.formatString(drawchainList.persona)
                                        }
                                    </span>
                                    {
                                        necessaryPersona && !isObjectEmpty(necessaryPersona) &&
                                        <ItemDesk item={Object.assign(necessaryPersona, {
                                            FOR: necessaryPersona.for,
                                            ABS: necessaryPersona.abs,
                                            DFT: necessaryPersona.dft,
                                            MND: necessaryPersona.mnd,
                                            INT: necessaryPersona.int,
                                            EXP: necessaryPersona.exp,
                                            token_type_id: TokenType.Persona,
                                        })} className="m-t-27" cardFirst hidePrices />
                                    }
                                </div>
                            </div>
                            <div className="w-450 px-3 py-2">
                                <CustomButton
                                    disabled={item?.draw_button === "non-active"}
                                    handleClick={handleDrawChain}
                                    btnClassName="btn btn-custom-active my-3 py-1 w-100"
                                    label={translations.formatString(buttonlist.drawChainBtn)}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default DrawChainDetail

export const DrawChainCoverImg = ({ src }) => {
    const isPh = useMediaQuery({ maxWidth: 480 });

    return (
        isPh ? (
            <div className="w-100" style={{
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: 220
            }}></div>
        ) : <img
            style={{
                objectFit: "cover",
                objectPosition: "top",
                aspectRatio: '13/2',
            }}
            src={src}
            className="w-100"
        />
    )
}