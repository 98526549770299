import React, { useContext, useEffect, useState } from 'react'
import BottomNav from '../../layouts/mainlayout/BottomNav'
import Header from '../../layouts/mainlayout/Header'
import ScrollToTop from '../common/ScrollToTop'
import SidebarWrapper from './SidebarWrapper'
import { getToken, setDoNotShowAgain, getDoNotShowAgain } from '../../services/storage'
import { LeviasFooter } from '../common/Footer'
import { strings, translations } from "../../services/localization";
import { useMediaQuery } from 'react-responsive'
import { LeviasSwalIDMsg } from "../../components/common/Swal";
import { ReactComponent as GradientSVG } from '../../assets/icons/gradient.svg';
import { ReactComponent as Gradient2SVG } from '../../assets/icons/gradient2.svg';
import { appContext } from '../../context/appContext'
import { Helmet } from "react-helmet"
import { REACT_APP_ENV } from "../../config";
import { isChrome } from 'react-device-detect'

const AppWrapper = ({ children, disableHeader, hideNav, squareActive, newsActive, offMainbody, gamesActive, disableSidebar, offPaddingBot, disableSearchbox, pageid, className, title, enableAddtionalNav, additionalBottom, hideBottomNav, activeProfile, accountActive, smallFont, noLayout = false, activeWallet, homeActive, offOverFlowX, isForm, isCenter, isLeft, disableFooter, disableBottomNav, positionFixed, disabled, isLeviasLayout, isLeviasHandler, subNavItems, enableMobileNav, enableMobileSearch, onSearch, scrollableHeader, gameDetailObj, mainbodyClassName, leviasIDMsg, isCustomDetailPage, isLeviasTeamAndPrivacy, isNoSpaceAround, topicsActive, leviasPcBlockClass, isFilter, isSort, noPadding, hideToolbar, sidebarWrapperClassName, sidebarWrapperDivClassName, disableSideContentScroll, mLeft, width, metaTitle, metaDescription, isSpecCodeD, subNavOnChange, isDrawchainDetail }) => {

    const [onShow, setOnShow] = useState(false)
    const [notSignIn, setNotSignIn] = useState(false)
    const [scrollPoint, setScrollPoint] = useState(0)

    const loginLeviasIdMsgModal = translations.loginLeviasIdMsgModal;
    const commonlist = translations.common;
    const [onLoad, setOnload] = useState(false)
    const { hasInput, showRecentSearch, language } = useContext(appContext);

    const onGetToken = async () => {
        var token = await getToken()
        if (token) {
            setOnShow(true)
            setNotSignIn(false)
        } else {
            setNotSignIn(true)
        }
    }

    useEffect(() => {
        onGetToken()

        // If has result token, don't show showLeviasIDMsg modal bos
        let url_string = `${window.location}`
        let url = new URL(url_string);
        url_string = url_string.replace(url?.hash?.substring()[0], '?')
        url = new URL(url_string)
        const hasResultToken = url.searchParams.get("result") ? false : true
        if (hasResultToken && leviasIDMsg) {
            showLeviasIDMsg()
        }
    }, [])

    useEffect(() => {
        if (language) {
            const langClass = translations.formatString(translations.common.langClass);
            if (document.body && langClass) {
                document.body.className = langClass;
            }
        }
    }, [language])

    const showLeviasIDMsg = async () => {
        const loginNoShowAgain = getDoNotShowAgain()
        if (loginNoShowAgain === 'true') {
            return
        }
        LeviasSwalIDMsg.fire({
            html: `
            <h3>${strings.formatString(loginLeviasIdMsgModal.LEVIAS_ID)}</h3>
            <p style="text-align: center;">${strings.formatString(loginLeviasIdMsgModal.For_using_the_services_of_ANICANA_θupa_you_need_your_LEVIAS_ID_provided_by_LEVIAS_INC)}</P>
           `,
            confirmButtonText: strings.formatString(loginLeviasIdMsgModal.NEXT),

        }).then((result) => {
            setDoNotShowAgain('true')
        })
    }

    const gameDetailHeader = (val) => {
        setScrollPoint(val)
    }

    const isPh = useMediaQuery({ maxWidth: 576 })
    const isPC = useMediaQuery({ minWidth: 992 })

    // const getClassNameByDevice = () => {
    //     let default_classname = '';
    //     if (!disableHeader && !gamesActive && !activeWallet && !topicsActive && hasInput) {
    //         if (isMobile) {
    //             if (isSafari) {
    //                 console.log('is safari')
    //                 default_classname += 'safari-only ';
    //             } else if (isChrome) {
    //                 console.log('is chrome')
    //                 default_classname += 'chrome-only';
    //             }
    //         }
    //     }
    //     return default_classname
    // }

    // const template_classname = getClassNameByDevice();

    const browser_type_css = isChrome ? 'isChrome' : '';

    let template =
        <div id={isLeviasLayout ? (isPh ? 'levias' : 'levias-pc') : isSpecCodeD ? 'code-wrapper' : 'anicana-portal'} className={`layout ${hasInput && !scrollableHeader && !disableHeader ? 'hasInput' : ''} ${translations.formatString(commonlist.langClass)} ${browser_type_css}`}>
            <div className={`app-wrapper levias-app-wrapper ${className || ''} ${scrollableHeader ? 'scrollable-app-wrapper' : ''}`} id="app-wrapper" style={disabled ? { pointerEvent: 'none' } : {}}>
                <div className=''>
                    {
                        !disableHeader &&
                        <Header noLayout={noLayout} hideNav={hideNav} enableMobileNav={enableMobileNav} onSearch={onSearch} enableMobileSearch={enableMobileSearch} isLeviasLayout={isLeviasLayout} isLeviasHandler={isLeviasHandler} notSignIn={notSignIn} smallFont={smallFont} title={title} disableSearchbox={disableSearchbox} enableAddtionalNav={onShow && true} activeProfile={activeProfile} activeWallet={activeWallet} scrollableHeader={scrollableHeader} gameDetailObj={gameDetailObj} scrollPoint={scrollPoint} isCustomDetailPage={isCustomDetailPage} hideToolbar={hideToolbar} pageid={pageid} isSpecCodeD={isSpecCodeD} isDrawchainDetail={isDrawchainDetail} />
                    }
                    <div className={showRecentSearch ? 'body-overlay' : ''}>
                        {
                            !disableSidebar ?
                                <SidebarWrapper isFilter={isFilter} isSort={isSort} subNavItems={subNavItems} subNavOnChange={subNavOnChange} offOverFlowX={offOverFlowX} positionFixed={positionFixed} pageid={pageid} sideContentStyle={{ paddingLeft: 16, paddingRight: 16, paddingBottom: offPaddingBot ? offPaddingBot : noLayout ? '80' : additionalBottom ? 220 : 100 }} hasAdditionalBottom={!!additionalBottom} scrollableHeader={scrollableHeader} gameDetailHeader={gameDetailHeader} isCustomDetailPage={isCustomDetailPage} scrollPoint={scrollPoint} isNoSpaceAround={isNoSpaceAround} noPadding={noPadding ? 'padding-0' : ''} sidebarWrapperClassName={sidebarWrapperClassName} disableSideContentScroll={disableSideContentScroll}>
                                    <div className={`px-lg-0 ps-lg-0 pt-lg-0 ${isCustomDetailPage || isNoSpaceAround ? '' : ' pt-4'} ${sidebarWrapperDivClassName || ''}`}>
                                        <div className='side-content-wrapper'>
                                            <div className={isCenter ? "flex-center" : isLeft ? "flex-center-left" : ""}>
                                                <div className={`levias-pc-block levias-pc-block-term-and-privacy ${leviasPcBlockClass || ''} ${isForm ? "form-wrapper" : ""}`}>
                                                    <div className='levias-pc-blur'></div>
                                                    <div id="main-content">
                                                        {children}
                                                    </div>
                                                </div>
                                            </div>
                                            {additionalBottom && isPC && (
                                                <div className='d-flex align-items-center justify-content-center'
                                                    style={{
                                                        marginLeft: mLeft + 'px',
                                                        marginTop: '-80px',
                                                        width: width + 'px'
                                                    }}
                                                >
                                                    <div className="pb-2 pt-3"
                                                        style={{
                                                            width: 300,
                                                        }}
                                                    >
                                                        {additionalBottom}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </SidebarWrapper> :
                                <div id="mainbody" style={{ paddingBottom: offPaddingBot ? offPaddingBot : noLayout ? '80' : additionalBottom ? 220 : 80 }} className={`${mainbodyClassName || ''} ${offMainbody ? 'offMainbody' : 'mainbody'} ${isLeviasTeamAndPrivacy ? 'mainbody-term-and-pripacy' : ''}`}>
                                    <div className={isCenter ? "flex-center" : isLeft ? "flex-center-left" : ""}>
                                        <div className={`levias-block levias-pc-block ${leviasPcBlockClass || ''} ${isLeviasTeamAndPrivacy ? 'levias-pc-block-term-and-privacy' : ''} ${isForm ? "form-wrapper" : ""}`}>
                                            <div className='levias-pc-blur'></div>
                                            <div id="main-content">
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                    {additionalBottom && isPC && (
                                        <div className="card d-none d-md-block py-2 bottom-card">
                                            <div className="pb-2 pt-3">{additionalBottom}</div>
                                        </div>
                                    )}
                                    {!noLayout && !disableFooter
                                        ? isLeviasLayout
                                            ? <LeviasFooter />
                                            : <></>
                                        : <></>
                                    }
                                </div>
                        }
                        {!noLayout && !disableBottomNav && !isLeviasLayout ?
                            <BottomNav gamesActive={gamesActive} homeActive={homeActive} squareActive={squareActive} newsActive={newsActive} additionalBottom={additionalBottom} hideBottomNav={hideBottomNav} accountActive={accountActive} pageid={pageid} isPh={isPh} />
                            : <></>
                        }
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', top: -500 }}>
                <GradientSVG />
                <Gradient2SVG />
            </div>
        </div>

    const titleSEO = metaTitle || 'ANICANAテュラー'
    const descriptionSEO = metaDescription || 'ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <ScrollToTop>
            <Helmet>
                <title>{titleSEO}</title>
                <meta name="title" content={titleSEO} />
                <meta name="description" content={descriptionSEO} />
                <meta property="og:title" content={titleSEO} />
                <meta property="og:description" content={descriptionSEO} />
                {REACT_APP_ENV !== 'production' && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>

            {/* {
                pageid === 'Home' &&
                <video loop autoPlay muted mute='true' id="myVideo">
                    <source src='./Anicana_FirstView_noOverlay_0723.mp4' type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            } */}
            {template}
        </ScrollToTop>
    )
}

export default AppWrapper
