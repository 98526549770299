import React from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'

const WalletSendComplete = () => {
    return (
        <AppWrapper>
            <div className='flex-center w-100' style={{ height: '50vh' }}>
                <h5 className='fw-bold'>送信完了</h5>
            </div>
        </AppWrapper>
    )
}

export default WalletSendComplete