import React, { useContext, useEffect, useRef, useState } from 'react'
import { appContext } from '../../context/appContext'
import { translations } from "../../services/localization";
import AppWrapper from '../../components/wrappers/AppWrapper'
import axios from "axios";
import { API_DOMAIN } from "../../config";
import { OnLoading } from '../../components/common/OnLoading'
import { TopicItem } from '../../components/common/Item'
import { useMediaQuery } from "react-responsive";
import { getData } from "../../services/fetch";

const TrendList = () => {
    const { language, timeZone } = useContext(appContext)
    const cardRef = useRef()

    const [news, setNews] = useState();
    const [loading, setLoading] = useState(true);
    const content = translations.trendList
    const [pageId, setPageId] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();

    const shortForm = language === 'English' ? 'en' : 'jp'

    const fetchNewData = (category) => {
        setPageId('Trend');
        setLoading(true);
        const url = API_DOMAIN + "tokens/get-trends?language=" + shortForm + "&timezone=" + timeZone
            + (category ? '&category_id=' + category.id : '');
        axios
            .get(url)
            .then((response) => {
                if (response.data) {
                    const trendgameData = response.data.data.trend_games;
                    const trendnewsData = response.data.data.trend_news;
                    const conbimetrends = [...trendgameData, ...trendnewsData];
                    setNews([...conbimetrends].sort((a, b) => a.trend - b.trend));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const fetchCategories = async () => {
        const response = await getData(`trend/news-categories?language=${shortForm}`);
        if (response.ok) {
            setCategories(response.data.data);
        }
    }

    const handleSubNavOnChange = (category) => {
        setCategory(category);
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        if (language && timeZone) {
            fetchNewData(category);
        }
    }, [language, timeZone, category])

    const metaTitle = 'トレンド｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のトレンド一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    const isPh = useMediaQuery({ maxWidth: 480 })

    return (
        <AppWrapper
            title={translations.formatString(content.headerTitle)}
            pageid={pageId}
            sidebarWrapperDivClassName={'p-t-0 m-t--24'}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            noPadding
            isNoSpaceAround
            subNavItems={categories}
            subNavOnChange={handleSubNavOnChange}>
            {
                loading ? <OnLoading /> : (news && news.length > 0 && (
                    <div className={`mt-4 responsive-grid space-${isPh ? 10 : 15}px`}>
                        {
                            news.map((item, index) => (
                                <TopicItem key={item.id} item={item} cardRef={cardRef} />
                            )
                            )
                        }
                    </div>)
                )
            }

        </AppWrapper>
    )
}

export default TrendList
