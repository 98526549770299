import React from 'react'

import AppWrapper from '../wrappers/AppWrapper'
import { Btn } from './Button'

function Notifier() {
  return (
    <div className='app-wrapper notifier-wrapper'>
      <div>
        <div></div>
        <div>
          A URL for password reset will be sent to your registered email address.
        </div>
        <div>
          <Btn intent="none" className='notifier-btn' style={{ color: '#FFF' }}>
            Ok
          </Btn >
        </div>
      </div>
    </div>
  )
}

export default Notifier