import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_DOMAIN } from "../../config";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { SwalCorrect, SwalEmail, SwalWrong } from "../../components/common/Swal";
import { strings, translations } from "../../services/localization";

import {
    ChangeMobileForm,
    MobileVerifyForm,
    PasswordResetForm,
    SetPasswordResetForm,
    TwoFactorVerifyForm,
} from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {BottomModal, LeviasBottomModal} from "../../components/common/BottomModal";
import { getToken } from "../../services/storage";
import { postData } from "../../services/fetch";


function ChangeMobile() {
    const content = translations.changeMobileForm;
    const buttonlist = translations.buttonlist;
    const messagelist = translations.message;
    const changeMobileVerificationPage = translations.changeMobileVerificationPage; // Usage: strings.formatString(changeMobileVerificationPage.change_mobile_number)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [emailValidated, setEmailValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const language = useSelector((state) => state.language.language);
    const [email, setEmail] = useState("");
    // const [emailValid, setEmailValid] = useState(false)
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [phoneCodeValid, setPhoneCodeValid] = useState(false);

    const [smsVerificationCode, setSmsVerificationCode] = useState("");
    const [idmsRegistrationStep, setIdmsRegistrationStep] = useState("setPhone");
    const [onShow, setOnShow] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState("");
    const [phoneValid, setPhoneValid] = useState(false)
    const [transactionId, setTransactionId] = useState('');

    const onShowHandler = (type, status) => {
        if (type === "password") {
            setShowPassword(status);
        } else if (type === "confirm-password") {
            setShowConfirmPassword(status);
        }
    };

    const passwordValidator = (value, result) => {
        if (value === "password") {
            if (result.length > 7) {
                if (result === confirmPassword) {
                    setPasswordValid(true);
                }
            }
        } else if (value === "con-password") {
            if (result.length > 7) {
                if (password === result) {
                    setPasswordValid(true);
                }
            }
        }
    };

    const textChangeHandler = (type, value) => {
        if (type === "email") {
            setEmail(value);
            emailValidator(type, value);
        } else if (type === "phone") {
            setPhoneNumber(value);
            phoneValidator("phone", value);
        } else if (type === "confirm-phone") {
            setConfirmPhoneNumber(value);
            phoneValidator("con-phone", value);
        } else if (type === "verify-phone-code") {
            if (value.length > 5) {
                setPhoneCodeValid(true);
            }
            else {
                setPhoneCodeValid(false);
            }
            setSmsVerificationCode(value);
        } else if (type === "password") {
            setPassword(value);
            passwordValidator("password", value);
        } else if (type === "confirm-password") {
            setConfirmPassword(value);
            passwordValidator("con-password", value);
        }
    };

    const onEmailVerifyHandler = () => {
        setIdmsRegistrationStep('phoneVerify')
    };

    const onShowModalHandler = () => {
        setOnShow(true);
    };

    const onCloseHandler = () => {
        setOnShow(false);
    };

    const emailValidator = () => {
        setEmailValidated(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
    };

    const sendSmsHandler = async () => {
        var token = await getToken()
        const url = API_DOMAIN + 'accounts/change-mobile'
        axios
            .post(
                url,
                {
                    mobile: phoneNumber,
                    confirmMobile: confirmPhoneNumber
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data) {
                    if (response.data.response.errorMessage) {
                        setLoading(false);
                        let message = response.data.response.errorMessage;
                        SwalWrong.fire({
                            title: "Error",
                            text: message,
                        });
                        // } else if (response.data.message) {
                        //   setLoading(false);
                        //   SwalCorrect.fire({
                        //     title: `You have been successfully signed out`,
                        //   }).then((result) => {

                        //   });
                    } else if (response.data) {
                        setTransactionId(response.data.response.transactionId);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.data && error.response.data.message) {
                    SwalWrong.fire({
                        title: "Error",
                        text: error.response.data.message
                    });
                }
                else if (error.response.data && error.response.data.reason) {
                    SwalWrong.fire({
                        title: "Error",
                        text: error.response.data.reason
                    });
                }
            })
    }

    useEffect(() => {
        if (transactionId) {
            setIdmsRegistrationStep('phoneVerify');
        }
    }, [transactionId])

    const nextStepHandler = async () => {
        if (idmsRegistrationStep === 'setPhone') {
            sendSmsHandler()
        }
        else if (idmsRegistrationStep === 'phoneVerify') {
            var response = await postData(`accounts/verify-change-mobile`, {
                otp: smsVerificationCode,
                transactionId,
                mobile: phoneNumber
            });
            if (response.ok) {
                SwalCorrect.fire({
                    text: strings.formatString(changeMobileVerificationPage.Your_mobile_number_has_been_successfully_changed),
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/account_detail");
                    }
                });
            }
        }
    }

    const phoneValidator = (value, result) => {
        var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        if (value === "phone") {
            if (phoneno.test(result) && phoneno.test(confirmPhoneNumber) && confirmPhoneNumber === result) {
                setPhoneValid(true);
            }
        } else if (value === "con-phone") {
            if (phoneno.test(result) && phoneno.test(phoneNumber) && result === phoneNumber) {
                setPhoneValid(true);
            }
        }
        else {
            setPhoneValid(false)
        }
    }

    let body = (
        <>
            <ChangeMobileForm
                showPassword={showPassword}
                showConfirmPassword={showConfirmPassword}
                confirmPhoneNumber={confirmPhoneNumber}
                onShowHandler={onShowHandler}
                emailValidated={emailValidated}
                setEmailValidated={setEmailValidated}
                onEmailVerifyHandler={onEmailVerifyHandler}
                emailValidator={emailValidator}
                textChangeHandler={textChangeHandler}
                phoneNumber={phoneNumber}
                phoneValid={phoneValid}
                smsVerificationCode={smsVerificationCode}
                nextStepHandler={nextStepHandler}
            />
        </>
    );
    if (idmsRegistrationStep === "phoneVerify") {
        body = (<MobileVerifyForm
            setIdmsRegistrationStep={setIdmsRegistrationStep}
            onShowModalHandler={onShowModalHandler}
            phoneCodeValid={phoneCodeValid}
            smsVerificationCode={smsVerificationCode}
            nextStepHandler={nextStepHandler}
            textChangeHandler={textChangeHandler}
        />)
    } else if (idmsRegistrationStep === "setPassword") {
        body = (
            <SetPasswordResetForm
                showPassword={showPassword}
                showConfirmPassword={showConfirmPassword}
                onShowHandler={onShowHandler}
                onShowModalHandler={onShowModalHandler}
                nextStepHandler={nextStepHandler}
                passwordValid={passwordValid}
                password={password}
                confirmPassword={confirmPassword}
                textChangeHandler={textChangeHandler}
            />
        )
    }

    return (
        <>
            <BottomModal onCloseHandler={onCloseHandler} onShow={onShow} />
            <AppWrapper title={strings.formatString(content.headerTitle)} isForm isCenter hasInput>
                <Container fluid className="d-flex flex-row auth-container">
                    {body}
                </Container>
            </AppWrapper>
        </>
    );
}

export default ChangeMobile;
