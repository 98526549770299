import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedItems: [],
    isSelectionMode: false,
}

const myArcanaSlice = createSlice({
    name: 'myArcana',
    initialState: initialState,
    reducers: {
        toggleSelectionMode(state, action) {
            state.isSelectionMode = !state.isSelectionMode;
        },
        setSelectedItem(state, action) {
            if(state.selectedItems.find(item => item.token_id === action.payload.token_id)===undefined){
                state.selectedItems.push(action.payload);
            }
        },
        clearItems(state, action) {
            state.selectedItems = [];
        },
        removeSelectedItem(state, action) {
            state.selectedItems = state.selectedItems.filter(item => item.token_id !== action.payload.token_id);
        }
    },
})

export const { toggleSelectionMode, setSelectedItem, clearItems, removeSelectedItem } = myArcanaSlice.actions;

export default myArcanaSlice;