import React, { useReducer, useState, useEffect, Suspense } from "react";
import "../../style/ProductDetail.scss";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../components/common/Button";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GameDetailFeature } from "../../components/common/ProductItem";
import { API_DOMAIN } from "../../config";
import "../../style/app/gamedetail.scss";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { OnLoading } from "../../components/common/OnLoading";
import { useContext } from "react";
import { appContext } from "../../context/appContext";
import { SwalWrong } from "../../components/common/Swal";
import { formatMoney } from "../../components/common/commonFnc";
import { useMediaQuery } from 'react-responsive'
import ScrollContainer from 'react-indiana-drag-scroll'
import RatingReview from "../news/ratingreview";
import { AuthContext } from "../../context/authContext";
import { getData } from "../../services/fetch";
import { translations } from "../../services/localization";
import { ExportPurpleIcon } from "../../components/common/Icon";

function GamesDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gamesDetail, setgamesDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gameLanguage, setGameLanguage] = useState([])
  const [reviews, setReviews] = useState()

  const { language, timeZone } = useContext(appContext)
  const { user } = useContext(AuthContext)
  const content = translations.productList;

  const fetchGameDetailData = async () => {
    const shortForm = language === 'English' ? 'en' : 'jp'
    const url = API_DOMAIN + "games_detail/" + `${id}?language=` + shortForm + "&timezone=" + timeZone;
    return axios
      .post(url);
  };

  const fetchRatingReviewData = async () => {
    const shortForm = language === 'English' ? 'en' : 'jp'
    const url = API_DOMAIN + `rating-and-review/${id}?language=${shortForm}&timezone=${timeZone}`;
    return getData(url);
  };

  useEffect(() => {
    (async function fetchData() {
      if (language && timeZone) {
        const promises = [fetchGameDetailData(), fetchRatingReviewData()];
        const [detailReponse, ratingResponse] = await Promise.allSettled(promises).then(results => results.map(result => result.value));
        if (detailReponse?.data) {
          setgamesDetail(detailReponse.data.data);
          const languageArray = detailReponse.data.data?.game_language;
          setGameLanguage(languageArray);
        }
        if (ratingResponse?.data) {
          setReviews(ratingResponse.data.data);
        }
        setLoading(false);
      }
    })();
  }, [language, timeZone]);


  function createMarkup() {
    return { __html: (gamesDetail.body_en && !isJP) ? gamesDetail.body_en : gamesDetail.body };
  }

  const isJP = translations._language === 'jp'
  const isPC = useMediaQuery({ minWidth: 992 })
  const isPh = useMediaQuery({ maxWidth: 576 })
  const topImage = !isPh && gamesDetail.top_pc_image_url ? gamesDetail.top_pc_image_url : gamesDetail.top_image_url

  const metaTitle = `${gamesDetail?.title || ''}｜ANICANA θυρα（アニカナテュラー）`
  const metaDescription = `${gamesDetail?.body || ''}｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています`

  return (
    <AppWrapper offOverFlowX gamesActive
      title={content.headerTitle}
      isCustomDetailPage
      gameDetailObj={isPC ? '' : gamesDetail} isNoSpaceAround metaTitle={metaTitle} metaDescription={metaDescription}
    >
      {
        loading ? <OnLoading /> :
          <>
            <div className="d-flex flex-column">
              <img className="w-100" style={{ borderRadius: '0', objectFit: 'cover', maxHeight: 195 }} src={topImage} alt="Overviews" />
              <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto custom-hide-scroll position-relative game-detail-preview-box-title flex-row" style={{ padding: '0 20px', bottom: 20 }}>
                {/*<div style={{ height: '80px', marginLeft:'5px' }} className='col-lg-2 custom-col-4 games-detail-info d-flex me-2 me-lg-3 justify-content-center align-items-center flex-column'>*/}
                {/*    <span style={{ fontSize: '15px' }} className='primary-color fw-600'>Ratings</span>*/}
                {/*    <span style={{ fontSize: '22px' }} className='fw-500 f-condensed'>4.4</span>*/}
                {/*  </div>*/}
                <div style={{ height: '80px', marginLeft: '5px' }} className='col-lg-2 custom-col-4 games-detail-info d-flex me-2 me-lg-3 justify-content-center align-items-center flex-column'>
                  <span className='primary-color fs-15 fw-600'>
                    {content.rating}
                  </span>
                  <span className='fw-500 fs-18 f-condensed'>
                    {reviews.total_rating !== undefined ? reviews.total_rating.toFixed(1) : 0}
                  </span>
                </div>
                <div style={{ height: '80px', marginLeft: '5px' }} className='col-lg-2 custom-col-4 games-detail-info d-flex me-2 me-lg-3 justify-content-center align-items-center flex-column'>
                  <span className='primary-color fs-15 fw-600'>
                    {content.age}
                  </span>
                  <span className='fw-500 fs-18 f-condensed'>{gamesDetail.targert_min_age}</span>
                </div>
                <div style={{ height: '80px' }} className='col-lg-2 custom-col-4 games-detail-info d-flex me-2 me-lg-3 justify-content-center align-items-center flex-column'>
                  <span className='primary-color fs-15 fw-600'>
                    {content.lang}
                  </span>
                  <span className='fw-500 fs-18 f-condensed'>{gameLanguage}</span>
                </div>
                <div style={{ height: '80px' }} className='col-lg-2 custom-col-4 games-detail-info d-flex me-2 me-lg-3 justify-content-center align-items-center flex-column'>
                  <span className='primary-color fs-15 fw-600'>
                    {content.category}
                  </span>
                  <span className='fw-500 fs-18 f-condensed'>{gamesDetail?.game_categories?.name}</span>
                </div>
                <div
                  onClick={() => gamesDetail?.square_id && navigate(`/squares/${gamesDetail?.square_id}`)}
                  style={{ height: '80px', cursor: gamesDetail?.square_id ? 'pointer' : '' }} className='col-lg-2 custom-col-4 games-detail-info d-flex justify-content-center align-items-center flex-column'>
                  <span className='primary-color fs-15 fw-600 text-nowrap'>
                    {gamesDetail?.square_id ? (
                      <>
                        {content.square}
                        <ExportPurpleIcon className="ms-1" style={{ marginBottom: isJP ? 3 : 1 }}/>
                      </>
                    ) : content.provider}
                  </span>
                  <span className='fw-500 fs-18 f-condensed gamemaker_top'>{gamesDetail ? gamesDetail?.developer : ''}</span>
                </div>
              </ScrollContainer>
              <div className="mb-4 p-3">
                <div className="w-450">
                  <a href={gamesDetail.url}>
                    <div className={"btn py-1 w-100 d-flex flex-column justify-content-center " + (gamesDetail?.url ? 'btn-custom-active' : 'btn-custom-nonactive')} style={{ fontSize: '20px', minHeight: '65px', marginTop: '10px' }}>
                      {gamesDetail?.url ?
                        <>
                          {/* <div className="fw-00 f-regular" style={{fontSize:'20px'}}>
                PLAY
              </div> */}
                          <div style={{ fontSize: '20px' }} className='f-regular fw-400'>
                            {gamesDetail.price ? (gamesDetail.price) : (language === 'English' ? `No Price` : `無料 (ゲーム内課金有り)`)}
                          </div>
                        </>
                        :
                        <div className='f-regular fw-400 fs-20'>
                          {gamesDetail.price ? (gamesDetail.price) : (language === 'English' ? `No Price` : `無料 (ゲーム内課金有り)`)}
                        </div>
                      }
                    </div>
                  </a>
                </div>
              </div>
              <div className="my-2 mt-6 p-3">
                <span className="primary-color fw-500 f-expended" style={{ fontSize: '25px' }}>Preview</span>
                <Suspense fallback={<OnLoading inline />}>
                  <ScrollContainer className="scroll-container overflow-auto scroll-behavior-auto game-detail-preview-box mt-2">

                    {
                      gamesDetail?.show_align == 'horizontal'
                        ?
                        (gamesDetail?.game_preview_images || []).map((each, index) =>
                          <div key={index} className="wp-lg-20 each-game-detail-preview-box col-10 me-2" style={{ overflow: 'hidden' }}>
                            <a href={each.image_url} data-lightbox="image-1" data-title="Preview">
                              <img className="w-100 h-100" src={each.image_url} style={{ objectFit: 'cover' }} alt="Preview" />
                            </a>
                          </div>
                        )
                        :
                        (gamesDetail?.game_preview_images || []).map((each, index) =>
                          <div key={index} className="wp-lg-15 each-game-detail-preview-box col-10 me-2" style={{ overflow: 'hidden' }}>
                            <a href={each.image_url} data-lightbox="image-1" data-title="Preview">
                              <img className="w-100 h-100" src={each.image_url} style={{ objectFit: 'cover' }} alt="Preview" />
                            </a>
                          </div>
                        )
                    }
                  </ScrollContainer>
                </Suspense>
              </div>

              {/* Ratings & Reviews */}
              {
                reviews !== undefined &&
                <RatingReview
                  data={reviews}
                  user={user} id={id}
                  fetchRatingReviewData={fetchRatingReviewData}
                />
              }

              <div className="mt-6 mb-1 p-3">
                <span className={`primary-color fw-500 f-expended`} style={{ fontSize: '25px' }}>
                  Overviews
                </span>
              </div>
              <div style={{ margin: '0 20px' }} className="game-overview-content">
                <div
                  dangerouslySetInnerHTML={createMarkup()}
                  className={`wp-resp-30 text-justify fw-500 child-fs-16 ${language === 'English' ? 'f-expended' : 'f-notosan'}`}
                  style={{ fontSize: '16px' }}
                />
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p> */}
              </div>

              <div className="mt-5 mt-6 text-center p-3 p-b-0 p-b-sm-3">
                <a href={gamesDetail.url}>
                  <div className={"btn py-1 w-100 d-flex flex-column justify-content-center " + (gamesDetail?.url ? 'btn-custom-active' : 'btn-custom-nonactive')} style={{ fontSize: '20px', minHeight: '65px' }}>
                    {gamesDetail?.url ?
                      <>
                        {/* <div className="fw-00 f-regular" style={{fontSize:'20px'}}>
                PLAY
              </div> */}
                        <div style={{ fontSize: '20px' }} className='f-regular fw-400'>
                          {gamesDetail.price ? (gamesDetail.price) : (language === 'English' ? `No Price` : `無料 (ゲーム内課金有り)`)}
                        </div>
                      </>
                      :
                      <div style={{ fontSize: '20px' }} className='f-regular fw-400'>
                        {gamesDetail.price ? (gamesDetail.price) : (language === 'English' ? `No Price` : `無料 (ゲーム内課金有り)`)}
                      </div>
                    }
                  </div>
                </a>
              </div>
            </div>

            {/* <div className="my-2 mt-4">
        <span className="primary-color fw-500" style={{ fontSize: '25px' }}>News</span>
        <div className="game-detail-preview-box mt-2">
          <div className="col-6">
            <div style={{ height: '110px' }} className="each-game-detail-preview-box me-2"></div>
            <div>
              <span className="text-justify fw-400 f-regular" style={{ fontSize: '14px' }}>Zombie siblings  are on the way</span>
            </div>
            <div>
              <span className="fw-500 f-condensed type-text" style={{ fontSize: '14px' }}>2022.09.18.</span>
            </div>
          </div>
          <div className="col-6">
            <div style={{ height: '110px' }} className="each-game-detail-preview-box me-2"></div>
            <div>
              <span className="text-justify fw-400 f-regular" style={{ fontSize: '14px' }}>Zombie siblings  are on the way</span>
            </div>
            <div>
              <span className="fw-500 f-condensed type-text" style={{ fontSize: '14px' }}>2022.09.18.</span>
            </div>
          </div>
          <div className="col-6">
            <div style={{ height: '110px' }} className="each-game-detail-preview-box me-2"></div>
            <div>
              <span className="text-justify fw-400 f-regular" style={{ fontSize: '14px' }}>Zombie siblings  are on the way</span>
            </div>
            <div>
              <span className="fw-500 f-condensed type-text" style={{ fontSize: '14px' }}>2022.09.18.</span>
            </div>
          </div>
          <div className="col-6">
            <div style={{ height: '110px' }} className="each-game-detail-preview-box me-2"></div>
            <div>
              <span className="text-justify fw-400 f-regular" style={{ fontSize: '14px' }}>Zombie siblings  are on the way</span>
            </div>
            <div>
              <span className="fw-500 f-condensed type-text" style={{ fontSize: '14px' }}>2022.09.18.</span>
            </div>
          </div>
          <div className="col-6">
            <div style={{ height: '110px' }} className="each-game-detail-preview-box me-2"></div>
            <div>
              <span className="text-justify fw-400 f-regular" style={{ fontSize: '14px' }}>Zombie siblings  are on the way</span>
            </div>
            <div>
              <span className="fw-500 f-condensed type-text" style={{ fontSize: '14px' }}>2022.09.18.</span>
            </div>
          </div>
        </div>
      </div> */}

            {/* <div>
        <div className="my-2 mt-4">
          <span className="primary-color fw-500" style={{ fontSize: '25px' }}>Updates</span>
        </div>
        <div className="d-flex justify-content-between me-2">
          <span className="text-justify fw-600 f-regular" style={{ fontSize: '16px' }}>Version 3.2</span>
          <span className="text-justify fw-500 f-condensed" style={{ fontSize: '16px' }}>2022.09.18. </span>
        </div>
        <ul className="labs-list-font">
          <li className="text-justify fw-500 f-condensed" style={{ fontSize: '18px', margin: 0 }}>Point 1</li>
          <li className="text-justify fw-500 f-condensed" style={{ fontSize: '18px', margin: 0 }}>Point 1</li>
          <li className="text-justify fw-500 f-condensed" style={{ fontSize: '18px', margin: 0 }}>Point 1</li>
        </ul>
        <button className="col-6 btn btn-custom-active my-3fw-500 f-expended" style={{ fontSize: '14px', height: 'inherit' }}>version history</button>
      </div> */}
          </>
      }
      {/* <GameStartBanner /> */}
    </AppWrapper>
  );
}
export default GamesDetail;
