import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "../../style/ProductDetail.scss";
import { useNavigate } from "react-router-dom";
import { languageAction } from "../../store/Language";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { CheckboxIcon, CheckboxOnclickIcon } from "../../components/common/Icon";
import { API_DOMAIN } from "../../config";
import { clearLocalStorage, getToken } from "../../services/storage";
import axios from "axios";
import { SwalCorrect, SwalWrong } from "../../components/common/Swal";
import { authAction } from "../../store/Auth";
import Modal from "../../components/common/Modal";
import { strings, translations } from "../../services/localization";
import LaddaButton, { XL, L, EXPAND_RIGHT } from 'react-ladda';
import { AuthContext } from "../../context/authContext";

function DeleteAccount() {
  const [Laddaloading, setLaddaLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const { logout } = useContext(AuthContext);
  const [chosenLanguage, setChosenLanguage] = useState(language.language);
  const [firstCheck, setFirstCheck] = useState(false)
  const [secCheck, setSecCheck] = useState(false)
  const [thirdCheck, setThirdCheck] = useState(false)
  const [reason, setReason] = useState('')
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const content = translations.delectAccount;
  const buttonlist = translations.buttonlist;
  const message = translations.message;
  const deleteAccountpage = translations.deleteAccountpage; // Usage: strings.formatString(deleteAccountpage.Check_conditions)
  
  const validator = () => {
    if (reason.length > 2 && firstCheck === true && secCheck === true && thirdCheck === true) {
      setValid(true)
    }
    else {
      setValid(false)
    }
  }

  useEffect(() => {
    validator()
  }, [reason, firstCheck, secCheck, thirdCheck])

  const onDeleteHandler = async () => {
    setLaddaLoading(true);
    var token = await getToken()
    const url = API_DOMAIN + 'user/delete'
    axios
      .post(
        url,
        {
          reason
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log('user/delete', response);
        if (response.data) {
          if (response.data.message) {
            setLoading(false);
            setLaddaLoading(false);
            SwalCorrect.fire({
              title: strings.formatString(deleteAccountpage.complete_Modal_Text),
            }).then((result) => {
              if (result.isConfirmed) {
                logout();
              }
            });
          } else if (response.data.response.errorMessage) {
            setLoading(false);
            setLaddaLoading(false);
            let message = response.data.response.errorMessage;
            SwalWrong.fire({
              title: "Error",
              text: message,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLaddaLoading(false);
        if (error.response.data.message) {
          SwalWrong.fire({
            title: "Error",
            // text: response.data.message,
            text: error.response.data.message
          });
        }
        else if (error.response.data.reason) {
          SwalWrong.fire({
            title: "Error",
            // text: response.data.message,
            text: error.response.data.reason
          });
        }
      })
  }
  return (
    <>
      <AppWrapper className="auth-container" title={translations.formatString(content.mainTitle)} smallFont hideBottomNav={displayModal}>
        <Modal displayModal={displayModal} onClose={() => setDisplayModal(!displayModal)}>
          <div className="text-center">
            <span className="fw-500">
            { strings.formatString(deleteAccountpage.confirm_Modal_Title) }
            </span>
          </div>
          <div className='flex-center flex-column'>
            <LaddaButton loading={Laddaloading} className="btn btn-custom-active  btn-mini mt-4 mb-5" onClick={onDeleteHandler} data-color="#eee" data-size={L} data-style={EXPAND_RIGHT} data-spinner-size={30} data-spinner-color="#ddd" data-spinner-lines={11}>
              yes
            </LaddaButton>
            <button type='button' onClick={() => setDisplayModal(false)} className='btn btn-custom-cancel btn-mini'>no</button>
          </div>
        </Modal>
        <div className="container-fluid">
          <div
            className="col-12 px-2 d-flex flex-column"
          >
            <div className="my-2 mt-3">
              <span>{ strings.formatString(deleteAccountpage.Check_conditions) }</span>
            </div>
            <div>

              <div className="mx-3">
                <div className="d-flex align-items-center my-4">
                  <div id="condition1">
                  {
                    firstCheck === true ? (
                      <CheckboxOnclickIcon
                        className="mx-2"
                        onCheckHandler={() => setFirstCheck(false)}
                      />
                    ) : (
                      <CheckboxIcon
                        onCheckHandler={() => setFirstCheck(true)}
                        className="mx-2"
                      />
                    )
                  }
                  </div>
                    <span for="condition1"
                    // onClick={() => setIdmsRegistrationStep("term")}
                    className="kardust-light-font term-policy">
                    {translations.formatString(content.condition1)}
                  </span>
                </div>
                
                <div className="d-flex align-items-center my-4">
                  <div id="condition2">
                  {
                    secCheck === true ? (
                      <CheckboxOnclickIcon
                        className="mx-2"
                        onCheckHandler={() => setSecCheck(false)}
                      />
                    ) : (
                      <CheckboxIcon
                        onCheckHandler={() => setSecCheck(true)}
                        className="mx-2"
                      />
                    )
                  }</div>
                  <span for="condition2"
                    // onClick={() => setIdmsRegistrationStep("term")}
                    className="kardust-light-font term-policy"
                  >
                    {translations.formatString(content.condition2)}
                  </span>
                </div>
                <div className="d-flex align-items-center my-4">
                  <div id="condition3">
                  {
                    thirdCheck === true ? (
                      <CheckboxOnclickIcon
                        className="mx-2"
                        onCheckHandler={() => setThirdCheck(false)}
                      />
                    ) : (
                      <CheckboxIcon
                        onCheckHandler={() => setThirdCheck(true)}
                        className="mx-2"
                      />
                    )
                  }</div>
                  <span for="condition3"
                    // onClick={() => setIdmsRegistrationStep("term")}
                    className="kardust-light-font term-policy"
                  >
                    {translations.formatString(content.condition3)}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="my-2">
                <span>{ strings.formatString(deleteAccountpage.Reason_for_withdrawal) }</span>
              </div>
              <div>
                <textarea
                  className="auth-form-input-absolute form-control border-box rounded border border-secondary"
                  style={{ background: 'transparent', color: 'white' }}
                  id="exampleFormControlTextarea1"
                  rows="3"
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
              {/* <div className="my-2">
                <span>テキストテキストテキストテキスト</span>
              </div> */}
            </div>
            <div className="my-2">
              <span>{ strings.formatString(deleteAccountpage.Important_points) }</span>
            </div>
            <div className="mx-3">
              <div>
                <div className="d-flex align-items-center my-4">
                  <div id="point1">
                  <div className="rounded me-2" style={{ background: 'linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%)', width: '15px', height: '15px' }}>
                  </div>
                  </div>
                  <span for="ponit1" className="important-point">
                  {translations.formatString(content.point1)}
                  </span>
                </div>
                <div className="d-flex align-items-center my-4">
                  <div id="point2">
                    <div className="rounded me-2" style={{ background: 'linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%)', width: '15px', height: '15px' }}>
                    </div>
                  </div>
                  <span for="ponit2" className="important-point">
                  {translations.formatString(content.point2)}
                  </span>
                </div>
                <div className="d-flex align-items-center my-4">
                  <div id="point3">
                    <div className="rounded me-2" style={{ background: 'linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%)', width: '15px', height: '15px' }}>
                    </div>
                  </div>
                  <span for="ponit3" className="important-point">
                  {translations.formatString(content.point3)}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center py-4 d-flex w-100 justify-content-evenly pb-0">
              <button
                // style={{ opacity: 0.5 }}
                className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                onClick={() => navigate('/account_detail')}
              >
                {translations.formatString(buttonlist.cancelBtn)}
              </button>
              {
                valid === true ?
                  <button onClick={() => setDisplayModal(true)} className="btn btn-custom-active my-3 py-1 col-5">
                    {translations.formatString(buttonlist.submitBtn)}
                  </button> :
                  <button disabled className="btn btn-custom-success my-3 py-1 col-5">
                    {translations.formatString(buttonlist.submitBtn)}
                  </button>
              }
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
}

export default DeleteAccount;
