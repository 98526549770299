import React from 'react'
import { isMobileSafari } from "react-device-detect";

const Modal = ({ displayModal, onClose, children }) => {
    return (
        <div>
            <div className={`Modal modal-slideup ${displayModal ? "Show" : ""} ${isMobileSafari ? "is-mobile-safari-model" : ""}`}>
                {children}
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={onClose}
            />
        </div>
    )
}

export default Modal
