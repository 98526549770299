import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TransactionItem } from '../../components/common/Item'
import Swal from '../../components/common/Swal'
import { WalletTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { Web3authContext } from '../../context/web3authContext'
import { getData } from '../../services/fetch'
import RPC from "../../components/common/web3RPC";
import { appContext } from '../../context/appContext'
import { getWalletAddress } from "../../services/storage";
import { OnLoading } from '../../components/common/OnLoading'
import WalletTransactionDetail from './WalletTransactionDetail';
import { postData } from '../../services/fetch'
import { strings, translations } from "../../services/localization";
import { calculateBN } from '../../components/common/commonFnc';
import { useIntersectionWithLastRef } from '../../hooks/useIntersectionWithLastRef'

const WalletTransactions = () => {
    const { wallet_address, init } = useContext(Web3authContext);
    const [balances, setBalances] = useState({
        arcana: null,
        anima: null,
        token: null,
    })
    const [transactions, setTransactions] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [last_tx_id, setLast_tx_id] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false)
    const transactionsRef = useRef();
    const walletRef = useRef();
    const last_tx_ref = useRef();
    transactionsRef.current = transactions;
    walletRef.current = getWalletAddress();
    last_tx_ref.current = last_tx_id;
    const { timeZone } = useContext(appContext)
    const walletTransaction = translations.walletTransaction;
    const wallet = translations.wallet

    useEffect(() => {
        console.log({ wallet_address, ref: walletRef.current })
    }, [wallet_address])

    const { ref } = useIntersectionWithLastRef(paginationLoading, hasMore,
        () => getTransactions(transactionsRef.current, walletRef.current, last_tx_ref.current))

    const getTransactions = async (transactions, wallet_address, last_tx_id) => {
        if (wallet_address && last_tx_id !== undefined) {
            setPaginationLoading(true);
            var response = await getData(`tokens/transactions?wallet_address=${wallet_address}&limit=10&last_tx_id=${last_tx_id}&timezone=${timeZone}`);
            if (response.ok) {
                const { data, last_transaction_id, has_next_tx_history, total } = response.data
                setTotalCount(total)
                if (data) {
                    const transactionHistory = data.map((i) => ({ ...i, amount: calculateBN(i.amount, i.decimal, 2) || 0 }))
                    setTransactions(JSON.parse(JSON.stringify(transactions.concat(transactionHistory))));
                }
                setHasMore(has_next_tx_history)
                setLast_tx_id(last_transaction_id ? last_transaction_id : undefined);
            }
            setPaginationLoading(false);
        }
    }

    const updateLastSeenTokenDate = async () => {
        await postData(`user/update-tx-last-seen-date`);
    }

    // Detect React Page Leave Event 
    useEffect(() => {
        // Component Mount Event
        return async () => {
            // Component Unmount Event
            if (total_count > 0) {
                // Page Leave Event
                const ids = transactionsRef.current.filter((i) => i.is_read === false).map((j) => j.tx_id)
                if (ids.length > 0) {
                    await postData(`tokens/transactions/read`, { ids });
                }
            }
        };
    }, [total_count]) // Do not add another state

    useEffect(() => {
        if (getWalletAddress() && timeZone) {
            getTransactions(transactionsRef.current, getWalletAddress(), last_tx_ref.current);
        }

        async function fetchData() {
            setLoading(true);
            await getAnimaBalance(getWalletAddress());
            await getArcanaBalance(getWalletAddress());
            await updateLastSeenTokenDate()
            setLoading(false);
        }
        if (timeZone) {
            fetchData();
        }
    }, [timeZone]);

    const getAnimaBalance = async (address) => {
        const rpc = new RPC(null);
        const balance = await rpc.getAnimaBalance(address);
        balances.anima = balance;
        setBalances(JSON.parse(JSON.stringify(balances)));
    };
    const getArcanaBalance = async (address) => {
        const rpc = new RPC(null);
        const balance = await rpc.getArcanaBalance(address);
        balances.arcana = balance;
        setBalances(JSON.parse(JSON.stringify(balances)));
    };


    return (
        <AppWrapper title={wallet.headerTitle}>
            <div id="wallet_dashboard">
                <WalletTitle title={strings.formatString(walletTransaction.headerTitle)} total={total_count} />
                {loading ? <OnLoading /> : (<div className='row'>
                    {
                        transactions && transactions.map((item, index) =>
                            <div key={index} className='col-12 col-md-6 col-xl-4' ref={transactions.length === index + 1 ? ref : null}>
                                <TransactionItem wallet_address={wallet_address} transaction={item} onClick={() => {
                                    Swal.fire({
                                        html: (<WalletTransactionDetail wallet_address={wallet_address} transaction={item} />),
                                        customClass: 'wallet-transaction-swal',
                                        confirmButtonText: `OK`,
                                    });
                                }} />
                            </div>
                        )
                    }
                    {
                        paginationLoading && (
                            <OnLoading
                                pRelative
                                noLabel
                                marginAuto
                                className={"m-0"}
                            />
                        )
                    }
                </div>)}
            </div>
        </AppWrapper>
    )
}

export default WalletTransactions
