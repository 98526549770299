import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonWithIcon } from '../../components/common/Button';
import { formatNumber, isObjectEmpty } from '../../components/common/commonFnc';
import { TokenType } from '../../components/common/enum';
import { AbyssIcon, ArcanaIcon, DeterminationIcon, ForceIcon, HeartIcon, IntelligenceIcon, MindIcon, PersonaIcon } from '../../components/common/Icon';
import Item, { DetailContent } from '../../components/common/Item';
import { OnLoading } from '../../components/common/OnLoading';
import Swal, { SwalCorrect, SwalWrong } from '../../components/common/Swal';
import { HomeTitle, WalletPageTitle } from '../../components/common/Title';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { translations } from "../../services/localization";
import { Web3authContext } from '../../context/web3authContext';
import { postData } from '../../services/fetch';
import { useDidUpdateEffect } from '../../services/hook';
import { getWalletAddress } from '../../services/storage';
import { useMediaQuery } from 'react-responsive';
import { ProgressLoading } from '../../components/common/Loading';
import { CustomButton } from '../../components/common/Button';

const WalletAbsorb = () => {
    const navigate = useNavigate();
    const { wallet_address, provider, setProvider, loginIfNotLogin, isValidWalletAddress, logout, absorbContract } = useContext(Web3authContext);
    const { absorber, target } = useLocation().state || {};
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [startAbsorb, setStartAbsorb] = useState(false);
    const [id_token, setIdToken] = useState(null);
    const content = translations.walletAbsorb
    const buttonlist = translations.buttonlist
    const isPC = useMediaQuery({ minWidth: 992 })

    const handleSelect = (token_type_id) => {
        navigate(`/wallet/absorb/list/${token_type_id}`, { state: { absorber: absorber || {}, target: target || {} } })
    }

    const handleAbsorb = useCallback(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
        setLoading(true);
        setStartAbsorb(true);
    }, [id_token]);

    useEffect(() => {
        // if(absorber && !isObjectEmpty(absorber) && absorber.token_id && target && !isObjectEmpty(target) && target.token_id) {
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        } else {
            setProvider(provider)
            console.log("Provider set")
        }
        // }
    }, [provider]);

    // useEffect(() => {
    //     if (id_token) {
    //         loginIfNotLogin(id_token)
    //     }
    // }, [id_token])

    useDidUpdateEffect(() => {
        if (startAbsorb && getWalletAddress() && provider) {
            absorb();
        }
    }, [wallet_address, provider, startAbsorb])

    /*
<CustomButton
                  disabled={true}
                  btnClassName="btn-custom-active my-3 py-1 contact-btn"
                  label={strings.formatString(buttonlist.absorbBtn)}
                />
    */

    const AdditionBtn = () => absorber && !isObjectEmpty(absorber) && target && !isObjectEmpty(target) && !loading ? (
        <CustomButton
            handleClick={handleAbsorb}
            btnClassName="btn-custom-active my-3 py-1 contact-btn"
            label={translations.formatString(buttonlist.absorbBtn)}
        />
    ) :
        <CustomButton
            disabled={true}
            btnClassName="btn-custom-active my-3 py-1 contact-btn"
            label={translations.formatString(buttonlist.absorbBtn)}
        />

    const delay = (func, sec = 1000) => setTimeout(func, sec);

    const absorb = async () => {
        const isValid = await isValidWalletAddress();
        setStep(1);
        await delay(() => setStep(2));
        await delay(() => setStep(3), 2000);
        await delay(() => setStep(4), 3000);
        if (isValid) {
            if (absorber && !isObjectEmpty(absorber) && absorber.token_id && target && !isObjectEmpty(target) && target.token_id) {
                const receipt = await absorbContract(absorber.token_id, target.token_id);
                console.log('absorbContract res', receipt);
                if (receipt && receipt.status) {
                    setStep(5);
                    SwalCorrect.fire({
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        html: <p className='m-b-100'>{translations.formatString(content.absorbSuccessMsg)}</p>
                    });
                    setTimeout(() => {
                        navigate(`/wallet/${TokenType.getRouteName(absorber.token_type_id)}/preview/${absorber.token_id}`, { state: { absorber } });
                        Swal.close();
                    }, 3000)
                } else {
                    setStep(5);
                    SwalWrong.fire({
                        allowOutsideClick: false,
                        html: <p className='m-b-30'>{translations.formatString(content.absorbFailMsg)}</p>
                    }).then((result) => {
                        if (result.isConfirmed) {
                            delay(() => setLoading(false));
                        }
                    });
                }
            }
        } else {
            setStep(5);
            delay(() => setLoading(false))
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
            });
            logout();
        }
    }

    return (
        <AppWrapper title={translations.formatString(content.headerTitle)}
            additionalBottom={
                <MobileView>
                    <AdditionBtn />
                </MobileView>
            }
        >
            {
                loading ? <AbsorbLoading step={step} /> :
                    <div className='m-x-3'>
                        <WalletPageTitle title={translations.formatString(content.mainTitle)} underlineClassName="m-t-20 m-b-35" hasUnderline />
                        <div className={`${isPC ? 'w-100 d-flex mt-5 mb-4' : 'mx-3 my-4'}`} style={{ gap: 80 }}>
                            <div className="form-wrapper flex-column">
                                <HomeTitle title={translations.formatString(content.absorber)} className="f-expanded fw-500 fs-25" />
                                <span className='f-regular fw-500 fs-18'>{translations.formatString(content.descAbsorber)}</span>
                                {absorber && !isObjectEmpty(absorber) && <ItemDesk item={absorber} className="m-t-27" cardFirst />}
                                <ButtonWithIcon className="w-100 m-t-35 m-b-50"
                                    icon={<PersonaIcon className="active-3 m-r-16" />}
                                    text={translations.formatString(buttonlist.selectPersonaBtn)}
                                    onClick={() => handleSelect(TokenType.Persona)}
                                />
                            </div>
                            <div className="form-wrapper flex-column">
                                <HomeTitle title={translations.formatString(content.target)} className="f-expanded fw-500 fs-25" />
                                <span className='f-regular fw-500 fs-18 m-b-35'>{translations.formatString(content.descTarget)}</span>
                                {target && !isObjectEmpty(target) && <ItemDesk item={target} className="m-t-27" cardFirst={isPC} />}
                                <ButtonWithIcon className="w-100 m-y-35"
                                    icon={<ArcanaIcon className="active-3 m-r-16" style={{ width: 27.12, height: 27.12 }} />}
                                    text={translations.formatString(buttonlist.selectArcanaBtn)}
                                    onClick={() => handleSelect(TokenType.Arcana)}
                                />
                            </div>
                            {!isPC && (
                                <BrowserView>
                                    <AdditionBtn />
                                </BrowserView>
                            )}
                        </div>
                        {
                            isPC && (
                                <BrowserView>
                                    <AdditionBtn />
                                </BrowserView>
                            )
                        }
                    </div>
            }
        </AppWrapper>
    )
}

export default WalletAbsorb

export const ItemDesk = ({ item, className, cardFirst, hidePrices }) => {
    const content = translations.walletAbsorb;
    const isSP = useMediaQuery({ maxWidth: 450 })
    return (
        <div className={`item-desk ${className || ''}`} style={{ maxWidth: isSP ? 360 : 450 }}>
            <div className='row gx-0'>
                <div className={`w-175px order-${cardFirst ? 0 : 1}`}>
                    <Item item={item} isPersona={Number(item.token_type_id) === Number(TokenType.Persona)} hidePrices={hidePrices} imgClassName="h-176" />
                </div>
                <div className={`col p-${cardFirst ? 'l' : 'r'}-20`}>
                    <span className="text-gradient f-regular fw-400 fs-25">Scores</span>
                    <DetailContent className='m-t-19' icon={<ForceIcon />} attr={translations.formatString(content.FOR)} value={formatNumber(item.FOR)} />
                    <DetailContent className='m-t-19' icon={<AbyssIcon />} attr={translations.formatString(content.ABS)} value={formatNumber(item.ABS)} />
                    <DetailContent className='m-t-19' icon={<DeterminationIcon />} attr={translations.formatString(content.DFT)} value={formatNumber(item.DFT)} />
                    <DetailContent className='m-t-19' icon={<MindIcon />} attr={translations.formatString(content.MND)} value={formatNumber(item.MND)} />
                    <DetailContent className='m-t-19' icon={<IntelligenceIcon />} attr={translations.formatString(content.INT)} value={formatNumber(item.INT)} />
                    <DetailContent className='m-t-19' icon={<HeartIcon />} attr={translations.formatString(content.EXP)} value={formatNumber(item.EXP)} />
                </div>
            </div>
        </div>
    )
}

const AbsorbLoading = ({ step }) => {
    return (
        <div className='d-flex flex-column flex-center' style={{ minHeight: 500 }}>
            {
                [
                    "persona checking",
                    "arcana checking",
                    "transaction creating",
                    "transaction checking"
                ].map((it, index) => <ProgressLoading
                    title={it}
                    startLoading={step == index + 1}
                    isFinished={step > index + 1} />)
            }
        </div>
    )
}