import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { Web3authContext } from '../../context/web3authContext';
import { AuthContext } from "../../context/authContext";
import { getData, postData } from '../../services/fetch';
import { strings, translations } from '../../services/localization'
import Swal, { SwalWrong } from '../../components/common/Swal';
import { getValue, getWalletAddress } from "../../services/storage";
import Header from '../../layouts/mainlayout/Header';
import { CustomButton } from '../../components/common/Button';

const CodeD = () => {

  const buttonlist = translations.buttonlist;
  const content = translations.codeD;

  const navigate = useNavigate();
  const { code_d_id } = useParams()
  // const { user } = useContext(AuthContext)
  const [queryParameters] = useSearchParams()
  const code = queryParameters.get('code')

  const [disable, setDisable] = useState(true);
  const [couponCode, setCouponCode] = useState(null);
  const [status, setStatus] = useState(false)
  const [couponDetail, setCouponDetail] = useState(null);
  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState("")
  const [user, setUser] = useState(null)

  const onCompleteInput = (values) => {
    if (status) {
      setDisable(false);
      const first_value = values.substring(0, 4)
      const second_value = values.substring(4, 8)
      const code = first_value.concat('-')
      const coupon = code.concat(second_value)
      setCouponCode(coupon)
      setCouponDetail(coupon)
    }
  }

  const alreadyUsedCoupon = () => {
    SwalWrong.fire({
      title: "Error",
      text: content.aleradyUsed,
    });
  }

  // const getCouponDetail = async (coupon) => {
  //   // const data = await getData(`/code-d/QJAA-E2OS`)
  //   const data = await getData(`/code-d/${coupon}`)
  //   data.status === 200 && data.data.valid ? setCouponDetail(data.data.coupon) : alreadyUsedCoupon();
  // }

  const useCouponCode = async () => {
    const data = await postData(`code-d/${couponCode}/use`, {
      wallet_address: getWalletAddress()
    })

    if (data && typeof data.data === 'string' && data.data.match(/used/)) {
      alreadyUsedCoupon();
    } else {
      if (data.data.signature !== null) {
        let coupon = data.data.coupon
        const callbackUrl =  window.location.origin + window.location.pathname;
        code_d_id === '1' || code_d_id === '2' ?
          navigate(`/arcana-gen/${coupon.egg_id}/${coupon.seed}/${data.data.signature}/1/${getWalletAddress()}/${coupon.symbol}/${coupon.mana_info}/${coupon.mana_value}?type=${code_d_id}&r=${callbackUrl}`)
          :
          navigate(`/arcana-gen/${coupon.egg_id}/${coupon.seed}/${data.data.signature}/1/${getWalletAddress()}/${coupon.symbol}/${coupon.mana_info}/${coupon.mana_value}?r=${callbackUrl}`)
      }
    }
  }

  const onFocusHandler = (i) => {
    console.log('focus')
    if (user === null || user === {}) {
      setFocus(true)
    } else {
      setValue(code??"")
    }
  }

  const onChangeHandler = (event) => {
    setValue(event.target.value??"")
    event.target.value.length === 8 || code !== null ? onCompleteInput(event.target.value) : setDisable(true)
  }

  useEffect(() => {
    if (focus && (user === null || user === {})) {
      Swal.fire({
        allowOutsideClick: false,
        html: (<div className='text-center fs-18 text-white'>{content.popupText}</div>)
      }).then((result) => {
        if (result.isConfirmed) {
          setFocus(false)

          if (!getWalletAddress()) {
            // navigate('/login?r=/code-d')
            if (code !== null) {
              navigate(`/login/idms?r=/code-d?code=${code}`);
            } else {
              navigate('/login/idms?r=/code-d');
            }
          }
        }
      });
    }

    if (status && code !== null) {
      onFocusHandler()
      onCompleteInput(code)
    }
  }, [focus, status])

  const codeDHealth = async () => {
    const data = await getData('/code-d-health')
    if (data.status === 200 && data.data.status === "I'm Up!") {
      setStatus(true)
    }
  }

  useEffect(() => {
    codeDHealth()

    const getUserInfo = async () => {
      setUser(await getValue('user_info'))
    }

    getUserInfo()
  }, [])

  // const onKeyUpHandler = (e, i) => {
  //   setIndex(i)
  // }

  const metaTitle = 'コードディ｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  return (
    <div className={`${code_d_id === '1' ? 'clip-left-bg-white' : code_d_id === '2' ? 'clip-left-bg-blue' : ''}`}>
      <AppWrapper
        className="auth-container"
        title="CODE-D"
        activeProfile
        pageid="CodeD"
        isSpecCodeD={code_d_id === '1' || code_d_id === '2' ? true : false}
        disableBottomNav
        disableSidebar={code_d_id === '1' || code_d_id === '2' ? true : false}
        disableSearchbox={code_d_id === '1' || code_d_id === '2' ? true : false}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <div className={`${code_d_id === '1' ? 'code-d-w-wrapper' : code_d_id === '2' ? 'code-d-b-wrapper' : 'code-d-wrapper'} mx-3 mx-md-auto`}>
          <div
            className={`fw-400 mt-3 fs-25 text-uppercase text-center jp-f-notosan`}
          >
            {content.codeDInput}
          </div>
          <div className={`${code_d_id === '1' || code_d_id === '2' ? '' : 'divider'} my-3`}></div>

          <input
            className='pin-input-field text-uppercase fw-400'
            maxLength={8}
            value={value}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />

          <div className='about-content mt-5 text-center lh-lg jp-f-notosan fw-400'>
            {content.message}
          </div>

          <Link to={`${code_d_id === '1' ? '/whats-code-d/1' : code_d_id === '2' ? '/whats-code-d/2' : '/whats-code-d'}`}>
            <div className='text-center code-d-question fs-20 my-5 jp-f-notosan'>
              {content.whatsCodeD}
            </div>
          </Link>

          {
            code_d_id === '1' || code_d_id === '2' ?
              <CustomButton
                btnClassName={`w-450 btn-code-d-cancel bg-green fs-20 fw-400 common-btn`}
                handleClick={useCouponCode}
                label={strings.formatString(buttonlist.submitBtn)}
                style={{ height: 50 }}
              />
              :
              disable || !couponDetail ?
                <CustomButton disabled={true}
                  btnClassName={`w-450 btn-custom-active fs-25 f-regular common-btn`}
                  label={strings.formatString(buttonlist.submitBtn)}
                  style={{ height: 50 }}
                />
                :
                <CustomButton
                  btnClassName={`w-450 btn-custom-active fs-25 f-regular common-btn`}
                  handleClick={useCouponCode}
                  label={strings.formatString(buttonlist.submitBtn)}
                  style={{ height: 50 }}
                />
          }

          <div className='m-t-50'>
            <span className='fs-14 '>
              Copyright
              &copy; ANIDRIVE Inc.
            </span>
            <br />
            <span className='fs-14 '>
              All rights reserved.
            </span>
          </div>
        </div>
      </AppWrapper>
    </div>
  )
}

export default CodeD
