import React from 'react'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as SearchCircle } from '../../assets/icons/search-circle.svg';
import { ReactComponent as SearchGradient } from '../../assets/icons/search_gradient.svg';
import { ReactComponent as Bookmark } from '../../assets/icons/bookmark.svg';
import { ReactComponent as PlusCircle } from '../../assets/icons/plus_circle.svg';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron_right.svg';
import { ReactComponent as ChevronRight2 } from '../../assets/icons/chevron_right_2.svg';
import { ReactComponent as ChevronRightBg } from '../../assets/icons/chevron_right_bg.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron_down.svg';
import { ReactComponent as Category } from '../../assets/icons/category.svg';
import { ReactComponent as CategoryGradient } from '../../assets/icons/category_gradient.svg';
import { ReactComponent as Element } from '../../assets/icons/elements.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as GradientStar } from '../../assets/icons/star.svg';
import { ReactComponent as DNA } from '../../assets/icons/dna.svg';
import { ReactComponent as Birthday } from '../../assets/icons/birthday.svg';
import { ReactComponent as Anima } from '../../assets/icons/anima.svg';
import { ReactComponent as Anima2 } from '../../assets/icons/anima-2.svg';
import { ReactComponent as Anicana } from '../../assets/icons/anicana.svg';
import { ReactComponent as Anikana } from '../../assets/icons/anikana.svg';
import { ReactComponent as AnikanaGradient } from '../../assets/icons/anikana-logo.svg';
import { ReactComponent as LeviasLogo } from '../../assets/images/levias-icon-logo.svg';
import { ReactComponent as Persona } from '../../assets/icons/persona.svg';
import { ReactComponent as Square } from '../../assets/icons/square.svg';
import { ReactComponent as SquareGradient } from '../../assets/icons/square_gradient.svg';
import { ReactComponent as Vitality } from '../../assets/icons/vitality.svg';
import { ReactComponent as Magic } from '../../assets/icons/magic.svg';
import { ReactComponent as Force } from '../../assets/icons/force.svg';
import { ReactComponent as Abyss } from '../../assets/icons/abyss.svg';
import { ReactComponent as Determination } from '../../assets/icons/determination.svg';
import { ReactComponent as Mind } from '../../assets/icons/mind.svg';
import { ReactComponent as Intelligence } from '../../assets/icons/intelligence.svg';
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';
import { ReactComponent as Arcana } from '../../assets/icons/arcana.svg';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as Back } from '../../assets/icons/back-icon.svg';
import { ReactComponent as BackGradient } from '../../assets/icons/back-two-vector.svg';
import { ReactComponent as Word_A } from '../../assets/icons/word_a.svg';
import { ReactComponent as Word_P } from '../../assets/icons/word_p.svg';
import { ReactComponent as Buy } from '../../assets/icons/buy.svg';
import { ReactComponent as Sell } from '../../assets/icons/sell.svg';
import { ReactComponent as Bid } from '../../assets/icons/bid.svg';
import { ReactComponent as Ask } from '../../assets/icons/ask.svg';
import { ReactComponent as History } from '../../assets/icons/history.svg';
import { ReactComponent as HistoryGradient } from '../../assets/icons/history-active.svg';
import { ReactComponent as ManaAddress } from '../../assets/icons/mana-address.svg';
import { ReactComponent as ManaAddressActive } from '../../assets/icons/mana-address-active.svg';
import { ReactComponent as Function } from '../../assets/icons/function.svg';
import { ReactComponent as Payment } from '../../assets/icons/payment.svg';
import { ReactComponent as Setting } from '../../assets/icons/setting.svg';
import { ReactComponent as SignIn } from '../../assets/icons/sign-in.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Sort } from '../../assets/icons/sort.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as Bulk } from '../../assets/icons/bulk.svg';
import { ReactComponent as BulkBg } from '../../assets/icons/bulk-bg.svg';
import { ReactComponent as BulkArcana } from '../../assets/icons/bulk-arcana.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as ArcanaCopy } from '../../assets/icons/arcana-copy.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Send } from '../../assets/icons/send.svg';
import { ReactComponent as SendGradient } from '../../assets/icons/send_gradient.svg';
import { ReactComponent as Receive } from '../../assets/icons/receive.svg';
import { ReactComponent as ReceiveGradient } from '../../assets/icons/receive_gradient.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Checkbox } from '../../assets/icons/checkbox.svg'
import { ReactComponent as LeviasCheckbox } from '../../assets/icons/levias-checkbox.svg'
import { ReactComponent as CheckboxOnclick } from '../../assets/icons/checkbox-onclick.svg'
import { ReactComponent as LeviasCheckboxOnclick } from '../../assets/icons/levias-checkbox-onclick.svg'
import { ReactComponent as HidePassword } from '../../assets/icons/hide-password.svg'
import { ReactComponent as LeviasHidePassword } from '../../assets/icons/levias-hide-password.svg'
import { ReactComponent as ShowPassword } from '../../assets/icons/show-password.svg'
import { ReactComponent as LeviasShowPassword } from '../../assets/icons/levias-show-password.svg'
import { ReactComponent as Camera } from '../../assets/icons/camera.svg'
import { ReactComponent as LeviasCamera } from '../../assets/icons/levias-camera.svg'
import { ReactComponent as Profile } from '../../assets/icons/profile.svg'
import { ReactComponent as LeviasProfile } from '../../assets/icons/levias-profile.svg'
import { ReactComponent as QR } from '../../assets/icons/qr_code.svg'
import { ReactComponent as Correct } from '../../assets/icons/correct.svg'
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg'
import { ReactComponent as LeviasCancel } from '../../assets/icons/levias-cancel.svg'
import { ReactComponent as ProfileVectorActive } from '../../assets/icons/profile-active.svg'
import { ReactComponent as ProfileVector } from '../../assets/icons/profile-icon.svg'
import { ReactComponent as WalletVectorActive } from '../../assets/icons/wallet-active.svg'
import { ReactComponent as HomeActive } from '../../assets/icons/home-active.svg'
import { ReactComponent as Home } from '../../assets/icons/home.svg'
import { ReactComponent as Trend } from '../../assets/icons/trend.svg'
import { ReactComponent as Thank } from '../../assets/icons/thank.svg'
import { ReactComponent as MenuActive } from '../../assets/icons/menu-active-pixelied.svg'
import { ReactComponent as NewsActive } from '../../assets/icons/news-active.svg'
import { ReactComponent as GamePad } from '../../assets/icons/gamepad.svg'
import { ReactComponent as AccountCard } from '../../assets/icons/account-card.svg'
import { ReactComponent as AccountDetail } from '../../assets/icons/account-detail.svg'
import { ReactComponent as AccountHistory } from '../../assets/icons/account-history.svg'
import { ReactComponent as AccountSettings } from '../../assets/icons/account-settings.svg'
import { ReactComponent as AccountWallet } from '../../assets/icons/account-wallet.svg'
import { ReactComponent as AccountProfile } from '../../assets/icons/account-profile.svg'
import { ReactComponent as SignOut } from '../../assets/icons/sign-out.svg'
import { ReactComponent as SendEmail } from '../../assets/icons/sent-email.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit.svg'
import { ReactComponent as Edit2 } from '../../assets/icons/edit-2.svg'
import { ReactComponent as Export } from '../../assets/icons/export.svg'
import { ReactComponent as Warning } from '../../assets/icons/warning.svg'
import { ReactComponent as Warning2 } from '../../assets/icons/warning2.svg'
import { ReactComponent as Switch } from '../../assets/icons/3D-switch.svg'
import { ReactComponent as NewsMenu } from '../../assets/icons/menu-news.svg'
import { ReactComponent as ThanksMenu } from '../../assets/icons/menu-thanks.svg'
import { ReactComponent as ArcanaMenu } from '../../assets/icons/menu-arcana.svg'
import { ReactComponent as PersonaMenu } from '../../assets/icons/menu-persona.svg'
import { ReactComponent as SquareMenu } from '../../assets/icons/menu-square.svg'
import { ReactComponent as LabsMenu } from '../../assets/icons/menu-labs.svg'
import { ReactComponent as SupportMenu } from '../../assets/icons/menu-support.svg'
import { ReactComponent as CodeDMenu } from '../../assets/icons/code-d.svg'
import { ReactComponent as Cate } from '../../assets/icons/cate-icon.svg'
import { ReactComponent as Delete } from '../../assets/icons/delete-account.svg'
import { ReactComponent as OnLoading } from '../../assets/icons/on-loading.svg'
import { ReactComponent as GamesMore } from '../../assets/icons/game-more.svg'
import { ReactComponent as Gallery } from '../../assets/icons/gallery.svg'
import { ReactComponent as LeviasGallery } from '../../assets/icons/levias-gallery.svg'
import { ReactComponent as TakePhoto } from '../../assets/icons/take-photo.svg'
import { ReactComponent as LeviasTakePhoto } from '../../assets/icons/levias-take-photo.svg'
import { ReactComponent as ArcanaNoImg } from '../../assets/icons/arcana-no-img.svg'
import { ReactComponent as ArcanaNoImgDetail } from '../../assets/icons/arcana-no-img-detail.svg'
import { ReactComponent as ProfileForNoImg } from '../../assets/icons/profile-for-no-img.svg'
import { ReactComponent as SwithButton } from '../../assets/icons/switch.svg'
// import { ReactComponent as ArcanaVector } from '../../assets/icons/arcana_vector.svg'
// import { ReactComponent as PersonaVector } from '../../assets/icons/persona_vector.svg'
// import { ReactComponent as AnimaVector } from '../../assets/icons/anima_vector.svg'
import { ReactComponent as GamePadVector } from '../../assets/icons/gamepad-solid.svg'
import { ReactComponent as ArcanaIconWalletSend } from '../../assets/icons/arcana_icon_wallet_send.svg'
import { ReactComponent as PersonaIconWalletSend } from '../../assets/icons/persona_icon_wallet_send.svg'
import { ReactComponent as GoldTokenT } from '../../assets/icons/gold_token_t_icon.svg'
import { ReactComponent as TokenIconWalletSend } from '../../assets/icons/token_icon_wallet_send.svg'
import { ReactComponent as TokenNoImage } from '../../assets/icons/token_no_image.svg'
import { ReactComponent as Ramcoin } from '../../assets/icons/ramcoin.svg'
import { ReactComponent as HomeNav } from '../../assets/images/home-vector.svg'
import { ReactComponent as Product } from '../../assets/icons/product.svg'
import { ReactComponent as SideProduct } from '../../assets/icons/sideproduct.svg'
import { ReactComponent as ProductActive } from '../../assets/icons/product-gradient.svg'
import { ReactComponent as MeuSearchIconNav } from '../../assets/icons/menu-search.svg'
import { ReactComponent as MeuSearchActiveIconNav } from '../../assets/icons/menu-search-active.svg'
import { ReactComponent as MusicOn } from '../../assets/icons/music-on.svg'
import { ReactComponent as MusicOff } from '../../assets/icons/music-off.svg'


import { ReactComponent as Connected } from '../../assets/icons/connected.svg';
import { ReactComponent as DisConnected } from '../../assets/icons/disconnected.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ReactComponent as Tick } from '../../assets/icons/tick.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { color } from './style';
import { addGradientColor, removeGradientColor } from './commonFnc';
import { ReactComponent as ArcanaActive } from '../../assets/icons/arnaca_icon.svg'
import { ReactComponent as Topics } from '../../assets/icons/topics.svg'
import { ReactComponent as ArcanaSide } from '../../assets/icons/arcana_side.svg';
import { ReactComponent as TokenAdd } from '../../assets/icons/token_add.svg';
import { ReactComponent as TokenSend } from '../../assets/icons/token_send.svg';
import { ReactComponent as TokenReceive } from '../../assets/icons/token_receive.svg';
import { ReactComponent as TokenDelete } from '../../assets/icons/token_delete.svg';
import { ReactComponent as TokenWalletSend } from '../../assets/icons/token_wallet_send.svg'
import { ReactComponent as TokenWalletReceive } from '../../assets/icons/token_wallet_receive.svg'
import { ReactComponent as TokenWalletDelete } from '../../assets/icons/token_wallet_delete.svg'
import { ReactComponent as Polygon } from '../../assets/icons/polygon.svg';
import { ReactComponent as LineHeaderRight } from '../../assets/images/line-header-right.svg';
import { ReactComponent as WorldNews } from '../../assets/icons/world_news_white.svg';
import { ReactComponent as QuestionMark } from '../../assets/icons/question_mark.svg';
import { ReactComponent as AdamantineRock } from '../../assets/icons/adamantine-rock.svg';
import { ReactComponent as AdamantineRockActive } from '../../assets/icons/adamantine-rock-active.svg';
import { ReactComponent as CrimsonFlame } from '../../assets/icons/crimson-flame.svg';
import { ReactComponent as CrimsonFlameActive } from '../../assets/icons/crimson-flame-active.svg';
import { ReactComponent as ExternalTree } from '../../assets/icons/eternal-tree.svg';
import { ReactComponent as ExternalTreeActive } from '../../assets/icons/eternal-tree-active.svg';
import { ReactComponent as GoldenLight } from '../../assets/icons/golden-light.svg';
import { ReactComponent as GoldenLightActive } from '../../assets/icons/golden-light-active.svg';
import { ReactComponent as HeavenlyDew } from '../../assets/icons/heavenly-dew.svg';
import { ReactComponent as HeavenlyDewActive } from '../../assets/icons/heavenly-dew-active.svg';
import { ReactComponent as PeerlessSteel } from '../../assets/icons/peerless-steel.svg';
import { ReactComponent as PeerlessSteelActive } from '../../assets/icons/peerless-steel-active.svg';
import { ReactComponent as UnfathomableAbyss } from '../../assets/icons/unfathomable-abyss.svg';
import { ReactComponent as UnfathomableAbyssActive } from '../../assets/icons/unfathomable-abyss-active.svg';
import { ReactComponent as TowardGradient } from '../../assets/icons/toward-gradient.svg';
import { ReactComponent as Yen } from '../../assets/icons/yen.svg';

// filter icon
import { ReactComponent as FilterGoldenLight } from '../../assets/icons/filter/golden-light.svg';
import { ReactComponent as FilterHeavenlyDew } from '../../assets/icons/filter/heavenly-dew.svg';
import { ReactComponent as FilterPeerlessSteel } from '../../assets/icons/filter/peerless-steel.svg';
import { ReactComponent as FilterUnfathomableAbyss } from '../../assets/icons/filter/unfathomable.svg';
import { ReactComponent as FilterEternalTree } from '../../assets/icons/filter/eternal-tree.svg';
import { ReactComponent as FilterCrimsonFlame } from '../../assets/icons/filter/crimson-flame.svg';
import { ReactComponent as FilterAdamantineRock } from '../../assets/icons/filter/adamantine-rock.svg';
// end filter icon
import { ReactComponent as Subscribe } from '../../assets/icons/subscribe.svg';
import { ReactComponent as UnSubscribe } from '../../assets/icons/unsubscribe.svg';
import { ReactComponent as DrawChain } from '../../assets/icons/draw-chain.svg';
import { ReactComponent as Chain } from '../../assets/icons/chain.svg';
import { ReactComponent as ChainSingle } from '../../assets/icons/chain-single.svg';
import { ReactComponent as DropdownIndicator } from '../../assets/icons/dropdown-indicator.svg';
import { ReactComponent as Swap } from '../../assets/icons/swap-icon.svg';

import { ReactComponent as Absorb } from '../../assets/icons/absorb.svg';
import { ReactComponent as PersonaElement } from '../../assets/icons/persona-element.svg';
import { ReactComponent as PersonaReality } from '../../assets/icons/persona-reality.svg';
import { ReactComponent as ArcanaElement } from '../../assets/icons/arcana-element.svg';
import { ReactComponent as Key } from '../../assets/icons/Key.svg';
import { ReactComponent as ClipLeft } from '../../assets/icons/clip-bottom-right.svg';
import { ReactComponent as ClipRight } from '../../assets/icons/clip-top-left.svg';
import { ReactComponent as MaintenanceModeSP } from '../../assets/icons/maintenance_sp.svg';
import { ReactComponent as MaintenanceModePC } from '../../assets/icons/maintenance_pc.svg';
import { ReactComponent as Envelope } from '../../assets/icons/envelope.svg';
import { ReactComponent as TapScreen } from '../../assets/icons/tapscreen.svg';
import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg';
import { ReactComponent as PhoneStack } from '../../assets/icons/phone-stack.svg';
import { ReactComponent as ArcanaPlus } from '../../assets/icons/createArcana.svg';
import { ReactComponent as Selected } from '../../assets/icons/selected.svg';
import { ReactComponent as FAQ } from '../../assets/icons/faqs.svg';
import { ReactComponent as Plus } from '../../assets/icons/Plus.svg';
import { ReactComponent as SearchThin } from '../../assets/icons/SearchThin.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as ExportPurple } from '../../assets/icons/export-purple.svg';
import { ReactComponent as GoToTop } from '../../assets/icons/go-to-top.svg';
import { ReactComponent as GettingStarted } from '../../assets/icons/getting-started.svg';
import { ReactComponent as ArcanaCircle } from '../../assets/icons/arcana-circle.svg';
import { ReactComponent as PersonaCircle } from '../../assets/icons/persona-circle.svg';
import { ReactComponent as Glossary } from '../../assets/icons/glossary.svg';

export const ArrowRightIcon = props => <ArrowRight {...props} className={`icon ${props.className || ''}`} fillOpacity={.5} />
export const SearchIcon = props => <Search {...props} className={`icon ${props.className || ''}`} fillOpacity={.4} />
export const SearchCircleIcon = props => <SearchCircle {...props} className={`${props.className || ''}`} />
export const SearchGradientIcon = props => <SearchGradient {...props} className={`${props.className || ''}`} />
export const BookmarkIcon = props => <Bookmark {...props} className={`icon ${props.className || ''}`} style={{ fill: props.is_bookmarked ? color.bookmark : color.disabled }} />
export const PlusCircleIcon = props => <PlusCircle {...props} className={`icon ${props.className || ''}`} />
export const PlusGradientCircleIcon = props => <i {...props} className={`icon plus-icon ${props.className || ''}`} />
export const WalletIcon = props => <Wallet {...props} className={`${props.className || ''}`} />
export const WalletGradientIcon = props => <i className={`icon wallet ${props.className || ''}`}></i> //<Wallet {...props} className={`icon ${props.className || ''}`} />
export const UserIcon = props => <i className={`icon user-icon ${props.className || ''}`} onMouseDown={addGradientColor} onMouseUp={removeGradientColor}></i> //<User {...props} className={` ${props.className || ''}`} />
export const BellIcon = props => <i className={`icon bell-icon ${props.className || ''}`} onMouseDown={addGradientColor} onMouseUp={removeGradientColor}></i> //<Bell {...props} className={`icon ${props.className || ''}`} />
export const ChevronRightIcon = props => <ChevronRight {...props} className={`icon ${props.className || ''}`} />
export const ChevronRight2Icon = props => <ChevronRight2 {...props} className={`icon ${props.className || ''}`} />
export const ChevronRightBgIcon = props => <ChevronRightBg {...props} className={`icon ${props.className || ''}`} />
export const ChevronDownIcon = props => <ChevronDown {...props} className={`icon ${props.className || ''}`} />
export const CategoryIcon = props => <Category {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const CategoryGradientIcon = props => <CategoryGradient {...props} className={`icon ${props.className || ''}`} />
export const ElementIcon = props => <Element {...props} className={`icon ${props.className || ''}`} />
export const StarIcon = props => <Star {...props} className={`icon ${props.className || ''}`} style={props.gradient === 1 ? { fill: "url(#star_paint)" } : {}} />
export const GradientStarIcon = props => <GradientStar {...props} className={`icon ${props.className || ''}`} style={props.gradient === 1 ? { fill: "url(#star_gradient)" } : {}} />
export const DNAIcon = props => <DNA {...props} className={`icon ${props.className || ''}`} />
export const BirthdayIcon = props => <Birthday {...props} className={`icon ${props.className || ''}`} />
export const AnimaIcon = props => <Anima {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const Anima2Icon = props => <Anima2 {...props} className={`icon ${props.className || ''}`} /> 
export const AnicanaIcon = props => <Anicana {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const AniKanaIcon = props => <Anikana {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const AnikanaGradientIcon = props => <AnikanaGradient {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const LeviasLogoIcon = props => <LeviasLogo {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const PersonaIcon = props => <Persona {...props} className={`icon ${props.className || ''}`} style={{ ...props.style }} />
export const SquareIcon = props => <Square {...props} className={`icon ${props.className || ''}`} />
export const GradientSquareIcon = props => <SquareGradient {...props} className={`icon ${props.className || ''}`} />
export const VitalityIcon = props => <Vitality {...props} className={`icon ${props.className || ''}`} />
export const MagicIcon = props => <Magic {...props} className={`icon ${props.className || ''}`} />
export const ForceIcon = props => <Force {...props} className={`icon ${props.className || ''}`} />
export const AbyssIcon = props => <Abyss {...props} className={`icon ${props.className || ''}`} />
export const DeterminationIcon = props => <Determination {...props} className={`icon ${props.className || ''}`} />
export const MindIcon = props => <Mind {...props} className={`icon ${props.className || ''}`} />
export const IntelligenceIcon = props => <Intelligence {...props} className={`icon ${props.className || ''}`} />
export const HeartIcon = props => <Heart {...props} className={`icon ${props.className || ''}`} />
export const ArcanaIcon = props => <Arcana {...props} className={`icon ${props.className || ''}`} />
export const LockIcon = props => <Lock {...props} className={`icon ${props.className || ''}`} />
export const BackIcon = props => <Back {...props} className={`icon ${props.className || ''}`} />
export const BackGradientIcon = props => <BackGradient {...props} className={`${props.className || ''}`} />

export const Word_AIcon = props => <Word_A {...props} className={`icon ${props.className || ''}`} />
export const Word_PIcon = props => <Word_P {...props} className={`icon ${props.className || ''}`} />
export const BuyIcon = props => <Buy {...props} className={`icon ${props.className || ''}`} />
export const SellIcon = props => <Sell {...props} className={`icon ${props.className || ''}`} />
export const BidIcon = props => <Bid {...props} className={`icon ${props.className || ''}`} />
export const AskIcon = props => <Ask {...props} className={`icon ${props.className || ''}`} />
export const HistoryIcon = ({ gradient, className, ...rest }) => gradient ? <HistoryGradient {...rest} className={`icon ${className || ''}`} /> : <History {...rest} className={`icon ${className || ''}`} />
// export const HistoryIcon = props => <History {...props} className={`icon ${props.className || ''}`} style={props.gradient === 1 ? { fill: "url(#star_paint)" } : {}} />
export const ManaAddressIcon = props => <ManaAddress {...props} className={`icon ${props.className || ''}`} />
export const ManaAddressActiveIcon = props => <ManaAddressActive {...props} className={`icon ${props.className || ''}`} />
export const FunctionIcon = props => <Function {...props} className={`icon ${props.className || ''}`} />
export const PaymentIcon = props => <Payment {...props} className={`icon ${props.className || ''}`} />
export const SettingIcon = props => <Setting {...props} className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const SignInIcon = props => <SignIn {...props} className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const MenuIcon = props => <Menu {...props} className={`icon ${props.className || ''}`} style={{ stroke: color.primary }} />
export const CloseIcon = props => <Close {...props} className={`icon ${props.className || ''}`} style={{ ...props.style, stroke: (props.style && props.style.stroke) ? props.style.stroke : color.primary }} />
export const CloseIconFilter = props => <Close {...props} className={`icon-stroke ${props.className || ''} ${props.active ? 'active' : ''}`}  />
export const SortIcon = props => <Sort {...props} className={`icon ${props.className || ''}`} />
export const FilterIcon = props => <Filter {...props} className={`icon ${props.className || ''}`} />
export const BulkIcon = props => <Bulk {...props} className={`icon ${props.className || ''}`} />
export const BulkBgIcon = props => <BulkBg {...props} className={`icon ${props.className || ''}`} />
export const BulkArcanaIcon = props => <BulkArcana {...props} className={`icon ${props.className || ''}`} />
export const CopyIcon = props => <Copy {...props} className={` ${props.className || ''}`} />
export const ArcanaCopyIcon = props => <ArcanaCopy {...props} className={` ${props.className || ''}`} />

export const DashboardIcon = props => <Dashboard {...props} className={`icon ${props.className || ''}`} />
export const SendIcon = ({ gradient, className, ...rest }) => gradient ? <SendGradient {...rest} className={className || ''} /> : <Send {...rest} className={`icon ${className || ''}`} />
export const ReceiveIcon = ({ gradient, className, ...rest }) => gradient ? <ReceiveGradient {...rest} className={className || ''} /> : <Receive {...rest} className={`icon ${className || ''}`} />
export const InfoIcon = props => <Info {...props} className={`icon ${props.className || ''}`} />
export const CheckboxIcon = ({ onCheckHandler, className, type, ...rest }) => <Checkbox {...rest} onClick={() => onCheckHandler(type, true)} className={`icon ${className || ''}`} style={{ cursor: 'pointer' }} />
export const LeviasCheckboxIcon = ({ onCheckHandler, className, type, ...rest }) => <LeviasCheckbox {...rest} onClick={() => onCheckHandler(type, true)} className={`icon ${className || ''}`} style={{ cursor: 'pointer' }} />
export const CheckboxOnclickIcon = ({ onCheckHandler, className, type, ...rest }) => <CheckboxOnclick {...rest} onClick={() => onCheckHandler(type, false)} className={`icon ${className || ''}`} style={{ cursor: 'pointer' }} />
export const LeviasCheckboxOnclickIcon = ({ onCheckHandler, className, type, ...rest }) => <LeviasCheckboxOnclick {...rest} onClick={() => onCheckHandler(type, false)} className={`icon ${className || ''}`} style={{ cursor: 'pointer' }} />
export const HidePasswordIcon = ({ onShowHandler, className, type, ...rest }) => <HidePassword {...rest} onClick={() => onShowHandler(type, false)} className={`icon ${className || ''}`} />
export const LeviasHidePasswordIcon = ({ onShowHandler, className, type, ...rest }) => <LeviasHidePassword {...rest} onClick={() => onShowHandler(type, false)} className={`icon ${className || ''}`} />
export const ShowPasswordIcon = ({ onShowHandler, className, type, ...rest }) => <ShowPassword {...rest} onClick={() => onShowHandler(type, true)} className={`icon ${className || ''}`} />
export const LeviasShowPasswordIcon = ({ onShowHandler, className, type, ...rest }) => <LeviasShowPassword {...rest} onClick={() => onShowHandler(type, true)} className={`icon ${className || ''}`} />
export const CameraIcon = ({ pickImageHandler, className, ...rest }) => <Camera {...rest} onClick={() => pickImageHandler()} className={`icon ${className || ''}`} style={{ right: 0, bottom: 0, cursor: 'pointer' }} />
export const LeviasCameraIcon = ({ pickImageHandler, className, ...rest }) => <LeviasCamera {...rest} onClick={() => pickImageHandler()} className={`icon ${className || ''}`} style={{ right: 0, bottom: 0, cursor: 'pointer' }} />
export const ProfileIcon = props => <Profile {...props} className={`icon ${props.className || ''}`} style={{ fill: '#0000' }} />
export const LeviasProfileIcon = props => <LeviasProfile {...props} className={`icon ${props.className || ''}`} style={{ fill: '#0000' }} />
export const QRIcon = props => <QR {...props} className={`${props.className || ''}`} />
export const ConnectedIcon = props => <Connected {...props} className={`${props.className || ''}`} />
export const DropdownIcon = props => <Dropdown {...props} className={`${props.className || ''}`} />
export const TickIcon = props => <Tick {...props} className={`icon ${props.className || ''}`} />
export const PlayIcon = props => <Play {...props} className={`icon ${props.className || ''}`} />
export const CorrectIcon = props => <Correct {...props} className={`${props.className || ''}`} />
export const CancelIcon = ({ onCloseHandler, ...props }) => <Cancel {...props} className={`${props.className || ''}`} onClick={onCloseHandler} />
export const LeviasCancelIcon = ({ onCloseHandler, ...props }) => <LeviasCancel {...props} className={`${props.className || ''}`} onClick={onCloseHandler} />
export const DisConnectedIcon = props => <DisConnected {...props} className={`${props.className || ''}`} />
// export const WalletVectorIcon = props => <WalletVector {...props} className={`${props.className || ''}`} />
export const ProfileVectorIcon = props => <ProfileVector {...props} className={`${props.className || ''}`} />
export const WalletVectorActiveIcon = props => <WalletVectorActive {...props} className={`${props.className || ''}`} />
export const ProfileVectorActiveIcon = props => <ProfileVectorActive {...props} className={`${props.className || ''}`} />
export const HomeActiveIcon = props => <HomeActive {...props} className={`${props.className || ''}`} />
export const HomeNavIcon = props => <HomeNav {...props} className={`${props.className || ''}`} />
export const HomeIcon = props => <Home className={`icon-stroke ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TrendIcon = props => <Trend className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ThankIcon = props => <Thank className={`icon ${props.classNonShowHandlerame || ''} ${props.active ? 'active' : ''}`} />
export const MenuActiveIcon = props => <MenuActive {...props} className={`${props.className || ''}`} />
export const NewsActiveIcon = props => <NewsActive {...props} className={`${props.className || ''}`} />
export const GamePadIcon = props => <GamePad {...props} className={`icon-stroke ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const AccountCardIcon = props => <AccountCard {...props} className={`${props.className || ''}`} />
export const AccountDetailIcon = props => <AccountDetail {...props} className={`${props.className || ''}`} />
export const AccountHistoryIcon = props => <AccountHistory {...props} className={`${props.className || ''}`} />
export const AccountWalletIcon = props => <AccountWallet {...props} className={`${props.className || ''}`} />
export const AccountSettingsIcon = props => <AccountSettings {...props} className={`${props.className || ''}`} />
export const AccountProfileIcon = props => <AccountProfile {...props} className={`${props.className || ''}`} />
export const SignOutIcon = props => <SignOut {...props} className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const SendEmailIcon = props => <SendEmail {...props} className={`${props.className || ''}`} />
export const SwitchIcon = props => <Switch {...props} className={`${props.className || ''}`} />
export const EditIcon = ({ onChangeName, ...props }) => <Edit {...props} onClick={onChangeName} className={`${props.className || ''}`} style={{ marginTop: 'auto', marginBottom: 'auto', top: 0, bottom: 0 }} />
export const Edit2Icon = ({ onChangeName, className, style, ...rest }) => <Edit2 {...rest} onClick={onChangeName} className={`${className || ''}`} style={{ marginTop: 'auto', marginBottom: 'auto', top: 0, bottom: 0, ...style }} />
export const ExportIcon = ({ className, ...rest }) => <Export {...rest} className={`${className || ''}`} />
export const WarningIcon = ({ className, ...rest }) => <Warning {...rest} className={`${className || ''}`} />
export const Warning2Icon = ({ className, ...rest }) => <Warning2 {...rest} className={`${className || ''}`} />
export const MenuNewsIcon = props => <NewsMenu {...props} className={`icon-stroke ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const MenuThanksIcon = props => <ThanksMenu {...props} className={`${props.className || ''}`} />
export const MenuArcanaIcon = props => <ArcanaMenu {...props} className={`${props.className || ''}`} />
export const MenuPersonaIcon = props => <PersonaMenu {...props} className={`${props.className || ''}`} />
export const MenuSquareIcon = props => <SquareMenu {...props} className={`${props.className || ''}`} style={props.active ? {fill : 'url(#gradient_paint)'} : {fill: '#fff'}} />
export const MenuLabsIcon = props => <LabsMenu {...props} className={`${props.className || ''}`} />
export const MenuSupportIcon = props => <SupportMenu {...props} className={`${props.className || ''}`} />
export const MenuCodeDIcon = props => <CodeDMenu {...props} className={`${props.className || ''}`} style={props.gradient === 1 ? { fill: "url(#gradient_paint)" } : {fill: "#EBEBE7"}} />
export const CateIcon = props => <Cate {...props} className={`${props.className || ''}`} />
export const DeleteIcon = props => <Delete {...props} className={`${props.className || ''}`} />
export const OnLoadingIcon = props => <OnLoading {...props} className={`${props.className || ''}`} />
export const GamesMoreIcon = props => <GamesMore {...props} className={`${props.className || ''}`} />
export const GalleryIcon = props => <Gallery {...props} className={`${props.className || ''}`} />
export const LeviasGalleryIcon = props => <LeviasGallery {...props} className={`${props.className || ''}`} />
export const TakePhotoIcon = props => <TakePhoto {...props} className={`${props.className || ''}`} />
export const LeviasTakePhotoIcon = props => <LeviasTakePhoto {...props} className={`${props.className || ''}`} />
export const ArcanaNoImgIcon = props => <ArcanaNoImg {...props} className={`${props.className || ''}`} />
export const ArcanaNoImgDetailIcon = props => <ArcanaNoImgDetail {...props} className={`${props.className || ''}`} />
export const ProfileForNoImgIcon = props => <ProfileForNoImg {...props} className={`${props.className || ''}`} />
export const SwitchButtonIcon = props => <SwithButton {...props} className={`${props.className || ''}`} />
// export const ArcanaVectorIcon = props => <ArcanaVector {...props} className={`${props.className || ''}`} />
// export const PersonaVectorIcon = props => <PersonaVector {...props} className={`${props.className || ''}`} />
// export const AnimaVectorIcon = props => <AnimaVector {...props} className={`${props.className || ''}`} />
export const GamePadVectorIcon = props => <GamePadVector {...props} className={`${props.className || ''}`} />
export const ArcanaWalletSendIcon = props => <ArcanaIconWalletSend {...props} className={`${props.className || ''}`} />
export const PersonaWalletSendIcon = props => <PersonaIconWalletSend {...props} className={`${props.className || ''}`} />
export const GoldTokenTIcon = props => <GoldTokenT {...props} className={`${props.className || ''}`} />
export const TokenTWalletSendIcon = props => <TokenIconWalletSend {...props} className={`${props.className || ''}`} />
export const TokenNoImageIcon = props => <TokenNoImage {...props} className={`${props.className || ''}`} />
export const RamcoinIcon = props => <Ramcoin {...props} className={`${props.className || ''}`} />
export const ArcanaActiveIcon = props => <ArcanaActive {...props} className={`${props.className || ''}`} />
export const TopicsIcon = props => <Topics className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ArcanaSideIcon = props => <ArcanaSide className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TokenAddIcon = props => <TokenAdd className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TokenSendIcon = props => <TokenSend className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TokenReceiveIcon = props => <TokenReceive className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TokenDeleteIcon = props => <TokenDelete className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const TokenWalletSendIcon = props => <TokenWalletSend {...props} className={`${props.className || ''}`} />
export const TokenWalletReceiveIcon = props => <TokenWalletReceive {...props} className={`${props.className || ''}`} />
export const TokenWalletDeleteIcon = props => <TokenWalletDelete {...props} className={`${props.className || ''}`} />
export const PolygonIcon = props => <Polygon {...props} className={`${props.className || ''}`} />
export const LineHeaderRightIcon = props => <LineHeaderRight className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const WorldNewsIcon = props => <WorldNews className={`icon ${props.className || ''} `} style={props.gradient ? {fill: "url(#gradient_paint)"} : {}} />
export const QuestionMarkIcon = props => <QuestionMark className={`icon ${props.className || ''} `} />
export const AdamantineRockIcon = props => <AdamantineRock className={`icon ${props.className || ''} `} />
export const AdamantineRockActiveIcon = props => <AdamantineRockActive className={`icon ${props.className || ''} `} />
export const CrimsonFlameIcon = props => <CrimsonFlame className={`icon ${props.className || ''} `} />
export const CrimsonFlameActiveIcon = props => <CrimsonFlameActive className={`icon ${props.className || ''} `} />
export const ExternalTreeIcon = props => <ExternalTree className={`icon ${props.className || ''} `} />
export const ExternalTreeActiveIcon = props => <ExternalTreeActive className={`icon ${props.className || ''} `} />
export const GoldenLightIcon = props => <GoldenLight className={`icon ${props.className || ''} `} />
export const GoldenLightActiveIcon = props => <GoldenLightActive className={`icon ${props.className || ''} `} />
export const HeavenlyDewIcon = props => <HeavenlyDew className={`icon ${props.className || ''} `} />
export const HeavenlyDewActiveIcon = props => <HeavenlyDewActive className={`icon ${props.className || ''} `} />
export const PeerlessSteelIcon = props => <PeerlessSteel className={`icon ${props.className || ''} `} />
export const PeerlessSteelActiveIcon = props => <PeerlessSteelActive className={`icon ${props.className || ''} `} />
export const UnfathomableAbyssIcon = props => <UnfathomableAbyss className={`icon ${props.className || ''} `} />
export const UnfathomableAbyssActiveIcon = props => <UnfathomableAbyssActive className={`icon ${props.className || ''} `} />
export const TowardGradientIcon = props => <TowardGradient className={`icon ${props.className || ''} `} />
export const ProductIcon = props => <Product className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const SideProductIcon = props => <SideProduct className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ProductActiveIcon = props => <ProductActive className={`icon ${props.className || ''} `} style={{ ...props.style }}/>
export const MeuSearchNavIcon = props => <MeuSearchIconNav className={`icon ${props.className || ''} `} />
export const MeuSearchActiveNavIcon = props => <MeuSearchActiveIconNav className={`icon ${props.className || ''} `} />
export const YenIcon = props => <Yen className={`icon ${props.className || ''} `} />

export const MusicOnIcon = props => <MusicOn className={`icon ${props.className || ''} `} onClick={() => props.onClick()}/>
export const MusicOffIcon = props => <MusicOff className={`icon ${props.className || ''} `} onClick={() => props.onClick()}/>

// filter icon

export const FilterAdamantineRockIcon = props => <FilterAdamantineRock className={`${props.className || ''} `} style={{ ...props.style }} />
export const FilterCrimsonFlameIcon = props => <FilterCrimsonFlame className={`${props.className || ''} `} style={{ ...props.style }} />
export const FilterEternalTreeIcon = props => <FilterEternalTree className={`${props.className || ''} `} style={{ ...props.style }} />
export const FilterGoldenLightIcon = props => <FilterGoldenLight className={`${props.className || ''} `} style={{ ...props.style }} />
export const FilterHeavenlyDewIcon = props => <FilterHeavenlyDew className={`icon ${props.className || ''} `} style={{ ...props.style }} />
export const FilterPeerlessSteelIcon = props => <FilterPeerlessSteel className={`${props.className || ''} `} style={{ ...props.style }} />
export const FilterUnfathomableAbyssIcon = props => <FilterUnfathomableAbyss className={`${props.className || ''} `} style={props.style } />
// end filter icon

export const SubscribeIcon=(props)=><Subscribe {...props} className={`icon ${props.className || ''} `} />
export const UnSubscribeIcon=(props)=><UnSubscribe {...props} className={`icon ${props.className || ''} `} />
export const DrawChainIcon=(props)=><DrawChain {...props} />
export const DropdownIndicatorIcon=(props)=><DropdownIndicator {...props}/>
// #region wallet icons
export const AbsorbIcon = props => <Absorb className={`icon ${props.className || ''} `} style={props.style} />
export const ChainSingleIcon=(props)=><ChainSingle className={`icon ${props.className || ''} `} style={props.style} />
export const PersonaElementIcon = props => <PersonaElement className={`icon ${props.className || ''} `} style={props.style} />
export const PersonaRealityIcon = props => <PersonaReality className={`icon ${props.className || ''} `} style={props.style} />
export const ArcanaElementIcon = props => <ArcanaElement className={`icon ${props.className || ''} `} style={props.style} />
// #endregion
export const ChainIcon=(props)=><Chain {...props} />
export const KeyIcon=(props)=><Key className={`key-icon ${props.lg?'large':''}`} {...props}/>
export const SwapIcon = props => <Swap className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ClipLeftIcon = props => <ClipLeft className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ClipRightIcon = props => <ClipRight className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />

export const MaintenanceSPIcon = (props) => <MaintenanceModeSP {...props} />
export const MaintenancePCIcon = (props) => <MaintenanceModePC {...props} />
export const EnvelopeIcon = (props) => <Envelope {...props} />
export const TapScreenIcon = (props) => <TapScreen {...props} />
export const SpinnerIcon = (props) => <Spinner {...props} />
export const PhoneStackIcon=(props)=><PhoneStack className={`${props.lg?'phone-stack-icon-lg':''}`} {...props}/>
export const ArcanaPlusIcon = (props) => <ArcanaPlus className={`${props.lg?'arcana-plus-icon-large':''}`} {...props} />
export const SelectedIcon = (props) => <Selected {...props} />
export const FAQIcon = (props) => <FAQ {...props} className={`faq-icon ${props.className || ''}`} style={props.active ? {fill : 'url(#gradient_paint)'} : {fill: '#fff'}} />
export const PlusIcon = (props) => <Plus {...props} />
export const SearchThinIcon = (props) => <SearchThin {...props} />
export const MinusIcon = (props) => <Minus {...props} />
export const ExportPurpleIcon = (props) => <ExportPurple {...props} />
export const GoToTopIcon = (props) => <GoToTop {...props} />
export const GettingStartedIcon = (props) => <GettingStarted {...props} className={`getting-started ${props.sm?'sm':''}`} />
export const ArcanaCircleIcon = (props) => <ArcanaCircle {...props} className={`arcana-persona ${props.className || ''}`} />
export const PersonaCircleIcon = (props) => <PersonaCircle {...props} className={`arcana-persona ${props.className || ''}`} />
export const GlossaryIcon = (props) => <Glossary {...props} className={`glossary-icon ${props.className || ''}`} />
