import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    API_DOMAIN,
    REACT_APP_ARCANA_CONTRACT_ADDRESS,
    REACT_APP_BLOCKCHAIN_API_URL,
    REACT_APP_WALLET_CLIENT_ID,
} from '../../config'
import RPC, { initWeb3, web3authModalParams } from "../../components/common/web3RPC";
import { SwalCorrect, SwalWrong } from "../../components/common/Swal";
import { postData } from "../../services/fetch";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { TokenType } from "../../components/common/enum";
import { Select } from "../../components/common/Form";

const sample_addresses = [
    {
        name: 'Seller (metamask)',
        address: '0xFf5BC900110f5c4eb6Ce2faf2081B4151655B3f3'
    },
    {
        name: 'test-login (web3auth)',
        address: '0xfa9101dFE1c289A467a3BF823B6297fF8F340DD3'
    },
]

const ArcanaTest = () => {
    const [error, setError] = useState(null);
    const [result, setResult] = useState('');
    const [web3auth, setWeb3auth] = useState(null);
    const [provider, setProvider] = useState(null);
    const [token_type_id, setToken_type_id] = useState(TokenType.Anima);
    const [transaction_id, setTransaction_id] = useState('');

    const [image, setImage] = useState([]);

    const tokenName = React.useRef(null);
    const creatorName = React.useRef(null);
    const tokenIdRef = React.useRef(null);
    const fromWalletAddressRef = React.useRef(null); //0xFf5BC900110f5c4eb6Ce2faf2081B4151655B3f3
    const toWalletAddressRef = React.useRef(null); //0x724BB427E1851473081b85F3eCc9dD8720BA99c8
    const { seed, egg_id, sig } = useParams();

    const init = async () => {
        try {
            const web3auth = initWeb3();
            setWeb3auth(web3auth);
            await web3auth.initModal(web3authModalParams);

            if (web3auth.provider) {
                setProvider(web3auth.provider);
            };

            if (!web3auth) {
                console.log("web3auth not initialized yet");
                return;
            }
            const web3authProvider = await web3auth.connect();
            setProvider(web3authProvider);
        } catch (e) {
            console.error(e)
        }
    };

    const transferContract = async (token_type_id, to, token_id) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        var hash = await rpc.transferContract(token_type_id, to, token_id);
        if (hash) {
            setTransaction_id(hash);
        }
    };

    const transferToken = () => {
        if (toWalletAddressRef.current.value && tokenIdRef.current.value) {
            transferContract(token_type_id, toWalletAddressRef.current.value, tokenIdRef.current.value);
        } else {
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Please enter To Wallet Address and Token ID'
            })
        }
    }

    useEffect(() => {
        if (transaction_id) {
            setResult(`transaction_id: ${transaction_id}`);
            SwalCorrect.fire({
                title: 'The transaction is now pending.',
                text: `Transaction ID is ${transaction_id}`
            })
        }
    }, [transaction_id])

    const handleSubmit = event => {
        event.preventDefault();
        console.log(tokenName.current.value);
    }
    const handleFile = event => {
        const formData = new FormData();
        formData.append("file", image);
        console.log(tokenName)
        formData.append('creatorName', creatorName.current.value)
        formData.append('tokenName', tokenName.current.value)

        fetch(API_DOMAIN + "arcana-metadata", {
            method: 'POST',

            body: formData,
            dataType: "jsonp"
        })
    };
    const onChangeImg = event => {
        console.log(event.target.files[0])
        setImage(event.target.files[0])
    };


    useEffect(() => {
        if (!web3auth) {
            init();
        }
        toWalletAddressRef.current.value = '0xfa9101dFE1c289A467a3BF823B6297fF8F340DD3';
        tokenIdRef.current.value = '1';

        return () => {
            setTransaction_id('');
        }
    }, []);

    useEffect(() => {
        if (provider) {
            getAccounts();
        }
    }, [provider])

    const login = async () => {
        // if (provider) {
        //     await logout();
        // }
        init();
    };

    const getUserInfo = async () => {
        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }
        const user = await web3auth.getUserInfo();
        setResult(JSON.stringify(user, undefined, 4));
    };

    const logout = async () => {
        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }
        await web3auth.logout();
        setProvider(null);
    };

    const getChainId = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const chainId = await rpc.getChainId();
        setResult(chainId);
    };

    const getAccounts = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts();
        console.log('connected address', address);
        setResult(address);
        return address;
    };

    const getBalance = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const balance = await rpc.getBalance();
        setResult(balance);
    };

    const getAnimaBalance = async (address) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const balance = await rpc.getAnimaBalance(address);
        setResult(balance);
    };

    const sendTransaction = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const receipt = await rpc.sendTransaction();
        setResult(JSON.stringify(receipt, undefined, 4));
    };

    const signMessage = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const signedMessage = await rpc.signMessage();
        setResult(signedMessage);
    };

    const getPrivateKey = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const privateKey = await rpc.getPrivateKey();
        setResult(JSON.stringify(privateKey, null, 4));
    };

    const getTransferSignature = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const signature = await rpc.getTransferSignature(tokenIdRef.current.value, toWalletAddressRef.current.value);
        setResult(signature);
        return signature;
    };

    const generateArcana = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts()
        const privateKey = await rpc.generateArkana(egg_id, address, seed, sig);
        setResult(privateKey);
    }

    const loggedInView = (
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={generateArcana} >
                generateArcana
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={getUserInfo} >
                Get User Info
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={getChainId} >
                Get Chain ID
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={getAccounts} >
                Get Accounts
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={getBalance} >
                Get Balance
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={() => getAnimaBalance('')} >
                Get Anima Balance
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={sendTransaction} >
                Send Transaction
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={signMessage} >
                Sign Message
            </button>
            <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={getPrivateKey} >
                Get Private Key
            </button>
        </div>
    );



    return (
        <AppWrapper>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
                        <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={login} >
                            Connect
                        </button>
                        <button className={'btn btn-custom-cancel me-3 mb-3'} onClick={logout} >
                            Log Out
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <h3 className="mb-3">Token Transfer</h3>
                    <h6>Data To Test</h6>
                    {
                        sample_addresses.map((address, index) =>
                            <code className="pointer" onClick={() => toWalletAddressRef.current.value = address.address}>
                                <div className="row">
                                    <div className="col-4">
                                        {address.name}
                                    </div>
                                    <div className="col-8">
                                        : {address.address}
                                    </div>
                                </div>
                            </code>
                        )
                    }
                    <div className="row mt-3">
                        <div className="col-12">
                            {/* <div className="mb-3">
                                    <label className="form-label">From Wallet Address</label>
                                    <input type="text" ref={fromWalletAddressRef} className="form-control" />
                                </div> */}
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="form-label">Token Type</label>
                                    <Select options={[
                                        { text: "Arcana", value: TokenType.Arcana },
                                        { text: "Persona", value: TokenType.Persona },
                                        { text: "Anima", value: TokenType.Anima },
                                    ]} value={token_type_id} onChange={e => setToken_type_id(e.target.value)} />
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="form-label">To Wallet Address</label>
                                    <input type="text" ref={toWalletAddressRef} className="form-control textbox" />
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="form-label">{Number(token_type_id) === Number(TokenType.Anima) ? 'Amount' : 'Token Id'}</label>
                                    <input type="text" ref={tokenIdRef} className="form-control textbox" />
                                </div>
                            </div>
                            <button type="button" onClick={() => getAnimaBalance(toWalletAddressRef.current.value || '')} className="btn btn-custom-cancel me-3 mb-3">Get Anima Balance of Inputted Address</button>
                            <button type="button" onClick={transferToken} className="btn btn-custom-cancel me-3 mb-3">Transfer With Smart Contract</button>
                        </div>
                    </div>
                    <div className="divider my-3"></div>
                    <div style={{ marginTop: 20 + "px" }}>
                        {loggedInView}
                    </div>
                </div>
                <div className="col-6">
                    <div id="console" className="sticky-top" style={{ whiteSpace: "pre-line" }}>
                        <h6>Result</h6>
                        <div className="card p-3" style={{ borderRadius: 5, minHeight: 500, maxHeight: '100vh' }}>
                            <code style={{ whiteSpace: "pre-line", wordBreak: 'break-all' }}>{result}</code>
                        </div>
                    </div>
                </div>
            </div>
        </AppWrapper>
    )
}

export default ArcanaTest;