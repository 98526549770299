import React from "react";
import productCardStyle from "../../style/ProductItem.module.css";
import { Link } from "react-router-dom";
import { ArcanaNoImgIcon } from "./Icon";
import { translations } from "../../services/localization";
import { NewBadge } from "./Badge";
import moment from "moment";

export function ProductItem(props) {

  const language = translations._language;

  const { hideBottomBar, showTopNews, px0 } = props;

  return (
    <>
      <Link to={props.path || '#'}>
        <div className={`d-flex align-items-center ${!px0 && 'px-3'}`}>
          <div
            className={`position-relative w-30 ${props.squareimage ? "" : "rounded-3"}`}
            /*style={{ overflow: 'hidden',
            }}*/
          >
            {showTopNews &&
              <div className="position-absolute text-center fs-20 fw-600 tag-bg" style={{ borderRadius: '10px 0', width: 35 }}>
                <div className="gradient-text">{props.index}</div>
              </div>
            }
            {props.image ?
              <img className={`h-100 square-image ${props.squareimage ? "" : "rounded-3"}`} src={props.image} />
              :
              <ArcanaNoImgIcon className={`h-100 square-image ${props.squareimage ? "" : "rounded-3"}`} />
            }
          </div>
          <div className="w-75 d-flex flex-column justify-content-center mx-3">
            <div className="d-flex flex-row align-items-center">
              <p className="f-regular fw-400 type-text m-0 p-title fs-15">
                {props.news_categories}
              </p>
              {
                props.showNewBadge &&
                <NewBadge className='ms-2' defaultValue={false} withinHour={3} date={props.newBadgeCheckDate} />
              }
            </div>
            <p className={`f-condensed-light fw-500 my-2 p-title text-overflow-vertical-2 ${language === 'jp' ? '' : 'italic'}`}>
              {props.title}
            </p>
            {props.isAd?
            <span className="fw-500 fs-13" style={{ color: 'rgba(255, 255, 255, 0.91)' }}>Advertisement</span>
            :<span className="f-condensed fw-500 type-text fs-15">
              {
                props.isUTC ?
                  moment(props.published).utc().format("YYYY.MM.DD \xa0HH:mm") :
                  moment(props.published).format("YYYY.MM.DD \xa0HH:mm")
              }
            </span>}
          </div>
        </div>
      </Link>
      {!hideBottomBar && <div className="divider my-3 w-100 mx-auto"></div>}
    </>
  );
}

export function GameItem(props) {
  return (
    <Link className='games-each-bg mb-3' to={props.path || '#'}>
      <div className='d-flex h-100'>
        <div className="col-9 d-flex align-items-center">
          <div className="ms-2" style={{ height: '60px', width: '60px', overflow: 'hidden', borderRadius: '5px' }}>
            <img
              className="h-100"
              src={props.thumbnail_image_url}
            />
          </div>
          <div className="mx-2">
            {/* <span>1</span> */}
          </div>
          <div className='col-8 my-1 d-flex flex-column'>
            <span className="fw-600 games-type" style={{ fontSize: '14px' }}>{props.game_categories ? props.game_categories : 'RPG'}</span>
            <span className="fw-400" style={{ fontSize: '14px' }}>{props.title}</span>
            <span className="fw-400 italic" style={{ fontSize: '13px' }}>{props.short_body ? props.short_body : 'no short body here'}</span>
          </div>
        </div>
        <div className="games-price-tag-bg col-3 d-flex flex-column justify-content-center align-items-center">
          <span style={{ fontSize: '18px' }} className='fw-500 f-condensed mb-0'>￥{props.price ? props.price : '0'}</span>
          <span style={{ fontSize: '12px' }} className='fw-400 f-regular italic'>See Detalis</span>
        </div>
      </div>
    </Link>
  )
}

export function GameDetailFeature(props) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-start" style={{}}>
        <div style={{ width: '15px', height: '15px', backgroundColor: 'gray', borderRadius: '50%' }}></div>
        <span className="ps-1" style={{ fontSize: 'small', width: '100px' }}>{props.feature}</span>
      </div>
    </>
  )
}
