import Modal from "react-bootstrap/Modal";
import { Button } from "../../pages/squares/Button";
import { EnvelopeIcon } from "./Icon";
import { translations } from '../../services/localization';

export const ContactModal = ({ show, onClose, contact }) => {
    const content = translations.squareDetail.footer;
    return (
        <Modal centered size="sm" show={show} contentClassName="show-key-modal react-bootstrap-modal-custom" dialogClassName="mx-auto justify-content-center">
            <Modal.Body>
                <div
                    className="d-flex justify-content-center align-items-center flex-column px-3"
                    style={{
                        paddingTop: 43
                    }}>
                    <EnvelopeIcon />
                    <p className="text-center fs-18" style={{ fontWeight: 400, margin: '30px 0' }}>
                        {content.contactDescription}
                    </p>
                    <p className="text-center fs-20" style={{ fontWeight: 400, marginBottom: 30 }}>
                        {contact}
                    </p>
                    <Button
                        onClick={onClose}
                        active
                        className="w-100 fs-25 bordor-radius-15"
                        style={{ height: 55, marginBottom: 56 }}
                    >
                        OK
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};