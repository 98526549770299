import React, { useState, useEffect, useRef, useMemo, useContext, useCallback, createContext } from 'react';
import { DropdownIndicatorIcon } from '../../components/common/Icon';
import { useMediaQuery } from 'react-responsive';
import { appContext } from "../../context/appContext";
import { translations } from '../../services/localization';
import { useWindowResize } from '../../hooks/useWindowResize';

const DropdownContext = createContext(null);

const TabList = ({ items, tabRef, filter, setFilter }) => {
    const [visibleItems, setVisibleItems] = useState([]);
    const [dropdownItems, setDropdownItems] = useState([]);
    const containerRef = useRef();
    const squareDetailContent = translations.squareDetail;
    const { language } = useContext(appContext);
    const isEng = language === "English";
    const windowSize = useWindowResize();
    const GAP = 10;
    const [shouldShowOptions, setShouldShowOptions] = useState(false);

    const toggle = () => {
        setShouldShowOptions(prevState => !prevState);
    };

    const updateItems = () => {
        const containerWidth = containerRef.current?.clientWidth;
        let currentWidth = 0;
        const updatedVisibleItems = [];
        const updatedDropdownItems = [];
        const gap = GAP;
        const dropdownIconWidth = document.getElementById('item-dropdown')?.clientWidth;
        if (dropdownIconWidth) {
            currentWidth = gap + currentWidth + dropdownIconWidth;
        }
        const tabAllWidth = document.getElementById('item-all')?.clientWidth;
        if (tabAllWidth) {
            currentWidth += tabAllWidth;
        }
        items?.forEach((item) => {
            const itemWidth = document.getElementById(`item-${item?.id}`)?.clientWidth;
            currentWidth = currentWidth + gap + itemWidth;
            if (currentWidth < containerWidth) {
                updatedVisibleItems.push(item);
            } else {
                updatedDropdownItems.push(item);
            }
        });
        setVisibleItems(updatedVisibleItems);
        setDropdownItems(updatedDropdownItems);
    };

    useEffect(() => {
        updateItems();
    }, [items, windowSize.width]);

    const renderListItem = useCallback((item) => {
        return (
            <DropdownItem
                key={`dropdown-item-${item?.id}`}
                setFilter={setFilter}
                active={filter === item?.id}
                item={item}
            >
                {isEng ? item.tab_name_en : item.tab_name}
            </DropdownItem>
        )
    }, [filter])

    const dropdownList = useMemo(() => {
        return dropdownItems.map(renderListItem);
    }, [dropdownItems, renderListItem, filter])

    const isPC = useMediaQuery({ minWidth: 992 })
    const tabBarStyle = { width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: isPC ? 'flex-start' : 'space-between', alignItems: 'center', columnGap: GAP }

    const renderButton = (prefix) => (item) => (
        <Button
            id={`${prefix}-${item?.id}`}
            key={item?.id}
            onClick={() => {
                setFilter(item?.id);
                setShouldShowOptions(false);
            }}
            active={item?.id === filter}
        >
            {isEng ? item.tab_name_en : item.tab_name}
        </Button>
    )

    const renderDropdown = useCallback((prefix) => (<>
        {dropdownItems?.length > 0 &&
            <div style={{ position: 'relative', zIndex:3, minWidth: 20, height: 20 }} id={`${prefix}-dropdown`}>
                <DropdownControl />
                <DropdownList>
                    {dropdownList}
                </DropdownList>
            </div>}
    </>), [dropdownItems, filter])

    return (
        <div className={`${isPC ? 'primary-bg-gradient' : ''} m-b-35`} style={{ position: isPC ? 'sticky' : 'static', top: 0, zIndex: isPC ? 99 : 0, width: '100%', fontSize: 20, whiteSpace: 'nowrap', paddingLeft: 8, paddingRight: 8 }}>
            <div style={{ position: 'absolute', inset: 0, zIndex: -100, opacity: 0, ...tabBarStyle }}>
                <Button
                    id={`item-all`}
                >
                    {squareDetailContent?.all}
                </Button>
                {items?.map(renderButton('item'))}
            </div>
            <div ref={containerRef}>
                <div ref={tabRef} style={tabBarStyle}>
                    <Button
                        onClick={() => {
                            setFilter("All");
                            setShouldShowOptions(false);
                        }}
                        active={"All" === filter}
                    >
                        {squareDetailContent?.all}
                    </Button>
                    {visibleItems.map(renderButton('prefix'))}
                    <DropdownContext.Provider value={{ shouldShowOptions, toggle }}>
                        {renderDropdown('item')}
                    </DropdownContext.Provider>
                </div>
            </div>
        </div>
    );
};

const DropdownControl = () => {
    const { shouldShowOptions, toggle } = useContext(DropdownContext);
    return (
        <div style={{ minWidth: 20, height: 20 }} className="position-relative">
            <input type="checkbox" className="tab-dropdown-icon" style={{ opacity: 0, minWidth: 20, height: '100%', margin: 0 }} checked={shouldShowOptions} onChange={() => {
                toggle && toggle();
            }} />
            <div style={{ width: 20, height: 20, position: 'absolute', top: 0, left: 0, display: 'grid', placeItems: 'center', zIndex: -1 }}>
                <DropdownIndicatorIcon />
            </div>
        </div>
    )
}

const DropdownList = ({ children }) => {
    return (
        <ul className='tab-dropdown-list gradient-bg' style={{
            position: 'absolute',
            top: '100%', right: 0,
            transform: 'translate(0,20px)',
            color: 'white',
            listStyleType: 'none',
            padding: 5,
            borderRadius: 5,
            borderTop:'0.5px solid #BF96FF',
            borderRight:'1px solid #BF96FF',
            borderBottom:'1px solid #BF96FF',
            borderLeft:'0.5px solid #BF96FF',
            // boxShadow: '-1px 0 3px #BF96FF'
        }}>
            {children}
        </ul>
    )
}

const DropdownItem = ({ children, active, setFilter, item }) => {
    const { toggle } = useContext(DropdownContext);
    return (
        <li
            className={`fs-15 ${active ? 'active-dropdown-item' : ''}`}
            style={{ margin: 0, padding: '2px 5px' }}
            onClick={() => {
                setFilter(item?.id);
                toggle();
            }}>
            {children}
        </li>
    )
}

const Button = ({ children, onClick, active, id }) => {
    const isPC = useMediaQuery({ minWidth: 992 })
    return (
        <button
            id={id}
            onClick={onClick}
            type="button"
            className={`text-nowrap filter-btn square-filter-btn fs-15 ${active ? 'active' : ''}`}
            style={{ flex: '0 0 fit-content', padding: isPC ? '15px 32px' : '8px' }}
        >
            {children}
        </button>
    )
}

export default TabList;
