import { clear } from '@testing-library/user-event/dist/clear'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TickIcon } from './Icon'
import ReactProgressBar from './ProgressBar'
import { TokenType } from './enum'
import { Btn } from './Button'

const Loading = () => {
    return (
        <div>Loading</div>
    )
}

export default Loading

export const ProgressBar = ({ text, finished, tokenId, isSucceed = true, callbackUrl }) => {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [transitionDuration, setTransitionDuration] = useState(0.05);
    //const [finished, setFinished] = useState(false);
    const progressRef = useRef();
    const transitionDurationRef = useRef();
    progressRef.current = progress;
    transitionDurationRef.current = transitionDuration;

    useEffect(() => {
        const progressInterval = setInterval(() => {
            if (progressRef.current >= 100) {
                clearInterval(progressInterval);
                setTimeout(() => {
                    //setFinished(true);
                    setTransitionDuration(1);
                }, [1000])
            } else {
                // if (progressRef.current === 90) {
                //     setTransitionDuration(.2);
                // } else if (progressRef.current === 95) {
                //     setTransitionDuration(.3);
                // }
                setProgress(progress => progress + 1);
            }
        }, (transitionDurationRef.current * 10000));

        return () => {
            setProgress(0);
            setTransitionDuration(0.01);
            clearInterval(progressInterval);
            //setFinished(false);
        }
    }, [])

    const goPreview = () => {
        navigate(`/wallet/${TokenType.getRouteName(TokenType.Arcana)}/preview/` + tokenId)
    }

    return (
        <div>
            <div className={`loading progress-bar ${finished ? 'finished' : ''}`}>
                {
                    !finished && <span className='text'>{text}</span>
                }
                <div className='parent'>
                    {
                        isSucceed ?
                            <div className='child' style={{
                                width: finished ? 'inherit' : `${progress}%`,
                                transition: `width ${transitionDuration}s linear`
                            }}>
                                <TickIcon className="tick-icon" />
                            </div>
                            : <></>
                    }
                </div>
                <div className='message'>
                    {
                        isSucceed ?
                            <span className='text mb-5'>Your ARCANA has been<br />successfully generated!</span> :
                            <span className='text mb-0'>Generate ARCANA<br /> failed.</span>
                    }
                    {callbackUrl ?
                        <Btn intent="activeMini" onClick={() => { window.location.href = callbackUrl }}>back</Btn>
                        :
                        <Btn intent="activeMini" onClick={() => { goPreview() }}>Preview</Btn>
                    }
                </div>
            </div>
        </div>
    )
}

export const ProgressLoading = ({ startLoading, finishedLoading, isFinished, title }) => {
    const [progress, setProgress] = useState(0);
    const progressRef = useRef();
    progressRef.current = progress;
    const transactionInterval = Math.floor(Math.random() * 101);

    useEffect(() => {
        let progressInterval = null;
        if (startLoading && !isFinished) {
            progressInterval = setInterval(() => {
                if (progressRef.current >= 95) {
                    clearInterval(progressInterval);
                    if (finishedLoading) {
                        finishedLoading();
                    }
                } else {
                    setProgress(progress => progress + 1)
                }
            }, (transactionInterval));
        }
        if (isFinished) {
            setProgress(100);
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        }

        return () => {
            setProgress(0);
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        }
    }, [startLoading, isFinished])

    return (
        <div className='d-flex flex-row justify-content-between align-items-center form-wrapper mb-5'>
            <span className='f-expanded fw-500 progress-bar-title'>{title}</span>
            <ReactProgressBar
                percentage={progress}
                startColor="#E85EFF"
                endColor="#2DD9FF"
                gradientId="progress"
            >
                {
                    progress === 100 && <div className='progressLoadingSymbol' />
                }
            </ReactProgressBar>
        </div>
    )
}

export const ProgressLoadingParallel = ({ total, text, finished, tokenId, isSucceed = true, callbackUrl, step, videoName }) => {
    const navigate = useNavigate();
    const goPreview = () => {
        let url = `/wallet/${TokenType.getRouteName(TokenType.Arcana)}/preview/` + tokenId;
        if (callbackUrl) {
            url += '?r=' + encodeURIComponent(callbackUrl)
        }
        navigate(url)
    }

    useEffect(() => {
        if (isSucceed && tokenId) {
            goPreview();
        }
    }, tokenId, isSucceed)

    const text_a = [
        "metadata creating",
        "ipfs uploading",
        "transaction creating",
        "transaction checking",
    ]

    useEffect(() => {
        console.log("Finished", finished)
    }, [finished])

    return (
        <>
        {
                videoName ? (
                <div className='myVideoWrapper start-0'>
                    <video autoPlay muted playsInline id="myVideo" className="start-0">
                        <source src={`${window.location.origin}/Smoke_R.mp4`} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                ) : null
        }
        <div className='flex-center' style={{ minHeight: 500 }}>
            {
                total > step && !finished ?
                    <div style={{ width: 500 }}>
                        {
                            [...Array(total)].map((progress, index) =>
                                <ProgressLoading key={index} title={text_a[index]} startLoading={index <= step} isFinished={index < step} />
                            )
                        }
                    </div>
                    :
                    <div className='message my-5 d-flex flex-column flex-center'>
                        {
                            isSucceed && tokenId ?
                                '' :
                                <span className='text text-center mb-0'>Generate ARCANA<br /> failed.</span>
                        }

                    </div>
            }
        </div>
        </>
    )
}
