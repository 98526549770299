import moment from 'moment';
import React from 'react'
import { useNavigate } from "react-router-dom";
import { createMarkup, handleSearch, isJP } from '../../components/common/commonFnc';
import { NewBadge } from './Badge';
import { translations } from "../../services/localization";

function TopNewsMobile(props) {
    const navigate = useNavigate();
    let { topNews, topImage, showNewBadge, showPublishedDate } = props;
    const isAd = !(topNews && 'news_categories' in topNews);
    if (!topNews) return <div style={{ marginBottom:16, height: 240 }}></div>
    return (
        topNews?.image_url_top?.split('.').pop() == 'mp4'
            ?
            <div onClick={() => { !isAd && navigate(`/news_detail/${topNews?.id}`) }}
                className='pe-lg-5 d-lg-flex position-relative' 
                style={{ marginBottom: 25 }}
            >
                <video className={'iframe-video'} src={topNews.image_url_top} autoPlay muted playsInline></video>
            </div>
            :
            <div onClick={() => { !isAd && navigate(`/news_detail/${topNews?.id}`) }} className='pe-lg-5 d-lg-flex position-relative' style={{ marginBottom: 25 }}>
                <div className='col-12 col-lg-5'>
                    <div className='flex-center w-100 pointer' style={{ height: 240, background: `url('${topImage || topNews?.thumbnail_image_url}') center/cover no-repeat` }}></div>
                </div>
                <div className='col-12 col-lg-7 position-absolute' style={{ padding: '0 25px', bottom: 10 }}>
                    <div className='w-100 d-flex flex-column justify-content-between align-items-start' style={{ minWidth: 0 }}>
                        {/*<h4 className='fw-500 jp-fw-400 f-regular text-start my-2 f-18'>{(translations._language === 'jp' ? topNews?.title : topNews?.title_en) || topNews?.title_en}</h4>*/}
                        <div className='fw-500 f-regular text-justify mb-2 fs-12 text-overflow-vertical-2' style={{ fontSize: 16 }}>
                            {/* {createMarkup((translations._language === 'jp' ? topNews?.sub_title : topNews?.sub_title_en) || topNews?.sub_title_en)} */}
                            {/*<span className='font-inherit' dangerouslySetInnerHTML={{ __html: isJP() ? topNews?.sub_title : topNews?.sub_title_en }}></span>*/}
                        </div>
                        {
                            (showPublishedDate || showNewBadge) &&
                            <div className="w-full flex-center">
                                {
                                    showPublishedDate &&
                                    <div className="date" style={{ fontSize: 15, color: '#BF96FF', fontFamily: 'KardustCondensed' }}>
                                        {/*{topNews && moment(topNews.published).format('YYYY.MM.DD \xa0HH:mm')}*/}
                                    </div>
                                }
                                {
                                    showNewBadge &&
                                    <NewBadge className='ms-2' defaultValue={moment().diff(topNews.published, 'day') === 0} />
                                }
                            </div>
                        }
                    </div>
                </div>

            </div>
    )
}

export default TopNewsMobile
