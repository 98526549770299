import React, { useState, useEffect, useContext, useRef } from "react";
import "../../style/ProductDetail.scss";
import '../../style/app/code-d.scss'

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { FileInput, FormLabel, TextArea, TextBox } from "../../components/common/Form";
import { W3authLoginButton } from "../../components/common/W3authLoginButton";
import { ProgressLoadingParallel } from "../../components/common/Loading";
import axios from "axios";
import { API_DOMAIN, REACT_APP_ARCANA_CONTRACT_ADDRESS } from "../../config";
import RPC from "../../components/common/web3RPC";
import { useLocation } from 'react-router-dom';
import { strings, translations } from "../../services/localization";
import { BorderBoxText, WalletAddress } from "../wallet/Wallet";
import { BackIcon, Edit2Icon, ExportIcon } from "../../components/common/Icon";
import { ItemDetailCard } from "../../components/common/Item";
import { convertFileToBase64, convertFileToBlob, createImgUrl } from "../../components/common/commonFnc";
import { LaddaButton } from "../../components/common/Button";
import Swal, { SwalWarn, SwalWrong } from "../../components/common/Swal";
import { postData, toFormData } from "../../services/fetch";
import { appContext } from "../../context/appContext";
import { Web3authContext } from "../../context/web3authContext";
import { getWalletAddress, setWalletAddress, setRedirect, removeValue } from "../../services/storage";
import { AuthContext } from "../../context/authContext";
import { OnLoading } from "../../components/common/OnLoading";

const nameOptions = [{ value: '1', text: "name" }];
const descriptionOptions = [{ value: '1', text: "text" }];
const styleOptions = [{ value: '1', text: "R&D" }];
const personaOptions = [{ value: '1', text: "N/A" }];

function ArcanaGen({ isDebug }) {
    const content = translations.createArcanaGen;
    const buttonlist = translations.buttonlist;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [queryParameters] = useSearchParams()
    const type = queryParameters.get('type')

    const language = useSelector((state) => state.language);
    const [chosenLanguage, setChosenLanguage] = useState(language.language);
    const [_provider, setProvider] = useState(null);

    // const [name, setName] = useState('1');
    const [debug, setDebug] = useState(isDebug || false);
    const [debugMode, setDebugMode] = useState(isDebug ? 'success' : 'error');
    const [tokenName, setTokenName] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');
    const [callbackUrl, setCallbackUrl] = useState('');
    // const [style, setStyle] = useState('1');
    // const [persona, setPersona] = useState('1');
    const [loading, setLoading] = useState(false);
    const [preloading, setPreLoading] = useState(false);
    const { pseed, pegg_id, psig, requestId, ptoAddress, pmanaInfo, puser_symbol, pmanaValue, initManaAddress } = useParams();
    const [seed, setSeed] = useState(false);
    const [sig, setSignature] = useState(false);
    const [egg_id, setEggId] = useState(false);
    const [toAddress, setToAddress] = useState(false);
    const [manaInfo, setManaInfo] = useState(false);
    const [user_symbol, setUserSymbol] = useState(false);
    const [manaValue, setManaValue] = useState(false);
    const [videoName, setVideoName] = useState(null);


    const [isToAddressValid, setIsToAddressValid] = useState(false);
    const [isEggIdValid, setIsEggIdValid] = useState(false);
    const [finished, setFinished] = useState(false);
    const [succeed, setSucceed] = useState(false);
    const [tokenId, setTokenId] = useState(null);
    const [timeoutDt, setTimeoutDt] = useState(null);
    const [symbol, setSymbol] = useState('Nyan');
    const [matrixCode, setMatrixCode] = useState('');
    const [step, setStep] = useState(0);
    const [manaOpen, setManaOpen] = useState(false);

    const [manaAddress, setManaAddress] = useState('');
    const [seedValue, setSeedValue] = useState(seed);

    const [isRequested, setIsRequested] = useState(false);
    const [loadingOnStart, setLoadingOnStart] = useState(false);
    const { arcanaGenTempData, setArcanaGenTempData } = useContext(appContext);
    const { logout } = useContext(AuthContext);
    const { wallet_address, provider, loginIfNotLogin, setWallet_address } = useContext(Web3authContext);
    const [previewItem, setPreviewItem] = useState({
        "token_id": 0,
        "image": null,
        "thumbnail": null,
        "name": "????????????",
        "owner": "????????????",
        "creator": "????????????",
        "greenStar": 0,
        "FOR": '???',
        "ABS": '???',
        "DFT": '???',
        "MND": '???',
        "INT": '???',
        "EXP": '???',
        "preview": true,
    });
    const [id_token, setIdToken] = useState(null);
    const search = useLocation().search;
    const state = useLocation().state;
    const query = new URLSearchParams(search);


    useEffect(() => {
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        } else {
            //get provider
            const rpc = new RPC(provider);
            rpc.getAccounts().then(address => {
                setWallet_address(address)
            })

        }
    }, [provider]);



    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    const loadByManaAddress = async (_manaAddress) => {
        const rpc = new RPC();
        const manaInfo = await rpc.getInfoByManaAddress(_manaAddress);
        if (!manaInfo) {
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: '存在しないMANAアドレスです。'
            }).then((result) => {
            })
            setIsToAddressValid(false)
            return;

        }
        if (manaInfo.isDone) {
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'eggはすでに使用されています。'
            }).then((result) => {
            })
            setIsToAddressValid(false)
            return;
        }

        axios.get('https://ipfs.octillion.jp/ipfs/' + _manaAddress).then(res => {
            setManaAddress(_manaAddress)
            setSeed(manaInfo.info.seed,)
            setEggId(manaInfo.info.eggId)
            setSignature(manaInfo.info.signature)
            setToAddress(manaInfo.info.beneficiary)

            setUserSymbol(res.data.mana.symbol ?? null)
            setManaValue(res.data.mana.value ?? null)
            setManaInfo(res.data.mana.info ?? null)

        })
    }

    const checkEggIdExist = async (egg_id, wallet_address) => {
        if (egg_id && wallet_address) {
            console.log('checkEggIdExist', { egg_id, wallet_address });
            const rpc = new RPC();
            const isExist = await rpc.isEggExist(egg_id, wallet_address);
            setIsEggIdValid(isExist);
            if (!isExist) {
                SwalWrong.fire({
                    title: "Error",
                    text: `eggId:${egg_id}が存在しません `,
                });
            }
        }
    }

    useEffect(() => {
        if (toAddress && wallet_address) {
            if (debug) {
                return;
            }
            console.log('check toAddress', { toAddress, wallet_address, isDiff: toAddress !== wallet_address })
            if (toAddress && toAddress.match(/^0x\w+$/)) {
                console.log('isDeff true')
                if (toAddress !== wallet_address) {
                    SwalWrong.fire({
                        title: 'エラーが発生しました',
                        text: '送信先アドレスが異なります。送り先を確認して下さい。'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // logout(true);
                        }
                    })
                } else {
                    setIsToAddressValid(true);
                }
            }
        }
    }, [toAddress, wallet_address])

    useEffect(() => {
        if (isToAddressValid) {
            checkEggIdExist(egg_id, wallet_address)
        }
    }, [isToAddressValid, egg_id, wallet_address])

    useEffect(() => {
        setRedirect(window.location.pathname)
        const getTempData = async () => {
            if (state) {
                console.log({ state })
                if (state.returnFromLogin) {
                    if (arcanaGenTempData) {
                        const formdata = arcanaGenTempData.data;
                        const tokenName = formdata.get('tokenName');
                        const creatorName = formdata.get('creatorName');
                        const image = formdata.get('image');
                        const matrixCode = formdata.get('matrixCode');
                        const description = formdata.get('description');
                        console.log({ tokenName, creatorName, image, matrixCode, previewItem: arcanaGenTempData.previewItem, description })
                        setTokenName(tokenName);
                        setCreatorName(creatorName);
                        setImage(image);
                        setMatrixCode(matrixCode);
                        setDescription(description);
                        setPreviewItem(arcanaGenTempData.previewItem);
                        console.log(user_symbol)
                        // setTimeout(() => setLoadingOnStart(true), 3000);
                        setLoadingOnStart(true);
                        onClickGenerate();
                    }
                }
                // const { tokenName, creatorName, image, matrixCode, previewItem, description } = state.data;
            }
        }
        getTempData();
    }, [state])

    useEffect(() => {
        if (loadingOnStart && provider) {
            onLoadProvider(provider);
        }
    }, [loadingOnStart, provider])
    useEffect(() => {
        if (initManaAddress && !manaAddress) {
            loadByManaAddress(initManaAddress)
        } else if (!manaAddress && getWalletAddress()) {
            setSeed(pseed)
            setManaValue(pmanaValue != 'null' ? pmanaValue : null)
            setManaInfo(pmanaInfo != 'null' ? pmanaInfo : null)
            setEggId(pegg_id)
            setSignature(psig)
            setToAddress(ptoAddress)
            setUserSymbol(puser_symbol != 'null' ? puser_symbol : null)

            createManaMetadata()


        }

        // if(window.location.href.match(/\/debug/)){
        //     setDebug(true)
        //     setDebugMode('success')
        // }

    }, []);

    useEffect(() => {
        if (!toAddress) {
            return;
        }
        if (user_symbol) {
            setSymbol(user_symbol)
        }
        setLoading(false);


        // 1時間後
        let ts = (new Date()).getTime()
        //3秒
        //let ts_after = ts + (1000 * 3 *  1);
        //1時間
        let ts_after = ts + (1000 * 60 * 30 * 1);
        console.log(new Date(ts_after))
        if (toAddress && !toAddress.match(/^0x\w+$/)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "To address is invalid",
            })
        }

        setTimeoutDt(new Date(ts_after))
        if (process.env.REACT_APP_API_DOMAIN.match(/localhost/)) {
            console.log("DEBUG MODE")
            setDebug(false)
            setDebugMode('success')
            // setDebugMode('error')
        }

        if (!isRequested) {
            addRequest()
            setIsRequested(true);
        }
    }, [toAddress])
    useEffect(() => {
        if (manaAddress && provider && isToAddressValid && ptoAddress) {
            const url = API_DOMAIN + "arcana-register-mana";
            const formData = new FormData();
            formData.append("toAddress", toAddress);
            formData.append("manaAddress", manaAddress);
            formData.append("seed", seed);
            formData.append("egg_id", egg_id);
            formData.append("sig", sig);

            axios.post(url, formData).then(res => {
                console.log(res)
            })
            // const rpc = new RPC(provider);
            // rpc.registerMana(toAddress,manaAddress,egg_id,seed,sig,function (transactionHash) {
            //
            //     let checkTransaction = () => {
            //         rpc.getTransaction(transactionHash).then(res => {
            //             console.log("GET TRANSACTION")
            //             console.log(res)
            //             if (!res ) {
            //                 setTimeout(checkTransaction, 2000);
            //                 return;
            //             }
            //             setPreLoading(false)
            //             console.log(res)
            //             console.log("MANA info saved!")
            //         })
            //     }
            //     checkTransaction();
            //
            //     console.log(transactionHash)
            // }, async function (error) {
            //     console.log("ERROR!")
            //     try {
            //         console.log(await rpc.decodeError(error))
            //         console.log(error.data.message ? error.data.message : error.data ? error.data : error.message ? error.message : '')
            //     } catch (e) {
            //
            //     }
            //
            //     if (error.receipt && error.receipt.revertReason) {
            //         error.receipt.revertReasonDecoded = await rpc.decodeError(error);
            //         //console.log(web3.utils.hexToAscii(error.receipt.revertReason))
            //     }
            //
            //
            //     //window.location.href = callbackUrlAddHashParam + 'null'
            // })

        }

    }, [manaAddress, provider, isToAddressValid]);


    function createManaMetadata() {
        const url = API_DOMAIN + "arcana-mana-metadata";
        let eggId = pegg_id;
        let signature = psig;

        let version = '1'
        let info = pmanaInfo
        let value = pmanaValue
        let beneficiary = ptoAddress
        let symbol = puser_symbol
        let seed = pseed
        const data = {
            version,
            eggId, beneficiary, seed, signature,
            'mana': {
                info, value, symbol
            },
        };

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {

            if (response.data) {
                // setGames(data);
                setManaAddress(response.data.hash)

            } else {
                console.log("error");
            }
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            })
        });

    }

    function addRequest() {
        const url = API_DOMAIN + "arcana-gen-request";
        const formData = new FormData();
        formData.append("eggId", egg_id);
        formData.append("seed", seed);
        formData.append("sign", sig);
        formData.append("toAddress", toAddress);
        formData.append("requestId", requestId);
        if (!requestId) {
            return;
        }

        axios
            .post(url, formData)
            .then((response) => {
                if (response.data) {
                    // setGames(data);
                    // console.log(data);

                } else {
                    console.log("error");
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                })
            });
    }

    function setTransactionIdRequest(transactionId, status, error_txt = null) {
        const url = API_DOMAIN + "arcana-set-transaction";
        const formData = new FormData();
        formData.append("eggId", egg_id);
        formData.append("seed", seed);
        formData.append("sign", sig);
        formData.append("toAddress", toAddress);
        formData.append("requestId", requestId ?? '');
        formData.append("errorTxt", error_txt);
        if (transactionId) {
            formData.append("transactionId", transactionId);
        }

        if (debug && debugMode == 'error') {
            formData.append("status", 'error');
        }
        if (status) {
            formData.append("status", status);
        }
        return axios
            .post(url, formData)

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                })
            });
    }

    const handleSubmit = async (provider) => {

        const rpc = new RPC(provider);


        const address = await rpc.getAccounts()

        if (!provider) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "provider not initialized yet",
            })
            setLoading(false)
            return;
        }

        console.log(creatorName)
        console.log(address)

        const url = API_DOMAIN + "arcana-metadata";
        const formData = new FormData();
        formData.append("eggId", egg_id);
        if (image) {
            formData.append("file", image);
        }
        formData.append("tokenName", tokenName);
        formData.append("creatorName", creatorName.trim() == "" ? address : creatorName);
        formData.append("description", description);
        formData.append("debug", debug ? 'true' : 'false');

        setLoading(true)
        axios
            .post(url, formData)
            .then((response) => {
                if (response.data && response.data.hash) {
                    setStep(1)
                    generateArcana(response.data.hash, provider)
                    // setGames(data);
                    // console.log(data);

                } else {
                    console.log("error");
                    setLoading(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message ?? 'Unknown Error',
                })
                setLoading(false)
            });
    }

    const toFinishStep = async (res) => {

    }

    const generateArcana = async (metadataHash, provider) => {
        const video = selectVideo(seed);
        setVideoName(video);
        removeValue('redirect_uri');
        if (requestId && !requestId.match(/^\-?\d+$/)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "RequestId is invalid.",
            })
            return;
        }

        const rpc = new RPC(provider);
        const address = await rpc.getAccounts()
        console.log(address, egg_id, toAddress, seed, metadataHash, sig)
        let callbackUrlAddHashParam = query.get('r')
        if (callbackUrlAddHashParam) {
            if (callbackUrlAddHashParam.match(/\?/)) {
                callbackUrlAddHashParam += "&requestId=" + requestId + "&txHash=";
            } else {
                callbackUrlAddHashParam += "?requestId=" + requestId + "&txHash=";
            }
        }
        let is_error = false;
        rpc.generateArkana(egg_id, toAddress, seed, metadataHash, sig, debugMode, function (transactionHash) {
            //here
            setTransactionIdRequest(transactionHash).then((response) => {
                if (is_error) {
                    setFinished(true);
                    setSucceed(false);
                    console.log('error!')
                    return;
                }
                setStep(2)
                console.log(transactionHash)
                const formData = new FormData();
                formData.append("eggId", egg_id);
                formData.append("seed", seed);
                formData.append("sign", sig);
                formData.append("toAddress", toAddress ?? address);
                formData.append("requestId", requestId ?? '');
                if (debug) {
                    formData.append("debugMode", debugMode);
                }
                let checkTransaction = () => {
                    const url = API_DOMAIN + "arcana-status-by-sign";
                    axios
                        .post(url, formData)
                        .then(async (response) => {
                            console.log('SUCCEED POST')
                            console.log(response.data);
                            const status = response.data.data.status;
                            console.log(status)
                            if (!requestId || status == 'transaction_created') {
                                console.log("DONE!")
                                console.log("aha", transactionHash);
                                const res = await rpc.getTransaction(transactionHash)
                                console.log("GET TRANSACTION")
                                console.log(res)
                                if (!res || res.logs.length == 0) {
                                    setTimeout(checkTransaction, 2000);
                                    return;
                                }
                                console.log(res)
                                let arcanaTokenId = null
                                res['logs'].forEach(log => {
                                    console.log(log)
                                    if (log['address'] === REACT_APP_ARCANA_CONTRACT_ADDRESS) {
                                        arcanaTokenId = parseInt(log['topics'][log['topics'].length - 1], 16)
                                    }
                                })
                                console.log(arcanaTokenId)
                                setTokenId(arcanaTokenId)
                                if (callbackUrlAddHashParam) {
                                    setCallbackUrl(callbackUrlAddHashParam + transactionHash)
                                }
                                setStep(3)
                                setSucceed(true)
                                setFinished(true)
                            } else if (status == 'error') {
                                if (callbackUrlAddHashParam) {
                                    setCallbackUrl(callbackUrlAddHashParam + 'null')
                                }
                                setFinished(true)
                                setSucceed(false)
                                // Swal.fire({
                                //     icon: "error",
                                //     title: "Error",
                                //     text: 'transaction error'
                                // })
                            } else {
                                //wait transaction
                                setTimeout(checkTransaction, 2000);
                            }
                        })
                        .catch((error) => {
                            console.log('ERROR POST')
                            console.log(error)
                            Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: error.response.data.message,
                            })
                            if (callbackUrlAddHashParam) {
                                setCallbackUrl(callbackUrlAddHashParam + 'null')
                            }
                            setFinished(true)
                            setSucceed(false)
                        });
                }
                checkTransaction();

                return
            })
        }, async function (error) {
            console.log("ERROR!")
            if (callbackUrlAddHashParam) {
                setCallbackUrl(callbackUrlAddHashParam + 'null')
            }
            setFinished(true)
            setSucceed(false)
            is_error = true
            try {
                console.log(await rpc.decodeError(error))
                console.log(error.data.message ? error.data.message : error.data ? error.data : error.message ? error.message : '')
            } catch (e) {

            }

            if (error.receipt && error.receipt.revertReason) {
                error.receipt.revertReasonDecoded = await rpc.decodeError(error);
                //console.log(web3.utils.hexToAscii(error.receipt.revertReason))
            }
            setTransactionIdRequest(null, 'error', JSON.stringify(error)).then((response) => {
                console.log("setTransactionIdRequest res", response)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Generate ARCANA Error.",
                })
            })
            setSucceed(false)

            //window.location.href = callbackUrlAddHashParam + 'null'
        }, debug).then(res => {
            console.log(res)
        })

        return;
        // if(!ret){
        // }else{
        //     setFinished(true)
        //
        //     return
        //     return;
        //     console.log(ret)
        //     console.log(ret.events)
        //     let last_event = ret.events[ret.events.length-1];
        //     console.log(last_event)
        //     let last_topics = last_event['raw']['topics'][last_event['raw']['topics'].length-1]
        //     console.log(last_topics)
        //
        //     const tokenId = parseInt(last_topics,16)
        //     setTokenId(tokenId)
        //     setFinished(true)
        //     console.log("TOKEN ID IS:"+tokenId)
        // }
        // console.log(ret);
    };

    const onLoadProvider = (provider) => {
        if (provider) {
            setProvider(provider)

            handleSubmit(provider)
        }

    }

    const onClickGenerate = () => {
        // if(timeoutDt < new Date()){
        //     Swal.fire({
        //         icon: "error",
        //         title: "Timeout",
        //         text: "Process timeout.",
        //     })
        //     return;
        // }



        if (!debug) {


        }
        setLoading(true)
        return true;

    }

    const convertBase64 = async (file) => {
        const base64 = await convertFileToBase64(file);
        setPreviewItem(item => JSON.parse(JSON.stringify(Object.assign(item, { image: base64, imageName: file.name, imageType: file.type, blob: convertFileToBlob(file) }))));
    };

    const getImgURL = async (file) => {
        const url = createImgUrl(file);
        setPreviewItem(item => JSON.parse(JSON.stringify(Object.assign(item, { image: url, imageName: file.name, imageType: file.type, blob: convertFileToBlob(file) }))));
    };

    useEffect(() => {
        // convert only in large device in which image is previewed to prevent unnecessary blob or base64 conversion.
        if (image && window.innerWidth >= 992) {
            console.log({ image });
            try {
                getImgURL(image);
            } catch (e) {

            }
            // convertBase64(image);
        }
    }, [image])

    useEffect(() => {
        setPreviewItem(item => JSON.parse(JSON.stringify(Object.assign(item, { name: tokenName || "????????????" }))));
    }, [tokenName])

    const toggleManaOpen = () => setManaOpen(open => !open);

    const onClickImageRestriction = () => {
        SwalWarn.fire({
            title: content.descUploadRestriction
        })
    }

    const CreateArcanaBtn = () =>
        <W3authLoginButton
            onClickGenerate={onClickGenerate}
            setFormData={() => { setArcanaGenTempData({ data: toFormData({ tokenName, creatorName, image, matrixCode, description }), previewItem }) }}
            onLoadProvider={onLoadProvider}
            generateButtonFlg={true}
            // loginOnStart={state ? state.returnFromLogin : false}
            loginOnStart={false}
            disabled={!isToAddressValid && !isDebug}
        // loginOnStart={loadingOnStart}
        />

    const SetManaBtn = () =>
        <LaddaButton className=" btn btn-custom-5 w-100 mt-3" onClick={() => { }}>
            {translations.formatString(buttonlist.confirmBtn)}
        </LaddaButton>

    return (
        <div className={`${type === '1' ? 'clip-left-bg-white' : type === '2' ? 'clip-left-bg-blue' : ''}`}>
            <AppWrapper title={strings.formatString(content.headerTitle)}
                noLayout={false}
                disableSidebar={true}
                smallFont={true}
                hideBottomNav={true}
                hideNav={true}
                enableAddtionalNav={false}
                className='px-lg-5 pt-lg-3'
                mainbodyClassName='mt-lg-4 pb-lg-3'
                additionalBottom={
                    (!loading && !succeed) ? <div className='d-flex d-lg-none justify-content-center'>
                        <CreateArcanaBtn />
                    </div> : false

                }
                isSpecCodeD={type === '1' || type === '2' ? true : false}
            >

                <>
                    {preloading ? <OnLoading /> :
                        <>
                            {
                                loading ?
                                    <ProgressLoadingParallel videoName={videoName} step={step} total={4} finished={finished} tokenId={tokenId} isSucceed={succeed}
                                        callbackUrl={callbackUrl} text="generating" /> :
                                    <div className="arcanagen">
                                        <div className="row item-detail">
                                            <div className="d-none d-lg-block" style={{ flex: '0 0 360px' }}>
                                                <ItemDetailCard item={previewItem} isArcanaGen />
                                            </div>
                                            <div className="col pt-4 pt-lg-0 ps-lg-4" style={{ maxWidth: '800px' }}>
                                                <div className="row">
                                                    <div className="col-12 d-none d-lg-block">
                                                        {/* <div className='divider d-none d-lg-block divider-fixed' style={{ maxWidth: '60%' }}></div> */}
                                                        <div className='divider w-100 mx-0' style={{ borderWidth: 0.5 }}></div>
                                                    </div>
                                                    <div className="col-12 arcana-gen-form">
                                                        {
                                                            manaOpen ?
                                                                <div className="row gx-35px">
                                                                    <div className="col-12">
                                                                        <div className="d-flex flex-row align-items-center mb-4">
                                                                            <BackIcon className={`pointer ${type === '1' ? 'green-icon' : 'white-icon'}`} onClick={toggleManaOpen} />
                                                                            <h4 className="ms-3 pt-1">{translations.formatString(content.labelSetMana)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6">
                                                                        {/* <TextBox className='f-regular fs-18' containerClassName='mb-1' label={strings.formatString(content.labelManaAddress)} placeholder={""} value={''} /> */}
                                                                        <BorderBoxText
                                                                            titleComponent={<FormLabel label={strings.formatString(content.labelManaAddress)} />}
                                                                            value={manaAddress} style={{ marginBottom: 20 }} className={type === '1' ? 'green-stroke-icon' : type === '2' ? 'white-icon' : ''} />
                                                                        {/*<div className={"d-flex flex-row align-items-center justify-content-end mb-3"}>*/}
                                                                        {/*    <span style={{ fontSize: 13, textTransform: 'inherit', maxWidth: 'unset' }} className='fw-400 f-regular ms-1 attribute-value'>{content.descManaAddress}</span>*/}
                                                                        {/*</div>*/}
                                                                        <TextBox className='f-regular fs-18' containerClassName='mb-1'
                                                                            label={strings.formatString(content.labelSymbol)} placeholder={""}
                                                                            onChange={e => setSymbol(e.target.value)} value={symbol} disabled={true} />
                                                                        <p style={{ fontSize: 13 }}
                                                                            className="fw-500 f-regular mb-3 text-end">{content.descSymbol}</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6">
                                                                        <TextBox className='f-regular fs-18' containerClassName='mb-3'
                                                                            label={strings.formatString(content.labelMana)} placeholder={""}
                                                                            onChange={e => setSeedValue(e.target.value)} value={manaValue}
                                                                            disabled={true} />
                                                                        <TextArea className='f-regular fs-18' containerClassName='mb-3'
                                                                            label={strings.formatString(content.labelManaInformation)} placeholder={""}
                                                                            value={manaInfo} disabled={true} />
                                                                        {/*<div className='d-none d-lg-flex justify-content-between mt-3'>*/}
                                                                        {/*    <SetManaBtn />*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row gx-35px">
                                                                    <span className="fw-500 attribute-value" style={{
                                                                        fontSize: 16,
                                                                        maxWidth: "100%",
                                                                        marginBottom: 12
                                                                    }}>{translations.formatString(content.descArcanaGen)}</span>
                                                                    <div className="col-12">
                                                                        <TextBox className='fs-18 pointer bg-transparent border-color' containerClassName='pointer mb-3'
                                                                            value={strings.formatString(content.labelSetMana)} onClick={toggleManaOpen} onClickIcon={toggleManaOpen}
                                                                            icon={<ExportIcon className={type === '1' ? 'green-stroke-icon' : type === '2' ? 'sky-blue-stroke-icon' : ''} />} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-6">
                                                                        <TextBox className='fs-18' containerClassName='mb-3'
                                                                            label={strings.formatString(content.labelSetArcanaName)}
                                                                            placeholder={"ARCANA’s name"} onChange={e => setTokenName(e.target.value)}
                                                                            value={tokenName} icon={<Edit2Icon className={type === '1' || type === '2' ? 'inactive-icon' : 'gradient-icon'} />} />
                                                                        <TextBox className='fs-18' containerClassName='mb-3'
                                                                            label={strings.formatString(content.labelCreatorName)}
                                                                            placeholder={"Creator’s name"} onChange={e => setCreatorName(e.target.value)}
                                                                            value={creatorName} icon={<Edit2Icon className={type === '1' || type === '2' ? 'inactive-icon' : 'gradient-icon'} />} />
                                                                        <FileInput className='mb-3' label={strings.formatString(content.labelSetImages)}
                                                                            placeholder={"choose image"} value={image}
                                                                            onChange={(file) => setImage(file)}
                                                                            warningText={content.labelUploadRestriction}
                                                                            onClickWarning={onClickImageRestriction} iconclass={type === '1' || type === '2' ? 'stroke-inactive-icon' : ''} warningIconClass={type === '1' ? 'green-icon' : type === '2' ? 'sky-blue-icon' : 'gradient-icon'} />
                                                                        <WalletAddress titleClassName={'m-l-0'} style={{ marginBottom: 20 }} disabledIcon />
                                                                    </div>
                                                                    <div className="col-12 col-lg-6">
                                                                        <TextBox className='fs-18' containerClassName='mb-3 disabled'
                                                                            label={strings.formatString(content.labelSetPersona)} disabled
                                                                            placeholder={"Persona's name"} value={''} icon={<Edit2Icon className={type === '1' || type === '2' ? 'inactive-icon' : 'gradient-icon'} />} />
                                                                        <TextArea className='fs-18' containerClassName='mb-2'
                                                                            label={strings.formatString(content.labelSetDescription)}
                                                                            placeholder={"description"} onChange={e => setDescription(e.target.value)}
                                                                            value={description} icon={<Edit2Icon className={type === '1' || type === '2' ? 'inactive-icon' : 'gradient-icon'} />} />
                                                                        <div className='d-none d-lg-flex justify-content-between'>
                                                                            {wallet_address ?
                                                                                <CreateArcanaBtn />
                                                                                : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="col-12 d-none d-lg-block">
                                                        <div className='divider w-100 mx-0'
                                                            style={{ borderWidth: 0.5 }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }</>
                    }</>
            </AppWrapper>
        </div>
    );
}

export default ArcanaGen;

function selectVideo(seeds) {
    const categories = ['Forest', 'Galaxy', 'Smoke'];
  
    let rRate, srRate, ssrRate;
  
    if (seeds <= 10000) {
      rRate = 0.98;
      srRate = 0.01;
      ssrRate = 0.01;
    } else if (seeds > 10000 && seeds <= 50000) {
      rRate = 0.5;
      srRate = 0.49;
      ssrRate = 0.01;
    } else if (seeds > 50000 && seeds <= 100000) {
      rRate = 0.35;
      srRate = 0.6;
      ssrRate = 0.05;
    } else if (seeds > 100000 && seeds <= 250000) {
      rRate = 0.1;
      srRate = 0.8;
      ssrRate = 0.1;
    } else if (seeds > 250000 && seeds <= 500000) {
      rRate = 0.05;
      srRate = 0.7;
      ssrRate = 0.25;
    } else if (seeds > 500000 && seeds <= 1000000) {
      rRate = 0.01;
      srRate = 0.49;
      ssrRate = 0.5;
    } else if (seeds > 1000000){
      rRate = 0.01;
      srRate = 0.14;
      ssrRate = 0.85;
    }
  
    // Select a category randomly
    const category = categories[Math.floor(Math.random() * categories.length)];
  
    // Generate a random number to determine the rarity (R, SR, or SSR)
    const rarity = Math.random();
  
    // Determine the selected video based on rarity
    let selectedVideo;
    if (rarity < rRate) {
      selectedVideo = `${category}_R.mov`;
    } else if (rarity < rRate + srRate) {
      selectedVideo = `${category}_SR.mov`;
    } else {
      selectedVideo = `${category}_SSR.mov`;
    }
  
    return selectedVideo;
  }