import ItemImg from './assets/images/item_img.png';
import { toBigNumber } from './components/common/commonFnc';

const data = {
    "tokens": [
        {
            "token_id": 1000,
            "image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": "https://...",
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 2,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": "https://...",
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 1,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": "https://...",
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 1,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": "https://...",
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 1,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        }
    ],
    "transactions": [
        {
            "id": "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            "bid_order_id": 21,
            "ask_order_id": 22,
            "token_id": "1",
            "token_type_id": 1,
            "amount": 1000,
            "status": "traded",
            "status_jp": "JP Traded",
            "error": null,
            "from": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
            "to": "0x81A223bC34609EF4f871A249558133874Ac91A38",
            "matched_at": "2022-06-03T14:21:10.000000Z",
            "token_received_at": "2022-06-03T14:21:10.000000Z",
            "money_received_at": "2022-06-03T14:21:10.000000Z",
            "token_sent_at": "2022-06-03T14:21:10.000000Z",
            "tradeded_at": "2022-06-03T14:21:10.000000Z",
            "cancelled_at": "2022-06-03T14:21:10.000000Z",
            "created_at": "2022-06-03T14:21:10.000000Z",
            "updated_at": "2022-06-03T14:21:10.000000Z",
            "token": {
                "token_id": 1,
                "image": ItemImg,
                "name": "Arcana-004",
                "owner": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
                "creator": "0x81A223bC34609EF4f871A249558133874Ac91A38",
                "metadata": {
                    "name": "Arcana-004",
                    "description": "This is Arcana NFT.",
                    "image": "ipfs://QmaDQofsxTRDZUegwPuBp7yfyKUKwifEaUrG97VzSDavZp"
                },
                "token_type_id": 1,
                "ask_price": 0,
                "bid_price": 100,
                "is_bookmarked": false
            }
        },
        {
            "id": "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb",
            "bid_order_id": 21,
            "ask_order_id": 22,
            "token_id": "2",
            "token_type_id": 1,
            "amount": 1000,
            "status": "traded",
            "status_jp": "JP Traded",
            "error": null,
            "from": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
            "to": "0x81A223bC34609EF4f871A249558133874Ac91A38",
            "matched_at": "2022-06-03T14:21:10.000000Z",
            "token_received_at": "2022-06-03T14:21:10.000000Z",
            "money_received_at": "2022-06-03T14:21:10.000000Z",
            "token_sent_at": "2022-06-03T14:21:10.000000Z",
            "tradeded_at": "2022-06-03T14:21:10.000000Z",
            "cancelled_at": "2022-06-03T14:21:10.000000Z",
            "created_at": "2022-06-03T14:21:10.000000Z",
            "updated_at": "2022-06-03T14:21:10.000000Z",
            "token": {
                "token_id": 2,
                "image": ItemImg,
                "name": "Arcana-004",
                "owner": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
                "creator": "0x81A223bC34609EF4f871A249558133874Ac91A38",
                "metadata": {
                    "name": "Arcana-004",
                    "description": "This is Arcana NFT.",
                    "image": "ipfs://QmaDQofsxTRDZUegwPuBp7yfyKUKwifEaUrG97VzSDavZp"
                },
                "token_type_id": 1,
                "ask_price": 0,
                "bid_price": 100,
                "is_bookmarked": false
            }
        },
        {
            "id": "cccccccccccccccccccccccccccccccccccccc",
            "bid_order_id": 21,
            "ask_order_id": 22,
            "token_id": "3",
            "token_type_id": 1,
            "amount": 1000,
            "status": "traded",
            "status_jp": "JP Traded",
            "error": null,
            "from": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
            "to": "0x81A223bC34609EF4f871A249558133874Ac91A38",
            "matched_at": "2022-06-03T14:21:10.000000Z",
            "token_received_at": "2022-06-03T14:21:10.000000Z",
            "money_received_at": "2022-06-03T14:21:10.000000Z",
            "token_sent_at": "2022-06-03T14:21:10.000000Z",
            "tradeded_at": "2022-06-03T14:21:10.000000Z",
            "cancelled_at": "2022-06-03T14:21:10.000000Z",
            "created_at": "2022-06-03T14:21:10.000000Z",
            "updated_at": "2022-06-03T14:21:10.000000Z",
            "token": {
                "token_id": 3,
                "image": ItemImg,
                "name": "Arcana-004",
                "owner": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
                "creator": "0x81A223bC34609EF4f871A249558133874Ac91A38",
                "metadata": {
                    "name": "Arcana-004",
                    "description": "This is Arcana NFT.",
                    "image": "ipfs://QmaDQofsxTRDZUegwPuBp7yfyKUKwifEaUrG97VzSDavZp"
                },
                "token_type_id": 1,
                "ask_price": 0,
                "bid_price": 100,
                "is_bookmarked": false
            }
        },
        {
            "id": "dddddddddddddddddddddddddddddddddddddd",
            "bid_order_id": 21,
            "ask_order_id": 22,
            "token_id": "4",
            "token_type_id": 1,
            "amount": 1000,
            "status": "traded",
            "status_jp": "JP Traded",
            "error": null,
            "from": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
            "to": "0x81A223bC34609EF4f871A249558133874Ac91A38",
            "matched_at": "2022-06-03T14:21:10.000000Z",
            "token_received_at": "2022-06-03T14:21:10.000000Z",
            "money_received_at": "2022-06-03T14:21:10.000000Z",
            "token_sent_at": "2022-06-03T14:21:10.000000Z",
            "tradeded_at": "2022-06-03T14:21:10.000000Z",
            "cancelled_at": "2022-06-03T14:21:10.000000Z",
            "created_at": "2022-06-03T14:21:10.000000Z",
            "updated_at": "2022-06-03T14:21:10.000000Z",
            "token": {
                "token_id": 4,
                "image": ItemImg,
                "name": "Arcana-004",
                "owner": "0x0F056eab0D6D063Ca271cfc0db4C02a441380353",
                "creator": "0x81A223bC34609EF4f871A249558133874Ac91A38",
                "metadata": {
                    "name": "Arcana-004",
                    "description": "This is Arcana NFT.",
                    "image": "ipfs://QmaDQofsxTRDZUegwPuBp7yfyKUKwifEaUrG97VzSDavZp"
                },
                "token_type_id": 1,
                "ask_price": 0,
                "bid_price": 100,
                "is_bookmarked": false
            }
        }
    ],
    "personas": [
        {
            "token_id": 1000,
            "image": ItemImg,
            "ipfs_image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": ItemImg,
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 2,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "ipfs_image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": ItemImg,
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 2,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "ipfs_image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": ItemImg,
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 2,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        },
        {
            "token_id": 1000,
            "image": ItemImg,
            "ipfs_image": ItemImg,
            "name": "Zombie Siblings",
            "owner": "0x0cf060573b0f776fe1ac37b9896e963a820abcde",
            "creator": "John Doe",
            "metadata": {
                "image": ItemImg,
                "name": "xxxxxx"
            },
            "bid_price": 100000,
            "ask_price": 160000,
            "is_bookmarked": true,
            "token_type_id": 2,
            "elements": "RED FIRE",
            "greenStar": 6,
            "bloodline": "DANCER'S IMAGE",
            "birthday": "2022.10.23",
            "ABS": 200,
            "DFT": 100,
            "EXP": 1406,
            "FOR": 630,
            "INT": 2629,
            "MND": 3917,
            "feature": ["誕生日から14日間ロック", "Draw Chain 強化（1）", "ANMステーキング"],
            "details": {
                "createdby": "WOLF NFTs",
                "contract": "0x495f…7b5e",
                "blockchain": "ANICANA",
                "square": "MIF Game Studio",
                "token_standard": "MX-1155",
                "mx_creator_bonus": "0.01 ANM"
            }
        }
    ],
    "dummy_persona": {
        "token_id": "42949804033",
        "owner": "0x30fFd54fE0D1aeFF01ef556Ff359517591Fa1Db4",
        "elements": 1,
        "FOR": -10,
        "ABS": 9,
        "DFT": 0,
        "MND": 0,
        "INT": 6,
        "EXP": 0,
        "anima": 1000000000000000000,
        "absorbed_count": 1,
        "birthday": "2023-04-20",
        "birthday_datetime": "2023-04-20T15:42:16",
        "metadata": {
            "name": "persona",
            "creator": "popo",
            "image": "QmYCQ3oX4M8snuesMah8cCfH5z9wuDWZm9rxLmZT5z1BzH",
            "description": ""
        },
        "name": "persona",
        "creator": "popo",
        "image": "https://ark2-ipfs.anicana-api.akqjt.io/ipfs/QmYCQ3oX4M8snuesMah8cCfH5z9wuDWZm9rxLmZT5z1BzH",
        "token_type_id": "3",
        "greenStar": null,
        "feature": [],
        "details": {
            "blockchain": "ANICANA",
            "token_standard": "ERC-721"
        }
    },
    "games": {
        "data": [
            {
                "id": 1,
                "thumbnail_image_url": null,
                "square_thumbnail_image_url": null,
                "top_image_url": null,
                "top_pc_image_url": null,
                "title": "レヴィアス株式会社／法定通貨で実現できる世界初のX to Earn プラットフォーム「アニカナ」を開発",
                "title_en": "レヴィアス株式会社／法定通貨で実現できる世界初のX to Earn プラットフォーム「アニカナ」を開発",
                "short_body": null,
                "short_body_en": null,
                "url": "https://www.dota2.com/home",
                "game_language": null,
                "recommend": "recommend",
                "topic": "topic",
                "show_product": "show",
                "show_align": "horizontal",
                "view_count": 9,
                "top_game": 1,
                "trend": 2,
                "published": "2023-04-25T05:29:00.000000Z",
                "created_at": "2023-04-25T05:29:51.000000Z",
                "game_category": "Game Category Two",
                "game_maker_id": 1,
                "game_maker_name": "Game Marker One",
                "game_maker_icon": "https://anikana-portal-test.s3.ap-northeast-1.amazonaws.com/gmaemakericon/1/646f3f6131d10Attack_on_Titan___Key_Art_EN.jpg",
                "types": {
                    "id": 1,
                    "name": "Game Type One EN",
                    "language": "en",
                    "created_at": "2023-06-14T10:47:57.000000Z",
                    "updated_at": "2023-06-14T10:47:57.000000Z"
                },
                "types_jp": {
                    "id": 2,
                    "name": "Game Type One JP",
                    "language": "jp",
                    "created_at": "2023-06-14T10:47:57.000000Z",
                    "updated_at": "2023-06-14T10:47:57.000000Z"
                }
            },
            {
                "id": 5,
                "thumbnail_image_url": null,
                "square_thumbnail_image_url": null,
                "top_image_url": null,
                "top_pc_image_url": null,
                "title": "Test News Categories Update",
                "title_en": "Test News Categories Update",
                "short_body": "TEst",
                "short_body_en": null,
                "url": "https://www.dota2.com/home",
                "game_language": null,
                "recommend": "recommend",
                "topic": "topic",
                "show_product": "show",
                "show_align": "horizontal",
                "view_count": 0,
                "top_game": 1,
                "trend": 0,
                "published": "2023-05-30T14:04:00.000000Z",
                "created_at": "2023-05-30T14:05:04.000000Z",
                "game_category": "Game Category Two",
                "game_maker_id": 1,
                "game_maker_name": "Game Marker One",
                "game_maker_icon": "https://anikana-portal-test.s3.ap-northeast-1.amazonaws.com/gmaemakericon/1/646f3f6131d10Attack_on_Titan___Key_Art_EN.jpg",
                "types": null,
                "types_jp": null
            },
            {
                "id": 2,
                "thumbnail_image_url": "https://anikana-portal-test.s3.ap-northeast-1.amazonaws.com/game/thumbnail/2/6447659a13d2aironmanmarvelavangersi44208.jpg",
                "square_thumbnail_image_url": null,
                "top_image_url": null,
                "top_pc_image_url": null,
                "title": "Game Two",
                "title_en": "Game Two",
                "short_body": null,
                "short_body_en": null,
                "url": null,
                "game_language": "en",
                "recommend": "recommend",
                "topic": "non-topic",
                "show_product": "show",
                "show_align": "horizontal",
                "view_count": 0,
                "top_game": 0,
                "trend": 3,
                "published": "2023-04-25T05:30:00.000000Z",
                "created_at": "2023-04-25T05:31:06.000000Z",
                "game_category": "Game Category One",
                "game_maker_id": 2,
                "game_maker_name": "Game Marker Two",
                "game_maker_icon": "https://anikana-portal-test.s3.ap-northeast-1.amazonaws.com/gmaemakericon/2/646f3eaed89c942d7beeaf3251080f7df2c6532ab3f57.jpg",
                "types": null,
                "types_jp": null
            },
            {
                "id": 3,
                "thumbnail_image_url": null,
                "square_thumbnail_image_url": null,
                "top_image_url": null,
                "top_pc_image_url": null,
                "title": "Testing Game",
                "title_en": "Testing Game",
                "short_body": null,
                "short_body_en": null,
                "url": null,
                "game_language": "JP",
                "recommend": "recommend",
                "topic": "topic",
                "show_product": "show",
                "show_align": "horizontal",
                "view_count": 0,
                "top_game": 0,
                "trend": 0,
                "published": "2023-04-27T13:15:00.000000Z",
                "created_at": "2023-04-27T13:15:42.000000Z",
                "game_category": null,
                "game_maker_id": 1,
                "game_maker_name": "Game Marker One",
                "game_maker_icon": "https://anikana-portal-test.s3.ap-northeast-1.amazonaws.com/gmaemakericon/1/646f3f6131d10Attack_on_Titan___Key_Art_EN.jpg",
                "types": null,
                "types_jp": null
            }
        ],
        "links": {
            "first": "http://localhost:8000/api/games?page=1",
            "last": "http://localhost:8000/api/games?page=1",
            "prev": null,
            "next": null
        },
        "meta": {
            "current_page": 1,
            "from": 1,
            "last_page": 1,
            "links": [
                {
                    "url": null,
                    "label": "&laquo; Previous",
                    "active": false
                },
                {
                    "url": "http://localhost:8000/api/games?page=1",
                    "label": "1",
                    "active": true
                },
                {
                    "url": null,
                    "label": "Next &raquo;",
                    "active": false
                }
            ],
            "path": "http://localhost:8000/api/games",
            "per_page": 10,
            "to": 4,
            "total": 4
        }
    }
}

export default data;

const testCases = [
    { etherValue: 1000, decimalPlaces: 18 },
    { etherValue: 10000, decimalPlaces: 18 },
    { etherValue: 123456, decimalPlaces: 18 },
    { etherValue: 100, decimalPlaces: 18 },
    { etherValue: 20, decimalPlaces: 18 },
    { etherValue: 5, decimalPlaces: 18 },
    { etherValue: 0, decimalPlaces: 18 },
    { etherValue: 0.02, decimalPlaces: 18 },
    { etherValue: 0.0000008, decimalPlaces: 18 },
    { etherValue: 0.0000006, decimalPlaces: 18 },
    { etherValue: 1000.0000006, decimalPlaces: 18 },
    { etherValue: 0.000000000000000000002, decimalPlaces: 18 },
    { etherValue: 1000, decimalPlaces: 30 },
    { etherValue: 10000, decimalPlaces: 30 },
    { etherValue: 123456, decimalPlaces: 30 },
    { etherValue: 100, decimalPlaces: 30 },
    { etherValue: 20, decimalPlaces: 30 },
    { etherValue: 5, decimalPlaces: 30 },
    { etherValue: 0, decimalPlaces: 30 },
    { etherValue: 0.02, decimalPlaces: 30 },
    { etherValue: 0.0000008, decimalPlaces: 30 },
    { etherValue: 0.0000006, decimalPlaces: 30 },
    { etherValue: 1000.0000006, decimalPlaces: 30 },
];

// testCases.forEach(({ etherValue, decimalPlaces }) => {
//     const value = toBigNumber(etherValue, decimalPlaces);
//     console.log(`Input: ${etherValue}, ${decimalPlaces}`);
//     console.log(`Output: ${value}`);
//     console.log(`Length: ${value.length}`);
//     console.log('---');
// });