import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TransactionItem } from '../../components/common/Item'
import Swal from '../../components/common/Swal'
import { WalletTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { Web3authContext } from '../../context/web3authContext'
import { getData } from '../../services/fetch'
import RPC from "../../components/common/web3RPC";
import { appContext } from '../../context/appContext'
import { getWalletAddress } from "../../services/storage";
import { OnLoading } from '../../components/common/OnLoading'
import WalletTransactionDetail from './WalletTransactionDetail';
import { postData } from '../../services/fetch'
import { strings, translations } from "../../services/localization";
import { BN } from "web3-utils";
import { getTypeByContractAddress } from '../../components/common/enum'
import moment from 'moment'
import { useIntersectionWithLastRef } from '../../hooks/useIntersectionWithLastRef'

const WalletDrawHistory = () => {
    const { wallet_address, init } = useContext(Web3authContext);
    const [balances, setBalances] = useState({
        arcana: null,
        anima: null,
        token: null,
    })
    const [transactions, setTransactions] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [has_next_page, setHas_next_page] = useState(false);
    const transactionsRef = useRef();
    const walletRef = useRef();
    const has_next_page_ref = useRef();
    transactionsRef.current = transactions;
    walletRef.current = getWalletAddress();
    has_next_page_ref.current = has_next_page;
    const { timeZone } = useContext(appContext)
    const allTransactions = useRef();
    const walletDrawHistory = translations.walletDrawHistory;
    const wallet = translations.wallet

    const { ref } = useIntersectionWithLastRef(paginationLoading, has_next_page, () => {
        setPage(page => page + 1)
    })

    const getDrawHistories = async (wallet_address, pageNo) => {
        pageNo == 1 ? setLoading(true) : setPaginationLoading(true);
        const response = await getData(`drawchain-histories?page=${pageNo}&wallet_address=${wallet_address}`);
        if (response.ok) {
            setTransactions(response.data.data);
            setTotalCount(response.data.total);
            setHas_next_page(response.data.has_next_page);
        }
        setLoading(false);
        setPaginationLoading(false);
    }

    useEffect(() => {
        getDrawHistories(getWalletAddress(), page);
    }, [page]);

    return (
        <AppWrapper title={wallet.headerTitle}>
            <div id="wallet_dashboard" className='drawchain'>
                <WalletTitle title={strings.formatString(walletDrawHistory.title)} total={total_count} />
                {loading ? <OnLoading /> : (<div className='row'>
                    {
                        transactions && transactions.map((transaction, index) =>
                            <div key={`draw_history_item_index_${index}`} className='col-12 col-md-6 col-xl-4 history' ref={transactions === index + 1 ? ref : null}>
                                <DrawHistoryItem status="success" transaction={transaction} />
                            </div>
                        )
                    }
                    {
                        paginationLoading && (
                            <OnLoading
                                pRelative
                                noLabel
                                marginAuto
                                className={"m-0"}
                            />
                        )
                    }
                </div>)}
            </div>
        </AppWrapper>
    )
}

export default WalletDrawHistory

export const DrawHistoryItem = ({ transaction, wallet_address, status, onClick }) => {
    const content = translations.walletDrawHistory;
    return (
        <div className="card w-100 transaction-item mb-3" onClick={onClick}>
            <div className="card-body">
                <div className='d-flex flex-column'>
                    <span className={`f-regular fs-20 fw-400 test-start m-b-5 text-capitalize ${status}`}>{translations.formatString(content.labelSuccess)}</span>
                    <span className='f-condensed fs-12 fw-500 text-start mb-2'>{moment(transaction.createdAt).format("YYYY.MM.DD. HH:mm")}</span>
                    <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
                        <span className='label'>{translations.formatString(content.lableDrawChainName)}</span>
                        <span className='desc'>{transaction.drawchainName}</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
                        <span className='label'>{translations.formatString(content.lableUsedPersona)}</span>
                        <span className='desc'>{transaction.personaName}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
