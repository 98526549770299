/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../../style/ProductDetail.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { API_DOMAIN } from "../../config";
import moment from 'moment';
import "../../style/newdetail.css";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { ArcanaNoImgDetailIcon } from "../../components/common/Icon";
import { OnLoading } from "../../components/common/OnLoading";
import { appContext } from "../../context/appContext";
import { useContext } from "react";
import { SwalWrong } from "../../components/common/Swal";
import { useMediaQuery } from 'react-responsive'
import { translations } from "../../services/localization";
import { extractTextFromTags, parseToExecutableHTML } from "../../components/common/commonFnc";
import ReactStyleBlocker from "../../components/common/ReactStyleBlocker";

function NewsDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [newsDetail, setnewsDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language, timeZone } = useContext(appContext)
  const content = translations.newsList;

  const fetchNewDetailData = () => {
    setLoading(true);
    const shortForm = language === 'English' ? 'en' : 'jp'
    const url = API_DOMAIN + "news_detail/" + `${id}?language=` + shortForm + "&timezone=" + timeZone;
    axios
      .post(url)
      .then((response) => {
        if (response.data) {
          setnewsDetail(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        SwalWrong.fire({
          title: 'Error',
          text: 'Cant fetch data'
        })
        setLoading(false)
      });
  };

  const isPh = useMediaQuery({ maxWidth: 576 })
  const isPC = useMediaQuery({ minWidth: 992 })

  const topImage = !isPh && newsDetail.image_url_pc ? newsDetail.image_url_pc : newsDetail.image_url
  useEffect(() => {
    if (language && timeZone) {
      fetchNewDetailData();
    }
  }, [language, timeZone]);

  const description = extractTextFromTags(parseToExecutableHTML(newsDetail?.body || ''));
  const metaTitle = `${newsDetail?.title || ''}｜ANICANA θυρα（アニカナテュラー）`
  const metaDescription = `${description}｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています`

  return (
    <AppWrapper
      title={content.headerTitle}
      noPadding={true}
      isNoSpaceAround metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {loading
        ? <OnLoading />
        : <>
          <div className="d-flex flex-column">
            <div
              className={newsDetail.image_url ? "mb-2 w-100 d-flex align-items-center justify-content-center" : "mb-2 w-100 d-flex align-items-center justify-content-center border-box"}
            >
              {
                topImage ?
                  <img
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      aspectRatio: `13/${isPC ? 2 : 7.5}`,
                    }}
                    src={topImage}
                    className="w-100"
                  />
                  :
                  <ArcanaNoImgDetailIcon
                    className="img-fluid w-100 px-2"
                    style={{
                      objectFit: "cover",
                      objectPosition: "top",
                      aspectRatio: `13/${isPC ? 2 : 7.5}`,
                    }}
                  />
              }
            </div>

            <div className="d-flex justify-content-between px-4 my-2 mb-3">
              <span className="type-text fw-600 newsdetail-category" >{newsDetail.news_categories}</span>
              <span className="type-text fw-600 newsdetail-datetime" > {moment(newsDetail.published).format('YYYY.MM.DD HH:mm')}</span>

            </div>
            <div className="mb-0 mb-sm-3 px-4">
              <span className="f-regular" style={{ fontSize: '25px' }}>
                {newsDetail?.title}
              </span>
              <div style={{ fontSize: '14px' }} className="mt-3 text-justify kardust-expanded-light-font richtextbody">
                {newsDetail?.body && (
                  <ReactStyleBlocker>
                    <div dangerouslySetInnerHTML={{ __html: parseToExecutableHTML(newsDetail?.body) }}></div>
                  </ReactStyleBlocker>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </AppWrapper>
  );
}

export default NewsDetail;
