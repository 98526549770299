import moment from 'moment'
import React, { useEffect, useState } from 'react'

const Badge = () => {
    return (
        <div>Badge</div>
    )
}

export default Badge

export const NewBadge = ({ defaultValue = true, withinHour, date, className }) => {
    const [isNew, setIsNew] = useState(defaultValue);
    const handleNew = (withinHour, date) => {
        if (withinHour && date) {
            setIsNew(moment(date).isAfter(moment().add(-withinHour, 'hours')));
        } 
    }

    useEffect(() => {
        handleNew(withinHour, date);
    }, [withinHour, date])

    if (!isNew) return <></>

    return (
        <div className={`badge-custom-3 ${className}`}>
            New
        </div>
    )
}