import React, { useContext, useEffect } from 'react';
import RLaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { PersonaIcon } from './Icon';
import { cva } from "class-variance-authority";
import { appContext } from '../../context/appContext';


const buttonStyle = {
  margin: '10px 0'
};

const Button = ({ label, handleClick }) => {
  return (
    <div>
      <button
        className="button"
        style={buttonStyle}
        onClick={handleClick}
      >
        {label}
      </button>
    </div>
  )
}

export default Button;

export const BtnCustom = props => {
  return (
    <a {...props} onClick={props.onClick} className={`btn btn-primary btn-custom${props.variant ? "-" + props.variant : ''} ${props.className} flex-center`}>{props.children}</a>
  )
}

export const LaddaButton = props => {
  const { variant, className, children, type, loading, onClick, custom, ...rest } = props;
  return (
    <RLaddaButton data-style={EXPAND_LEFT} loading={loading} {...rest} type={type || 'button'} onClick={onClick} className={custom ? className : `btn btn-primary btn-custom${variant ? "-" + variant : ''} ${className}`}>{children}</RLaddaButton>
  )
}

export const ButtonWithIcon = ({ icon, text, className, variant = "cancel", ...rest }) => {
  return (
    <BtnCustom className={`flex-center ${className || ''}`} variant={variant} {...rest}>
      {icon}
      {text}
    </BtnCustom>
  )
}

export const CustomButton = ({ disabled = false, label, handleClick, btnClassName, style }) => {
  return (
    <button
      className={`${btnClassName}`}
      style={{ ...style }}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

export const buttonCVA = cva("", {
  variants: {
    intent: {
      active: [
        "btn", "btn-custom-active"
      ],
      outline: [
        "btn", "btn-custom-cancel",
      ],
      swalActive: [
        "btn", "btn-custom-active", "text-white", "btn-mini", "btn-swal-offset", "mx-2"
      ],
      swalCancel: [
        "btn", "btn-custom-cancel", "btn-mini", "btn-swal-offset", "mx-2"
      ],
      activeMini: [
        "btn", "btn-custom-active", "btn-mini"
      ],
      outlineMini: [
        "btn", "btn-custom-cancel", "btn-mini"
      ],
      leviasActive: [
        "btn", "levias-btn", "levias-btn-custom-active-no-before"
      ],
      leviasCancel: [
        "btn", "levias-btn", "levias-btn-custom-cancel"
      ],
      none: ["fs-20"]
    },
  },
  defaultVariants: {
    intent: "active",
  },
});

export const Btn = ({
  children,
  className,
  divClassName,
  intent,
  ...props
}) => {
  const { language } = useContext(appContext)
  return (
    <RLaddaButton data-style={EXPAND_LEFT} className={buttonCVA({ intent, className })} {...props}>
      <div className={`${language === "Japanese" ? 'f-notosan' : 'f-regular baseline-offset'} ${divClassName || ""}`}>
        {children}
      </div>
    </RLaddaButton >
  )
}
