import { useCallback, useEffect, useRef, useState } from 'react';

export function useIntersection({
  root = null,
  rootMargin = '0px',
  threshold = 0,
} = {}) {
  const [entry, setEntry] = useState(null);
  const observer = useRef(null);
  const elementRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    if (node !== null) {
      observer.current = new IntersectionObserver(([newEntry]) => {
        setEntry(newEntry);
      }, {
        root,
        rootMargin,
        threshold,
      });

      observer.current.observe(node);
    }
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return { observer, entry, ref: elementRef };
}
