import React, { useContext, useEffect, useRef } from 'react'
import { appContext } from '../../context/appContext';

const Linkify = ({ children }) => {

    const linkifyRef = useRef();
    const uniq_id = 'linkify_id' + (new Date()).getTime();
    const { language } = useContext(appContext);

    const createLink = (e, target = '_blank') => {
        const href = e.href;
        if (!href) {
            e.href = e.innerHTML;
            e.target = target;
        }
    }

    useEffect(() => {
        if (language && children && linkifyRef) {
            var documentToLinkify = linkifyRef.current;
            if (documentToLinkify) {
                const links = [...documentToLinkify.getElementsByTagName("a")];
                console.log(documentToLinkify, links)
                if (links && links.length > 0) {
                    links.forEach(element => {
                        createLink(element)
                    });
                }
            }
        }
    }, [language, children, linkifyRef])

    return (
        <div ref={linkifyRef} className='linkify'>
            {children}
        </div>
    )
}

export default Linkify