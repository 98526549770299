import React, { useEffect } from 'react'
import { Policy } from '../components/auth/AuthForm';
import AppWrapper from '../components/wrappers/AppWrapper';
import "../style/app/Privacy.scss";

const Privacy = () => {
    const metaTitle = 'プライバシーポリシー｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプライバシーポリシーです｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <AppWrapper pageid="PrivacyPolicy" metaTitle={metaTitle} metaDescription={metaDescription}>
            <div className='container mt-5 privacy' id='detail'>
                <Policy checkedPolicy={true} disabledBtn />
            </div>
        </AppWrapper>
    )
}

export default Privacy

export const PrivacyContent = props => {
    const { title, children, content, level } = props;
    return (
        <li className={`level-${level}`}>
            <p>{title}</p>
            {
                children ? children : <p>{content}</p>
            }
        </li>
    )
}
