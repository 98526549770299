import Modal from "react-bootstrap/Modal";

export const MiniGameLink = ({ url, onClose }) => {
    return (
        <Modal centered size="sm" show={!!url} contentClassName="mini-game-modal show-btn-close show-key-modal react-bootstrap-modal-custom" dialogClassName="mx-auto justify-content-center" onHide={onClose}>
            <Modal.Header closeButton>
                Mini Game
            </Modal.Header>
            <Modal.Body>
                <iframe
                    title="Mini Game"
                    src={url}>
                </iframe>
            </Modal.Body>
        </Modal>
    )
};