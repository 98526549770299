import React, { useState } from 'react'

import { ReactComponent as LineHeaderLeft } from '../../assets/images/line-header-left.svg';
import { ReactComponent as LineHeaderRight } from '../../assets/images/line-header-right.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/more-icon.svg';
import { LineHeaderRightIcon } from '../../components/common/Icon'
import { Link } from "react-router-dom";
import { strings, translations } from "../../services/localization";

export const WalletTitle = ({ title, total, className, style }) => {
  return (
    <p className={`text-start subHeader ${className || ''}`} style={style || {}}>
      <span className='wallet-title'>{title}</span>
      {total != null ?
        <font className={'m-l-12 text-default total-count'}>{total}</font>
        : ''}
    </p>
  )
}

export const WalletPageTitle = ({ title, hasUnderline, className, underlineClassName }) => {
  const isJP = translations._language === 'jp'
  return (
    <>
      <h4 className={`send-anima-title text-center jp-fw-400 ${className || ''}`} style={isJP ? { fontFamily: "Noto Sans JP", lineHeight: '29px', letterSpacing: 0, fontSize: 20 } : {}}>{title}</h4>
      {
        hasUnderline && <div className={`divider ${underlineClassName || ''}`} style={{ marginTop: 25, marginBottom: 36, borderWidth: 0.5 }}></div>
      }
    </>
  )
}

export const HomeTitle = ({ browsersize, title, className, textClass, notFlex, style, moreLink, hideLine = false, hideLeftLine = false }) => {
  const buttonlist = translations.buttonlist;
  return (
    <div className={`mb-3 ${notFlex ? '' : 'd-flex flex-row  justify-content-between'} text-start subHeader ${className || ''}`} style={style || {}}>
      <div className={`pc-mb-1 d-flex align-items-center`}>
        {
          (browsersize || window.innerWidth) > 768 && !hideLine && !hideLeftLine  ? <LineHeaderLeft></LineHeaderLeft> : <></>
        }
        <span className={`itemHeader jp-fw-400 primary-color pb-1 ${textClass || ''}`} style={style || {}}>{title}</span>
        {!hideLine && <LineHeaderRightIcon></LineHeaderRightIcon>}
      </div>
      {
        moreLink &&
        <div>
          <Link to={moreLink || {}}>
            <MoreIcon className={'more'} style={{ marginTop: '3px', float: "right", fill: '#bf96ff'  }}></MoreIcon><span className='me-2 primary-color subHeader more' style={{ float: "right" }} > { strings.formatString(buttonlist.more) }</span>
          </Link>
        </div>
      }

    </div>
  )
}
