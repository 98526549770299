import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { SwalCorrect, SwalWrong } from "../../components/common/Swal";
import "../../style/Account.scss";
import { useState } from "react";
import { useEffect } from "react";
import { setRedirect, removeValue, getWalletAddress } from "../../services/storage";
import { strings, translations } from "../../services/localization";
import { fetchData } from "../../services/fetch";
import { OnLoading } from "../../components/common/OnLoading";
import { useForceUpdate } from "../../services/hook";
import { appContext } from "../../context/appContext";
import { CustomButton } from "../../components/common/Button";

function ContactUs() {
  const content = translations.contactUs;
  const buttonlist = translations.buttonlist;
  const contactPage = translations.contactPage;
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const { language } = useContext(appContext)
  const forceUpdate = useForceUpdate();
  const loginHandler = useCallback(() => {
    setRedirect(window.location.pathname)
    navigate('/login/idms', { state: { location: "contact" } });
  }, []);

  useEffect(() => {
    if (name?.length > 0 && /^[\w-\\.]+@([\w-]+\.)+[\w-]+$/.test(email) && question?.length > 0) {
      setFormValid(true)
    }
    else {
      setFormValid(false)
    }
  }, [name, email, question])


  const onSubmitHandler = async () => {
    removeValue('redirect_uri');
    const wallet_address = getWalletAddress()
    fetchData(`/contact`, {
      name,
      email,
      question,
      wallet_address
    }, 'post', false).then(response => {
      if (response.ok) {
        if (response.data.message)
          SwalCorrect.fire({
            title: strings.formatString(contactPage.complete_Modal_Text),
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
      } else {
        SwalWrong.fire({
          title: "Error",
          text: response.data.message,
        })
      }
    })
  }


  useEffect(() => {
    forceUpdate();
  }, [language])

  const metaTitle = 'コンタクト｜ANICANA θυρα（アニカナテュラー）'
  const metaDescription = 'ANICANA θυρα（アニカナテュラー）に関するお問合せはこちら｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

  return (
    <AppWrapper
      pageid="Contact"
      className="auth-container"
      title={strings.formatString(content.headerTitle)} hasInput
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {loading === true ? <OnLoading /> :
        <div className="p-2 mb-3 mt-4 mx-auto contact-div">

          <div className="w-50 mb-3 mx-auto text-center">
            <span className="fs-25">
              {strings.formatString(content.title)}
            </span>
            <div className="divider my-4"></div>
          </div>
          {
            !getWalletAddress() &&
            <div>
              <p className="kardust-light-font text-justify mb-0">
                {strings.formatString(content.lableNotify)}
              </p>
              <div className={'text-center'}>
                <CustomButton
                  handleClick={loginHandler}
                  btnClassName="btn-custom-active my-3 py-1 mx-auto contact-btn"
                  label={strings.formatString(buttonlist.signinBtn)}
                />
              </div>
              <div className="divider mb-4 mt-4 w-100 mx-auto"></div>
            </div>
          }
          <div className="row">
            <div className="mb-3 col-12 col-md-6">
              <label className="py-1 pb-2" style={{ fontSize: '18px' }}>
                {strings.formatString(contactPage.Name)}
              </label>
              <input onChange={(e) => setName(e.target.value)} className="auth-form-input-absolute p-2 ps-3 w-100" />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="py-1 pb-2" style={{ fontSize: '18px' }}>
                {strings.formatString(contactPage.Email)}
              </label>
              <input onChange={(e) => setEmail(e.target.value)} className="auth-form-input-absolute p-2 ps-3 w-100" />
            </div>
          </div>
          <div className="mb-3">
            <label className="py-1 pb-2" style={{ fontSize: '18px' }}>{strings.formatString(contactPage.Question)}</label>
            <textarea
              className="auth-form-input-absolute form-control border-box rounded border border-secondary text-white bg-transparent"
              style={{ height: 300 }}
              rows="3"
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            {
              formValid === true ?
                <CustomButton
                  handleClick={onSubmitHandler}
                  label={strings.formatString(buttonlist.sendBtn)}
                  btnClassName="btn-custom-active my-3 py-1 contact-btn"
                /> :
                <CustomButton
                  disabled={true}
                  btnClassName="btn-custom-active my-3 py-1 contact-btn"
                  label={strings.formatString(buttonlist.sendBtn)}
                />
            }
          </div>
        </div>
      }
    </AppWrapper>
  );
}

export default ContactUs;