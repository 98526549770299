import React, { useEffect } from 'react'
import { TermOfUse } from '../components/auth/AuthForm';
import AppWrapper from '../components/wrappers/AppWrapper';

const Terms = () => {
    const metaTitle = '利用規約｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）の利用規約です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <AppWrapper pageid="TermsOfUse" metaTitle={metaTitle} metaDescription={metaDescription}>
            <div className='container mt-5 privacy' id='detail'>
                <TermOfUse checkedTerm={true} disabledBtn />
            </div>
        </AppWrapper>
    )
}

export default Terms
