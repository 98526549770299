import React, { useState, useEffect, useContext, useRef } from "react";
import "../../style/ProductDetail.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CameraIcon, ProfileForNoImgIcon } from "../../components/common/Icon";
import { EditIcon } from "../../components/common/Icon";
import { AuthContext } from "../../context/authContext";
import { useDispatch } from "react-redux";
import "../../style/newdetail.css";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { SwalChangeProfileName, SwalProfileIcon } from "../../components/common/Swal";
import { profileAction } from "../../store/Profile";
import {BottomModal} from "../../components/common/BottomModal";
import { strings, translations } from "../../services/localization";
import SwitchButton from "../../components/common/SwitchButton";
import { fetchData } from "../../services/fetch";
import { useLayoutEffect } from "react";
import { setValue } from "../../services/storage";
import { useMediaQuery } from 'react-responsive'

function AccountDetail() {
  const { user, setUser, userIdms, setUserIdms } = useContext(AuthContext);
  const dispatch = useDispatch();
  const content = translations.acountDetail;
  const accountDetailPage = translations.accountDetailPage; // Usage: strings.formatString(accountDetailPage.Email_Address)
  const changeNamePage = translations.changeNamePage; // Usage: strings.formatString(changeNamePage.Change_profile_name)
  const [loading, setLoading] = useState(false)
  const [thisUser, setThisUser] = useState()

  const onChangeName = () => {
    SwalChangeProfileName(strings.formatString(changeNamePage.Change_profile_name), setLoading, user, (value)=>{
      setUser(value);
      setUserIdms(value);
    })
  }

  const [fileName, setFileName] = useState()
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [type, setType] = useState()
  const [name, setName] = useState()
  const [isValid, setIsValid] = useState(false);

  const [takePhoto, setTakePhoto] = useState(false);
  const [imageFile, setImageFile] = useState('');

  const [showImageCapModal, setShowImageCapModal] = useState();

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const filePickerRef = useRef();
  const fileCaptureRef = useRef();
  const [dataUri, setDataUri] = useState("");



  useLayoutEffect(() => {
    setThisUser(user)

  }, [user])

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
      setImageFile(fileReader.result);
      if (fileReader?.result) {
        fetchImage(fileReader.result)
      }
      dispatch(profileAction.add(fileReader.result));
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const fetchImage = async (formdata) => {
    var response = await fetchData(`user/update`, {
      image: formdata,
      image_file_name: fileName
    }, 'post', false);
    if (response.ok) {
      //   setLoading(false)
      let newUserInfo = { ...response.data.user }
      newUserInfo.image = formdata
      newUserInfo.account_name = response.data.user_idms.accountName;
      setUser(newUserInfo)
      setUserIdms(newUserInfo)
      if (response.data) {
        if (response.data.errorMessage) {
          // setLoading(false);
        }
      }
    }
  }

  const pickedHandler = async (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      if (event.target.files[0].name) {
        setFileName(event.target.files[0].name);
      }
      setFile(pickedFile)
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
  };

  const takePhotoHandler = () => {
    fileCaptureRef?.current?.click();
    // setTakePhoto(true)
    setShowImageCapModal(false);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
    setShowImageCapModal(false);
  };

  let img = ''

  if (previewUrl) {
    img = previewUrl
  }
  else if (user?.image) {
    img = user.image
  }

  const isPC = useMediaQuery({ minWidth: 992 })
  const showModalHandler = () => {
      isPC ? swalShowImageCapModal() : setShowImageCapModal(true)
  }
  const swalShowImageCapModal = () => {
    SwalProfileIcon.fire({
        text: strings.formatString(accountDetailPage.changeProfileLabel),
        confirmButtonText: strings.formatString(accountDetailPage.GALLERY),
        // denyButtonText: strings.formatString(accountDetailPage.TAKE_PHOTO),
    }).then((result) => {
      if (result.isConfirmed) {
        pickImageHandler()
      }/* else if (result.isDenied) {
        takePhotoHandler()
      }*/
    });
  }

  return (
    <>
      {
        <BottomModal
          takePhotoHandler={takePhotoHandler}
          pickImageHandler={pickImageHandler}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          profileCapture
          accountDetailCapture
          label={strings.formatString(accountDetailPage.changeProfileLabel)}
        />
      }
      <AppWrapper className="auth-container" title={translations.formatString(content.headerTitle)} activeProfile>
        <div className="container-fluid" >
          <input
            id="image"
            ref={filePickerRef}
            style={{ display: "none" }}
            type="file"
            accept=".jpg,.png,.jpeg"
            onChange={pickedHandler}
          />
          <div className="col-12">
            <div className="d-flex align-items-center flex-column col-12 my-2">
            <div className="mb-3 position-relative">
                <div className="rounded-circle over-h profile-pic-general profile-acc-pic d-flex justify-content-center" style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${img})`
                }}>
                  {!img && <ProfileForNoImgIcon className="position-absolute w-100 h-100 end-0" />}
                  <CameraIcon
                    className="position-absolute profile-cam-icon"
                    pickImageHandler={showModalHandler}
                  />
                </div>
            </div>
              <div className="position-relative">
                <span className="mx-3 text-center account-name">{userIdms?.account_name ? userIdms.account_name : strings.formatString(content.noName)}</span><EditIcon onChangeName={onChangeName} className="position-absolute" />
              </div>
            </div>
            <div className="divider mb-4 mt-3 w-100 mx-auto"></div>

            <div className="my-2 d-flex flex-column py-2">
              <div className="position-relative">
                <span className="me-2 account-email-label">{ strings.formatString(accountDetailPage.Wallet_Address ) }</span>
              </div>
              <div className='mt-2 mx-2 text-muted account-email ' style={{wordBreak:'break-all'}}>{user.wallet_address}</div>
            </div>

            {/*<div className="my-2 d-flex flex-column py-2">*/}
            {/*  <div className="position-relative">*/}
            {/*    <span className="me-2 account-email-label">{ strings.formatString(accountDetailPage.Email_Address) }</span>*/}
            {/*    <Link to="/change_email">*/}
            {/*      <EditIcon className="position-absolute" />*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*  <span className='mx-2 text-muted account-email'>{userIdms?.email}</span>*/}
            {/*</div>*/}
            {/*<div className="my-2 d-flex flex-column py-2">*/}
            {/*  <div className="position-relative">*/}
            {/*    <span className="me-2 account-mobile-label">{ strings.formatString(accountDetailPage.Mobile_Number) }</span>*/}
            {/*    <Link to="/change_mobile">*/}
            {/*      <EditIcon className="position-absolute" />*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*  <span className='mx-2 text-muted account-phone'>{ userIdms?.mobileNumber }</span>*/}
            {/*</div>*/}
            <div>
              {/* <div className="my-2 d-flex py-2">
                <span className="kardust-light-font me-2">{ strings.formatString(accountDetailPage.Security_Code) }</span>
                <SwitchButtonIcon />
              </div> */}
              {/*<div className="my-2 d-flex py-2">*/}
              {/*  <span className="me-2 account-mfa-label">{ strings.formatString(accountDetailPage.MFA) }</span>*/}
              {/*  <SwitchButton />*/}
              {/*</div>*/}
              {/*<div className="position-relative py-2 my-2">*/}
              {/*  <div className="position-relative">*/}
              {/*    <span className="me-2 account-password-label">{ strings.formatString(accountDetailPage.Password) }</span>*/}
              {/*    <Link to="/change_password">*/}
              {/*      <EditIcon className="position-absolute" />*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="my-3 my-4 text-start d-flex justify-content-start">
                <span onClick={() => navigate('/delete_account')} className="kardust-font" style={{ color: '#FF0000' }}>{ strings.formatString(accountDetailPage.Delete_Account) }</span>
                <DeleteIcon />
            </div>*/}
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
}

export default AccountDetail;
