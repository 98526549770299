import { useState } from "react";
import styled from '@emotion/styled';
import { keyframes } from "@emotion/react";

export default function DropdownComponent({
    placeholder,
    closeOnSelect = true,
    value,
    onChange = () => { },
    options = [],
    renderDropdownIndicator = (open) => <DropdownIndicator open={open} />,
    listBgColor = null,
    width = '120px',
    borderRadius = null
}) {
    const [open, setOpen] = useState(false);

    const handleOnClickDropdownItem = (option) => {
        onChange(option);
        if (closeOnSelect) {
            setOpen(false);
        }
    }

    const handleOnClickDropdownControl = () => {
        setOpen(prev => !prev);
    }

    return (
        <Dropdown>
            <DropdownControl
                borderRadius={borderRadius}
                width={width}
                onClick={handleOnClickDropdownControl}>
                {value?.label ? <Value>{value?.label}</Value> : <Placeholder>{placeholder}</Placeholder>}
                {renderDropdownIndicator(open)}
            </DropdownControl>
            <DropdownList
                className={open ? 'open' : ''}
                width={width}
                listBgColor={listBgColor}>
                {options?.map(option => {
                    return (
                        <DropdownItem
                            className={option.group?'group':''}
                            key={option.key}
                            onClick={() => option.group ? null : handleOnClickDropdownItem(option)}>
                            {option.label}
                        </DropdownItem>
                    )
                })}
            </DropdownList>
        </Dropdown>
    )
}

const fadeIn = keyframes`
    0% {
        display:none;
        opacity:0;
    }
    100% {
        display:block;
        opacity:1;
    }
`

const fadeOut = keyframes`
    0% {
        display:block;
        opacity:1;
    }
    100% {
        display:none;
        opacity:0;
    }
`

const Dropdown = styled.div`
    position: relative;
    width: fit-content;
    height: 20px;
`

const DropdownControl = styled.button`
    width: ${props => props.width ?? '120px'};
    border: ${props => `1px solid ${props.borderColor ?? '#BF96FF'}`};
    border-radius: ${props => props.borderRadius ?? '10px'};
    outline: none;
    background-color: transparent;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const DropdownList = styled.ul`
    width: ${props => props.width ?? '120px'};
    background: ${props => props.listBgColor ?? 'transparent'};
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(0, 30px);
    color: white;
    list-style-type: none;
    padding: 5px 0;
    margin: 0;
    border-radius: 5px;
    border-top: 0.5px solid #BF96FF;
    border-right: 1px solid #BF96FF;
    border-bottom: 1px solid #BF96FF;
    border-left: 0.5px solid #BF96FF;
    box-shadow: -1px 0 3px #BF96FF;
    &:not(.open){
        display:none;
        opacity:0;
        animation: 0.15s ease ${fadeOut};
    }
    &.open{
        display:block;
        opacity:1;
        animation: 0.15s ease ${fadeIn};
    }
`

const DropdownItem = styled.li`
    font-size: 16px !important;
    margin: 0 !important;
    padding: 8px;
    &.group {
        color:gray;
        text-decoration:underline;
        text-underline-offset:3px;
    }
    &:not(.group){
        padding-inline-start:14px;
    }
`

const Placeholder = styled.span`
    font-size: 16px !important;
    color:white;
    opacity: 0.5;
`
const Value = styled.span`
    font-size: 16px !important;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    color:white;
`

const IconWrapper = styled.div`
    width: 12px;
    height: 12px;
    display: grid;
    place-items: center;
    transform: ${props => `rotate(${props.open ? 180 : 90}deg)`};
    transform-origin: center;
    color: #BF96FF;
    transition: transform 0.15s ease-in-out;
    margin-left: 10px;
`

const DropdownIndicator = ({ open }) => {

    return (
        <IconWrapper open={open}>
            <TriangleIcon />
        </IconWrapper>
    )
}

const TriangleIcon = styled.div`
    & {
        position: relative;
        background-color: #BF96FF;
        text-align: left;
        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
    }
    
    &:before,
    &:after {
        content: '';
        position: absolute;
        background-color: inherit;
    }
    &,
    &:before,
    &:after {
        width:  0.5em;
        height: 0.5em;
        border-top-right-radius: 30%;
    }
    &:before {
	    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &:after {
	    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
`