import React, { useLayoutEffect, useRef, useState } from 'react'
import QRCode from "react-qr-code";

const QRCodeComponent = ({ value }) => {

  const [size, setSize] = useState(150);
  const qrCodeRef = useRef();

  // useLayoutEffect(() => {
  //   if (qrCodeRef.current) {
  //     if (qrCodeRef.current.offsetWidth > 300) {
  //       setSize(256)
  //     } else if (qrCodeRef.current.offsetWidth > 200) {
  //       setSize(200);
  //     } else {
  //       setSize(100);
  //     }
  //   }
  // }, []);

  return (
    value &&

    <div className='text-center mt-4' ref={qrCodeRef}>
      <div className='border-box'>
        <QRCode
          value={value}
          // size={size}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          bgColor='none'
          fgColor={'white'}
        />
      </div>
    </div>
  )
}

export default QRCodeComponent