import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { TokenItem } from '../../components/common/Item'
import { WalletTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { getValue } from '../../services/storage'
import { OnLoading } from '../../components/common/OnLoading'
import { strings, translations } from "../../services/localization";
import { TokenAddIcon, TokenWalletDeleteIcon } from "../../components/common/Icon";
import { getData, postData } from "../../services/fetch";
import { getWalletAddress } from '../../services/storage'
import { Web3authContext } from '../../context/web3authContext'
import RPC from "../../components/common/web3RPC";
import { setUserTokens } from '../../components/common/commonFnc';
import { useMediaQuery } from 'react-responsive'

const WalletToken = () => {
  const navigate = useNavigate();
  const { wallet_address, provider, init, loginIfNotLogin, setWallet_address } = useContext(Web3authContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [balance, setBalance] = useState();
  const [id_token, setIdToken] = useState(null);
  const [balanceByContract, setBalanceByContract] = useState({});
  const [deleteIcon, setDeleteIcon] = useState(false);


  useEffect(() => {
    getItems();
  }, []);


  useEffect(() => {
    if (id_token) {
      loginIfNotLogin(id_token)
    }
  }, [id_token])

  const getItems = async () => {
    setLoading(true);
    let items = await getData(`user-tokens`);
    items = await setUserTokens(items?.data?.response || [])
    setItems(items || []);
    setUser(await getValue('user_info'))
    setLoading(false);
  }

  useEffect(() => {
    if (items) {
      let _balanceByContract = {}
      items.map(async (item) => {
        const rpc = new RPC();
        let balance = await rpc.getCustomTokenBalance(item.contract_address, getWalletAddress(), 2)
        _balanceByContract[item.contract_address] = balance ?? '-'
        setBalanceByContract(JSON.parse(JSON.stringify(_balanceByContract)))
      })
    }
  }, [items])

  const onLoadingHandler = (val) => {
    setLoading(val);
  }

  const tokenPage = translations.tokenPage

  const showDelete = () => {
    setDeleteIcon(!deleteIcon)
  }

  const isPC = useMediaQuery({ minWidth: 992 })

  return (
    <>
      <AppWrapper title={strings.formatString(tokenPage.My_Wallet)} enableMobileSearch>
        <div className='wallet-token'>
          <div className='d-flex justify-content-between align-items-center mb-3 token-header'>
            <div>
              <WalletTitle title={strings.formatString(tokenPage.My_Tokens)} total={items.length} className="mb-0" />
            </div>
            <div className="d-flex align-items-center" >
              {/* <span className='subHeader'>{strings.formatString(tokenPage.ADD)}</span> */}
              {
                isPC ?
                  <div onClick={() => showDelete()} className="pointer">
                    <TokenWalletDeleteIcon className='wallet-send-icon' />
                  </div>
                  : <></>
              }
              <div onClick={!deleteIcon ? () => navigate(`/wallet/token/add`) : ''} className={`${deleteIcon ? "icon-opacity" : ""} pointer`}>
                <TokenAddIcon className='wallet-send-icon mx-2' />
              </div>
            </div>
          </div>
          {
            loading ? <OnLoading /> :
              <>
                <div className='token-body row' style={{ margin: '0px -5px' }}>
                  {
                    items.map((item, index) =>
                      <div key={index} className='col-12 col-md-6 col-lg-6 col-xl-4' style={{ padding: '0px 5px' }}>
                        <TokenItem item={item} balance={balanceByContract[item.contract_address] ?? '-'} user={user} deleteIcon={deleteIcon} onLoadingHandler={onLoadingHandler} onGetItemHandler={getItems} />
                      </div>
                    )
                  }
                </div>
              </>
          }
        </div>
      </AppWrapper>
    </>
  )
}

export default WalletToken
