import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { CancelIcon, LeviasCancelIcon, GalleryIcon, LeviasGalleryIcon, TakePhotoIcon, LeviasTakePhotoIcon } from "./Icon";
import { useNavigate } from "react-router-dom";
import "../../style/Auth.scss";
import "../../style/app/loading.scss";
import { strings, translations } from "../../services/localization";
import Swal, { swalOption, leviasSwalOption, SwalBoxContent } from "./Swal";
import { fetchData } from "../../services/fetch";
import { useMediaQuery } from 'react-responsive'
import { FaSpinner } from 'react-icons/fa';
import { LeviasSwalCorrect, LeviasSwalWrong } from "../../components/common/Swal";
import { AuthContext } from '../../context/authContext'
import { Btn } from "./Button";

export function BottomModal({
  label = "cancel? All data you have registered will be discarded.",
  onShow,
  onCloseHandler,
  onSubmitHandler,
  profileCapture,
  accountDetailCapture,
  pickImageHandler,
  takePhotoHandler,
  emailVerify,
  phoneVerify,
  setIdmsRegistrationStep,
  cancelBtnLabel = "Cancel",
  BackBtnLabel = "Back",
  navigation = "",
  notNavigate,
  goBack,
  hideCross = false,
}) {

  const isPC = useMediaQuery({ minWidth: 992 })
  const navigate = useNavigate();
  const accountDetailPage = translations.accountDetailPage;

  const goTo = () => {
    if (navigation.length > 0) {
      navigate(navigation)
    } else if (goBack) {
      navigate(-1)
    } else {
      navigate("/login/idms")
    }
  }

  let body = (
    <>
      <span className="d-flex flex-column m-b-40">{label}</span>
      <Btn
        intent="activeMini"
        onClick={notNavigate ? onSubmitHandler : goTo}
        className="mx-auto col-8 aniBtn m-b-30"
      >
        {cancelBtnLabel}
      </Btn>
      <Btn
        intent="outlineMini"
        className="mx-auto col-8 aniBtn "
        style={{ backDropFilter: 0 }}
        onClick={onCloseHandler}
      >
        {BackBtnLabel}
      </Btn>
    </>
  );
  if (profileCapture) {
    body = (
      <>
        <span>{label}</span>
        <div className="">
          <Btn
            intent="outlineMini"
            onClick={pickImageHandler}
            className="mx-auto col-8 aniBtn mb-4"
            style={{ margin: '33px 0px' }}
          >
            <GalleryIcon className='me-2' />
            {strings.formatString(accountDetailPage.GALLERY)}
          </Btn>
        </div>
        {!accountDetailCapture && <div className="">
          <Btn
            intent="outlineMini"
            className="mx-auto col-8 aniBtn"
            id="take-photo"
            style={{ backDropFilter: 0 }}
            onClick={takePhotoHandler}
          >
            <TakePhotoIcon className='me-2' />
            take photo
          </Btn>
        </div>}
      </>
    );
  } else if (emailVerify) {
    body = (
      <>
        <span>{label}</span>
        <div className="">
          <Btn
            intent="outlineMini"
            onClick={() => setIdmsRegistrationStep('setEmail')}
            className="mx-auto col-8 aniBtn mb-4"
            style={{ margin: '33px 0px' }}
          >
            enter email
          </Btn>
        </div>
        <div className="">
          <Btn
            intent="outlineMini"
            className="mx-auto col-8 aniBtn"
            style={{ backDropFilter: 0 }}
            onClick={onCloseHandler}
          >
            enter code
          </Btn>
        </div>
      </>
    );
  } else if (phoneVerify) {
    body = (
      <>
        <span>{label}</span>
        <div className="">
          <Btn
            intent="outlineMini"
            onClick={() => setIdmsRegistrationStep('setPhone')}
            className="mx-auto col-8 aniBtn mb-4"
            style={{ margin: '33px 0px' }}
          >
            enter mobile
          </Btn>
        </div>
        <div className="">
          <Btn
            intent="outlineMini"
            className="mx-auto col-8 aniBtn"
            style={{ backDropFilter: 0 }}
            onClick={() => { }}
          >
            enter code
          </Btn>
        </div>
      </>
    );
  }
  return (
    <>
      {!isPC
        ? <>
          <motion.div
            initial={{
              opacity: 0,
              zIndex: -1,
            }}
            animate={{
              opacity: onShow ? 0.5 : 0,
              zIndex: onShow ? 99 : -1,
            }}
            transition={{
              duration: 0.3,
            }}
            className="background-blur position-fixed"
            onClick={onCloseHandler}
          ></motion.div>
          <motion.div
            initial={{
              y: "50vh",
            }}
            animate={{
              y: onShow ? 0 : "50vh",
              zIndex: onShow ? 100 : -1,
            }}
            transition={{
              duration: 0.3,
              // type:'spring',
              // stiffness:60
            }}
            className="border-box bottom-modal position-fixed"
            style={{ padding: '50px 60px' }}
          >
            {!hideCross &&
              <CancelIcon
                className="position-absolute cancel-icon"
                onCloseHandler={onCloseHandler}
              />
            }
            <div
              className="d-flex flex-column"
              style={{ textAlign: "center", fontSize: '20px', fontWeight: '500' }}
            >
              {
                body
              }
            </div>
          </motion.div>
        </>
        : <>
          <SwalBottomModal onShow={onShow} onCloseHandler={onCloseHandler} isLevias={false}>
            {/* <div className="mb-2">
            <CancelIcon
              className="position-absolute cancel-icon"
              onCloseHandler={onCloseHandler}
            />
          </div> */}
            {
              body
            }
          </SwalBottomModal>
        </>
      }
    </>
  );
  // if (accountDetailCapture) {
  // }else{
  //   return (
  //     <>
  //       <motion.div
  //         initial={{
  //           opacity: 0,
  //           zIndex: -1,
  //         }}
  //         animate={{
  //           opacity: onShow ? 0.5 : 0,
  //           zIndex: onShow ? 99 : -1,
  //         }}
  //         transition={{
  //           duration: 0.3,
  //         }}
  //         className="background-blur position-fixed"
  //         onClick={onCloseHandler}
  //       ></motion.div>
  //       <motion.div
  //         initial={{
  //           y: "50vh",
  //         }}
  //         animate={{
  //           y: onShow ? 0 : "50vh",
  //           zIndex: onShow ? 100 : -1,
  //         }}
  //         transition={{
  //           duration: 0.3,
  //           // type:'spring',
  //           // stiffness:60
  //         }}
  //         className="border-box bottom-modal position-fixed"
  //         style={{ padding: '47px 27px' }}
  //       >
  //         <CancelIcon
  //           className="position-absolute cancel-icon"
  //           onCloseHandler={onCloseHandler}
  //         />
  //         <div
  //           className="d-flex flex-column"
  //           style={{ textAlign: "center", fontSize: '20px', fontWeight: '500' }}
  //         >
  //           {
  //             body
  //           }
  //         </div>
  //       </motion.div>
  //     </>
  //   );
  // }
}

export function LeviasBottomModal({
  label = "cancel? All data you have registered will be discarded.",
  onShow,
  onCloseHandler,
  profileCapture,
  pickImageHandler,
  takePhotoHandler,
  emailVerify,
  phoneVerify,
  inputClass,
  setIdmsRegistrationStep,
  setTransactionId,
  title,
  params,
  isRegister
}) {
  const { login2FA, user } = useContext(AuthContext);
  const isPC = useMediaQuery({ minWidth: 992 })
  const leviasBottomModal = translations.leviasBottomModal;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loginPage = translations.loginPage
  const recendEmail = async () => {
    setLoading(true);
    if (isRegister) {
      const response = await fetchData(`accounts/register-email`, { 'email': params.email, 'confirmEmail': params.email }, 'post', true);
      if (response.ok) {
        setLoading(false);
        setIdmsRegistrationStep("emailVerify");
        LeviasSwalCorrect.fire({
          title: loginPage.Code_has_been_successfully_send,
        })
        onCloseHandler()
      } else {
        setLoading(false);
        onCloseHandler()
        LeviasSwalWrong.fire({
          title: loginPage.errorText,
          text: loginPage.user_not_found,
        });
      }
    } else {
      await login2FA(params.email, setLoading, setIdmsRegistrationStep, false, user.idms_id)
    }
    setLoading(false);
    onCloseHandler()
  }

  const onEmailVerifyHandler = async () => {
    setLoading(true);
    var response = await fetchData(`accounts/forgot-password`, {
      email: params.email,
      mobileNumber: params.phoneNumber,
    }, 'post', true);
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.transactionId) {
          setLoading(false);
          setIdmsRegistrationStep("phoneVerify");
          setTransactionId(response.data.transactionId);
          LeviasSwalCorrect.fire({
            title: loginPage.Code_has_been_successfully_send,
          })
        }
        onCloseHandler()
      }
    } else {
      setLoading(false);
      onCloseHandler()
      LeviasSwalWrong.fire({
        title: loginPage.errorText,
        text: loginPage.user_not_found,
      });
    }
  }

  const resendCode = () => {
    onEmailVerifyHandler();
  }

  let body = (
    <>
      {title ? <span>{title}</span> : <></>}
      <span>{label}</span>
      <Btn
        intent="leviasActive"
        onClick={() => navigate("/login/idms")}
        className="mx-auto col-10"
        style={{ margin: '33px 0px', zIndex: 9999 }}
      >
        {strings.formatString(leviasBottomModal.CANCEL)}
      </Btn>
      <div className="">
        <Btn
          intent="leviasCancel"
          className="mx-auto col-10"
          style={{ backDropFilter: 0 }}
          onClick={onCloseHandler}
        >
          {strings.formatString(leviasBottomModal.BACK)}
        </Btn>
      </div>
    </>
  );
  if (profileCapture) {
    body = (
      <>
        <span>{label}</span>
        <div className="">
          <Btn
            intent="leviasCancel"
            onClick={pickImageHandler}
            className="mx-auto col-10"
            style={{ margin: '33px 0px' }}
          >
            <LeviasGalleryIcon className='me-2' />
            {strings.formatString(leviasBottomModal.GALLERY)}
          </Btn>
        </div>
        <div className="">
          <Btn
            intent="leviasCancel"
            className="mx-auto col-10"
            style={{ backDropFilter: 0 }}
            onClick={takePhotoHandler}
          >
            <LeviasTakePhotoIcon className='me-2' />
            {strings.formatString(leviasBottomModal.TAKE_PHOTO)}
          </Btn>
        </div>
      </>
    );
  } else if (emailVerify) {
    body = (
      <>
        <span>{label}</span>
        {/* <div className="">
          <button
            onClick={() => setIdmsRegistrationStep('setEmail')}
            className="levias-btn levias-btn-custom-cancel mx-auto col-10"
            style={{ margin: '33px 0px' }}
          >
            {strings.formatString(leviasBottomModal.ENTER_EMAIL)}
          </button>
        </div> */}
        <div className="mt-3">
          <Btn
            intent="leviasCancel"
            className="mx-auto col-10"
            style={{ backDropFilter: 0 }}
            onClick={() => recendEmail()}
          >
            {loading && typeof FaSpinner === 'function' && <FaSpinner icon="spinner" className="spinner" />}{strings.formatString(leviasBottomModal.code_resend)}
          </Btn>
        </div>
      </>
    );
  } else if (phoneVerify) {
    body = (
      <>
        <span>{label}</span>
        {/* <div className="">
          <button
            onClick={() => setIdmsRegistrationStep('setPhone')}
            className="levias-btn levias-btn-custom-cancel mx-auto col-10"
            style={{ margin: '33px 0px' }}
          >
            {strings.formatString(leviasBottomModal.ENTER_MOBILE)}
          </button>
        </div> */}
        <div className="mt-3">
          <Btn
            intent="leviasCancel"
            className="mx-auto col-10"
            style={{ backDropFilter: 0 }}
            onClick={() => resendCode()}
          >
            {loading && typeof FaSpinner === 'function' && <FaSpinner icon="spinner" className="spinner" />}{strings.formatString(leviasBottomModal.ENTER_CODE)}
          </Btn>
        </div>
      </>
    );
  }
  return (
    <>
      {!isPC
        ? <>
          <motion.div
            initial={{
              opacity: 0,
              zIndex: -1,
            }}
            animate={{
              opacity: onShow ? 0.5 : 0,
              zIndex: onShow ? 99 : -1,
            }}
            transition={{
              duration: 0.3,
            }}
            className={`background-blur position-fixed ${inputClass || ''}`}
            onClick={onCloseHandler}
          ></motion.div>
          <motion.div
            initial={{
              y: "50vh",
            }}
            animate={{
              y: onShow ? 0 : "50vh",
              zIndex: onShow ? 100 : -1,
            }}
            transition={{
              duration: 0.3,
            }}
            className={`border-box levias-bottom-modal position-fixed ${inputClass || ''}`}
            style={{ padding: '47px 27px' }}
          >
            <LeviasCancelIcon
              className="position-absolute cancel-icon"
              onCloseHandler={onCloseHandler}
            />
            <div
              className="d-flex flex-column"
              style={{ textAlign: "center", fontSize: '20px', fontWeight: '500' }}
            >
              {
                body
              }
            </div>
          </motion.div>
        </>
        : <>
          <SwalBottomModal onShow={onShow} onCloseHandler={onCloseHandler} isLevias={true}>
            {
              body
            }
          </SwalBottomModal>
        </>
      }
    </>
  );
}

export const SwalBottomModal = ({ onShow, onCloseHandler, isLevias, children }) => {
  const option = !isLevias ? swalOption : leviasSwalOption;
  useEffect(() => {
    if (onShow) {
      Swal.mixin(option).fire({
        showConfirmButton: false,
        didClose: onCloseHandler,
        html: (
          <SwalBoxContent disableBtn isLevias={isLevias}>
            {children}
          </SwalBoxContent>
        )
      })
    } else {
      Swal.close();
    }
  }, [onShow])

  return <></>
}