import React, { useState } from 'react'
import QrReader from 'react-qr-reader2'

const QRReaderTest = () => {
  const [result, setResult] = useState(undefined);

  const handleScan = data => {
    if (data) {
      setResult(data)
    }
  }
  const handleError = err => {
    console.error(err)
  }

  return (
      <div id={'anicana-portal'}>
        <div className={`app-wrapper levias-app-wrapper flex-center flex-column`}>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
          <p className='w-100 text-overflow'>{result}</p>
        </div>
      </div>
  )
}

export default QRReaderTest;