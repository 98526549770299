import React from 'react'
import AppWrapper from '../components/wrappers/AppWrapper';
import { CookiesPolicy } from '../components/auth/AuthForm';

const CookiePolicy = () => {
    const metaTitle = 'クッキーポリシー｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のクッキーポリシーです｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <AppWrapper pageid="CookiePolicy" metaTitle={metaTitle} metaDescription={metaDescription}>
          <div className='container mt-lg-5 privacy' id="cookie-policy" style={{ marginBottom: 100 }}>
                <CookiesPolicy checkedCookie={true} disabledBtn />
            </div>
        </AppWrapper>
    )
}

export default CookiePolicy
