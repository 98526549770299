import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './store/index'
import 'react-multi-carousel/lib/styles.css';
import 'react-phone-input-2/lib/style.css'
import 'react-phone-number-input/style.css'
import './style/app/mypage.scss';
import './style/app/sidebar.scss';
import './style/app/react-pdf.scss';
import './style/app/app.scss';
import posthog from 'posthog-js'; // new

posthog.init( // new
  'phc_oPag6SvIe0aubz6StCkpeMau7AAoU1M4gGCeHb9tsYu', { api_host: 'https://app.posthog.com' }
)

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
