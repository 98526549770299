import moment from "moment";
import { defaultFilter } from "../../context/appContext";
import { fetchDataWithCancel, getData } from "../../services/fetch";
import { getToken, getValue } from "../../services/storage";
import { TokenType, getTokenImageByContractAddress, TokenTypeByName } from "./enum";
import { SwalCorrect } from "./Swal";
import { translations } from "../../services/localization";
import RPC from "../../components/common/web3RPC";
import axios from "axios";

var formatter = (decimalPlaces) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
    maximumFractionDigits: decimalPlaces,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const getDecimalPlaces = amount => amount?.toString().includes('.') ? amount.toString().split('.')[1].length : 0;

export const formatMoney = (amount, unit = '¥') =>
    formatter(getDecimalPlaces(amount)).format(amount).replace(/^(\D+)/, unit + ' ');

export const formatNumber = (amount, decimalPlaces = null) => {
    if (!amount) {
        return amount;
    }
    if (typeof amount !== 'string') {
        try {
            return amount.toLocaleString();
        } catch (e) {
            return amount
        }
    }
    let decimalPart = '';
    let formattedAmount = '';
    if (amount.includes('.')) {
        let [integerPart, fracPart] = amount.split('.');
        if (decimalPlaces !== null && fracPart.length > decimalPlaces) {
            fracPart = fracPart.substring(0, decimalPlaces);
        }
        formattedAmount = parseInt(integerPart).toLocaleString();
        decimalPart = fracPart;
    } else {
        formattedAmount = parseInt(amount).toLocaleString();
    }
    return `${formattedAmount}${decimalPart !== '' ? '.' + decimalPart : ''}`;
};

export const thousandSep = (val) => {
    return String(val).split("").reverse().join("")
        .replace(/(\d{3}\B)/g, "$1,")
        .split("").reverse().join("");
}

const gradientDuration = 500;

export const playGradientColor = e => {
    var x = e.target;
    x.classList.add("hover")
    setTimeout(() => x.classList.remove("hover"), gradientDuration);
}



export const addGradientColor = (e) => {
    removeAllGradientColor();
    var x = e.target;
    x.classList.add("hover");
}

export const addGradientColorById = (id) => {
    removeAllGradientColor();
    var x = document.getElementById(id);
    x.classList.add("hover");
}

export const removeAllGradientColor = e => {
    var classes = document.getElementsByClassName('gradient-text-hover');
    Array.from(classes).forEach((el) => {
        el.classList.remove("hover");
    });
}

export const removeGradientColor = (e) => {
    var x = e.target;
    setTimeout(() => x.classList.remove("hover"), gradientDuration);
}

export const copyToClipboard = (text, onConfirmCopy) => {
    navigator.clipboard.writeText(text);
    const commonlist = translations.common;
    return SwalCorrect.fire({
        title: translations.formatString(commonlist.copy_text),
        showCancelButton: false,
        customClass: {
            cancelButton: '',
        }
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed && onConfirmCopy) {
            onConfirmCopy()
        }
    })
}

export const shorternAddress = (address) => `${address?.substring(0, 6)}...${address?.substring(address.length - 4, address.length)}`

export const UrlExists = async (url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status != 404)
        return true;
    else
        return false;
}

export const formatPhoneNumberForDisplay = (phoneNumber, dialCode) => {
    var raw = dialCode ? phoneNumber.slice(dialCode.length) : phoneNumber;
    return raw;
}

export const getUniqueId = (prefix) => {
    const random = Math.floor(Math.random() * 10000000);
    const date = new Date().getTime();

    return `${prefix}_${random}_${date}`;
}

let prevRequest = null; // Variable to hold the previous request promise
export const handleSearch = async (search = '', filter = defaultFilter, sort = "DESC", wallet_address = '', page = 1, checkNew = false, limit = 12, group_id = null) => {
    let query_strings = "?";
    try {
        let contract_address = '';
        if (filter && filter.token_type_id) {
            contract_address = TokenType.getContractAddress(filter.token_type_id)
        }
        let data = {
            sort_order: sort,
            page: page,
            limit: String(limit),
            token_name: search,
        };
        if (wallet_address) {
            data = Object.assign(data, { wallet_address });
        } else {
            var _filter = JSON.parse(JSON.stringify(filter));
            for (var i in _filter) {
                if (isObject(_filter[i])) {
                    if (_filter[i]["normalizeValue"]) {
                        if (_filter[i]["low"]) {
                            _filter[i]["low"] += parseInt(_filter[i]["normalizeValue"])
                        }
                        if (_filter[i]["high"]) {
                            _filter[i]["high"] += parseInt(_filter[i]["normalizeValue"])
                        }
                    }
                }
            }
            // delete _filter.token_type_id;
            data = Object.assign(data, _filter);
        }
        if (group_id) {
            if (Number(filter.token_type_id) === Number(TokenType.Persona)) {
                query_strings += `persona_id=${group_id}`;
            }
        }
        if (contract_address) {
            data = Object.assign(data, { category: contract_address });
        }
        // Cancel the previous request by calling its cancel function
        if (prevRequest) {
            prevRequest.cancel('Request canceled');
        }

        const cancelTokenSource = axios.CancelToken.source();
        console.log("cancel token", { cancelTokenSource });
        if (prevRequest) {
            prevRequest.cancel(); // Cancel the previous request
        }
        const { request, cancel } = await fetchDataWithCancel(`tokens/search${query_strings}`, data, 'post');
        prevRequest = { cancel }; // Store the cancel function for the current request

        const response = await request;

        if (response && response.ok) {
            const user_info = await getValue('user_info');
            const { tokens, limit, total, has_next_page } = response.data.results;
            if (wallet_address || (checkNew && getToken())) { // check new status only for My Arcana, not all arcana list
                tokens.map(x => {
                    let isNew = true;
                    if (user_info) {
                        isNew = user_info.token_last_seen_date ? moment(x["last_updated"]).utc().isAfter(moment(user_info.token_last_seen_date).utc().format('YYYY-MM-DDTHH:mm:ss')) : true;
                    }
                    x.new = isNew;
                });
            }
            const last_page = parseInt(total / limit) + (total % limit === 0 ? 0 : 1);
            return { tokens, last_page, total, has_next_page };
        }
        return null;
    } catch (error) {
        console.log({ error })
        return null;
    }
}

export const getArcanaDetail = async (token_type_id, token_id, preview = false) => {
    try {
        const url = `tokens/${TokenType.getContractAddress(token_type_id)}/${token_id}` + ((preview || Number(token_type_id) === Number(TokenType.Persona)) ? "?call_detail=false" : "?t=t") + '&nocache=' + new Date().getTime()
        var response = await getData(url);
        if (response.ok) {
            let token = response.data.response.token;
            return (Object.assign(token, {
                // ask_price: 0, // for testing purpose
                // bid_price: 0, // for testing purpose
                token_type_id,
                greenStar: token.greenStar + 1,
                feature: [],
                details: {
                    blockchain: 'ANICANA',
                    token_standard: 'ERC-721',
                    //mx_creator_bonus: '0.01 ANM',
                }
            }));
        }
        return null;
    } catch (error) {
        console.log({ error })
        return null;
    }
}

export const remove = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const createImgUrl = (file) => file && URL.createObjectURL(file)

export const convertBlobToFile = (blob, name, type = "image/jpg") => new File([blob], name, { type, lastModified: new Date().getTime() })

export const convertFileToBlob = file => new Blob([JSON.stringify(file, null, 2)], {
    type: file.type,
});

export const isObject = A => (typeof A === "object" || typeof A === 'function') && (A !== null)
export const isObjectEmpty = A => isObject && !Object.keys(A).length

export const isChrome = () => {
    // Get the user-agent string
    let userAgentString =
        navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
        userAgentString.indexOf("Chrome") > -1;

    if (chromeAgent)
        chromeAgent = true;

    return chromeAgent;
}

export const isSafari = () => {

    // Get the user-agent string
    let userAgentString =
        navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
        userAgentString.indexOf("Chrome") > -1;

    let safariAgent =
        userAgentString.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent))
        safariAgent = false;

    return safariAgent;

    // return document.body.clientWidth < 576;
}

export function createMarkup(body, maxLength = 150) {
    let res = removeTags(body);
    res = res.trim();
    res = res.replace(/(\r\n|\n|\r)/gm, ""); //remove new line in body
    if (res.length > maxLength) {
        res = res.substring(0, maxLength).concat(' ...')
    }
    return res;
}

export const removeTags = (str) => {
    if (!str)// if null body, return blank
        return '';
    else
        str = str.toString();

    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
}

export const setUserTokens = async (tokens) => {
    try {
        const rpc = new RPC();

        const resp = tokens.map(async (i) => {
            const token_name = await rpc.getName(i.contract_address);
            switch (token_name) {
                case TokenTypeByName.Arcana:
                case TokenTypeByName.Anima:
                case TokenTypeByName.Persona:
                    i.token_name = token_name
                    break;
                default:
                    i.token_name = TokenTypeByName.Other
                    break;
            }

            i.token_image = getTokenImageByContractAddress(i.contract_address)
            return i
        })

        return (await Promise.all(resp))
    } catch (error) {
        return []
    }
}

export const isJP = () => translations._language === 'jp';

export const cutNumber = (number, digitsAfterDot) => {
    const str = `${number}`;

    if (Number.isInteger(Number(str))) {
        return parseInt(str).toFixed(digitsAfterDot)
    } else {
        var res = str.slice(0, str.indexOf('.') + digitsAfterDot + 1);
        if (digitsAfterDot) {
            return res.padEnd(str.indexOf('.') + digitsAfterDot + 1, "0");
        } else {
            return res.split('.')[0];
        }
    }
}

const { BN } = require("web3-utils");
export const calculateBN = (balance, decimals, decimal_places = 0, showFullBalance = false) => {
    balance = window.BigInt(balance ?? 0).toString() || 0;
    decimals = decimals || 0;
    const decimalsBN = new BN(10).pow(new BN(decimals));
    const amountBN = new BN(balance.toLocaleString('fullwide', { useGrouping: false })).divmod(decimalsBN);
    const base = amountBN.div + "." + amountBN.mod.toString(10, decimals);
    return showFullBalance ? base : cutNumber(base, decimal_places);
}

const BigNumber = require('bignumber.js');
export const toBigNumber = (etherValue, decimalPlaces) => {
    const value = new BigNumber(etherValue).times(new BigNumber(10).pow(decimalPlaces));
    return value.toFixed(0);
};

export function parseToExecutableHTML(rawString) {
    if (!rawString) {
        return null;
    }

    // Remove backslashes from the string
    const cleanString = rawString.replace(/\\/g, '');

    // Create a new temporary element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = cleanString;

    // Get the content within the <pre> tag
    const preElement = tempElement.querySelector('pre');
    if (!preElement) {
        return tempElement?.innerHTML;
    }

    const preContent = preElement.textContent;

    // Create a new element to hold the parsed HTML
    const parsedElement = document.createElement('div');
    parsedElement.innerHTML = preContent;

    // Return the parsed HTML code
    return parsedElement.innerHTML;
}

export function extractTextFromTags(htmlString) {
    if (!htmlString) {
        return '';
    }

    // Remove style tags and their contents
    const cleanedString = htmlString.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');

    // Create a temporary element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = cleanedString;

    // Select the desired tags
    const tagsToExtract = ['div', 'p'];

    // Set to store unique extracted text values
    const extractedTextSet = new Set();

    // Recursive function to traverse the DOM tree
    function traverse(element) {
        // If the current element is a desired tag, extract its text
        if (tagsToExtract.includes(element.tagName.toLowerCase())) {
            const text = element.textContent.trim();
            if (text) {
                extractedTextSet.add(text);
            }
        }

        // Traverse the child nodes
        const childNodes = element.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];
            if (childNode.nodeType === Node.ELEMENT_NODE) {
                traverse(childNode);
            }
        }
    }

    // Start traversing from the temporary element
    traverse(tempElement);

    // Convert the set to an array and join the extracted text values
    const extractedText = Array.from(extractedTextSet).join(', ');

    // Return the extracted text as a string
    return extractedText;
}

export const delay = (func, sec = 1000) => setTimeout(func, sec);

export const swalText = (text, tag = "div", noOffset = false) => {
    return `<${tag} class=${noOffset ? "mt-0" : ""}>${text}</${tag}>`
}
