import React, { useState, useEffect } from "react";
import "../../style/ProductDetail.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { Select } from "../../components/common/Form";
import Modal from "../../components/common/Modal";
import { timeZoneOptions } from "../../services/timeZone";
import { strings, translations } from "../../services/localization";
import { useContext } from "react";
import { appContext } from "../../context/appContext";
import { useLayoutEffect } from "react";
import { useForceUpdate } from "../../services/hook";
import { SwalCorrect, SwalSettings } from '../../components/common/Swal';
import { useMediaQuery } from 'react-responsive'
import Switch from "react-switch";
import { audioContext } from "../../context/audioContext";
import { swalText } from "../../components/common/commonFnc";
import { Btn } from "../../components/common/Button";

const nameOptions = [{ value: '1', text: "name" }];
const descriptionOptions = [{ value: '1', text: "text" }];
const styleOptions = [{ value: '1', text: "R&D" }];
const personaOptions = [{ value: '1', text: "N/A" }];
const languageOptions = [{ value: '1', text: 'English' }, { value: '2', text: '日本語' }]
// const timeZoneOptions = [{ value: '1', text: 'Asia/Tokyo (GMT+9)' }, { value: '2', text: 'Asia/Yangon (GMT+6:30)' }]

function Settings() {
    const content = translations.setting;
    const buttonlist = translations.buttonlist;
    const settingsPage = translations.settingsPage;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [chosenLanguage, setChosenLanguage] = useState();
    const [chosenTimeZone, setChosenTimeZone] = useState();
    const [provider, setProvider] = useState(null);
    const [displayModal, setDisplayModal] = useState(false)

    const [tokenName, setTokenName] = useState('');
    const [creatorName, setCreatorName] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [image, setImage] = useState('');
    const [temp, setTemp] = useState('')
    const [currentChange, setCurrentChange] = useState('')
    const [arrayTimeZone, setArrayTimeZone] = useState([])
    const { language, updateLang, timeZone, updateTimeZone, audio } = useContext(appContext)
    const { isMuted, handleMusicOnOff } = useContext(audioContext);
    const forceUpdate = useForceUpdate();

    const isEng = language === 'English';

    useLayoutEffect(() => {
        setChosenLanguage(language === 'Japanese' ? '2' : '1')
    }, [language])

    useLayoutEffect(() => {
        setChosenTimeZone(timeZone)
    }, [timeZone])


    const onChangeLanguageHandler = (e) => {
        setTemp(e)
        setCurrentChange('lang')
    }

    const onChangeTimeZoneHandler = (e) => {
        setTemp(e)
        setCurrentChange('time')
    }

    const isPC = useMediaQuery({ minWidth: 992 })

    useEffect(() => {
        if (currentChange !== '' || currentChange === 'time') {
            isPC ? onChangeHandler() : setDisplayModal(true)
        }
    }, [currentChange]);

    useEffect(() => {
        if (!displayModal) {
            setCurrentChange('')
        }
    }, [displayModal]);

    const onChangeHandler = () => {
        SwalSettings.fire({
            text: strings.formatString(content.messageNotify),
            confirmButtonText: swalText(strings.formatString(buttonlist.saveBtn)),
            cancelButtonText: swalText(strings.formatString(buttonlist.cancelBtn)),
        }).then((result) => {
            if (result.isConfirmed) {
                statusChangeHandler()
            }
            setCurrentChange('')
        });
    }

    const statusChangeHandler = () => {
        if (currentChange === 'lang') {
            if (temp === '1') {
                setChosenLanguage(temp)
                updateLang('English')
                // setValue('language', 'English')
                // onSetLanguageToEnglish()
            }
            else {
                setChosenLanguage(temp)
                updateLang('Japanese')
                // setValue('language', 'Japanese')
                // onSetLanguageToJapanese()
            }
            window.location.reload();
            // forceUpdate();
        }
        else if (currentChange === 'time') {
            setChosenTimeZone(temp)
            updateTimeZone(timeZoneOptions.filter((each) => {
                return each.value === temp
            })[0]?.value)
        }
        setCurrentChange('')
        setDisplayModal(false)
    }

    const metaTitle = '設定｜ANICANA θυρα（アニカナテュラー）'
    const metaDescription = 'ANICANA θυρα（アニカナテュラー）のプロダクト・ゲーム一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'
  
    return (
        <AppWrapper title={strings.formatString(content.mainTitle)} pageid="Setting" isForm isCenter hideBottomNav={displayModal} metaTitle={metaTitle} metaDescription={metaDescription}>
            <div style={{ marginTop: 15 + "px" }} className="px-2">
                <div className="my-2 d-flex flex-column py-2">
                    <span className="kardust-light-font">{strings.formatString(settingsPage.Version)}</span>
                    <div className="position-relative mt-2 ms-2">
                        <span className='mx-2 text-muted version-code' style={{ fontSize: '18px' }}>3.1</span>
                    </div>
                </div>
                <div className="divider my-4 w-100 mx-auto"></div>
                <div className="mb-5">
                    <audio id='beep' preload='auto' src='https://www.peter-weinberg.com/files/1014/8073/6015/BeepSound.wav'></audio>
                    {
                        chosenLanguage &&
                        <Select
                            label={strings.formatString(settingsPage.Language)}
                            options={languageOptions} value={chosenLanguage}
                            onChange={(e) => onChangeLanguageHandler(e.target.value)}
                            ms_0={'true'} className="setting-select" iconclass="setting-dropdown-icon"
                        />
                    }
                </div>
                <div className="mb-5">
                    {
                        timeZone &&
                        <Select
                            label={strings.formatString(settingsPage.Time_Zone)}
                            options={timeZoneOptions} value={chosenTimeZone}
                            onChange={(e) => onChangeTimeZoneHandler(e.target.value)}
                            ms_0={'true'} className="setting-select" iconclass="setting-dropdown-icon"
                        />
                    }
                </div>
                <div className="d-flex">
                    <span className="m-r-30">{strings.formatString(settingsPage.BGM)}</span>
                    <Switch
                        onChange={handleMusicOnOff}
                        checked={!isMuted}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className={`twoFa-switch-${!isMuted ? 'on' : 'off'}`}
                        activeBoxShadow=''
                        width={65}
                        handleDiameter={23}
                    />
                </div>
            </div>
            {
                <Modal displayModal={displayModal} onClose={() => setDisplayModal(!displayModal)}>
                    <div className='flex-center text-center flex-column'>
                        <span className="fw-500">
                            {strings.formatString(settingsPage.confirm_Modal_Text)}
                        </span>
                    </div>
                    <div className='flex-center flex-column w-90 mx-auto px-3'>
                        <Btn type='button' onClick={statusChangeHandler} className='mb-4 w-100' style={{ margin: '33px 0' }}>
                            {strings.formatString(buttonlist.saveBtn)}
                        </Btn>
                        <Btn intent="outline" type='button' onClick={() => setDisplayModal(false)} className='w-100'>
                            {strings.formatString(buttonlist.cancelBtn)}
                        </Btn>
                    </div>
                </Modal>
            }
        </AppWrapper>
    );
}

export default Settings;
