import React from 'react'

export const NumberCard = (props) => {
    return (
        <div className='card number-card'>
            <div className='flex-center'>
                <div className='number'>
                    {props.number}
                </div>
            </div>
            <div className={`card-body ${props.className}`}>
                {props.children}
            </div>
        </div>
    )
}

export const CardLineHeader = props => {
    const { title, className, textClassName, dividerClassName, textStyle, hasDivider } = props;
    return (
        <div className={`row card-line-header ${className || ''}`}>
            <p className={`col ${textClassName || ''}`} id="detail-label" style={textStyle}>{title}</p>
            {
                hasDivider &&
                <div className={`col divider ${dividerClassName || ''}`} style={{ borderWidth: 0.5, zIndex: 0 }}></div>
            }
        </div>
    )
}