/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppWrapper from '../../components/wrappers/AppWrapper'
import QRCode from "react-qr-code";
import { translations } from "../../services/localization";
import { Web3authContext } from '../../context/web3authContext'
import { CopyIcon } from '../../components/common/Icon'
import { copyToClipboard } from '../../components/common/commonFnc'
import { appContext } from '../../context/appContext'
import { getData } from '../../services/fetch'
import { getWalletAddress } from '../../services/storage'
import { OnLoading } from "../../components/common/OnLoading";

const WalletLevias = ({
  disabledTextOverflow
}) => {
  const navigate = useNavigate();
  const content = translations.walletReceive
  const levicaQR = translations.levica

  const { wallet_address, setWallet_address } = useContext(Web3authContext);
  const { timeZone } = useContext(appContext)
  const [leviasBalances, setLeviasBalances] = useState(0)
  const [leviasChainAddress, setLeviasChainAddress] = useState('')
  const [loading, setLoading] = useState(true);
  
  const getLevicaBalance = async () => {
    const resp = await getData(`user/levica-balance`);
    setLeviasBalances(resp?.balance);
    setLeviasChainAddress(resp?.levias_chain_address || '');
  };

useEffect(() => {
  async function fetchData() {
    const address = getWalletAddress()
    if(!address){
        navigate('/')
        return
    }
    setWallet_address(address)
    await getLevicaBalance()
    setLoading(false)
  }

  fetchData();
},[navigate, setWallet_address, timeZone]);

  return (
    <AppWrapper title={translations.formatString(content.headerTitle)}>
    {loading === true ? <OnLoading /> :
      <div className='levias-wrapper row'>
        <div className='col-12 col-md-6'>
          <div className='text-center text-uppercase fs-35 levias-title'>
            LEVICA
          </div>
          <div className='text-center text-uppercase fs-30'>{leviasBalances}</div>
          <div className='divider my-3'></div>
          <div className={`d-flex flex-column`}>
            <h4 className='mb-3 fs-20 wallet-address-title text-center'>
              {levicaQR.levicaAddress}
            </h4>
            
            <div className='w-100 d-flex flex-row justify-content-between align-items-center border-box mt-2'>
              <a className={`${!disabledTextOverflow ? "text-overflow" : ""} text-start fs-16`}>
                {leviasChainAddress || wallet_address}
              </a>
              <div className='ms-2' style={{ width: 22 }}>
                <CopyIcon data-bs-toggle="tooltip" 
                  data-bs-title="Default tooltip" 
                  data-bs-trigger="click" 
                  className="pointer"
                  onClick={() => copyToClipboard('8jr547h2we0df55hd8tj49esjero5i')} 
                />
              </div>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-12 text-center'>
              <h5 className='my-wallet-address fs-20'>{levicaQR.LevicaQRCode}</h5>
              <div className='text-center mt-4'>
                <div className='border-box'>
                  <QRCode value={leviasChainAddress || wallet_address} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6'></div>
      </div>
    }
    </AppWrapper>
  )
}

export default WalletLevias