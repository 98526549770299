import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "../../style/ProductDetail.scss";
import { useNavigate } from "react-router-dom";
import { languageAction } from "../../store/Language";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { HidePasswordIcon } from "../../components/common/Icon";
import { ShowPasswordIcon } from "../../components/common/Icon";
import { fetchData } from "../../services/fetch";
import { SwalCorrect, SwalWrong } from "../../components/common/Swal";
import { clearLocalStorage, getToken, getValue } from "../../services/storage";
import { AuthContext } from "../../context/authContext";
import { API_DOMAIN } from "../../config";
import axios from "axios";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha, } from 'react-simple-captcha';
import { authAction } from "../../store/Auth";
import { strings, translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";

function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [chosenLanguage, setChosenLanguage] = useState(language.language);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [loading, setLoading] = useState(false)
  const [auth, setAuth] = useState()
  const [captValid, setCaptValid] = useState(false)
  const content = translations.changePassword;
  const buttonlist = translations.buttonlist;
  const messagelist = translations.message;
  const changePasswordPage = translations.changePasswordPage; // Usage: strings.formatString(changePasswordPage.change_Password)

  const onShowHandler = (type, status) => {
    if (type === "password") {
      setShowPassword(status);
    } else if (type === "confirm-password") {
      setShowConfirmPassword(status);
    } else if (type === "old-password") {
      setShowOldPassword(status);
    }
  };

  const textChangeHandler = (type, value) => {
    if (type === "password") {
      setPassword(value);
      passwordValidator("password", value);
    } else if (type === "confirm-password") {
      setConfirmPassword(value);
      passwordValidator("con-password", value);
    } else if (type === "old-password") {
      setOldPassword(value);
      passwordValidator("old-password", value);
    }
  };

  const passwordValidator = (value, result) => {
    if (value === "password") {
      if (result.length > 7) {
        if (result === confirmPassword) {
          setPasswordValid(true);
        }
        else {
          setPasswordValid(false)
        }
      }
      else {
        setPasswordValid(false)
      }
    } else if (value === "con-password") {
      if (result.length > 7) {
        if (password === result) {
          setPasswordValid(true);
        }
        else {
          setPasswordValid(false)
        }
      }
      else {
        setPasswordValid(false)
      }
    }
    else if (value === "old-password") {
      if (result.length > 7) {
        if (password === result) {
          setPasswordValid(true);
        }
        else {
          setPasswordValid(false)
        }
      }
      else {
        setPasswordValid(false)
      }
    }
  };

  const { logout } = useContext(AuthContext)

  const getTokenHandler = async () => {
    var token = await getToken()
    setAuth(token)

  }

  useEffect(() => {
    getTokenHandler()
  }, [])

  const onSignOutHandler = () => {
    logout();
  }

  const onChangePasswordHandler = async () => {
    setLoading(true)
    const url = API_DOMAIN + 'accounts/change-password'
    axios
      .post(
        url,
        {
          password,
          confirmPassword: confirmPassword,
          oldPassword,
          // new_password:password,
          // confirm_password:confirmPassword
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.message) {
          SwalCorrect.fire({
            // text: response.data?.message
            text: strings.formatString(changePasswordPage.Your_password_has_been_successfully_changed)
          }).then((result) => {
            if (result.isConfirmed) {
              setLoading(false)
              onSignOutHandler()
            }
          })
        }
      })
      .catch((error) => {
        setLoading(false);
        SwalWrong.fire({
          title: "Error",
          // text: response.data.message,
          text: error.response.data.message ?? error.message
          // text: error.message
        });
      })
  }

  return (
    <>
      <AppWrapper className="auth-container" title={strings.formatString(content.headerTitle)} activeProfile isForm isCenter hasInput>
        <div className="container-fluid">
          {loading === true ? <OnLoading /> :
            <div className="col-12 px-2 d-flex flex-column">
              <div className="py-1 text-center">
                <span className="changePassword-label">{strings.formatString(changePasswordPage.change_Password)}</span>
                <div className="divider mb-2 mt-3 w-75 mx-auto"></div>
              </div>
              <div>
                <div className="my-2 mt-3">
                  <span>{strings.formatString(changePasswordPage.Old_Password)}</span>
                </div>
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute p-2 px-3 w-100 changePassword-pleaceholder"
                    onChange={(e) => textChangeHandler("old-password", e.target.value)}
                    value={oldPassword}
                    placeholder={strings.formatString(changePasswordPage.Old_Password)}
                    // onBlur={emailValidator}
                    type={showOldPassword === true ? "text" : "password"}
                  />
                  {showOldPassword === true ? (
                    <HidePasswordIcon
                      type="old-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="old-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>

                <div className="my-2 mt-3">
                  <span>{strings.formatString(changePasswordPage.New_Password)}</span>
                </div>
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute p-2 px-3 w-100 changePassword-pleaceholder"
                    onChange={(e) => textChangeHandler("password", e.target.value)}
                    value={password}
                    placeholder={strings.formatString(changePasswordPage.New_Password)}
                    // onBlur={emailValidator}
                    type={showPassword === true ? "text" : "password"}
                  />
                  {showPassword === true ? (
                    <HidePasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
              </div>

              <div className="my-3">
                <div className="my-2">
                  <span>{strings.formatString(changePasswordPage.Confirm_Password)}</span>
                </div>
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute px-3 p-2 w-100 changePassword-pleaceholder"
                    onChange={(e) => textChangeHandler("confirm-password", e.target.value)}
                    value={confirmPassword}
                    placeholder={strings.formatString(changePasswordPage.Confirm_Password)}
                    // onBlur={emailValidator}
                    type={showConfirmPassword === true ? "text" : "password"}
                  />
                  {showConfirmPassword === true ? (
                    <HidePasswordIcon
                      type="confirm-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="confirm-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
              </div>
              <div className="text-center py-4 d-flex w-100 justify-content-evenly">
                <button
                  onClick={() => navigate("/account_detail")}
                  className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                >
                  {strings.formatString(buttonlist.cancelBtn)}
                </button>
                {passwordValid === true ? (
                  <button
                    className="btn btn-custom-active my-3 py-1 col-5"
                    onClick={onChangePasswordHandler}
                  >
                    {strings.formatString(buttonlist.nextBtn)}
                  </button>
                ) : (
                  <button
                    disabled
                    className="btn btn-custom-success my-3 py-1 col-5"
                  >
                    {strings.formatString(buttonlist.nextBtn)}
                  </button>
                )}
              </div>
            </div>}
        </div>
      </AppWrapper>
    </>
  );
}

export default ChangePassword;


// <label for="exampleFormControlTextarea1">Example textarea</label>
//     <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
