import { useState, useEffect, useContext, useCallback, forwardRef, useImperativeHandle } from 'react'
import { useNavigate, Outlet, useMatch, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import numeral from "numeral";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {
    ArcanaPlusIcon,
    SpinnerIcon,
    KeyIcon,
    ArcanaNoImgIcon,
    ArcanaNoImgDetailIcon,
    UnSubscribeIcon,
    SubscribeIcon,
    DrawChainIcon,
} from "../../components/common/Icon";
import { Button } from "./Button";
import { ShowKeyModal } from "../../components/common/ShowKeyModal";
import { getToken, getWalletAddress } from "../../services/storage";
import { SwalCorrect, SwalWrong, SwalConfirm } from "../../components/common/Swal";
import { getData } from "../../services/fetch";
import { getValue } from "../../services/storage";
import { translations } from '../../services/localization';
import { useDidUpdateEffect, useWeb3AuthInit } from '../../services/hook';
import { Web3authContext } from '../../context/web3authContext';
import Web3 from 'web3';
import RPC from "../../components/common/web3RPC";
import { SquarePointAlert } from '../../components/common/SquarePointAlert';
import { RequireSquareRegistrationModal } from '../../components/common/RequireSquareRegistrationModal';
import { useMediaQuery } from 'react-responsive';
import { useOverflowDetection } from '../../hooks/useOverflowDetection';
import { appContext } from "../../context/appContext";
import { UnsubscribeConfirmation } from '../../components/common/UnsubscribeConfirmation';
import { getSquareAsync } from '../../store/Square';
import { OnLoading } from '../../components/common/OnLoading';

export const SquareProfile = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { square_id } = useParams();
    const { squareDetail, previous_square_id, loading } = useSelector(state => structuredClone(state.square));
    const content = translations.squareList
    const squareDetailContent = translations.squareDetail;
    const buttonlist = translations.buttonlist;

    const [contractLoading, setContractLoading] = useState(false);
    const [showKey, setShowKey] = useState(false);
    const [isLogined, setLogined] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(undefined);
    const [checkSubscriberLoading, setCheckSubscriberLoading] = useState(true);
    const [subscribers, setSubscribers] = useState(undefined);
    const [user, setUser] = useState(null);
    const [showSquarePointAlert, setShowSquarePointAlert] = useState(false);
    const [squarePoint, setSquarePoint] = useState(0);
    const [squarePointLoading, setSquarePointLoading] = useState(true);
    const [arcanaGenLinkLoading, setArcanaGenLinkLoading] = useState(false);
    const [showRequireSquareRegistration, setShowRequireSquareRegistration] = useState(false);
    const [showUnsubscribeConfirmation, setShowUnsubscribeConfirmation] = useState(false);
    const [hideBottomNav, setHideBottomNav] = useState(false);
    const match = useMatch('squares/:square_id');
    const [isArcanaGenAvailable, setIsArcanaGenAvailable] = useState(false);
    const { language } = useContext(appContext);

    const isEng = language === 'English';

    const shortLang = isEng ? 'en' : 'jp';

    const isPC = useMediaQuery({ minWidth: 992 });

    const { isValidWalletAddress, logout, squareContract } = useContext(Web3authContext);
    const [startToggleFollow, setStartToggleFollow] = useState(false);

    useEffect(() => {
        if (previous_square_id !== square_id) {
            dispatch(getSquareAsync({
                id:square_id,
                language:shortLang
            }));
        }
    }, [square_id])

    const handleToggleFollow = async () => {
        setStartToggleFollow(true);
    }

    const handleIsFollower = async () => {
        const rpc = new RPC(null);
        const isFollower = await rpc.getSquareIsFollower(square_id, getWalletAddress());
        setIsSubscribed(isFollower);
        setCheckSubscriberLoading(false);
    }
    const handleNumFollowers = async () => {
        const rpc = new RPC(null);
        const followers = await rpc.getSquareNumFollowers(square_id);
        setSubscribers(followers);
    }
    const handleSquareContract = async () => {
        try {
            setStartToggleFollow(false);
            const isValid = await isValidWalletAddress();
            setContractLoading(true);
            if (isValid) {
                const method = isSubscribed ? "unfollow" : "follow";
                const receipt = await squareContract(method, square_id);
                // const receipt = {status: false, revertReason: "0x08c379a0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000184552433732313a20696e76616c696420746f6b656e2049440000000000000000"}
                console.log('squareContract res', method, square_id, receipt);
                if (receipt && receipt.status) {
                    SwalCorrect.fire({
                        title: (!isSubscribed) ? content.subscribed : content.unsubscribed,
                        text: (!isSubscribed) ? content.subscribedSuccess : content.unsubscribeSuccess,
                        customClass:{
                            cancelButton:'d-none'
                        }
                    })
                    setIsSubscribed(isSubscribed => !isSubscribed);
                } else {
                    let revert_reason = "Error in subscribing";
                    if (receipt && receipt.revertReason) {
                        revert_reason = Web3.utils.toAscii(receipt.revertReason);
                        console.log({ revert_reason })
                    } else if (receipt && receipt.error) {
                        revert_reason = receipt.error;
                    }
                    SwalWrong.fire({
                        title: 'Error',
                        text: revert_reason
                    })
                }
            } else {
                SwalWrong.fire({
                    title: 'エラーが発生しました',
                    text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
                });
                logout();
            }
            setContractLoading(false);
        } catch (e) {
            setContractLoading(false);
            console.error(e);
        }
    }

    // useWeb3AuthInit(true, handleIsFollower, wallet_address);
    useWeb3AuthInit(startToggleFollow, handleSquareContract, startToggleFollow)
    useEffect(() => {
        handleIsFollower();
        handleNumFollowers();
    }, [isSubscribed])

    useDidUpdateEffect(() => {
        setContractLoading(false);
    }, [subscribers])

    useEffect(() => {
        (async () => {
            const token = await getToken();
            setLogined(!!token);
        })();
        getValue('user_info').then(response => {
            setUser(response);
            getArcanaGenAvailable();
        })
    }, [])

    const handleSquarePointAlert = useCallback(() => {
        if (isPC) {
            SwalConfirm.fire({
                title: translations.formatString(squareDetailContent?.generateArcana),
                html: (<div className='d-flex flex-column align-items-center pt-3'>
                    <Avatar src={squareDetail?.square_maker_icon} />
                    <span className='fs-20 text-nowrap my-1 mb-3' style={{ margin: '20px 0' }}>{numeral(squarePoint).format(0, 0)}</span>
                    <span>{translations.formatString(squareDetailContent?.squarePointAlert, squareDetail?.square_maker)}</span>
                </div>),
                confirmButtonText: `<span class="text-align-bt fs-20">${translations.formatString(squareDetailContent?.createArcanaDialog?.okButton)}</span>`,
                cancelButtonText: `<span class="text-align-bt fs-20">${translations.formatString(squareDetailContent?.createArcanaDialog?.cancelButton)}</span>`,
                reverseActions: true
            }).then((result) => {
                if (result.isConfirmed) {
                    handleArcanaGenRedirect();
                }
            })
        } else {
            setShowSquarePointAlert(true)
        }
    }, [isPC, squareDetail])

    useImperativeHandle(ref, () => ({
        handleSquarePointAlert
    }))

    const getUserSquarePoint = async () => {
        const walletAddress = await getWalletAddress();
        setSquarePointLoading(true);
        const response = await getData('/user-square-point?walletAddress=' + walletAddress +'&square_id='+square_id);
        setSquarePoint(response?.data?.data?.total_point);
        setSquarePointLoading(false);
    }

    useEffect(() => {
        if (isLogined) {
            getUserSquarePoint();
        }
    }, [isLogined])

    const getArcanaGenLink = async () => {
        const walletAddress = await getWalletAddress();
        const response = await getData('/generate-arcana?walletAddress=' + walletAddress+'&square_id='+square_id);
        return response?.data?.data?.url;
    }

    const getArcanaGenAvailable = async () => {
        const walletAddress = await getWalletAddress();
        if(walletAddress){
            const response = await getData('/generate-arcana/available?walletAddress=' + walletAddress+'&square_id='+square_id);
            setIsArcanaGenAvailable(response?.data?.data?.available)
        }
    }


    const handleArcanaGenRedirect = async () => {
        setArcanaGenLinkLoading(true);
        const url = await getArcanaGenLink();
        const callbackUrlParam = '?r=' + window.location.origin + window.location.pathname;
        if (url.includes(window.location.origin)) {
            // for same origin
            const route = url.split("").slice(window.location.origin.length).join("");
            navigate(route + callbackUrlParam);
        } else {
            // for different origin
            window.location.assign(url + callbackUrlParam);
        }
        setArcanaGenLinkLoading(false);
        setShowSquarePointAlert(false);
    }

    useEffect(() => {
        setHideBottomNav(showUnsubscribeConfirmation || showSquarePointAlert);
    }, [showUnsubscribeConfirmation, showSquarePointAlert])

    const onClickSubscribe = useCallback(async () => {
        if (isLogined) {
            if (isSubscribed) {
                if (isPC) {
                    const result = await SwalConfirm.fire({
                        text: translations.formatString(squareDetailContent?.confirmUnsubscribe),
                        confirmButtonText: `${translations.formatString(buttonlist.okBtn)}`,
                        cancelButtonText: `${translations.formatString(buttonlist.cancelBtn)}`,
                        reverseActions: true
                    });
                    if (!result.isConfirmed) {
                        return;
                    }
                } else {
                    setShowUnsubscribeConfirmation(true);
                    return;
                }
            }
            handleToggleFollow();
        } else {
            navigate("/login/idms", { state: { redirect_uri: window.location.pathname } });
        }
    }, [isLogined, isSubscribed, isPC])

    const onClickSubscribeDebounced = debounce(onClickSubscribe, 100);

    const { overflowX, containerRef } = useOverflowDetection();

    const style_mb_if_English = isEng ? { marginBottom:1 } :{};

    const GenerateArcanaButton = ({ className = '', noLabel, aspectRatio, style = {} }) => (
        <Button
            disabled={!isArcanaGenAvailable}
            style={aspectRatio ? { aspectRatio: '1 / 1', ...style } : style}
            onClick={handleSquarePointAlert}
            gap={0}
            active
            className={`ff-Kardust p-0 rounded-${(isPC && !noLabel) ? 'pill px-2 pe-3' : 'circle'} ${className}`}
            icon={<ArcanaPlusIcon />}>
            {(isPC && !noLabel) ? (<div style={style_mb_if_English}>{squareDetailContent?.createArcana}</div>) : null}
        </Button>
    )

    const metaTitle = "スクエア｜ANICANA θυρα（アニカナテュラー）";
    const metaDescription =
        "ANICANA　θυρα（アニカナテュラー）の最新スクエアです｜ANICANA　θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

    const loadingMessage = translations.formatString(isSubscribed?squareDetailContent?.unregistrationInProgress:squareDetailContent?.registrationInProgress);
    
    const [firstLine,secondLine] = loadingMessage.split('\n');

    return (
        <AppWrapper
            squareActive="1"
            title={isPC ? "Square" : <></>}
            pageid="Square"
            noPadding={true}
            isNoSpaceAround
            disableHeader={!isPC}
            sidebarWrapperDivClassName="p-0"
            sidebarWrapperClassName="offOverFlowX"
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            isCustomDetailPage
            hideBottomNav={hideBottomNav}
        >
            {
                (loading || contractLoading) ? <OnLoading label={contractLoading?(<>
                    <span>{firstLine}</span><br />
                    <span>{secondLine}</span>
                </>):null} className={(contractLoading && !isPC)?'w-100':''} /> : (
                    <div className='w-100 position-relative'>
                        <div className='w-100 position-relative'>
                            {(match && !isPC) ? (<div
                                className='position-absolute'
                                style={{
                                    zIndex: 20,
                                    left: 10, top: 30,
                                }}>
                                <div
                                    onClick={() => navigate("/squares")}
                                    className='game-detail-arrow'
                                    style={{
                                        margin: 0,
                                        backgroundColor: '#32304E'
                                    }}></div>
                            </div>) : null
                            }
                            <Banner
                                content={squareDetailContent}
                                squareMaker={{ name: squareDetail?.square_maker, icon: squareDetail?.square_maker_icon }}
                                onClick={() => setShowKey(true)}
                                subscribers={subscribers}
                                background_image={isPC ? squareDetail?.background_image : squareDetail?.thumbnail_background_image}
                            />
                        </div>
                        <div
                            className={`d-flex ${isPC ? '' : 'flex-column'}`}
                            style={{
                                width: '90%',
                                margin: 'calc(56px / 2 + 25px) auto calc(25px - 0.5rem)',
                                gap: 'calc(40px - 0.5rem)',
                                justifyContent: isLogined ? '' : 'flex-end'
                            }}>
                            {isLogined && (<div className='d-flex' style={{
                                height: 40,
                                justifyContent: isPC ? 'flex-start' : 'space-between',
                                alignItems: 'center',
                                gap: 10,
                                marginInlineEnd: isPC ? 0 : 20,
                                marginInlineStart: 20
                            }}>
                                <div className='d-flex flex-center' style={{ gap: 10 }}>
                                    <Avatar src={squareDetail?.parent_square_maker_icon??squareDetail?.square_maker_icon} sm />
                                    {squarePointLoading ? <SpinnerIcon /> : (
                                        <div className='d-flex flex-center' style={{ gap: 10 }}>
                                            <span className='fs-20 text-nowrap'>{numeral(squarePoint).format('0,0')}</span><span className='fs-12 mt-1' style={{ marginTop: 2 }}>{squareDetailContent?.squarePointUnit}</span>
                                        </div>
                                    )}
                                </div>
                                <div className={`${isPC ? 'ms-4' : ''} position-relative`}>
                                    <GenerateArcanaButton aspectRatio={!isPC} noLabel={!isPC} className={`${(isPC && overflowX) ? 'opacity-0' : ''}`} style={!isPC ? { width: 40 } : {}} />
                                    {(isPC && overflowX) && <GenerateArcanaButton className={`position-absolute start-0 p-0`} noLabel aspectRatio style={{ top: '50%', transform: 'translateY(-50%)', width: 40 }} />}
                                </div>
                            </div>)}
                            <div
                                ref={containerRef}
                                style={{
                                    flexGrow: 1,
                                    height: 40,
                                    display: 'flex',
                                    gap: isPC ? 20 : 0,
                                    justifyContent: isPC ? 'end' : 'space-between',
                                    marginLeft: isPC ? 'auto' : 0
                                }}>
                                <SubscribeButton
                                    className={`${isSubscribed ? 'btn-unsubscribe' : 'btn-subscribe'} d-flex justify-content-center align-items-center border-radius-10px`}
                                    style={{
                                        flex: `0 0 ${isPC ? '175px' : 'calc((100% / 2) - (10% / 3))'}`,
                                        height: 40,
                                        ...(isPC ? {} : { minWidth: 'calc(90% / 2)' }),
                                        gap: isPC ? 10 : 8,
                                    }}
                                    content={squareDetailContent}
                                    onClick={onClickSubscribeDebounced}
                                    isSubscribed={isSubscribed}
                                    loading={contractLoading}
                                    initialLoading={isLogined && checkSubscriberLoading}
                                    disabled={isLogined && checkSubscriberLoading}
                                />
                                <DrawChainButton
                                    className={`d-flex justify-content-center align-items-center border-radius-10px ${!isSubscribed ? 'semi-disabled' : ''}`}
                                    style={{
                                        flex: `0 0 ${isPC ? '175px' : 'calc((100% / 2) - (10% / 3))'}`,
                                        height: 40,
                                        ...(isPC ? {} : { minWidth: 'calc(90% / 2)' }),
                                        gap: isPC ? 10 : 8,
                                    }}
                                    onClick={() => {
                                        if (isSubscribed) {
                                            navigate(`/squares/${square_id}/draw-chains`, { state: { squareDetail } });
                                        } else {
                                            setShowRequireSquareRegistration(true);
                                        }
                                    }}
                                    content={squareDetailContent} />
                            </div>
                        </div>

                        <Outlet context={{
                            handleSquarePointAlert,
                            setHideBottomNav
                        }} />

                        <ShowKeyModal
                            value={squareDetail?.square_address}
                            show={showKey}
                            onClose={() => setShowKey(false)}
                        />
                        {squareDetail?.square_maker && (<SquarePointAlert
                            onSaveLoading={arcanaGenLinkLoading}
                            squareMaker={{ name: squareDetail?.square_maker, icon: squareDetail?.square_maker_icon }}
                            squarePoint={numeral(squarePoint).format('0,0')}
                            opened={showSquarePointAlert}
                            onClose={() => setShowSquarePointAlert(false)}
                            onSave={handleArcanaGenRedirect} />)}
                        <RequireSquareRegistrationModal
                            show={showRequireSquareRegistration}
                            onClose={() => setShowRequireSquareRegistration(false)} />
                        <UnsubscribeConfirmation
                            opened={showUnsubscribeConfirmation}
                            onClose={() => setShowUnsubscribeConfirmation(false)}
                            onOk={() => {
                                setShowUnsubscribeConfirmation(false);
                                handleToggleFollow();
                            }} />
                    </div>
                )
            }
        </AppWrapper>
    );
})

export const Banner = ({ background_image, subscribers, onClick, squareMaker }) => {
    const isPC = useMediaQuery({ minWidth: 992 })
    return (
        <div className="w-100 position-relative" style={{ zIndex: 10 }}>
            {
                background_image !== null ?
                    <img
                        style={{
                            objectFit: "cover",
                            objectPosition: "top",
                            aspectRatio: `13/${isPC ? 2 : 5}`,
                        }}
                        src={background_image}
                        className="w-100"
                    />
                    :
                    <ArcanaNoImgDetailIcon
                        className="img-fluid w-100 px-2"
                        style={{
                            objectFit: "cover",
                            objectPosition: "top",
                            aspectRatio: `13/${isPC ? 2 : 5}`,
                        }}
                    />
            }
            <div
                style={{ height: 56, zIndex: 2, margin: '0 auto', width: '90%' }}
                className="position-absolute start-50 top-100 translate-middle bg-gradient-transparent bd-gradient-rounded-pill rounded-pill d-flex flex-nowrap justify-content-start align-items-center"
            >
                <Avatar src={squareMaker?.icon} />
                <div className="d-flex flex-column ms-2">
                    <Name value={squareMaker?.name ?? "Square Maker"} />
                    <Subscribers subscribers={subscribers} />
                </div>
                <Key onClick={onClick} />
            </div>
        </div>
    )
};

export const Avatar = (props) => (
    props.src ?
        <img
            style={{
                objectFit: "cover",
                objectPosition: "center",
                left: -1,
                aspectRatio: '1/1'
            }}
            width={props.width ?? (props.sm ? 35 : 56)}
            className="position-relative top-0 rounded-pill m-0"
            src={props.src}
        />
        :
        <ArcanaNoImgIcon className="square-avator" />
);

const Subscribers = (props) => {
    const { language } = useContext(appContext);
    const isEng = language === "English"
    return isEng ? (
        <span className="fs-14">
            {numeral(props.subscribers).format("0a").toUpperCase()} subscribers
        </span>
    ) : (<span className="fs-14">
        スクエア登録者数 {numeral(props.subscribers).format("0a").toUpperCase()} 名
    </span>);
}

const Name = (props) => <span className="fs-18 capitalize">{props.value}</span>;

const Key = (props) => (
    <div className="ms-auto me-2">
        <KeyIcon onClick={props.onClick} />
    </div>
);

const SubscribeButton = ({ isSubscribed, onClick, content, loading, initialLoading, ...rest }) => {
    const { language } = useContext(appContext);
    const isEng = language === "English";
    const icon = isSubscribed ? <UnSubscribeIcon className="gray-icon" /> : <SubscribeIcon className="gray-icon" />;
    return (
        <Button
            active
            onClick={onClick}
            loading={loading || initialLoading}
            icon={icon}
            {...rest}
        >
            {
                <span className={`${isEng ? 'fs-18' : 'ms-1 fs-15'} text-start`} style={{ marginBottom: isEng ? 2 : 1 }}>
                    {initialLoading ? (isEng ? "Loading" : "読込中") : (isSubscribed ? content?.unsubscribe : content?.subscribe)}
                </span>
            }
        </Button>
    )
};

const DrawChainButton = ({ onClick, content, ...rest }) => {
    const { language } = useContext(appContext);
    const isEng = language === "English";
    return (
        <Button
            onClick={onClick}
            icon={<DrawChainIcon />}
            {...rest}
        >
            <span className={`${isEng ? 'fs-18' : 'fs-15'} text-start`} style={{ marginBottom: isEng ? 2 : 1 }}>
                {content?.drawChain}
            </span>
        </Button>
    )
};

function debounce(func, wait) {
    let timeout;

    return function (...args) {
        const context = this;

        const later = function () {
            timeout = null;
            func.apply(context, args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

