import { useMediaQuery } from "react-responsive";
import { SpinnerIcon } from "../../components/common/Icon";

export const Button = ({ active, children, className, custom, loading, icon, gap, iconWrapperClassName, reverse, childrenClassName, ...rest }) => {
    const classNameString = custom
        ? className
        : `btn btn-custom-${active ? "active" : "cancel"
        }-sm text-nowrap fs-inherit fs-15 ${className ?? ""}`;
    const isPC = useMediaQuery({ minWidth: 992 });
    return (
        <button type="button" className={classNameString} {...rest}>
            <div className={`w-100 d-flex flex-center ${reverse ? 'flex-row-reverse' : ''} ${childrenClassName}`} style={{ gap: (loading || icon) ? (gap ?? (isPC ? 10 : 8)) : 0, }}>
                <div className={`d-flex flex-center ${iconWrapperClassName ?? ''}`} style={{ flexShrink: 0 }}>{loading ? <SpinnerIcon /> : icon}</div>
                {children}
            </div>
        </button>
    );
};
