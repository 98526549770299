import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageFromStorage, getValue, setLanguageToStorage, setValue } from "../services/storage";
import { Web3authContext } from "./web3authContext";
import { onSetLanguageToEnglish, onSetLanguageToJapanese } from "../services/localization";
import { isObject } from "../components/common/commonFnc";
import { useDidUpdateEffect } from "../services/hook";
import moment from 'moment-timezone';

export const appContext = createContext(); // could have a default value

export const defaultFilter = { token_type_id: 1 }

const ENGLISH = 'English';
const JAPANESE = 'Japanese';

const DEFAULT_LANGUAGE = JAPANESE;

export const AppProvider = ({ children }) => {
    const navigate = useNavigate();
    const web3auth = useContext(Web3authContext);
    const [user, setUser] = useState({});
    const lang = getLanguageFromStorage();
    const [language, setLanguage] = useState(lang || DEFAULT_LANGUAGE);
    const [timeZone, setTimeZone] = useState()
    const [globalSearch, setGlobalSearch] = useState('');
    const [showRecentSearch, setShowRecentSearch] = useState(false);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState("DESC");
    const [filter, setFilter] = useState(defaultFilter);
    const [filterTotal, setFilterTotal] = useState(0);
    const [filterCount, setFilterCount] = useState(0);
    const [arcanaGenTempData, setArcanaGenTempData] = useState(undefined);
    const [hasInput, setHasInput] = useState(false);
    // const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (filter) {
            let total = Object.keys(filter).length - 1;
            if (total > 0) {
                for (let key in filter) {
                    if (key === "price" || key === "anima_price")
                        total--
                }
                setFilterTotal(total);
            } else {
                setFilterTotal(0);
            }
        }
    }, [filter])

    useDidUpdateEffect(() => {
        if (filter.token_type_id) {
            navigate(`tokens/${filter.token_type_id}`)
        }
    }, [filterCount])

    const loadLangTimeZone = async () => {
        const timeZone = await getValue('time_zone');
        if (timeZone) {
            setTimeZone(timeZone)
        } else {
            const tz = moment.tz.guess(true);
            setValue('time_zone', tz);
            setTimeZone(tz);
        }
        if (language === ENGLISH) {
            onSetLanguageToEnglish();
        }else{
            onSetLanguageToJapanese();
        }
    }

    useEffect(() => {
        loadLangTimeZone();
    }, [])

    useEffect(() => {
        setLanguageToStorage(language)
        if (language === ENGLISH) {
            onSetLanguageToEnglish();
        }
        else if (language === JAPANESE) {
            onSetLanguageToJapanese();
        }
    }, [language])

    const updateLang = (lang) => {
        setLanguage(lang)
    }

    const updateTimeZone = (time) => {
        setTimeZone(time)
        setValue('time_zone', time)
    }

    const updateFilter = (key, value) => {
        if (Array.isArray(value) && value.length == 0) {
            delete filter[key];
        } else if (isObject(value)) {
            let valid = false;
            for (let properties in value) {
                if (value[properties]) {
                    valid = true;
                }
            }
            if (valid) {
                filter[key] = value;
            } else {
                delete filter[key];
            }
        } else if ((typeof value === 'string' || value instanceof String) && !value) {
            delete filter[key];
        } else {
            filter[key] = value;
        }
        setFilter(JSON.parse(JSON.stringify(filter)));
    }

    const resetFilter = () => {
        setFilter(JSON.parse(JSON.stringify(defaultFilter)));
        increaseFilterCount();
    }

    const increaseFilterCount = () => {
        setFilterCount(count => count + 1);
    }

    const value = useMemo(() => ({
        language, timeZone, updateTimeZone, updateLang, search, setSearch, filter, setFilter, updateFilter, resetFilter, filterCount, increaseFilterCount, arcanaGenTempData, setArcanaGenTempData, hasInput, setHasInput, filterTotal, sort, setSort, globalSearch, setGlobalSearch, showRecentSearch, setShowRecentSearch
    }), [language, timeZone, search, filter, filterCount, arcanaGenTempData, hasInput, filterTotal, sort, globalSearch, showRecentSearch]);

    return (
        <appContext.Provider value={value}>
            {children}
        </appContext.Provider>
    )
}
