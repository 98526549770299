import React, {useEffect, useState} from 'react'
import { WalletTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { OnLoading } from '../../components/common/OnLoading'
import { ManaAddressItem } from '../../components/common/Item'
import RPC from "../../components/common/web3RPC";
import {getWalletAddress} from "../../services/storage";
import { translations } from '../../services/localization'

const WalletTransactions = () => {
    const MANA_PAGE_LIMIT = 10
    const [loading, setLoading] = useState(true);
    const [manaItems, setManaItems] = useState([])
    const [manaStartIndex, setManaStartIndex] = useState(0)
    const [manaloadLimit, setManaLoadLimit] = useState(MANA_PAGE_LIMIT)
    const [manaInfoCount, setManaInfoCount] = useState(null)

    const content = translations.wallet;
    // For Purpose UI Design

    useEffect(() => {

        console.log("MANA2!")
        const rpc = new RPC();
        setLoading(true);
        rpc.getInfoByBeneficiary(getWalletAddress(),0,100).then(res=>{
            if(res){
                setManaItems([...res].reverse())
            }
            setLoading(false);
        }).catch(err=>{
            console.log(err);
            setLoading(false)
        })
    },[]);



    return (
        <AppWrapper title={content.headerTitle}>
            {
                loading ? <OnLoading /> :
                    <div id="wallet_dashboard">
                        <WalletTitle title={"Mana Addresses"}  />
                        <div className='row'>
                            <ManaAddressItem items={manaItems}/>
                        </div>
                    </div>
            }
        </AppWrapper>
    )
}

export default WalletTransactions
