import React, { useEffect } from 'react';

class StyleBlocker extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.shadowRoot.innerHTML = '';
        while (this.childNodes.length > 0) {
            this.shadowRoot.appendChild(this.childNodes[0]);
        }
    }
}

window.customElements.define('style-blocker', StyleBlocker);

function ReactStyleBlocker({ onImageClick, ...props }) {
    useEffect(() => {
        const styleBlockers = document.querySelectorAll('style-blocker');
        styleBlockers && styleBlockers.forEach(styleBlocker => {
            const styleBlockerRoot = styleBlocker?.shadowRoot;
            const imgTags = styleBlockerRoot?.querySelectorAll('img');
            imgTags && imgTags.forEach(imgTag => {
                imgTag && imgTag.addEventListener('click', function (e) {
                    onImageClick && onImageClick(imgTag.getAttribute('src'));
                });
                imgTag.style.maxWidth = '100%';
            })
        })
    }, [])
    return (
        <style-blocker>
            <div {...props}>
                {props.children}
            </div>
        </style-blocker>
    )
}

export default ReactStyleBlocker;
