import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { copyToClipboard } from '../../components/common/commonFnc'
import { CopyIcon } from '../../components/common/Icon'
import { strings, translations } from '../../services/localization';
import { getWalletAddress } from '../../services/storage';
import { WalletAddress } from './Wallet';

function WalletAddressRow() {

  const userWalletPage = translations.userWalletPage;
  const wallet_address = getWalletAddress()
  const isPC = useMediaQuery({minWidth: 992})

  return (
    isPC ?
    <div className='d-lg-flex col-8'>
      <div className='mt-2 m-r-18'>
        <span
          className={`jp-f-notosan f-expended-regular text-white text-start wallet-address-label-pc-view jp-fw-400`}
        >
          {strings.formatString(userWalletPage.Address)}
        </span>
      </div>
      
      <div 
        className='d-flex flex-row justify-content-between align-items-center border-address f-condensed'
      >
        <a className={`text-overflow text-start wallet-address-pc-view fw-500 fs-15`}
        >
          {wallet_address}
        </a>
        <div className='ms-2' style={{width: 22}}>
          <CopyIcon 
            data-bs-toggle="tooltip" 
            data-bs-title="Default tooltip" 
            data-bs-trigger="click"
            className="pointer" 
            onClick={() => copyToClipboard(wallet_address)}
          />
        </div>
      </div>
    </div>
    :
    <div className='row col-lg-12'>
      <div className='col-lg-12'>
        <WalletAddress titleClassName='ms-0' />
      </div>
    </div>
  )
}

export default WalletAddressRow