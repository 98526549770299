import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import AppWrapper from "../components/wrappers/AppWrapper";
import { MaintenancePCIcon, MaintenanceSPIcon } from "../components/common/Icon";
import { appContext } from "../context/appContext";

const Maintenance = (props) => {

	const { language } = useContext(appContext);
	const isEng = language === "English";
    const isPC = useMediaQuery({ minWidth: 992 });

    return (
        <AppWrapper hideNav hideBottomNav disableSidebar mainbodyClassName="p-0">
            <div
                className="height-without-nav"
                style={{
                    display: 'grid',
                    placeItems: 'center',
                    padding: isPC ? '110px 110px 40px' : '20px 15px',
                    overflow:'auto'
                }}>
                <div className='myVideoWrapper start-0'>
                    <video autoPlay loop muted playsInline id="myVideo" className="start-0">
                        <source src='./Anicana_FirstView_noOverlay_0723.mp4' type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <div style={{
                    width: '100%',
                    color: 'white',
                    display: 'grid',
                    gridTemplate: `1fr / ${isPC ? 'repeat(2,1fr)' : '1fr'}`,
                    gap: 10
                }}>
                    <div style={{
                        textAlign: isPC ? 'start' : 'center'
                    }}>
                        <h1 style={{
                            fontWeight: 500,
                            fontSize: isPC ? 40 : 25
                        }}>{isEng?props?.title_en:props?.title}</h1>
                        <div style={{ fontSize: isPC ? 18 : 14 }} dangerouslySetInnerHTML={{ __html:isEng?props.description_en:props.description}}>

                        </div>

                        {/* {!isPC && <MaintenanceSPIcon style={{ width: '100%', maxWidth: 343 }} />} */}
                        {!isPC && <img src={props.thumbnail_image_url} style={{ width: '90%', maxWidth: 343, margin:'50px 0' }}/>}
                    </div>
                    <div className="d-flex flex-end">
                        {/* {isPC && <MaintenancePCIcon />} */}
                        {isPC && <img src={props.image_url} style={{marginLeft:'auto',width:380}}/>}
                    </div>
                </div>
                <p style={{
                    width: '100%',
                    textAlign: isPC ? 'start' : 'center',
                    marginTop: 'auto',
                    marginBottom: 0,
                    fontSize: 15,
                    fontWeight: 500
                }}><span className="text-fancy-gradient">{isEng?props.footer_text_en:props.footer_text}</span></p>
            </div>
        </AppWrapper>
    )
}

export default Maintenance;
