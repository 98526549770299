import { useContext, useState } from "react";
import { languageEnum, SortTypeEnum } from "../../components/common/enum";
import { CloseIconFilter } from '../../components/common/Icon';
import { appContext } from "../../context/appContext";
import { translations } from "../../services/localization";
import { motion } from "framer-motion";
import { Btn } from "../../components/common/Button";

// export const CardLineHeader = props => {
//     const { title, className, textClassName, dividerClassName, textStyle } = props;
//     return (
//         <div className={`row card-line-header ${className || ''}`}>
//             <p className={`col title fs-25 ${textClassName || ''}`} style={textStyle}>{title}</p>
//             <div className={`col divider ${dividerClassName || ''}`}></div>
//         </div>
//     )
// }

const Sort = ({ className, onRequestClose, isSidebar }) => {
    const { sort, setSort, language } = useContext(appContext);
    const content = translations.sort;
    const buttonlist = translations.buttonlist;
    const [tempSort, setTempSort] = useState(sort);

    const TokenSortTypes = [
        { label: content.Newest, value: SortTypeEnum.DESC },
        { label: content.Oldest, value: SortTypeEnum.ASC },
        { label: content.Yen_Highiest_Purchase, value: SortTypeEnum.YEN_HIGHEST_PURCHASE },
        { label: content.Yen_Lowest_Purchase, value: SortTypeEnum.YEN_LOWEST_PURCHASE },
        { label: content.Anima_Highiest_Purchase, value: SortTypeEnum.ANIMA_HIGHEST_PURCHASE },
        { label: content.Anima_Lowest_Purchase, value: SortTypeEnum.ANIMA_LOWEST_PURCHASE },
    ];

    const applySort = () => {
        setSort(tempSort);
        if (!isSidebar) {
            setTimeout(onRequestClose, 50);
        }
    }
    return (
        <div className="filter">
            <motion.ul
                initial={{ '--opacity': 0 }}
                animate={{ '--opacity': 1 }}
                style={{ opacity: 'var(--opacity)' }}
                transition={{ duration: isSidebar ? 0.5 : 0 }}
                className={`${isSidebar ? 'sidebar-nav' : ''} filter filter-nav ${className || ''}`} id="accordionSidebar">
                <li className="sidebar-brand p-0 m-0 mt-4 my-lg-4">
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div className={`title fw-400 p-0 ${language === languageEnum.jp ? 'fs-25' : 'fs-35'}`}>
                            {translations.formatString(content.headerTitle)}
                        </div>
                        <div className="pointer" onClick={() => onRequestClose(false)}>
                            <CloseIconFilter style={{ stroke: '#BF96FF' }} />
                            {/*<CloseIcon style={{ stroke: '#FFF', width: 'unset' }} />*/}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="pt-2">
                        {
                            TokenSortTypes.map((sorting, index) => {
                                const id = sorting.value + index;
                                return (
                                    <div key={id} className="d-flex flex-row justify-content-between" style={{ marginBottom: 30 }}>
                                        <label className={`f-regular fw-400 fs-15 sort ${sorting.value === tempSort ? 'primary-color' : ''}`} htmlFor={id}>{sorting.label}</label>
                                        <input onChange={e => setTempSort(e.target.value)} className="form-check-input radio" type="radio" value={sorting.value} checked={sorting.value === tempSort} name="sort" id={id} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </li>
                {/* <li className="d-none d-lg-flex">
                    <button
                        onClick={applySort}
                        className="btn btn-custom-active w-100 my-3"
                    >
                        {translations.formatString(buttonlist.sortConfirmBtn)}
                    </button>
                </li> */}
                <li className='d-flex filter-action sort-action' style={{ opacity: 'var(--opacity)' }}>
                    <div className='d-flex flex-row align-items-center justify-content-evenly h-100 w-100'>
                        <Btn
                            onClick={applySort}
                            className="w-100 mt-3 mb-5 max-w-unset"
                        >
                            {translations.formatString(buttonlist.sortConfirmBtn)}
                        </Btn>
                    </div>
                </li>
            </motion.ul>
        </div>
    )
}

export default Sort;
