import React, { useState } from 'react'
import ReactQrReader from 'react-qr-reader2'

const QRReader = ({ onScan, onError }) => {

    const handleScan = data => {
        if (data) {
            onScan(data);
        }
    }

    const handleError = err => {
        console.error(err);
        onError && onError();
    }

    return (
        <div id="anicana-portal">
            <div className={`app-wrapper flex-center flex-column`}>
                <ReactQrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                />
            </div>
        </div>
    )
}

export default QRReader;