import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { getData } from "../../services/fetch";
import { SwalWrong } from "../../components/common/Swal";
import { translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";
import { SquareCard } from "../../components/common/Item";

const SquareList = () => {
    const [loading, setLoading] = useState(false);
    const language = translations._language;
    const [squares, setSquares] = useState(null);
    const [page, setPage] = useState(1);

    const content = translations.squareList;

    const fetchData = async () => {
        setLoading(true);
        const response = await getData(
            `squares?page=${page}&language=${language}`
        );
        if (response.ok) {
            const data = response.data;
            if (data) {
                setSquares(data.data);
            }
            setLoading(false);
        } else {
            SwalWrong.fire({
                title: "Error",
                text: "Can't fetch data",
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const metaTitle = "スクエア｜ANICANA θυρα（アニカナテュラー）";
    const metaDescription =
        "ANICANA θυρα（アニカナテュラー）の最新スクエアです｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

    const isPh = useMediaQuery({ maxWidth: 480 })
    return (
        <AppWrapper
            squareActive="1"
            title={translations.formatString(content.headerTitle)}
            pageid="Square"
            sidebarWrapperDivClassName={"p-0"}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            noPadding={true} isNoSpaceAround
        >
            {loading ? (
                <OnLoading />
            ) : (
                <div className={`mt-4 responsive-grid space-${isPh?10:15}px`}>
                    {squares?.map(it=>(
                        <SquareCard key={it.id} language={language} {...it} gridItem={isPh}/>
                    ))}
                </div>
            )}
        </AppWrapper>
    );
};

export default SquareList;
