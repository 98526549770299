import React from "react";
import { bootstrap } from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import RoutePath from './routes/route'
import './App.css'
import './style/app/app.scss';
import { Web3authProvider } from "./context/web3authContext";
import { AuthProvider } from "./context/authContext";
import { AppProvider } from "./context/appContext";
import CookieBanner from "./components/common/CookieBanner";
import posthog from 'posthog-js'
import { useLocation } from "react-router-dom";
import { AudioProvider } from "./context/audioContext";

function App() {
  const { pathname } = useLocation();
  const parts = pathname.split('/');
  const callId = parts[3] !== undefined && parts[3];
  const signText = parts[4] !== undefined && parts[4];

  const isLevias = !(pathname != "/authorize" && pathname != "/login/idms" && pathname != `/login/idms/${callId}/${signText}` && pathname != "/register/idms" && pathname != "/password-reset" && pathname != "/change-email" && pathname != "/levias-privacy" && pathname != "levias-terms")

  // not sure isLevias is worked or not, so add this line
  const loginPage = pathname === '/login/idms'
  return (
    <div className="App">
      <AppProvider>
        <Web3authProvider>
          <AuthProvider>
            <AudioProvider>
              <RoutePath />
            </AudioProvider>
          </AuthProvider>
        </Web3authProvider>
        {
          posthog.has_opted_out_capturing() || posthog.has_opted_in_capturing() || isLevias || loginPage
            ? null
            : <CookieBanner isLevias={isLevias} />
        }
      </AppProvider>
    </div>
  )
}

export default App;
