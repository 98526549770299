import React, { useContext, useEffect } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { OnLoading } from '../components/common/OnLoading';
import Home from '../pages/Home'
import NewsList from '../pages/news/newlist'
import GamesList from '../pages/games/gamelist'
import NewsDetail from '../pages/news/newdetail'
import GamesDetail from '../pages/games/gamedetail'
import Auth from '../pages/auth/Auth'
import Privacy from '../pages/Privacy'
import Terms from '../pages/Terms'
import CookiePolicy from '../pages/CookiePolicy';
import Notifier from '../components/common/Notifier'
import ConnectWallet from '../pages/account/ConnectWallet'
import Wallet from '../pages/wallet/Wallet'
import WalletTransactions from '../pages/wallet/WalletTransactions'
import WalletManaAddresses from '../pages/wallet/WalletManaAddresses'
import WalletArcana from '../pages/wallet/WalletArcana'
import WalletToken from '../pages/wallet/WalletToken'
import WalletArcanaDetail from '../pages/wallet/WalletArcanaDetail'
import WalletTokenDetail from '../pages/wallet/WalletTokenDetail'
import WalletTokenAdd from '../pages/wallet/WalletTokenAdd'
import WalletTransactionDetail from '../pages/wallet/WalletTransactionDetail'
import WalletSend from '../pages/wallet/WalletSend'
import WalletSendToken from '../pages/wallet/WalletSendToken'
import WalletSendAddress from '../pages/wallet/WalletSendAddress'
import WalletSendConfirmation from '../pages/wallet/WalletSendConfirmation'
import WalletSendComplete from '../pages/wallet/WalletSendComplete'
import WalletReceive from '../pages/wallet/WalletReceive'
import WalletSupport from '../pages/wallet/WalletSupport'
import WalletSetting from '../pages/wallet/WalletSetting'
import OfferConfirmation from '../pages/wallet/OfferConfirmation'
import Account from '../pages/account/Account'
import AccountDetail from '../pages/account/AccountDetail'
import Language from '../pages/account/Language'
import ChangePassword from '../pages/account/ChangePassword'
import ChangeMobile from '../pages/account/ChangeMobile'
import ChangeEmail from '../pages/account/ChangeEmail'
import DeleteAccount from '../pages/account/DeleteAccount'
import Menu from '../pages/menu/Menu'
import Labs from '../pages/menu/Labs'
import Settings from '../pages/menu/Settings'
import ArcanaGen from '../pages/account/ArcanaGen'
import ContactUs from '../pages/account/ContactUs'
import ArcanaTest from '../pages/iframe/arcana_test'
import TokenList from '../pages/tokens/tokenlist'
import TopicList from '../pages/topics/topiclist'
import TrendList from '../pages/trends/trendlist'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import ChooseRegister from '../pages/auth/ChooseRegister'
import PasswordReset from '../pages/auth/PasswordReset'
import ForgotEmail from '../pages/auth/ForgotEmail'
import LeviasPrivacy from '../pages/LeviasPrivacy'
import LeviasTerms from '../pages/LeviasTerms'
import WalletLevias from '../pages/wallet/WalletLevias';
import QRReaderTest from '../pages/wallet/QRReaderTest';
import CodeD from '../pages/account/CodeD';
import SearchResultList from '../pages/search/SearchResult';
import Squares from '../pages/squares/Squares';
import SquareList from '../pages/squares/SquareList';
import SquareDetail from '../pages/squares/SquareDetail';
import WhatsCodeD from '../pages/account/WhatsCodeD';
import WalletAbsorb from '../pages/wallet/WalletAbsorb';
import WalletAbsorbList from '../pages/wallet/WalletAbsorbList';
import DrawChains from '../pages/drawChains/drawChains';
import DrawChainDetail from '../pages/drawChains/DrawChainDetail';
import DrawChainComparison from '../pages/drawChains/DrawChainComparison';
import withMaintenanceModeCheck from '../hoc/withMaintenanceModeCheck';
import { TokenType } from '../components/common/enum';
import Offer from '../pages/wallet/Offer';
import { appContext } from '../context/appContext';
import { useForceUpdate } from '../services/hook';
import OfferChooseCurrency from '../pages/wallet/OfferChooseCurrency';
import OfferPrice from '../pages/wallet/OfferPrice';
import WalletAnimaCharge from '../pages/wallet/WalletAnimaCharge';
import WalletAnimaChargeSuccess from '../pages/wallet/WalletAnimaChargeSuccess';
import WalletDrawHistory from '../pages/wallet/WalletDrawHistory';
import Games from '../pages/games/games';
import { SquareProfile } from '../pages/squares/SquareProfile';
import GuideAndFAQ from '../pages/guide-and-faq/GuideAndFAQ';
import Levica from '../pages/levica/Levica';
import PersonaCollectionDetail from '../pages/wallet/PersonaCollectionDetail';

const RoutePath = () => {
  const id = new URL(`${window.location}`)?.href.split('/')?.pop()
  const forceUpdate = useForceUpdate();
  const { language } = useContext(appContext);
  useEffect(() => {
    forceUpdate();
  }, [language])
  return (
    <div className="route">
      <Routes>
        <Route element={<CustomRouteWithMaintenanceMode />}>
          <Route path="/" element={<Home />} />
          <Route path="news" element={<NewsList />} />
          <Route path="news_detail/:id" element={<NewsDetail />} />
          <Route path="/games" element={<Navigate to="/products" />} />
          <Route path="/games_detail/:id" element={<Navigate to={id ? `/products_detail/${id}` : `/products`} />} />
          <Route path="products" element={<Games />} />
          <Route path="products/list" element={<GamesList />} />
          <Route path="products_detail/:id" element={<GamesDetail />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="cookies-policy" element={<CookiePolicy />} />
          <Route path="login" element={<Auth />} />
          <Route path="levica" element={<Levica />} />
          <Route path="connect/wallet" element={<ConnectWallet />} />
          <Route path="wallet" element={<Wallet activeItem={'transactions'} />} />
          <Route path="wallet/transactions" element={<WalletTransactions />} />
          <Route path="wallet/mana-addresses" element={<WalletManaAddresses />} />
          <Route path="wallet/arcana" element={<WalletArcana token_type_id={TokenType.Arcana} />} />
          <Route path="wallet/persona" element={<WalletArcana token_type_id={TokenType.Persona} />} />
          <Route path="wallet/levias" element={<WalletLevias />} />
          <Route path="wallet/token" element={<WalletToken />} />
          <Route path="wallet/arcana/detail/:token_id" element={<WalletArcanaDetail token_type_id={TokenType.Arcana} />} />
          <Route path="wallet/persona/detail/:token_id" element={<WalletArcanaDetail token_type_id={TokenType.Persona} />} />
          <Route path="wallet/persona/collection/detail/:id" element={<PersonaCollectionDetail token_type_id={TokenType.Persona} />} />
          <Route path="wallet/token/detail/:token_id" element={<WalletTokenDetail />} />
          <Route path="wallet/token/add" element={<WalletTokenAdd />} />
          <Route path="wallet/arcana/preview/:token_id" element={<WalletArcanaDetail preview token_type_id={TokenType.Arcana} />} />
          <Route path="wallet/persona/preview/:token_id" element={<WalletArcanaDetail preview token_type_id={TokenType.Persona} />} />
          <Route path="wallet/transaction/detail/:id" element={<WalletTransactionDetail />} />
          <Route path="wallet/send" element={<WalletSend />} />
          <Route path="wallet/send/token/:token_type_id/:token_id" element={<WalletSendToken />} />
          <Route path="wallet/choose/token/:token_type_id/:token_id" element={<WalletSendToken type='absorb' />} />
          <Route path="wallet/send/address" element={<WalletSendAddress />} />
          <Route path="wallet/charge/anima/:address/:amount" element={<WalletAnimaCharge />} />
          <Route path="wallet/charge/success" element={<WalletAnimaChargeSuccess />} />
          <Route path="wallet/send/confirmation/:token_type_id/:token_id/:token_name/:to_address/:anima_amount/:token_amount" element={<WalletSendConfirmation />} />
          <Route path="wallet/charge/confirmation/:token_type_id/:token_id/:token_name/:to_address/:anima_amount/:token_amount" element={<WalletSendConfirmation isAnimaCharge />} />
          <Route path="wallet/send/complete" element={<WalletSendComplete />} />
          <Route path="wallet/receive" element={<WalletReceive />} />
          <Route path="wallet/support" element={<WalletSupport />} />
          <Route path="wallet/absorb" element={<WalletAbsorb />} />
          <Route path="wallet/absorb/list/:token_type_id" element={<WalletAbsorbList />} />
          <Route path="wallet/setting" element={<WalletSetting />} />
          <Route path="offer/ask" element={<Offer />} />
          <Route path="offer/bid" element={<Offer />} />
          <Route path="offer/choose/currency" element={<OfferChooseCurrency />} />
          <Route path="offer/ask/price" element={<OfferPrice />} />
          <Route path="offer/bid/price" element={<OfferPrice />} />
          <Route path="wallet/draw-history" element={<WalletDrawHistory />} />
          <Route path="offer/ask/confirmation" element={<OfferConfirmation />} />
          <Route path="offer/bid/confirmation" element={<OfferConfirmation />} />
          <Route path='account' element={<Account />} />
          <Route path='account_detail' element={<AccountDetail />} />
          <Route path='language' element={<Language />} />
          <Route path='change_password' element={<ChangePassword />} />
          <Route path='change_mobile' element={<ChangeMobile />} />
          <Route path='change_email' element={<ChangeEmail />} />
          <Route path='delete_account' element={<DeleteAccount />} />
          <Route path='menu' element={<Menu />} />
          <Route path='labs' element={<Labs />} />
          <Route path='settings' element={<Settings />} />
          <Route path='wallet_setting' element={<WalletSetting />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path="arcana-test" element={<ArcanaTest />} />
          <Route path='loading' element={<OnLoading />} />
          <Route path="tokens/:token_type_id" exact strict element={<TokenList key={'tokenlist_key'} />} />
          <Route path="tokens/:token_type_id/collection" exact strict element={<TokenList key={'tokenlist_collection_key'} isCollection />} />
          <Route path="tokens/:token_type_id/collection/:collection_id" exact strict element={<TokenList key={'tokenlist_collectionlist_key'} />} />
          <Route path="topics" element={<TopicList />} />
          <Route path="trend" element={<TrendList />} />
          <Route path='notifier' element={<Notifier />} />
          <Route path='qrtest' element={<QRReaderTest />} />
          <Route path='code-d' element={<CodeD />} />
          <Route path='code-d/:code_d_id' element={<CodeD />} />
          <Route path='square-list' element={<SquareList />} />
          <Route path='whats-code-d' element={<WhatsCodeD />} />
          <Route path='whats-code-d/:code_d_id' element={<WhatsCodeD />} />
          <Route path='search' element={<SearchResultList />} />
          <Route path='squares' element={<Squares />} />
          <Route path='squares/:square_id' element={<SquareProfile />}>
            <Route index element={<SquareDetail />} />
            <Route path='draw-chains' element={<DrawChains />} />
            <Route path='draw-chains/:draw_chain_id' element={<DrawChainDetail />} />
            <Route path="draw-chains/:draw_chain_id/compare/:persona_id" element={<DrawChainComparison />} />
          </Route>
          <Route path="squares/:square_id/draw-chains/persona/list" element={<WalletAbsorbList type='drawchains' />} />
          <Route path="squares/:square_id/draw-chains/choose/token/:token_type_id/:token_id" element={<WalletSendToken type='drawchains' />} />
          <Route path="guide-and-faq" element={<GuideAndFAQ />} />
        </Route>
        <Route exact element={<ArcanaGenRouteWithMaintenanceMode />}>
          <Route path='arcana-gen' element={<ArcanaGen />} />
          <Route path="arcana-gen/:initManaAddress" element={<ArcanaGen />} />
          <Route path="arcana-gen/:pegg_id/:pseed/:psig/:requestId/:ptoAddress" element={<ArcanaGen />} />
          <Route path="arcana-gen/debug/:pegg_id/:pseed/:psig/:requestId/:ptoAddress" element={<ArcanaGen isDebug />} />
          <Route path="arcana-gen/:pegg_id/:pseed/:psig/:requestId/:ptoAddress/:puser_symbol/:pmanaInfo/:pmanaValue" element={<ArcanaGen />} />
        </Route>
        <Route exact element={<CustomRoute isLeviasLayout />}>
          <Route exact path="authorize" element={<Login />} />
          <Route exact path="login/idms/:callId/:signText" element={<Login />} />
          <Route exact path="login/idms" element={<Login />} />
          <Route exact path="register" element={<ChooseRegister />} />
          <Route exact path="register/idms" element={<Register />} />
          <Route exact path='password-reset' element={<PasswordReset />} />
          <Route exact path='change-email' element={<ForgotEmail />} />
          <Route exact path="levias-privacy" element={<LeviasPrivacy />} />
          <Route exact path="levias-terms" element={<LeviasTerms />} />
        </Route>
      </Routes>
    </div>
  )
}

export default RoutePath

const CustomRoute = (props) => {
  return (
    // <Suspense fallback={<Preloader {...props} isPh={isMobile}></Preloader>}>
    <Outlet />
    // </Suspense>
  )
}

const ArcanaGenRouteWithMaintenanceMode = withMaintenanceModeCheck(CustomRoute, true);

const CustomRouteWithMaintenanceMode = withMaintenanceModeCheck(CustomRoute)


