import { Button } from "../../pages/squares/Button";
import Modal from './Modal'
import { translations } from '../../services/localization';

export const UnsubscribeConfirmation = ({ opened, onClose, onOk }) => {
    const content = translations?.squareDetail;
    const buttonlist = translations.buttonlist;
    return (
        <Modal displayModal={opened} onClose={onClose}>
            <div className="d-flex justify-content-center align-items-center flex-column px-3" style={{ position: 'relative', zIndex: 1000 }}>
                <div className="mt-3 mb-45px text-center fs-20">{
                    translations.formatString(content?.confirmUnsubscribe)
                }</div>
                <Button
                    onClick={onOk}
                    active
                    className="w-100 fs-20 bordor-radius-15"
                    style={{ height: 55, marginBottom: 20 }}
                >
                    {translations.formatString(buttonlist.okBtn)}
                </Button>
                <Button
                    onClick={onClose}
                    className="w-100 fs-20 bordor-radius-15"
                    style={{ height: 55, marginBottom: 20 }}
                >
                    {translations.formatString(buttonlist.cancelBtn)}
                </Button>
            </div>
        </Modal>
    )
}