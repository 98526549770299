import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CopyIcon, KeyIcon } from "../../components/common/Icon";
import { Button } from "../../pages/squares/Button";
import { useClipboard } from '../../hooks/useClipboard';
import Toast from 'react-bootstrap/Toast';

export const ShowKeyModal = ({ show, onClose, value }) => {
    const clipboard = useClipboard();
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        if (clipboard.copied) {
            setShowToast(true);
        }
    }, [clipboard.copied])
    return (
        <Modal centered size="sm" show={show} contentClassName="show-key-modal react-bootstrap-modal-custom" dialogClassName="mx-auto justify-content-center">
            <Modal.Body>
                <div className="d-flex justify-content-center align-items-center flex-column px-3">
                    <KeyIcon lg />
                    <h1 className="fs-20 my-3">Square Address</h1>
                    <div className="w-100 clipboard mb-5 d-flex justify-content-center align-items-center" style={{ gap: 15 }}>
                        <div className="fs-16">
                            {value}
                        </div>
                        <div style={{ width: 22, height: 22, marginLeft: 'auto' }}><CopyIcon style={{ marginBottom: 3 }} onClick={() => clipboard.copy(value)} /></div>
                    </div>
                    <Toast
                        onClose={() => setShowToast(false)}
                        autohide
                        delay={1000}
                        show={showToast}
                        bg="dark"
                        className="position-absolute"
                        style={{ left: 0, bottom: '-100px' }}>
                        <Toast.Body className="text-white">Copied to clipboard</Toast.Body>
                    </Toast>
                    <Button
                        onClick={onClose}
                        active
                        className="w-100 fs-25 bordor-radius-15"
                        style={{ height: 55, marginBottom: 56 }}
                    >
                        OK
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};