import React, { createContext, useEffect, useMemo, useState } from "react";
import song from "../assets/audio/music.mp3";
import song2 from "../assets/audio/music2.mp3";
import song3 from "../assets/audio/music3.mp3";
import { getCookie, getLastPlayedAudio, setCookie, setLastPlayedAudio } from "../services/storage";
import { useDidUpdateEffect } from "../services/hook";
import { useMediaQuery } from 'react-responsive'

export const audioContext = createContext(); // could have a default value

export const AudioProvider = ({ children }) => {
    const [audioList] = useState([song, song2, song3]);
    // const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    const [audio, setAudio] = useState(undefined);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [audioInit, setAudioInit] = useState(false);
    const isPC = useMediaQuery({ minWidth: 992 })

    useEffect(() => {
        async function fetchData() {
            const cookieAccept = await getCookie('on-off-music')
            handleMusicOnOff(cookieAccept === 'true' && isPC)
        }

        if (audio && !audioInit) {
            fetchData();
        }
    }, [audio])

    useDidUpdateEffect(() => {
        if (!isMuted) {
            if (audio) {
                startAudioPlayer(audio);
            } else {
                setRandomAudio();
            }
        } else if (!audio) {
            setIsPlaying(false);
        }
    }, [audio, isMuted])

    useDidUpdateEffect(() => {
        if (!isMuted && !isPlaying) {
            setRandomAudio();
        }
    }, [isPlaying])

    const startAudioPlayer = async (audio) => {
        audio.muted = audioInit ? isMuted : true;
        audio.onended = handleAudioEnded;
        let isError = false;
        await audio.play()
            .catch((e) => {
                isError = true;
                audio.muted = true;
                setIsMuted(true)
                console.log('startAudioPlayer error', e);
                setCookie('on-off-music', false);
            });
        if (!isError) {
            setIsPlaying(true);
        }
    }

    const setRandomAudio = () => {
        let lastPlayedAudio = JSON.parse(getLastPlayedAudio());
        if (!lastPlayedAudio) {
            setLastPlayedAudio(0)
            lastPlayedAudio = 0
        }

        let randomIndex;
        do {
            randomIndex = Math.floor(Math.random() * audioList.length);
        } while (randomIndex === lastPlayedAudio);

        const randomAudio = new Audio(audioList[randomIndex]);
        setLastPlayedAudio(randomIndex)
        setAudio(randomAudio);
    }

    const handleAudioEnded = () => {
        setRandomAudio();
    }

    const handlePlay = () => {
        if (audio) {
            audio.muted = false;
        }
        setIsMuted(false);
    };
    const handleStopAll = () => {
        if (audio) {
            audio.muted = true;
        }
        setIsMuted(true);
    };

    const handleMusicOnOff = (e) => {
        setIsMuted(!e);
        setCookie('on-off-music', e)
        e ? handlePlay() : handleStopAll()
        setAudioInit(true);
    }
    const value = useMemo(() => ({
        audio, setAudio, isMuted, setIsMuted,
        handlePlay, handleStopAll, handleMusicOnOff
    }), [audio, isMuted]);

    return (
        <audioContext.Provider value={value}>
            {children}
        </audioContext.Provider>
    )
}
